import { closestCenter, DndContext, DragEndEvent, UniqueIdentifier } from "@dnd-kit/core"
import { arrayMove, SortableContext } from "@dnd-kit/sortable"
import AddIcon from "@mui/icons-material/Add"
import { Box, CircularProgress, Grid } from "@mui/material"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import { CustomButton } from "../../../../components/buttons/CustomButton"
import CustomDialog from "../../../../components/CustomDialog"
import { ProjectMenuTitleTypography } from "../../../../components/customTypography"
import { StyledModal } from "../../../../components/StyledModal"
import { TBOX_MODE } from "../../../../constants"
import { projectQueries, queryClient } from "../../../../networking/networking"
import { useOperatorStore, useSnackbarStore, useUserStore } from "../../../../stateManagement"
import { Product } from "../../../../types/genericTypes"
import { userCanEditProject } from "../../../../utils"
import { ConfigureProductGeneralForm } from "./configureProduct/ConfigureProductGeneralForm"
import { ConfigureProductPhasesForm } from "./configureProduct/ConfigureProductPhasesForm"
import { ConfigureProductQuestionsForm } from "./configureProduct/ConfigureProductQuestionsForm"
import ProductCard from "./ProductCard"


export interface editModalModel {
	isOpen: boolean,
	product?: Product
}
interface DeleteModalState {
	open: boolean,
	product?: Product
}

interface ModalTextType {
	title: string | React.ReactElement,
	subtitle: string
}

const ConfigureProjectProducts = () => {
	const [isDeleteActionModalOpen, setIsDeleteActionModalOpen] = useState<DeleteModalState>({ open: false })
	const [isConfigureProductModalOpen, setIsConfigureProductModalOpen] = useState<editModalModel>({ isOpen: false })
	const [sortedProductsIDs, setSortedProductsIDs] = useState<UniqueIdentifier[]>([])
	const isUserOperator = useOperatorStore().isUserOperator
	const userRole = useUserStore((state) => state.userRole)

	const { project_id = "" } = useParams()
	const [modalText, setModalText] = useState<ModalTextType>({
		title: "",
		subtitle: ""
	})
	const [formPhase, setFormPhase] = useState<1 | 2 | 3>(1)

	const { data: project } = useQuery(
		[projectQueries.getProject.name, parseInt(project_id)],
		() => projectQueries.getProject.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id)
		}
	)

	const userCanEdit = project ? userCanEditProject(project, userRole) : false

	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)

	const navigate = useNavigate()

	const { data: productsList = [], isFetching: isProductsListFetching, refetch: refetchProductsList } = useQuery(
		[projectQueries.getProducts.name, parseInt(project_id)],
		() => projectQueries.getProducts.fn(parseInt(project_id)),
		{
			select: data => _.orderBy(data, ["order"], ["asc"]),
			onSuccess: (data) => {
				setSortedProductsIDs(data.map((product) => product.id))
				if (isConfigureProductModalOpen.product) {
					setIsConfigureProductModalOpen(state => ({
						...state,
						product: data.find(p => p.id === state.product?.id)
					}))
				}
			}
		}
	)

	const updateProductMutation = useMutation(
		projectQueries.updateProduct.name,
		projectQueries.updateProduct.fn,
		{
			onSuccess: () => {
				refetchProductsList()
			},
			onError: (e) => {
				console.log("update error", e)
				openSnackbar("error", "Error updating products order")
			}
		}
	)

	const deleteProductMutation = useMutation(
		projectQueries.deleteProduct.name,
		projectQueries.deleteProduct.fn,
		{
			onSuccess: () => {
				refetchProductsList()
				queryClient.invalidateQueries([projectQueries.getProject.name, parseInt(project_id)])
			},
			onError: (e) => {
				console.log("delete error", e)
				openSnackbar("error", "Error deleting product")
			}
		}
	)

	useEffect(() => {
		switch (formPhase) {
			case 1:
				setModalText({
					title: <ProjectMenuTitleTypography>Configure product</ProjectMenuTitleTypography>,
					subtitle: "Fields with * are required"
				})

				break
			case 2:
				setModalText({
					title: <ProjectMenuTitleTypography>Insert phases for <ProjectMenuTitleTypography component="span" color="primary">{isConfigureProductModalOpen.product?.product_id}</ProjectMenuTitleTypography></ProjectMenuTitleTypography>,
					subtitle: ""
				})
				break
			case 3:
				setModalText({
					title: <ProjectMenuTitleTypography>Survey list for <ProjectMenuTitleTypography component="span" color="primary">{isConfigureProductModalOpen.product?.product_id}</ProjectMenuTitleTypography></ProjectMenuTitleTypography>,
					subtitle: ""
				})
				break
			default:
				break
		}
	}, [formPhase])

	useEffect(() => {
		if (!isProductsListFetching && sortedProductsIDs.length === productsList.length) {
			productsList.forEach((product, index) => {
				if (sortedProductsIDs[index] === product.id) return
				const newOrder = sortedProductsIDs.indexOf(product.id) + 1
				console.log("moved product with id ", product.id, "from position", index + 1, "to position", newOrder)
				updateProductMutation.mutate({
					project_id: parseInt(project_id),
					product_id: product.id,
					product: {
						order: newOrder,
						random_order: product.random_order
					}
				})
			})
		}
	}, [sortedProductsIDs])

	const handleNewProductModalClose = () => {
		setFormPhase(1)
		setIsConfigureProductModalOpen({ isOpen: false })
	}

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event
		if (over) {
			if (active.id !== over.id) {
				setSortedProductsIDs(ids => {
					const activeIndex = ids.indexOf(active.id)
					const overIndex = ids.indexOf(over.id)
					return arrayMove(ids, activeIndex, overIndex)
				})
			}
		}
	}

	if (isProductsListFetching) {
		return <CircularProgress />
	}

	return (
		<>
			<Grid container spacing={2}>
				<Grid item container justifyContent="flex-end" xs={12}>
					<Grid item >
						{!TBOX_MODE && userCanEdit &&
							<CustomButton
								primary
								text="New Product"
								icon={<AddIcon sx={{ color: "white" }} />}
								action={() => setIsConfigureProductModalOpen({ isOpen: true })}
							/>}
					</Grid>
				</Grid>
				<Grid item container spacing={2}>
					<DndContext
						collisionDetection={closestCenter}
						onDragEnd={handleDragEnd}
					>
						<SortableContext items={sortedProductsIDs}>
							{_.sortBy(productsList, item => sortedProductsIDs.indexOf(item.id)).map((product) =>
								<Grid item key={product.id}>
									<ProductCard
										project={project}
										userCanEdit={userCanEdit}
										isUserOperator={isUserOperator}
										disableDrag={isProductsListFetching || updateProductMutation.isLoading || !userCanEdit}
										product={product}
										onEdit={() => setIsConfigureProductModalOpen({
											isOpen: true,
											product: product
										})}
										onDelete={
											// isUserOperator ? undefined :
											userCanEdit ?
												() => setIsDeleteActionModalOpen({
													open: true,
													product
												}) : undefined
										}
									/>
								</Grid>
							)}
							{productsList.length === 0 && (
								<p>No products presents</p>
							)}
						</SortableContext>
					</DndContext>
				</Grid>
				<Grid item container justifyContent="flex-end">
					<Grid item>
						<CustomButton primary text="Next" action={() => navigate(`/projects/${project_id}/testers`)} disabled={updateProductMutation.isLoading} />
					</Grid>
				</Grid>
			</Grid>

			{/* CONFIGURE MODAL */}
			<CustomDialog
				open={isConfigureProductModalOpen.isOpen}
				onClose={handleNewProductModalClose}
				title={modalText.title}
				subtitle={modalText.subtitle}
				dialogProps={{
					fullScreen: true,
					open: isConfigureProductModalOpen.isOpen
				}}
			>
				<Box justifyContent="center" paddingX={10}>
					{
						formPhase === 1 ?
							<ConfigureProductGeneralForm setFormPhase={setFormPhase} order={productsList.length + 1} product={isConfigureProductModalOpen.product} setProduct={setIsConfigureProductModalOpen} productsList={productsList} userCanEdit={userCanEdit} />
							: (formPhase === 2 && isConfigureProductModalOpen.product) ?
								<ConfigureProductPhasesForm setFormPhase={setFormPhase} product={isConfigureProductModalOpen.product} project={project} userCanEdit={userCanEdit} />
								: (formPhase === 3 && isConfigureProductModalOpen.product && project) ?
									<ConfigureProductQuestionsForm setFormPhase={setFormPhase} closeModal={handleNewProductModalClose} product={isConfigureProductModalOpen.product} project={project} editMode={isConfigureProductModalOpen} userCanEdit={userCanEdit} />
									: null
					}
				</Box>
			</CustomDialog>

			{/* delete modal */}
			<StyledModal
				isOpen={isDeleteActionModalOpen.open}
				handleClose={() => { setIsDeleteActionModalOpen({ open: false }) }}
				title={`Do you want to delete "${isDeleteActionModalOpen.product?.product_id}"?`}
				subtitle="Warning: This action cannot be undone!"
				rightButton={
					{
						name: "Yes, I'm sure!",
						action: () => {
							if (isDeleteActionModalOpen.product) {
								deleteProductMutation.mutate({
									project_id: parseInt(project_id),
									product_id: isDeleteActionModalOpen.product.id
								})
								setIsDeleteActionModalOpen({ open: false })
							}
						}
					}
				}
				leftButton={
					{
						name: "Cancel",
						action: () => setIsDeleteActionModalOpen({ open: false })
					}
				}
			/>
		</>
	)
}

export default ConfigureProjectProducts
