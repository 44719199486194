import TableChartIcon from "@mui/icons-material/TableChart"
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import _ from "lodash"
import React, { useState } from "react"
import { UseMutationResult } from "react-query"

import { CustomButton } from "../../components/buttons/CustomButton"
import { DashboardCardParagraphTypography, DashboardCardParagraphTypographyBold, TitleTypography } from "../../components/customTypography"
import { editProjectParams } from "../../networking/networking"
import {long_data_item, Product, Project, ProjectTester} from "../../types/genericTypes"


interface props {
	testers?: ProjectTester[]
	onSaveTestersNotes?: UseMutationResult<Project, unknown, editProjectParams, unknown>
	notesAverage?: string | undefined
	pdfMode?: boolean,
	data: long_data_item[] | undefined
	productsList: Product[]
}

export const PADashboardTable = (props: props) => {
	const [averageNotes, setAverageNotes] = useState(props.notesAverage || "")
	// const [chartData, setChartData] = useState<chartData[]>()

	const handleTestersNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setAverageNotes(e.target.value)
	}

	const handleSaveTestersNotes = () => {
		props.onSaveTestersNotes?.mutate({ tester_notes: averageNotes === "" ? null : averageNotes })
	}

	const product_ids = _.uniq(props.data?.map(d => d.product_id))
	const phases_names = _.uniq(props.data?.map(d => d.phase_code))

	const columns = ["MT14", "MT23", "MT39", "MT45"]
	const ColumnsTranslation = {
		MT14: "CW",
		MT23: "R",
		MT39: "E",
		MT45: "FA"
	}
	const results: { [k: string]: { [j: string]: { [h: string]: number } } } = {}

	for (const product_id of product_ids) {
		for (const phase_name of phases_names) {
			const filteredListProduct = props.data?.filter(product => product.product_id === product_id && product.phase_code === phase_name)
			for (const column of columns) {
				const avgValue = _.meanBy(filteredListProduct, column)
				_.set(results, [product_id, phase_name, column], avgValue)
			}
		}
	}

	return (
		<Grid
			container
			sx={{
				padding: 3,
				paddingTop: 0
			}}
		>
			{/* CHART */}
			<Grid item container xs={12} md={6} >
				<Grid
					item
					container
					xs={12}
					sx={{
						maxHeight: "25px",
						marginBottom: 2
					}}
				>
					<TableChartIcon color="primary" sx={{ marginRight: 1 }} />
					<TitleTypography sx={{ marginRight: 1 }}>Results</TitleTypography>
				</Grid>
				<Grid
					item
					container
					xs={12}
					sx={{
						paddingRight: 2
					}}
				>
					<TableContainer
						component={Paper}
						sx={{
							maxWidth: 725,
							maxHeight: props.pdfMode ? undefined : 330,
						}}
					>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell align="center">Product</TableCell>
									<TableCell align="center">Phase</TableCell>
									{_.map(ColumnsTranslation, columns =>
										<TableCell key={columns} align="center"> {columns}</TableCell>
									)}
								</TableRow>
							</TableHead>
							<TableBody>
								{
									product_ids.map(product_id =>
										phases_names.map(phase_name =>
											<TableRow key={product_id + phase_name}>
												<TableCell align="center">{props.productsList.find(product => product.product_id.toLowerCase() === product_id.toLowerCase())?.product_id}</TableCell>
												<TableCell align="center">{props.productsList.find(product => product.product_id.toLowerCase() === product_id.toLowerCase())?.phases.find(phase => phase.marker_code === phase_name)?.name}</TableCell>
												{columns.map(column =>
													<TableCell key={column} align="center">{_.get(results, [product_id, phase_name, column]).toFixed(2)}</TableCell>
												)}
											</TableRow>
										)
									)
								}
							</TableBody>
						</Table>
					</TableContainer>
					{/* legenda */}
					<Grid item container sx={{ marginTop: 2 }}>
						<Grid item container>
							<DashboardCardParagraphTypographyBold sx={{ marginRight: 0.5 }}>CW:</DashboardCardParagraphTypographyBold>
							<DashboardCardParagraphTypography sx={{ marginRight: 2 }}>Cognitive Workload</DashboardCardParagraphTypography>

							<DashboardCardParagraphTypographyBold sx={{ marginRight: 0.5 }}>R:</DashboardCardParagraphTypographyBold>
							<DashboardCardParagraphTypography sx={{ marginRight: 2 }}>Relax</DashboardCardParagraphTypography>

							<DashboardCardParagraphTypographyBold sx={{ marginRight: 0.5 }}>E:</DashboardCardParagraphTypographyBold>
							<DashboardCardParagraphTypography sx={{ marginRight: 2 }}>Engagement</DashboardCardParagraphTypography>

							<DashboardCardParagraphTypographyBold sx={{ marginRight: 0.5 }}>FA:</DashboardCardParagraphTypographyBold>
							<DashboardCardParagraphTypography sx={{ marginRight: 2 }}>Frontal Asymmetry</DashboardCardParagraphTypography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{/* NOTES */}
			<Grid item xs={12} md={6}>
				<Grid container>
					<Grid item xs={12} sx={{ marginBottom: 2 }}>
						<TitleTypography>Notes</TitleTypography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							sx={{ minWidth: "100%" }}
							id="filled-multiline-static"
							multiline
							rows={13}
							value={averageNotes}
							placeholder="Write any notes here (thoughts, suggestions, observations, etc.)"
							variant="filled"
							onChange={handleTestersNotesChange}
						/>
					</Grid>
				</Grid>
			</Grid>
			{!props.pdfMode &&
				<Grid container justifyContent="flex-end" sx={{ marginTop: 2 }}>
					<Grid item>
						<CustomButton text="Save" action={handleSaveTestersNotes} primary />
					</Grid>
				</Grid>
			}
		</Grid >
	)
}
