import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { Chip, IconButton } from "@mui/material"
import { styled } from "@mui/material/styles"
import _ from "lodash"
import { ChangeEvent } from "react"

import { useSnackbarStore } from "../../../../../stateManagement"
import { File as BEFile } from "../../../../../types/genericTypes"



const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`

interface props {
	disabled?: boolean
	file: File | BEFile | undefined
	setFile: (file: File | undefined) => void
}
export default function InputFileUpload(props: props) {

	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)

	const handleFileUpload = (event: ChangeEvent<HTMLInputElement>): void => {
		const fileInput = event.target

		if (fileInput.files) {
			const selectedFile = fileInput.files[0]
			console.log(selectedFile.type)
			if (["image/jpeg", "image/png", "image/jpg"].includes(selectedFile.type)) {
				props.setFile(selectedFile)
			} else {
				openSnackbar("error", "Only .jpeg, .png or .jpg files accepted")
			}
		}
	}

	const handleFileDelete = () => {
		props.setFile(undefined)
	}

	const truncate = (filename: string) => {
		const splittedFilename = filename.split(".")
		const extension = splittedFilename.pop()
		return _.truncate(splittedFilename.join("."), { length: 10 }) + "." + extension
	}

	return (
		<>
			{!props.file ?
				<IconButton
					aria-label="upload"
					LinkComponent="label"
					href="#file-upload"
					disabled={props.disabled}
				>
					<VisuallyHiddenInput type="file" onChange={handleFileUpload} />
					<CloudUploadIcon color={props.disabled ? "disabled" : "primary"} />
				</IconButton >
				:
				<Chip
					label={truncate(_.get(props.file, "name", _.get(props.file, "filename", "N/D")))}
					sx={{
						backgroundColor: "white",
						border: "1px solid #cbcbcb"
					}}
					onDelete={props.disabled ? undefined : handleFileDelete}
				/>
			}
		</>
	)
}