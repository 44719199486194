import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined"
import { Box, Button, Grid, Modal, styled, SvgIconProps } from "@mui/material"
import { PropsWithChildren } from "react"

import { ProjectTester } from "../types/genericTypes"
import { DashboardCardTitleTypography, H1Typography } from "./customTypography"
interface Button {
	name: string;
	icon?: React.ReactElement<SvgIconProps>
	disabled?: boolean
	action: () => void;
}



interface props {
	isOpen: boolean,
	handleClose?: () => void,
	title: string,
	subtitle?: string,
	projectTesters?: ProjectTester[],
	leftButton?: Button,
	rightButton?: Button
}
//------- style ---------
const modalStyle = {
	position: "absolute" as const,
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 800,
	minHeight: 250,
	bgcolor: "background.paper",
	boxShadow: 24,
	padding: 4,
}
const StyledButton = styled(Button)(() => ({
	width: "150px",
	height: "45px",
	backgroundColor: "black",
	color: "white",
	boxShadow: "none",
	border: "1px solid black",
	cursor: "pointer",
	"&:hover": {
		backgroundColor: "black",
		color: "white"
	}
})) as typeof Button

//---------------------

export const StyledModal = (props: PropsWithChildren<props>) => {
	return (
		<Modal
			open={props.isOpen}
			onClose={props.handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			sx={{ overflow: "scroll" }}
		>
			<Box sx={modalStyle}>
				<Grid sx={{ minHeight: "100%" }} container direction="column" alignItems="center">
					{props.handleClose &&
						<Grid item alignSelf="flex-end" sx={{ marginBottom: 2 }}>
							<HighlightOffOutlinedIcon sx={{ cursor: "pointer" }} onClick={props.handleClose} />
						</Grid>
					}
					<Grid container item direction="column" alignItems="center" marginBottom={props.children ? undefined : 4} marginTop={props.handleClose ? undefined : 6}>
						<H1Typography sx={{ marginBottom: "10px" }}>{props.title}</H1Typography>
						{props.subtitle &&
							<DashboardCardTitleTypography color="text.secondary">{props.subtitle}</DashboardCardTitleTypography>
						}
					</Grid>
					<Grid
						container
						justifyContent="center"
					>
						{props.children}
					</Grid>
					<Grid container item justifyContent="center" spacing={3}>
						{props.leftButton &&
							<Grid item >
								<StyledButton
									sx={{
										backgroundColor: "white",
										color: "black",
										"&:hover": {
											backgroundColor: "white",
											color: "black"
										}
									}}
									size="small"
									onClick={props.leftButton.action}
									startIcon={props.leftButton.icon || undefined}
									variant="contained"
								>
									{props.leftButton.name}
								</StyledButton>
							</Grid>
						}
						{props.rightButton &&
							<Grid item >
								<StyledButton
									size="small"
									onClick={props.rightButton.action}
									startIcon={props.rightButton.icon || undefined}
									variant="contained"
									disabled={props.rightButton.disabled}
								>
									{props.rightButton.name}
								</StyledButton>
							</Grid>
						}
					</Grid>
				</Grid>
			</Box>
		</Modal >
	)
}
