import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import { Checkbox, Grid, InputAdornment, SvgIconProps, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { Control, Controller, UseFormRegister, useWatch } from "react-hook-form"

import { ParagraphTypography } from "../../../components/customTypography"
import { Phase } from "../../../types/genericTypes"
import { NewTemplateFormInput } from "../Templates"


interface props {
	icon: React.ReactElement<SvgIconProps>,
	phase: Phase,
	control: Control<NewTemplateFormInput>,
	register: UseFormRegister<NewTemplateFormInput>,
	setIsIncomplete: React.Dispatch<React.SetStateAction<boolean>>
}


const dragIconStyle = {
	color: "text.secondary",
	cursor: "grab",
	"&:active": {
		cursor: "grabbing",

	},
	"&:focus": {
		outline: "none"
	}
}

export const TemplatePhaseRow = (props: props) => {

	const [isError, setIsError] = useState(false)

	const isSelected = useWatch({
		control: props.control,
		name: `phases.${props.phase.id}.selected`
	})

	const phaseDuration = useWatch({
		control: props.control,
		name: `phases.${props.phase.id}.duration`
	})
	const otherPhaseName = useWatch({
		control: props.control,
		name: `phases.${props.phase.id}.name`
	})

	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: props.phase.id,
		disabled: !isSelected,
	})
	const draggableStyle = {
		transform: CSS.Transform.toString(transform),
		transition
	}

	useEffect(() => {
		if (phaseDuration < 6) {
			setIsError(true)
		} else if (phaseDuration >= 6) {
			setIsError(false)
		}

	}, [phaseDuration])

	return (
		<Grid container direction="column" spacing={2}>
			<Grid ref={setNodeRef} style={draggableStyle} >
				<Grid container>
					<Grid item container alignItems="center" xs={8}>
						<DragIndicatorIcon
							sx={dragIconStyle}
							{...attributes}
							{...listeners}
						/>
						<Controller
							control={props.control}
							name={`phases.${props.phase.id}.selected`}
							render={({ field }) => <Checkbox {...field} checked={!!field.value} />}
						/>

						<input type="hidden" {...props.register(`phases.${props.phase.id}.id`, { value: props.phase.id })} />

						{props.icon}
						{props.phase.id > 8 ?
							<TextField
								placeholder="Other phase name"
								{...props.register(`phases.${props.phase.id}.name`)}
								disabled={!isSelected}
								error={otherPhaseName?.length === 0}
								sx={!isSelected ? {
									backgroundColor: "#f1f1f1",
									marginLeft: 2
								} : { marginLeft: 2 }}
							/> :
							<ParagraphTypography sx={{ marginLeft: 2 }} {...props.register(`phases.${props.phase.id}.name`, { value: props.phase.name })}>
								{props.phase.name}
							</ParagraphTypography>
						}
					</Grid>
					<Grid item container alignItems="center" xs={4}>
						<TextField
							{...props.register(`phases.${props.phase.id}.duration`, { valueAsNumber: true })}
							type="number"
							error={isError}
							fullWidth
							disabled={!isSelected}
							sx={!isSelected ? { backgroundColor: "#f1f1f1" } : undefined}
							inputProps={{
								style: { textAlign: "center" },
								min: 6
							}}
							InputProps={{
								endAdornment: <InputAdornment position="end">s</InputAdornment>,
							}}
						/>
					</Grid>
				</Grid>
			</Grid >
		</Grid>
	)
}
