import { UniqueIdentifier } from "@dnd-kit/core"
import { AddOutlined as AddOutlinedIcon } from "@mui/icons-material"
import { CircularProgress, Grid } from "@mui/material"
import { AxiosError } from "axios"
import _ from "lodash"
import { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"

import { CustomButton } from "../../components/buttons/CustomButton"
import SubmitButton from "../../components/buttons/submitButton"
import CustomDialog from "../../components/CustomDialog"
import { ParagraphTypography } from "../../components/customTypography"
import { StyledModal } from "../../components/StyledModal"
import {Toolbar, ToolbarConfig} from "../../components/Toolbar"
import { questionsQueries, templatesQueries } from "../../networking/networking"
import { useSnackbarStore } from "../../stateManagement"
import { Template } from "../../types/genericTypes"
import { sortByCreationDate } from "../../utils"
import { TemplateCard } from "../projects/configure/template/components/TemplateCard"
import { TemplateFormGeneral } from "./components/TemplateFormGeneral"
import { TemplateFormPhases } from "./components/TemplateFormPhases"
import { TemplateFormQuestions } from "./components/TemplateFormQuestions"
export interface NewTemplateFormInput {
	name: string,
	description: string,
	phases: {
		[id: string]: {
			id: number
			duration: number,
			selected?: boolean,
			name: string,
			order: number
		}
	},
	questions: {
		[id: string]: {
			selected?: boolean
			id: number
			order: number
		}
	}
}

export const Templates = () => {

	const [isConfigureTemplateModalOpen, setisConfigureTemplateModalOpen] = useState(false)
	const [isDeleteTemplateModalOpen, setisDeleteTemplateModalOpen] = useState(false)
	const [templateToEdit, setTemplateToEdit] = useState<Template>()
	const [templateToDelete, setTemplateToDelete] = useState<Template>()
	const [formStep, setFormStep] = useState<1 | 2 | 3>(1)
	const [sortedPhasesIDs, setSortedPhasesIDs] = useState<UniqueIdentifier[]>([])
	const [sortedQuestionsIDs, setSortedQuestionsIDs] = useState<UniqueIdentifier[]>([])
	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)


	const toolbarConfig: ToolbarConfig = {
		title: "Templates",
		subtitle: "Manage templates from here",
		buttons: [
			{
				name: "New Template",
				icon: <AddOutlinedIcon />,
				action: () => setisConfigureTemplateModalOpen(true),
			}
		]
	}

	const { data: templates = [], isFetching: isTemplatesFetching, refetch: refetchTemplates } = useQuery(
		templatesQueries.listTemplates.name,
		templatesQueries.listTemplates.fn,
		{
			select: templates => _.orderBy(templates, ["created_at"], ["desc"])
		}
	)

	const { data: questions = [] } = useQuery(
		questionsQueries.listQuestions.name,
		questionsQueries.listQuestions.fn,
	)

	const createTemplateMutation = useMutation(
		templatesQueries.createTemplate.name,
		templatesQueries.createTemplate.fn,
		{
			onSuccess: () => {
				refetchTemplates()
				openSnackbar("success", "Template created successfully")
			},
			onError: (error: AxiosError) => {
				if (error.response?.status === 400) {
					openSnackbar("error", error.response?.data as string)
				} else {
					openSnackbar("error", "An error occurred while creating the Template")
				}
			}
		}
	)

	const updateTemplateMutation = useMutation(
		templatesQueries.updateTemplate.name,
		templatesQueries.updateTemplate.fn,
		{
			onSuccess: () => {
				refetchTemplates()
				openSnackbar("success", "Template edited successfully")
			},
			onError: () => {
				openSnackbar("error", "An error occurred while editing the Template")
			}
		}
	)

	const deleteTemplateMutation = useMutation(
		templatesQueries.deleteTemplate.name,
		templatesQueries.deleteTemplate.fn,
		{
			onSuccess: () => {
				refetchTemplates()
				openSnackbar("success", "Template deleted successfully")
			},
			onError: (error: AxiosError<{ message: string }>) => {
				if (error.response?.status === 400) {
					openSnackbar("error", error.response?.data.message)
				} else {
					openSnackbar("error", "An error occurred while deleting the Template")
				}
			}
		}
	)

	const {
		register: registerNewTemplate,
		handleSubmit: handleSubmitNewTemplate,
		getValues: getTemplateFormValues,
		control: newTemplateControl,
		watch: newTemplateWatch,
		reset: newTemplatereset,
	} = useForm<NewTemplateFormInput>({
		defaultValues: {
			name: "",
			description: "",
			phases: {},
			questions: questions.reduce(
				(accumulator, currentValue) => {
					return {
						...accumulator,
						[currentValue.id]: {
							selected: true
						}
					}
				},
				{}
			),
		}
	})

	const handleEdit = (id: number) => {
		const selectedTemplate = _.find(templates, { "id": id })
		setTemplateToEdit(selectedTemplate)
		setisConfigureTemplateModalOpen(true)
	}

	const handleDeleteDialogOpen = (id: number) => {
		const selectedTemplate = _.find(templates, { "id": id })
		setTemplateToDelete(selectedTemplate)
		setisDeleteTemplateModalOpen(true)
	}

	const handleDelete = () => {
		if (templateToDelete) {
			deleteTemplateMutation.mutate(templateToDelete.id)
		}
		setTemplateToDelete(undefined)
		setisDeleteTemplateModalOpen(false)
	}

	const handleConfigureDialogClose = () => {
		setTemplateToEdit(undefined)
		newTemplatereset()
		setFormStep(1)
		setisConfigureTemplateModalOpen(false)
	}

	const handleDeleteDialogClose = () => {
		setTemplateToDelete(undefined)
		setisDeleteTemplateModalOpen(false)
	}

	useEffect(() => {
		if (templateToEdit) {
			if (templateToEdit.phases && templateToEdit.questions) {
				const temporaryPhases: {
					order: number
					id: number
					duration: number
					selected?: boolean
					name: string
				}[] = []

				const temporaryQuestions: {
					selected: boolean
					id: number
					order: number
				}[] = []

				templateToEdit.phases.map((phase) => {
					const temporaryPhase = {
						id: phase.phase.id,
						duration: phase.duration,
						selected: true,
						name: phase.name,
						order: phase.order
					}
					temporaryPhases.push(temporaryPhase)
				})

				templateToEdit.questions.map((question) => {
					const temporaryQuestion = {
						id: question.question_id,
						selected: true,
						order: question.order
					}
					temporaryQuestions.push(temporaryQuestion)
				})

				const finalPhases = temporaryPhases.reduce(
					(accumulator, currentValue) => {
						return {
							...accumulator,
							[currentValue.id]: {
								id: currentValue.id,
								duration: currentValue.duration,
								selected: true,
								name: currentValue.name,
								order: currentValue.order
							}
						}
					},
					{}
				)

				const finalQuestions = temporaryQuestions.reduce(
					(accumulator, currentValue) => {
						return {
							...accumulator,
							[currentValue.id]: {
								selected: true,
								id: currentValue.id,
								order: currentValue.order
							}
						}
					},
					{}
				)

				newTemplatereset({
					name: templateToEdit.name,
					description: templateToEdit.description,
					phases: finalPhases,
					questions: finalQuestions
				})
			}
		} else {
			newTemplatereset({
				name: "",
				description: "",
				phases: {},
				questions: questions.reduce(
					(accumulator, currentValue) => {
						return {
							...accumulator,
							[currentValue.id]: {
								selected: true
							}
						}
					},
					{}
				),
			})
		}
	}, [templateToEdit])

	const onNewTemplateSubmit: SubmitHandler<NewTemplateFormInput> = (data) => {

		const unorderedPhases = _.filter(Object.values(data.phases), { "selected": true }).map((phase) => {
			delete phase.selected
			return (phase)
		})

		const unorderedQuestions = _.filter(Object.values(data.questions), { "selected": true }).map((question) => {
			delete question.selected
			return (question)
		})

		const selectedPhases: {
			order: number
			phase_id: number
			duration: number
			selected?: boolean
			template_phase_name: string
		}[] = []

		const selectedQuestions: {
			order: number
			question_id: number
		}[] = []

		sortedPhasesIDs.map((id, index) => {
			const temporaryPhase = unorderedPhases.find(phase => phase.id === id)
			if (temporaryPhase) {
				const orderedPhase = {
					phase_id: temporaryPhase.id,
					template_phase_name: temporaryPhase.name,
					duration: temporaryPhase.duration,
					order: Number(index)
				}
				selectedPhases.push(orderedPhase)
			}
		})

		const finalPhases = selectedPhases.map((phase, index) => {
			return ({
				...phase,
				order: index + 1
			})
		})

		sortedQuestionsIDs.map((id, index) => {
			const temporaryQuestion = unorderedQuestions.find(question => question.id === id)
			if (temporaryQuestion) {
				const orderedQuestion = {
					question_id: temporaryQuestion.id,
					order: Number(index)
				}
				selectedQuestions.push(orderedQuestion)
			}
		})

		const finalQuestions = selectedQuestions.map((question, index) => {
			return ({
				...question,
				order: index + 1
			})
		})

		if (templateToEdit) {
			updateTemplateMutation.mutate({
				id: templateToEdit.id,
				name: data.name,
				description: data.description,
				phases: finalPhases,
				questions: finalQuestions
			})
		} else {
			createTemplateMutation.mutate({
				name: data.name,
				description: data.description,
				phases: finalPhases,
				questions: finalQuestions
			})
		}
		newTemplatereset()
		setFormStep(1)
		setisConfigureTemplateModalOpen(false)

	}
	return (
		<Grid container padding={3}>
			<Toolbar config={toolbarConfig} />
			<Grid item container gap={2}>
				{
					isTemplatesFetching ?
						<Grid item container justifyContent="center">
							<CircularProgress />
						</Grid>
						: templates.length > 0 ?
							sortByCreationDate(templates).map((template) =>
								<TemplateCard
									key={template.id}
									template={template}
									onDelete={() => { handleDeleteDialogOpen(template.id) }}
									onEdit={() => { handleEdit(template.id) }}
								/>
							)
							: <ParagraphTypography>There are no templates</ParagraphTypography>
				}
			</Grid>

			{/* CONFIGURE DIALOG (CREATE/EDIT) */}
			<CustomDialog
				open={isConfigureTemplateModalOpen}
				onClose={handleConfigureDialogClose}
				title={templateToEdit ? "Edit Template" : "New Template"}
				subtitle="Fields with * are required"
			>
				<form onSubmit={handleSubmitNewTemplate(onNewTemplateSubmit)}>
					<Grid container justifyContent="center" sx={{ marginBottom: 3 }}>
						<Grid item>
							{formStep === 1 ?
								<TemplateFormGeneral
									register={registerNewTemplate}
									setFormStep={setFormStep}
									watch={newTemplateWatch}
								/> :
								formStep === 2 ?
									<TemplateFormPhases
										register={registerNewTemplate}
										setFormStep={setFormStep}
										control={newTemplateControl}
										sortedPhasesIDs={sortedPhasesIDs}
										setSortedPhasesIDs={setSortedPhasesIDs}
										watch={newTemplateWatch}
										getValues={getTemplateFormValues}
									/> :
									formStep === 3 ?
										<TemplateFormQuestions
											register={registerNewTemplate}
											setFormStep={setFormStep}
											control={newTemplateControl}
											sortedQuestionsIDs={sortedQuestionsIDs}
											setSortedQuestionsIDs={setSortedQuestionsIDs}
										/> :
										undefined
							}
						</Grid>
						{formStep === 3 &&
							<Grid container item justifyContent="center">
								<Grid item sx={{ marginRight: 2 }}>
									<CustomButton text="Back" action={() => setFormStep(2)} />
								</Grid>
								<Grid item>
									<SubmitButton text="Save" />
								</Grid>
							</Grid>
						}
					</Grid>
				</form>
			</CustomDialog>

			{/* DELETE DIALOG */}
			< StyledModal
				isOpen={isDeleteTemplateModalOpen}
				handleClose={handleDeleteDialogClose}
				title="Do you want to delete this Template?"
				subtitle="Warning: This action cannot be undone!"
				rightButton={
					{
						name: "Yes, I'm sure!",
						action: () => handleDelete()
					}
				}
				leftButton={
					{
						name: "Cancel",
						action: () => handleDeleteDialogClose()
					}
				}
			/>
		</Grid>

	)
}
