import LogoutIcon from "@mui/icons-material/Logout"
import { Grid, IconButton, Paper, Tooltip } from "@mui/material"
import React from "react"
import { Outlet, useNavigate } from "react-router-dom"

import thimus_wizard_logo_dark from "../../assets/logos/thimus_wizard_logo_dark.svg"


const TesterSectionEnhancedLayout = (props: React.PropsWithChildren) => {
	const navigate = useNavigate()

	const handleLogout = () => {
		navigate("/auth/signOut")
	}
	return (
		<Grid
			container
			paddingY={3}
			justifyContent="center"
			alignContent="space-between"
			height="100vh"
			gap={3}
		>
			<Grid item container justifyContent="center">
				<img
					alt="logo-icon"
					src={thimus_wizard_logo_dark}
				/>
			</Grid>
			<Grid item xs={9}>
				<Paper
					elevation={3}
					sx={{
						minHeight: "80vh",
						backgroundColor: "white",
						overflowY: "auto",
						padding: theme => theme.spacing(3),
					}}
				>
					{props.children ?? <Outlet />}
				</Paper>
			</Grid>
			<Grid
				item
				position="fixed"
				left={1}
				bottom={2}
			>
				<IconButton
					onClick={() => handleLogout()}
				>
					<Tooltip title="Logout" >
						<LogoutIcon fontSize="large" />
					</Tooltip>
				</IconButton>
			</Grid>
		</Grid>

	)
}

export default TesterSectionEnhancedLayout