import {
	AddOutlined as AddOutlinedIcon, Close,
	FilterAltOutlined as FilterAltOutlinedIcon,
	// KeyboardArrowDownOutlined as KeyboardArrowDownOutlinedIcon
} from "@mui/icons-material"
import SearchIcon from "@mui/icons-material/Search"
import {
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Radio
} from "@mui/material"
import dayjs from "dayjs"
import _ from "lodash"
import { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useNavigate } from "react-router-dom"

import { ReactComponent as DownloadIcon } from "../../assets/icons/downloadIcon.svg"
import { CustomButton } from "../../components/buttons/CustomButton"
import CustomDialog from "../../components/CustomDialog"
import { ParagraphTypography, ProjectMenuTitleTypography, TitleTypography } from "../../components/customTypography"
import { DashboardCardTitleTypography } from "../../components/customTypography"
import ProjectCard from "../../components/ProjectCard"
import { StyledModal } from "../../components/StyledModal"
import { Toolbar, ToolbarConfig } from "../../components/Toolbar"
import { ENVIRONMENT, PROJECT_STATUSES, SUPPORTED_LANGUAGES } from "../../constants"
import { companyQueries, projectQueries } from "../../networking/networking"
import { useOperatorStore, useSnackbarStore, useUserStore } from "../../stateManagement"
import { language, Project } from "../../types/genericTypes"
import { areObjectsEqual, companiesSorter, handleExcelResponseFile, sortByCreationDate, userCanEditProject } from "../../utils"
interface ModalState {
	open: boolean,
	project?: Project,
	isChecked?: boolean
}

export type Status = "in_configuration" | "invalid_configuration" | "running" | "completed"
interface filters {
	name: string,
	location: string,
	language: language | null,
	status: Status | undefined
}

const defaultFilters = {
	name: "",
	location: "",
	language: null,
	status: undefined
}

const Projects = () => {
	const [downloadModal, setDownloadModal] = useState(false)
	const [selectedProject, setSelectedProject] = useState<Project | undefined>()
	const [isDeleteActionModalOpen, setIsDeleteActionModalOpen] = useState<ModalState>({
		open: false,
		isChecked: false
	})
	const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false)
	const [filters, setFilters] = useState<filters>(defaultFilters)

	const persistOpStore = useOperatorStore()

	const navigate = useNavigate()
	const userRole = useUserStore((state) => state.userRole)
	const userCompany = useUserStore((state) => state.userCompany)

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsDeleteActionModalOpen((old) => ({
			...old,
			isChecked: event.target.checked
		}))
	}

	const { data: projects = [], refetch: refetchProjects, isFetching: isProjectsFetching } = useQuery(
		projectQueries.listProjects.name,
		projectQueries.listProjects.fn,
	)

	const { data: companies = [], isFetching: isCompaniesFetching } = useQuery(
		companyQueries.listCompanies.name,
		companyQueries.listCompanies.fn,
		{
			select: data => data.sort(companiesSorter)
		}
	)

	const toolbarConfig: ToolbarConfig = {
		title: "Projects",
		buttons: [{
			name: "New Project",
			primary: true,
			icon: <AddOutlinedIcon />,
			action: () => navigate("/projects/new/general"),
			hide: userRole !== "superadmin" && userRole !== "admin",
			disabled: isProjectsFetching
		},
		{
			name: "Filters",
			icon: <FilterAltOutlinedIcon />,
			action: () => setIsFiltersModalOpen(true),
			isNotificationIconActive: !areObjectsEqual(filters, defaultFilters),
			disabled: isProjectsFetching || projects.length === 0
		}]
	}
	const { openSnackbar } = useSnackbarStore((state) => ({
		openSnackbar: state.openSnackbar
	}))
	const handleDownloadModalOpen = (project: Project) => {
		setDownloadModal(true)
		setSelectedProject(project)
	}

	const handleDownloadModalClose = () => {
		setDownloadModal(false)
		setSelectedProject(undefined)
	}

	const downloadSurveyFileMutation = useMutation(
		projectQueries.downloadSurveyFile.name,
		projectQueries.downloadSurveyFile.fn,
		{
			onSuccess: (response) => {
				const filename_date_part = (selectedProject?.start_datetime || dayjs.utc()).format("YYYYMMDD")
				handleExcelResponseFile(response.data, `${filename_date_part}_${selectedProject?.name}_questionnaireEEG.xlsx`)
			},
			onError: error => {
				console.log("Error during download of project settings file!", error)
				openSnackbar("error", "Error during download of project settings file!")
			}
		}
	)
	const downloadStatsOutputAnalysisFileMutation = useMutation(
		projectQueries.downloadStatsOutput.name,
		projectQueries.downloadStatsOutput.fn,
		{
			onSuccess: (response) => {
				const tempLink = document.createElement("a")
				tempLink.style.display = "none"
				tempLink.href = response
				tempLink.download = selectedProject?.name || ""
				tempLink.setAttribute("target", "_blank")
				document.body.appendChild(tempLink)
				tempLink.click()
				document.body.removeChild(tempLink)
			},
			onError: error => {
				console.log("Error during download of stats project analysis file!", error)
				openSnackbar("error", "Error during download of stats project analysis file!")
			}
		}
	)

	const handleDownloadSurveyFile = () => {
		if (selectedProject) {
			downloadSurveyFileMutation.mutate(selectedProject.id)
		}
	}
	const handleDownloadAnalysisFile = () => {
		if (selectedProject) {
			downloadStatsOutputAnalysisFileMutation.mutate(selectedProject.id)
		}
	}


	const downloadProjectFileMutation = useMutation(
		projectQueries.downloadProjectSettingsFile.name,
		projectQueries.downloadProjectSettingsFile.fn,
		{
			onSuccess: (response) => {
				handleExcelResponseFile(response.data, "project_settings_" + selectedProject?.name + ".xlsx")
			},
			onError: error => {
				console.log("Error during download of project settings file!", error)
				openSnackbar("error", "Error during download of project settings file!")
			}
		}
	)
	const handleDownloadProjectSettingsFile = () => {
		if (selectedProject) {
			downloadProjectFileMutation.mutate(selectedProject.id)
		}
	}

	// const downloadProjectTestersListFileMutation = useMutation(
	// 	projectQueries.downloadProjectTestersListFile.name,
	// 	projectQueries.downloadProjectTestersListFile.fn,
	// 	{
	// 		onSuccess: (response) => {
	// 			handleExcelResponseFile(response.data, "project_testers_" + selectedProject?.name + ".xlsx")
	// 		},
	// 		onError: error => {
	// 			console.log("Error during download of project testers file!", error)
	// 			openSnackbar("error", "Error during download of project testers file!")
	// 		}
	// 	}
	// )

	const downloadEEGData = useMutation(
		projectQueries.downloadEEGData.name,
		projectQueries.downloadEEGData.fn,
		{
			onSuccess: (response) => {
				const tempLink = document.createElement("a")
				tempLink.style.display = "none"
				tempLink.href = response
				tempLink.download = selectedProject?.name || ""
				tempLink.setAttribute("target", "_blank")
				document.body.appendChild(tempLink)
				tempLink.click()
				document.body.removeChild(tempLink)
			},
			onError: error => {
				console.log("Error during download of project testers file!", error)
				openSnackbar("error", "Error during download of project testers file!")
			}
		}
	)

	const handleDownloadEEGData = () => {
		if (selectedProject) {
			downloadEEGData.mutate(selectedProject.id)
		}
	}
	// const handleDownloadTestersListFile = () => {
	// 	if (selectedProject) {
	// 		downloadProjectTestersListFileMutation.mutate(selectedProject.id)
	// 	}
	// }

	const downloadDemographicFileMutation = useMutation(
		projectQueries.downloadDemographicFile.name,
		projectQueries.downloadDemographicFile.fn,
		{
			onSuccess: (response) => {
				const filename_date_part = (selectedProject?.start_datetime || dayjs.utc()).format("YYYYMMDD")
				handleExcelResponseFile(response.data, `${filename_date_part}_${selectedProject?.name}_demographic.xlsx`)
			},
			onError: error => {
				console.log("Error during download of project demographic file!", error)
				openSnackbar("error", "Error during download of project demographic file!")
			}
		}
	)
	const handleDemographicFile = () => {
		if (selectedProject) {
			downloadDemographicFileMutation.mutate(selectedProject.id)
		}
	}

	const deleteProjectMutation = useMutation(
		projectQueries.deleteProject.name,
		projectQueries.deleteProject.fn,
		{
			onSuccess: () => {
				refetchProjects()
				openSnackbar("success", "Project successfully deleted")
			},
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			onError: (error: any) => {
				console.error("error deleting project:", error)
				if (error.response?.status == 400)
					openSnackbar("error", error.response.data as string)
				else
					openSnackbar("error", "An error occurred while deleting the project")
			},
			onSettled: () => {
				setIsDeleteActionModalOpen({ open: false })
			}
		}
	)

	const handleDeleteProject = () => {
		if (isDeleteActionModalOpen.project) {
			deleteProjectMutation.mutate(isDeleteActionModalOpen.project.id)
		}
	}

	const onFilterStatusCheck = (event: React.MouseEvent<HTMLElement>, path: filters["status"]) => {
		setFilters((prevState) => {
			return ({
				name: prevState.name,
				location: prevState.location,
				language: prevState.language,
				status: path
			})
		})
	}

	const resetFilterField = (fieldName: string) => {
		setFilters(old => ({
			...old,
			[fieldName]: ""
		}))
	}

	const onFiltersNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilters((prevState) => {
			return ({
				name: event.target.value,
				language: prevState.language,
				location: prevState.location,
				status: prevState.status
			})
		})
	}

	const onFiltersLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilters((prevState) => {
			return ({
				name: prevState.name,
				language: prevState.language,
				location: event.target.value,
				status: prevState.status
			})
		})
	}

	const onFiltersLanguageChange = (event: React.MouseEvent<HTMLElement>) => {
		const radioValue = (event.target as HTMLInputElement).value === filters.language ? null : (event.target as HTMLInputElement).value as language
		setFilters((prevState) => {
			return ({
				name: prevState.name,
				language: radioValue,
				location: prevState.location,
				status: prevState.status
			})
		})
	}

	const filterProjectsByFilters = (project: Project) => {
		if (filters.name && !project.name.toLocaleLowerCase().includes(filters.name.toLocaleLowerCase())) {
			return false
		}

		if (filters.location && !project.place?.toLocaleLowerCase().includes(filters.location.toLocaleLowerCase())) {
			return false
		}

		if (filters.language) {
			if (filters.language !== project.language) {
				return false
			}
		}

		let projectStatus = ""

		if (!project.configured) {
			projectStatus = "invalid_configuration"
		} else if (project.completed) {
			projectStatus = "completed"
		} else if (dayjs.utc().isAfter(project.start_datetime)) {
			projectStatus = "running"
		} else {
			projectStatus = "in_configuration"
		}

		if (filters.status === "completed" && projectStatus !== "completed") {
			return false
		}

		else if (filters.status === "running" && projectStatus !== "running") {
			return false
		}

		else if (filters.status === "invalid_configuration" && projectStatus !== "invalid_configuration") {
			return false
		}

		else if (filters.status === "in_configuration" && projectStatus !== "in_configuration") {
			return false
		}
		return true
	}
	const isItDangerousToDeleteTheProject = userRole === "superadmin" && (isDeleteActionModalOpen.project?.completed_session_number || 0) > 0
	return (
		<Grid container direction="column" padding={3}>
			<Toolbar config={toolbarConfig} />
			{isCompaniesFetching || isProjectsFetching ?
				<Grid container justifyContent="center">
					<Grid item>
						<CircularProgress />
					</Grid>
				</Grid>
				:
				<>
					{companies
						.filter(company => userRole === "superadmin" || company.cognito_group_name === userCompany?.GroupName || company.id === persistOpStore.operatorCompany)
						.map((company) => {
							const companyProjects = sortByCreationDate(projects).filter(project => project.company_id === company.id).filter(filterProjectsByFilters)
							return (
								<Grid
									gap={2}
									container
									item
									key={company.id}
									sx={{
										marginTop: 3,
										marginBottom: 3
									}}
								>
									<Grid item xs={12} sx={{ marginBottom: 2 }}>
										<ProjectMenuTitleTypography>{company.name}</ProjectMenuTitleTypography>
									</Grid>
									{
										companyProjects.map(project => (
											<ProjectCard
												key={project.id}
												onEdit={() => navigate(`/projects/${project.id}/general`)}
												onDelete={() => {
													setIsDeleteActionModalOpen({
														open: true,
														project: project,
														isChecked: false
													})
												}}
												onAnalytics={() => navigate(`/projects/${project.id}/dashboard`)}
												project={project}
												onPlay={() => navigate(`/projects/${project.id}/testersSelection`)}
												onDownload={() => handleDownloadModalOpen(project)}
												userCanEdit={userCanEditProject(project, userRole)}
											/>
										))
									}
									{companyProjects.length === 0 &&
										<ParagraphTypography color="text.disabled">
											There are no projects yet
										</ParagraphTypography>
									}
								</Grid>
							)
						})}
					<CustomDialog open={downloadModal} onClose={handleDownloadModalClose} title={"Project " + selectedProject?.name + " Resources"} colored>
						<Divider />
						<Grid container marginY={3}>
							{userRole === "superadmin" &&
								<Grid item container alignItems="center" justifyContent="space-between" margin={3}>
									<DashboardCardTitleTypography>Project settings</DashboardCardTitleTypography>
									{/*action={() => setIsConfigureProductModalOpen({ isOpen: true })}*/}
									<CustomButton primary icon={<DownloadIcon />} text="Download" action={handleDownloadProjectSettingsFile} />
								</Grid>
							}
							{(userRole === "superadmin" || (ENVIRONMENT !== "CLOUD_PRODUCTION" && userRole === "admin" && selectedProject?.completed)) &&
								<Grid item container alignItems="center" justifyContent="space-between" margin={3}>
									<DashboardCardTitleTypography>EEG Data</DashboardCardTitleTypography>
									<CustomButton primary icon={<DownloadIcon />} text="Download" action={handleDownloadEEGData} />
								</Grid>
							}
							{(ENVIRONMENT !== "CLOUD_PRODUCTION" && userRole === "admin" && selectedProject?.completed) &&
								<Grid item container alignItems="center" justifyContent="space-between" margin={3}>
									<DashboardCardTitleTypography>EEG Output</DashboardCardTitleTypography>
									{/* Per scaricare il long_data.csv */}
									{/*TODO: disabilitare se il progetto non ha i risultati delle analisi*/}
									<CustomButton primary icon={<DownloadIcon />} text="Download" action={handleDownloadEEGData} />
								</Grid>
							}
							{(userRole === "superadmin" || (userRole === "admin" && selectedProject?.completed)) &&
								<Grid item container alignItems="center" justifyContent="space-between" margin={3}>
									<DashboardCardTitleTypography>Survey</DashboardCardTitleTypography>
									<CustomButton primary icon={<DownloadIcon />} text="Download" action={handleDownloadSurveyFile} />
								</Grid>
							}
							{(userRole === "superadmin" || (userRole === "admin" && selectedProject?.completed)) &&
								<Grid item container alignItems="center" justifyContent="space-between" margin={3}>
									<DashboardCardTitleTypography>Demographics</DashboardCardTitleTypography>
									<CustomButton primary icon={<DownloadIcon />} text="Download" action={handleDemographicFile} />
								</Grid>
							}
							{(userRole === "superadmin") &&
								<Grid item container alignItems="center" justifyContent="space-between" margin={3}>
									<DashboardCardTitleTypography>Stats Output</DashboardCardTitleTypography>
									<CustomButton primary icon={<DownloadIcon />} text="Download" action={handleDownloadAnalysisFile} />
								</Grid>
							}
						</Grid>
					</CustomDialog>
				</>
			}
			<StyledModal
				isOpen={isDeleteActionModalOpen.open}
				handleClose={() => setIsDeleteActionModalOpen({ open: false })}
				title="Do you want to delete this project?"
				subtitle="Warning: This action cannot be undone!"
				rightButton={
					{
						name: "Yes, I'm sure!",
						icon: deleteProjectMutation.isLoading ? <CircularProgress size={18} /> : undefined,
						disabled: deleteProjectMutation.isLoading || (isItDangerousToDeleteTheProject && !isDeleteActionModalOpen.isChecked),
						action: handleDeleteProject
					}
				}
				leftButton={
					{
						name: "Cancel",
						action: () => setIsDeleteActionModalOpen({ open: false }),
						disabled: deleteProjectMutation.isLoading
					}
				}
			>
				{isItDangerousToDeleteTheProject &&
					<FormControlLabel required control={<Checkbox onChange={handleCheckboxChange} />} label="I am aware that deleting this project will also delete all sessions." />
				}
			</StyledModal>

			{/* FILTERS MODAL */}
			<CustomDialog
				open={isFiltersModalOpen}
				onClose={() => setIsFiltersModalOpen(false)}
				title="Filters"
			>
				<Grid container>
					{/* search by name */}
					<Grid item container sx={{ marginBottom: 2 }}>
						<Grid item xs={12} sx={{ marginBottom: 1 }}>
							<TitleTypography>Name</TitleTypography>
						</Grid>
						<Grid item xs={12}>
							<OutlinedInput
								onChange={onFiltersNameChange}
								value={filters.name}
								fullWidth
								placeholder="Search..."
								startAdornment={
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								}
								endAdornment={filters.name &&
									<IconButton
										onClick={() => resetFilterField("name")}
									>
										<Close />
									</IconButton>
								}
							/>
						</Grid>
					</Grid>

					{/* search by location */}
					<Grid item container sx={{ marginBottom: 2 }}>
						<Grid item xs={12} sx={{ marginBottom: 1 }}>
							<TitleTypography>Location</TitleTypography>
						</Grid>
						<Grid item xs={12}>
							<OutlinedInput
								onChange={onFiltersLocationChange}
								value={filters.location}
								fullWidth
								placeholder="Search..."
								startAdornment={
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								}
								endAdornment={filters.location &&
									<IconButton
										onClick={() => resetFilterField("location")}
									>
										<Close />
									</IconButton>
								}
							/>
						</Grid>
					</Grid>

					{/* status */}
					<Grid item container direction="column" xs={6}>
						<Grid item>
							<TitleTypography>Status</TitleTypography>
						</Grid>
						{PROJECT_STATUSES.map((status) => {
							const label = status.replace("_", " ")
							return (
								<Grid item key={status}>
									<FormControlLabel
										control={
											<Radio
												checked={filters.status === status}
												value={status}
												onClick={(e) => onFilterStatusCheck(e, status)}
											/>
										}
										label={_.capitalize(label)}
									/>
								</Grid>
							)
						})}
						<Grid item>
							<FormControlLabel
								control={
									<Radio
										checked={filters.status === undefined}
										value={undefined}
										onClick={(e) => onFilterStatusCheck(e, undefined)}
									/>
								}
								label="All"
							/>
						</Grid>
					</Grid>

					{/* language */}
					<Grid item container direction="column" xs={6} >
						<Grid item>
							<TitleTypography>Language</TitleTypography>
						</Grid>
						{SUPPORTED_LANGUAGES.map((language) => {
							return (
								<Grid item key={language} >
									<FormControlLabel
										control={
											<Radio
												checked={filters.language === language}
												value={language}
												onClick={(e) => onFiltersLanguageChange(e)}
											/>
										}
										label={_.capitalize(language)}
									/>
								</Grid>
							)
						})}
						<Grid item>
							<FormControlLabel
								control={
									<Radio
										checked={filters.language === null}
										value={null}
										onClick={(e) => onFiltersLanguageChange(e)}
									/>
								}
								label="All"
							/>
						</Grid>
					</Grid>

					<Grid container justifyContent="flex-end" columnGap={2}>
						<Grid item>
							<Button variant="text" onClick={() => setFilters(defaultFilters)}>Reset Filters</Button>
						</Grid>
						<Grid item>
							<Button variant="contained" onClick={() => setIsFiltersModalOpen(false)}>Confirm</Button>
						</Grid>
					</Grid>
				</Grid>
			</CustomDialog>
		</Grid >
	)
}

export default Projects
