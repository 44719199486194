import DeleteIcon from "@mui/icons-material/Delete"
import { CircularProgress, Grid } from "@mui/material"
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid"
import { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useNavigate } from "react-router-dom"

import { sessionsQueries } from "../networking/networking"
import { useSnackbarStore, useUserStore } from "../stateManagement"
import {Toolbar, ToolbarConfig} from "./Toolbar"



export const SessionDebug = () => {
	const [selectedSessionsId, setSelectedSessionsId] = useState<GridRowSelectionModel>([])
	const userRole = useUserStore((state) => state.userRole)
	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)

	const navigate = useNavigate()


	const { data: singleSession = [], refetch: refetchSessions } = useQuery(
		sessionsQueries.listSessions.name,
		sessionsQueries.listSessions.fn,
	)

	const deleteSessionsMutation = useMutation(
		sessionsQueries.deleteSessions.name,
		sessionsQueries.deleteSessions.fn,
	)

	const columns: GridColDef[] = [
		{
			field: "id",
			headerName: "Session ID",
			type: "number",
			width: 90,
		},
		{
			field: "tester_id",
			headerName: "Global Tester ID",
			type: "number",
			width: 120,
		},
		{
			field: "local_tester_id",
			headerName: "Local Tester ID",
			type: "number",
			width: 120,
		},
		{
			field: "project_id",
			headerName: "Project ID",
			type: "number",
			width: 90,
		},
		{
			field: "eeg_id",
			headerName: "EEG ID",
			type: "number",
			width: 90,
		},
		{
			field: "running",
			headerName: "Running",
			type: "boolean",
			width: 120,
		},
		{
			field: "calibration_completed",
			headerName: "Calibration",
			type: "boolean",
			width: 120,
		},
		{
			field: "baseline_completed",
			headerName: "Baseline",
			type: "boolean",
			width: 120,
		},
		{
			field: "file_path",
			headerName: "File Path",
			width: 200,
		},
		{
			field: "created_by",
			headerName: "Created By",
			width: 150,
		},
		{
			field: "created_at",
			headerName: "Created At",
			type: "dateTime",
			width: 150,
		},
		{
			field: "start_at",
			headerName: "Start",
			type: "dateTime",
			width: 150,
		},
		{
			field: "completed_at",
			headerName: "Completed At",
			type: "dateTime",
			width: 150,
		},
		{
			field: "uncompleted",
			headerName: "Uncompleted",
			type: "boolean",
			width: 120,
		},
		{
			field: "DBLE_data",
			headerName: "DBLE",
			type: "boolean",
			valueGetter: ({ value }) => Boolean(value),
			width: 90,
		},
		{
			field: "DBLE_data_edf",
			headerName: "DBLE edf",
			type: "boolean",
			valueGetter: ({ value }) => Boolean(value),
			width: 90,
		},
		{
			field: "FBLE_data",
			headerName: "FBLE",
			type: "boolean",
			valueGetter: ({ value }) => Boolean(value),
			width: 90,
		},
		{
			field: "FBLE_data_edf",
			headerName: "FBLE edf",
			type: "boolean",
			valueGetter: ({ value }) => Boolean(value),
			width: 90,
		},
		{
			field: "PBLE_data",
			headerName: "PBLE",
			type: "boolean",
			valueGetter: ({ value }) => Boolean(value),
			width: 90,
		},
		{
			field: "current_product_id",
			headerName: "Current Product ID",
			type: "number",
			width: 150,
		},
		{
			field: "current_phase_id",
			headerName: "Current Phase ID",
			type: "number",
			width: 130,
		},
		{
			field: "current_product_id_to_respond",
			headerName: "Current Product to Respond",
			type: "number",
			width: 150,
		},
	]

	const toolbarConfig: ToolbarConfig = {
		title: "Sessions",
		subtitle: "Manage all the Sessions from here",
		buttons: userRole === "superadmin" ?
			[
				{
					name: "Delete selected",
					icon: deleteSessionsMutation.isLoading ? <CircularProgress size={18} /> : <DeleteIcon />,
					action: async () => {
						try {
							await Promise.all(selectedSessionsId.map((id) => deleteSessionsMutation.mutateAsync(parseInt(id.toString()))))
							openSnackbar("success", "Sessions successfully deleted")
							await refetchSessions()
						} catch {
							openSnackbar("error", "Error deleting sessions")
						}
					},
					disabled: deleteSessionsMutation.isLoading || selectedSessionsId.length === 0,
					primary: false,
				},
			] : undefined
	}

	if (userRole !== "superadmin") {
		navigate("/")
	}
	return (
		<Grid container padding={3}>
			<Toolbar config={toolbarConfig} />
			<Grid
				item
				style={{
					height: "70vh",
					width: "100%",
				}}
			>
				<DataGrid
					showColumnVerticalBorder
					columns={columns}
					rows={singleSession}
					checkboxSelection
					disableRowSelectionOnClick
					getRowId={(session) => session.id}
					onRowSelectionModelChange={(selectedSessionIDs) => setSelectedSessionsId(selectedSessionIDs)}
					initialState={{
						sorting: {
							sortModel: [
								{
									field: "created_at",
									sort: "desc"
								}
							]
						}
					}}
				/>
			</Grid>
		</Grid>
	)
}
