import { Grid } from "@mui/material"

import { ParagraphTypography, TitleTypography } from "../../../components/customTypography"
import { Product } from "../../../types/genericTypes"
import { DashboardProductCard } from "./DashboardProductCard"

interface props {
	productsList: Product[]
}

export const DashboardProducts = (props: props) => {
	return (
		<Grid
			container
			sx={{
				padding: 3,
				paddingTop: 0,
				overflow: "auto"
			}}
		>
			<Grid item container justifyContent="space-between" sx={{ marginBottom: 2 }}>
				<Grid item xs={6}>
					<TitleTypography>Products Recap</TitleTypography>
				</Grid>
				<Grid item container xs={6} justifyContent="flex-end">
					<TitleTypography color="primary" sx={{ marginRight: 1 }}> {props.productsList.length}</TitleTypography>
					<TitleTypography> Total Products</TitleTypography>
				</Grid>
			</Grid>
			<Grid item container spacing={2}>
				{props.productsList.length > 0 ?
					props.productsList.map((product) =>
						<Grid item key={product.id}>
							<DashboardProductCard product={product} />
						</Grid>
					) :
					<ParagraphTypography>No products for this project</ParagraphTypography>
				}
			</Grid>
		</Grid>
	)
}
