import { Button, FormControlLabel, Grid, Radio } from "@mui/material"
import React, { useState } from "react"
import { Control, Controller } from "react-hook-form"

import { H1Typography, ParagraphTypography } from "../../../../../components/customTypography"
import { LanguageButton } from "../../../../../components/LanguageButton"
import { language, Question } from "../../../../../types/genericTypes"
import { NewAnswerFormInput } from "../../TesterSurveyLayout"

interface questionProps {
	question: Question
	language: language
	control?: Control<NewAnswerFormInput>
	preview?: boolean
}

export const TesterSingleAnswer = (props: questionProps) => {
	const [currentLang, setCurrentLang] = useState<language>(props.language)

	return (
		<>
			<Grid item container justifyContent="center" flexDirection="column" padding={2} rowGap={1}>
				{props.question.questions_i18n.filter(question => question.language === currentLang).map((question) =>
					<H1Typography textAlign="center" key={question.language}>{question.value}</H1Typography>
				)}
				<ParagraphTypography textAlign="center">Choose one answer</ParagraphTypography>
				{props.preview &&
					<Grid item container gap={1} justifyContent="center">
						{props.question.questions_i18n && props.question.questions_i18n.map(item =>
							<React.Fragment key={item.language}>
								<Grid item>
									<LanguageButton
										language={item.language}
										action={() => setCurrentLang(item.language)}
										isSelected={item.language === currentLang}
									/>
								</Grid>
							</React.Fragment >
						)}
					</Grid>
				}
			</Grid>
			<Grid
				marginX="10%"
				marginBottom={3}
				sx={{
					border: "1px solid #CCCCCC",
					borderStyle: "dashed"
				}}
			/>
			<Grid container justifyContent="center" >
				<Grid
					item
					xs={12}
					container
					justifyContent="center"
					spacing={1}
					direction={props.question.horizontal ? "row" : "column"}
				>
					{props.question.possible_answers.map((possible_answer) =>
						possible_answer.possible_answers_i18n?.filter(answer => answer.language === currentLang).map((answer) =>
							props.question.horizontal === true ? (
								<Grid item key={answer.possible_answer_id} xs>
									{props.control ?
										<Controller
											name="single_answer_response"
											control={props.control}
											rules={{ required: true }}
											render={({ field }) => (
												<Button
													type="button"
													variant="outlined"
													fullWidth
													onClick={() => field.onChange(answer.possible_answer_id)}
													sx={{
														minWidth: "100px",
														textTransform: "none",
														height: "50px",
														lineHeight: "15px",
													}}
													style={field.value === answer.possible_answer_id ? {
														backgroundColor: "#d8702e",
														color: "white"
													} : {
														color: "black",
														borderColor: "black"
													}}
												>
													{answer.value}
												</Button>
											)}
										/>
										:
										<Button
											type="button"
											variant="outlined"
											fullWidth
											sx={{
												minWidth: "100px",
												textTransform: "none",
												height: "50px",
												lineHeight: "15px",
											}}
											style={{
												color: "black",
												borderColor: "black"
											}}
										>
											{answer.value}
										</Button>
									}
								</Grid>
							) : (
								<Grid item key={answer.possible_answer_id} xs>
									{props.control ?
										<Controller
											name="single_answer_response"
											control={props.control}
											rules={{ required: true }}
											render={({ field }) => (
												<FormControlLabel
													value={answer.possible_answer_id}
													checked={field.value === answer.possible_answer_id}
													name={answer.possible_answer_id + ""}
													control={
														<Radio
															onChange={e => field.onChange(parseInt(e.target.value))}
														/>
													}
													label={answer.value}
													labelPlacement="end"
												/>
											)}
										/>
										:
										<FormControlLabel
											value={answer.possible_answer_id}
											name={answer.possible_answer_id + ""}
											control={
												<Radio
													onChange={e => (e.target as HTMLInputElement).value}
												/>
											}
											label={answer.value}
											labelPlacement="end"
										/>
									}
								</Grid>
							)
						)
					)}
				</Grid>
			</Grid>
		</>
	)
}
