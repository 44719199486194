import { Chip, Grid } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Accept, useDropzone } from "react-dropzone"

import { DefaultTextTypography } from "./customTypography"


interface dropzoneProps {
	acceptedFileFormats: Accept,
	file?: File[] | undefined,
	setFile?: (files: File[]) => void
	disabled?: boolean
}


const baseStyle = {
	height: 150,
	flex: 1,
	display: "flex",
	alignItems: "center",
	padding: "20px",
	borderWidth: 2,
	borderRadius: 2,
	borderColor: "#eeeeee",
	borderStyle: "dashed",
	backgroundColor: "#fafafa",
	color: "#bdbdbd",
	outline: "none",
	transition: "border .24s ease-in-out",
	cursor: "pointer",
}

const focusedStyle = {
	borderColor: "#2196f3"
}

const acceptStyle = {
	borderColor: "#00e676"
}

const rejectStyle = {
	borderColor: "#ff1744"
}
export function StyledDropzone(props: dropzoneProps) {
	const [errorMessage, setErrorMessage] = useState("")
	const acceptedFormats = Object.keys(props.acceptedFileFormats).map(value => (
		value === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
			value = ".xlsx" : value.split("/")[1])
	).join(", ")

	const text = `Only ${acceptedFormats} files will be accepted`

	const onDrop = useCallback((acceptedFiles: File[]) => {
		if (acceptedFiles) {
			if (props.setFile) {
				props.setFile(acceptedFiles)
			} else {
				console.log("no registration method provided")
			}
		}
	}, [])

	const {
		getRootProps,
		getInputProps,
		// isDragActive,
		fileRejections,
		isFocused,
		isDragAccept,
		isDragReject,
	} =
		useDropzone({
			onDrop,
			accept: props.acceptedFileFormats,
			maxFiles: 1,
			disabled: props.disabled,
			maxSize: 5242880
		})

	const style = useMemo(() => ({
		...baseStyle,
		...(isFocused ? focusedStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {}),
		...(props.disabled ? { cursor: "default" } : {})
	}), [
		isFocused,
		isDragAccept,
		isDragReject
	])

	const handleDelete = () => {
		if (props.setFile) {
			props.setFile([])
		}
	}

	useEffect(() => {
		if (fileRejections.length > 0) {
			const errors = fileRejections[0].errors
			setErrorMessage(
				errors[0].code === "file-too-large"
					? "File is larger than 5MB"
					: errors[0].message
			)
		} else {
			setErrorMessage("")
		}
	}, [props.file])
	return (
		<Grid
			item
			container
			justifyContent="center"
			direction="column"
			alignItems="center"
		>
			{/* <Grid item xs={12} sx={{ marginBottom: 2 }}> */}
			<Grid {...getRootProps({ style })} item container justifyContent="center" direction="column">
				<input {...getInputProps()} />
				<Grid item>Drag and drop a file smaller than 5MB here or click to select one.<br />{text}</Grid>
			</Grid>
			{/* </Grid> */}
			{props.file && props.file.length ?
				<Grid item container justifyContent="center" sx={{ marginTop: 2 }} width="fit-content">
					<Chip label={props.file[0].name} onDelete={handleDelete} />
				</Grid> :
				<Grid item marginTop={1}>
					<DefaultTextTypography color="error">{errorMessage}</DefaultTextTypography>
				</Grid>
			}
		</Grid>
	)
}