import { AddOutlined as AddOutlinedIcon } from "@mui/icons-material"
import { FormControl, Grid, MenuItem, Select, Typography } from "@mui/material"

import { ReactComponent as FranceIcon } from "../../../assets/icons/languages/france.svg"
import { ReactComponent as GermanyIcon } from "../../../assets/icons/languages/germany.svg"
import { ReactComponent as ItalyIcon } from "../../../assets/icons/languages/italy.svg"
import { ReactComponent as SpainIcon } from "../../../assets/icons/languages/spain.svg"
import { ReactComponent as UkIcon } from "../../../assets/icons/languages/uk.svg"
import { DefaultTextTypography } from "../../../components/customTypography"
import { language } from "../../../types/genericTypes"

interface propsLang {
	activeLanguages: language[]
	action: (lang: language) => void
}

const iconStyle = {
	height: 16,
	width: 16,
}

interface suppLangs {
	language: language,
	flag: JSX.Element
}

const supportedLanguages: suppLangs[] = [
	{
		language: "en",
		flag: <UkIcon style={iconStyle} />
	},
	{
		language: "it",
		flag: <ItalyIcon style={iconStyle} />
	},
	{
		language: "es",
		flag: <SpainIcon style={iconStyle} />
	},
	{
		language: "de",
		flag: <GermanyIcon style={iconStyle} />
	},
	{
		language: "fr",
		flag: <FranceIcon style={iconStyle} />
	},
]
export const Language = (props: propsLang) => {

	return (
		<>
			<Grid item>
				<FormControl fullWidth>
					<Select
						displayEmpty
						fullWidth
						onChange={(e) => props.action(e.target.value as language)}
						value=""
						sx={{
							height: "40px",
							width: "200px",
							// overflow: "hidden",
							borderRadius: "3px",
							backgroundColor: "#f1f1f1",
						}}
					>
						<MenuItem disabled value="">
							<DefaultTextTypography sx={{ display: "flex" }}>
								<AddOutlinedIcon /> Add Language
							</DefaultTextTypography>
						</MenuItem>
						{supportedLanguages
							.filter((selectedLang: suppLangs) => !props.activeLanguages.includes(selectedLang.language))
							.map((lang: suppLangs) => (
								<MenuItem key={lang.language} value={lang.language}>
									{lang.flag}
									<Typography marginLeft={1}>{lang.language.toUpperCase()}</Typography>
								</MenuItem>
							))}
					</Select>
				</FormControl>
			</Grid>
		</>
	)
}
