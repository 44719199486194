import _ from "lodash"

import awsmobile from "./aws-exports"
import { Status } from "./pages/projects/Projects"

type Environment =
		"CLOUD_DEV" | "CLOUD_STAGING" | "CLOUD_PRODUCTION" |
		"TBOX_DEV" | "TBOX_STAGING" | "TBOX_PRODUCTION" |
		"LOCAL_DEV"

/*
 Il file env-config.js, che carica _env_ in window, esiste solo quando avvia da docker.
 Le variabili d'ambiente REACT_APP_*, prese da process.env, ci sono solo quando avviato con npm.
 In cloud con Amplify viene usato aws-exports.js (in base al branch)
 */
const getEnvironmentName = (): Environment => {
	if (TBOX_MODE) {
		if (!REACT_APP_TBOX_ENVIRONMENT) {
			console.error("Missing REACT_APP_TBOX_ENVIRONMENT!")
			return "TBOX_DEV"
		}
		if (REACT_APP_TBOX_ENVIRONMENT.toUpperCase().includes("DEV")) return "TBOX_DEV"
		if (REACT_APP_TBOX_ENVIRONMENT.toUpperCase().includes("STAGING")) return "TBOX_STAGING"
		if (REACT_APP_TBOX_ENVIRONMENT.toUpperCase().includes("PRODUCTION")) return "TBOX_PRODUCTION"
	}

	const aws_lambda_endpoint = awsmobile.aws_cloud_logic_custom.find(el => el.name === "AdminQueries")?.endpoint || ""

	if (aws_lambda_endpoint.endsWith("/dev")) return "CLOUD_DEV"
	if (aws_lambda_endpoint.endsWith("/staging")) return "CLOUD_STAGING"
	if (aws_lambda_endpoint.endsWith("/prod")) return "CLOUD_PRODUCTION"
	return "LOCAL_DEV"
}

export const REACT_APP_TBOX: string | undefined = _.get(window, ["_env_", "REACT_APP_TBOX"], process.env.REACT_APP_TBOX)

export const REACT_APP_TBOX_ENVIRONMENT: string | undefined = _.get(window, ["_env_", "REACT_APP_TBOX_ENVIRONMENT"], process.env.REACT_APP_TBOX_ENVIRONMENT)

export const REACT_APP_BE_ADDRESS: string = _.get(window, ["_env_", "REACT_APP_BE_ADDRESS"], process.env.REACT_APP_BE_ADDRESS)

export const REACT_APP_MOCK = Boolean(_.get(window, ["_env_", "REACT_APP_MOCK"], process.env.REACT_APP_MOCK))

export const TBOX_MODE = Boolean(REACT_APP_TBOX)

export const ENVIRONMENT = getEnvironmentName()

export const PROJECT_STATUSES: Status[] = ["in_configuration", "invalid_configuration", "running", "completed"]

export const SUPPORTED_LANGUAGES = ["it", "en", "de", "es", "fr"]