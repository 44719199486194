import MenuIcon from "@mui/icons-material/Menu"
import { Box, CircularProgress, Grid, Theme, useMediaQuery } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import { Auth } from "aws-amplify"
import _ from "lodash"
import { useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"

import thimus_wizard_logo from "../assets/logos/thimus_wizard_logo.svg"
import {REACT_APP_MOCK, TBOX_MODE} from "../constants"
import {useOperatorStore, useUserStore} from "../stateManagement"
import { AppDrawer } from "./AppDrawer"

//-------- style ------------------
const logoStyle: object = {
	height: "100%",
	maxHeight: "50px",
}
//--------------------------------

export default function ThimusLayout() {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false)
	const [isUserAuthenticated, setIsUserAuthenticated] = useState(false)
	const persistOpStore = useOperatorStore()
	const setUserName = useUserStore((state) => state.setUserName)


	const navigate = useNavigate()

	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))

	useEffect(() => {
		if (REACT_APP_MOCK) return
		if (!TBOX_MODE) {
			Auth.currentAuthenticatedUser()
				.then((cognitoUser) => {
					setUserName(_.get(cognitoUser, "attributes.name"))
					setIsUserAuthenticated(true)
				})
				.catch(error => {
					console.warn(error)
					navigate("/auth/signIn", { replace: true })
				})
		} else if (persistOpStore.isUserOperator) {
			console.log("logging in as operator")
			setIsUserAuthenticated(true)
		} else {
			console.log("User not authorized")
			navigate("/auth/signIn", {replace: true})
		}
	}, [])

	if (!REACT_APP_MOCK && !isUserAuthenticated) {
		return (
			<Grid container justifyContent="center"><CircularProgress /></Grid>
		)
	}
	return (
		<>
			<AppBar position="static" sx={{ display: isMobile ? undefined : "none" }}>
				<Toolbar>
					<Grid container justifyContent="space-between" alignItems="center">
						<Grid item xs={1}>
							<IconButton
								size="large"
								edge="start"
								color="inherit"
								aria-label="menu"
								onClick={() => { setIsDrawerOpen(true) }}
							>
								<MenuIcon />
							</IconButton>
						</Grid>
						<img
							alt="svg icon"
							src={thimus_wizard_logo}
							style={logoStyle}
						/>
						<Grid item xs={1} />
					</Grid>
				</Toolbar>
			</AppBar>
			<Box sx={{ display: "flex" }}>
				<AppDrawer open={isDrawerOpen} setDrawerOpen={setIsDrawerOpen} />
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						width: "calc(100vw - 300px)"
					}}
				>
					<Outlet />
				</Box>
			</Box>
		</>
	)
}
