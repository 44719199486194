import "dayjs/locale/en"
import "./Style.css"

import { ThemeProvider } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { useEffect } from "react"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { QueryClientProvider } from "react-query"
import { createBrowserRouter, RouterProvider } from "react-router-dom"

import awsmobile from "./aws-exports"
import AuthLayout from "./components/AuthLayout"
import CustomSnackbar from "./components/customSnackbar"
import { SessionDebug } from "./components/SessionDebug"
import ThimusLayout from "./components/ThimusLayout"
import { REACT_APP_BE_ADDRESS, TBOX_MODE } from "./constants"
import customTheme from "./customTheme"
import { queryClient } from "./networking/networking"
import ForgotPassword from "./pages/auth/ForgotPassword"
import Login from "./pages/auth/login"
import SignOut from "./pages/auth/SignOut"
import { Classification } from "./pages/categories/Classification"
import Companies from "./pages/companies/companies"
import Phases from "./pages/phases/Phases"
import { PostAnalyticsDashboard } from "./pages/postAnalyticsDashboard/PostAnalyticsDashboardLayout"
import { TesterConnection } from "./pages/projects/activeProject/TesterConnection"
import { TestersSelection } from "./pages/projects/activeProject/TestersSelection"
import TestersSession from "./pages/projects/activeProject/TestersSession"
import ConfigureProjectGeneral from "./pages/projects/configure/ConfigureProjectGeneral"
import ConfigureProjectLayout from "./pages/projects/configure/ConfigureProjectLayout"
import ConfigureProjectOperators from "./pages/projects/configure/ConfigureProjectOperators"
import ConfigureProjectTesters from "./pages/projects/configure/ConfigureProjectTesters"
import ConfigureProjectProducts from "./pages/projects/configure/products/ConfigureProjectProducts"
import ConfigureProjectTemplate from "./pages/projects/configure/template/ConfigureProjectTemplate"
import Projects from "./pages/projects/Projects"
import { Questions } from "./pages/question/Questions"
import { TBoxes } from "./pages/tboxes/Tboxes"
import { Templates } from "./pages/templates/Templates"
import { Testers } from "./pages/testers/Testers"
import TesterMode from "./pages/testerView/TesterMode"
import { TesterModeLayout } from "./pages/testerView/TesterModeLayout"
import { TesterSectionEnd } from "./pages/testerView/TesterSectionEnd"
import TesterSectionEnhancedLayout from "./pages/testerView/TesterSectionEnhancedLayout"
import TesterSectionLayout from "./pages/testerView/TesterSectionLayout"
import { TesterSectionWelcome } from "./pages/testerView/TesterSectionWelcome"
import { TesterSurveyLayout } from "./pages/testerView/testerSurvey/TesterSurveyLayout"
import Users from "./pages/users/users"
import { useFullScreenStore, useSnackbarStore } from "./stateManagement"
dayjs.extend(utc)
dayjs.locale("en")

const router = createBrowserRouter([
	{
		path: "/auth",
		element: <AuthLayout />,
		children: [
			{
				path: "signIn",
				element: <Login />,
			},
			{
				path: "forgotPassword",
				element: <ForgotPassword />,
			},
			{
				path: "signOut",
				element: <SignOut />,
			},
		]
	},
	{
		element: <ThimusLayout />,
		children: [
			{
				path: "/",
				element: <Projects />
			},
			{
				path: "/projects",
				element: <Projects />,
			},
			{
				path: "/projects/new",
				element: <ConfigureProjectLayout />,
				children: [
					{
						path: "general",
						element: <ConfigureProjectGeneral />,
						handle: {
							title: "General",
							subtitle: "All fields with * are required.",
						}
					},
				]
			},
			{
				path: "projects/:project_id/dashboard",
				element: <PostAnalyticsDashboard />
			},
			{
				path: "/projects/:project_id/testersConnection",
				element: <TesterConnection />,
			},
			{
				path: "/projects/:project_id/testersSelection",
				element: <TestersSelection />,
			},
			{
				path: "/projects/:project_id/testersSession",
				element: <TestersSession />,
			},
			{
				path: "/projects/:project_id",
				element: <ConfigureProjectLayout />,
				children: [
					{
						path: "general",
						element: <ConfigureProjectGeneral />,
						handle: {
							title: "General",
							subtitle: "All fields with * are required.",
						}
					},
					{
						path: "template",
						element: <ConfigureProjectTemplate />,
						handle: {
							title: "Template",
							subtitle: "Choose from a template down here. You can always edit a template later.",
						}
					},
					{
						path: "products",
						element: <ConfigureProjectProducts />,
						handle: {
							title: "Products",
							subtitle: "Here you can select, edit and delete the project products. You can order the cards by dragging them.",
						}
					},
					{
						path: "testers",
						element: <ConfigureProjectTesters />,
						handle: {
							title: "Testers",
							subtitle: "Here you can select, create and upload the project testers.",
						}
					},
					{
						path: "operators",
						element: <ConfigureProjectOperators />,
						handle: {
							title: "Operators",
							subtitle: "Here you can select, edit and delete the project operators.",
						}
					},
				]
			},
			{
				path: "/phases",
				element: <Phases />
			},
			{
				path: "/companies",
				element: <Companies />
			},
			{
				path: "/users",
				element: <Users />
			},
			{
				path: "/templates",
				element: <Templates />
			},
			{
				path: "/testers",
				element: <Testers />
			},
			{
				path: "/questions",
				element: <Questions />
			},
			{
				path: "/tbox",
				element: <TBoxes />
			},
			{
				path: "/classification",
				element: <Classification />
			},
			{
				path: "/templates",
				element: <Templates />
			},
			{
				path: "/sessions",
				element: <SessionDebug />
			}
		]
	},
	{
		path: "/tester/:local_tester_id/project/:project_id",
		// questo non renderizza niente, ma capisce in quale pagina deve andare il tester.
		element: <TesterSectionLayout />,
		children: [
			{
				path: "welcome",
				element: <TesterSectionEnhancedLayout><TesterSectionWelcome /></TesterSectionEnhancedLayout>
			},
			{
				path: "session",
				element: <TesterModeLayout />,  // UI only
				children: [
					{
						path: "baseline",
						element: <TesterMode baseline />
					},
					{
						path: "baseline/running",
						element: <TesterMode baseline />
					},
					{
						path: ":product_id/:phase_id",
						element: <TesterMode />
					},
					{
						path: ":product_id/:phase_id/running",
						element: <TesterMode />
					},
				]
			},
			{
				path: "survey",
				element: <TesterSectionEnhancedLayout />,
				children: [
					{
						path: ":product_id/:question_id",
						element: <TesterSurveyLayout />
					}
				]
			},
			{
				path: "end",
				element: <TesterSectionEnhancedLayout><TesterSectionEnd /></TesterSectionEnhancedLayout>
			}
		]
	},
])

export const endpoint = TBOX_MODE ? REACT_APP_BE_ADDRESS : awsmobile.aws_cloud_logic_custom.find(el => el.name === "AdminQueries")?.endpoint || ""
function App() {
	const { setHandle } = useFullScreenStore()
	const handle = useFullScreenHandle()

	useEffect(() => {
		setHandle(handle)
	}, [])

	const { open, severity, message, closeSnackbar } = useSnackbarStore((state) => ({
		open: state.open,
		severity: state.severity,
		message: state.message,
		closeSnackbar: state.closeSnackbar
	}))
	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return
		}
		closeSnackbar()
	}

	return (
		<>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
				<ThemeProvider theme={customTheme}>
					<CssBaseline />
					<QueryClientProvider client={queryClient}>
						<FullScreen handle={handle}>
							<RouterProvider router={router} />
							<CustomSnackbar
								open={open}
								handleClose={handleClose}
								severity={severity}
								message={message}
							/>
						</FullScreen>
					</QueryClientProvider>
				</ThemeProvider>
			</LocalizationProvider>
		</>
	)
}

export default App
