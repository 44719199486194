import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import RouterOutlinedIcon from "@mui/icons-material/RouterOutlined"
import { CardActions, Grid, IconButton, styled, Tooltip } from "@mui/material"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import dayjs from "dayjs"

import { TBox } from "../types/genericTypes"
import {
	DashboardCardParagraphTypography,
	DashboardCardParagraphTypographyBold,
	DashboardCardTitleTypography
} from "./customTypography"

interface TboxProps {
	tbox: TBox,
	onEdit: () => void,
	onDelete: () => void,
	disableEdit?: boolean,
	disableDelete?: boolean
}

const StyledCard = styled(Card)(() => ({
	position: "relative",
	padding: 0
})) as typeof Card

const StyledIconContainerGrid = styled(Grid)(() => ({
	backgroundColor: "white",
	width: "80px",
	height: "80px",
	borderRadius: "50%",
	border: "5px solid #88B5B7"
})) as typeof Grid

const iconStyle = {
	fontSize: "50px",
	color: "#88B5B7"
}

const StyledIconButton = styled(IconButton)(
	{
		padding: 0,
		width: 16,
		height: 11,
	}
) as typeof IconButton
const cardContentStyle: object = {
	padding: 0,
	paddingBottom: 0,
	zIndex: 999,
	position: "relative",
	height: "100%"
}
const cardActionsStyle = {
	justifyContent: "flex-end",
	width: "inherit",
	backgroundColor: "#88B5B7",
	height: "35px",
	padding: "0 20px"
}
export default function TBoxCard(props: TboxProps) {

	return (
		<StyledCard
			sx={{
				minWidth: "300px",
				height: "200px",
			}}
		>
			<CardContent style={cardContentStyle}>
				{/* action bar */}
				<Grid container>
					<CardActions sx={cardActionsStyle}>
						{!props.disableEdit &&
							<StyledIconButton onClick={props.onEdit}>
								<EditIcon sx={{ fontSize: "16px" }} />
							</StyledIconButton>}
						{!props.disableDelete &&
							<StyledIconButton onClick={props.onDelete}>
								<DeleteIcon sx={{ fontSize: "16px" }} />
							</StyledIconButton>}
					</CardActions>
				</Grid>
				<Grid container alignItems="center" sx={{ height: "calc(100% - 35px)" }}>
					<Grid container justifyContent="center" item xs={6}>
						<StyledIconContainerGrid item container justifyContent="center" alignItems="center">
							<RouterOutlinedIcon sx={iconStyle} />
						</StyledIconContainerGrid>
					</Grid>
					<Grid container item xs={6} alignItems="flex-start" direction="column">
						<DashboardCardTitleTypography
							sx={{
								marginBottom: 1,
								color: "#88B5B7",
								maxWidth: "150px",
								overflowWrap: "break-word"
							}}
						>
							{props.tbox.name}
						</DashboardCardTitleTypography>
						{props.tbox.download_sync_at &&
							<Grid container direction="column">
								<Grid item>
									<DashboardCardParagraphTypographyBold>Last sync</DashboardCardParagraphTypographyBold>
								</Grid>
								<Grid item>
									{props.tbox.upload_sync_at &&
										<Tooltip title="Upload" placement="top">
											<DashboardCardParagraphTypography>
												{dayjs.utc(props.tbox.upload_sync_at).format("YYYY/MM/DD HH:mm:ss")}
											</DashboardCardParagraphTypography>
										</Tooltip>
									}
								</Grid>
								<Grid item>
									<Tooltip title="Download" placement="top">
										<DashboardCardParagraphTypography>
											{dayjs.utc(props.tbox.download_sync_at).format("YYYY/MM/DD HH:mm:ss")}
										</DashboardCardParagraphTypography>
									</Tooltip>
								</Grid>
							</Grid>
						}
					</Grid>
				</Grid>
			</CardContent>
		</StyledCard>
	)
}