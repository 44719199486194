import { closestCenter, DndContext, DragEndEvent, UniqueIdentifier } from "@dnd-kit/core"
import { arrayMove, SortableContext } from "@dnd-kit/sortable"
import { Alert, Grid } from "@mui/material"
import _ from "lodash"
import { useEffect, useState } from "react"
import { Control, UseFormGetValues, UseFormRegister, UseFormWatch } from "react-hook-form"
import { useQuery } from "react-query"

import { CustomButton } from "../../../components/buttons/CustomButton"
import { DashboardCardTitleTypography } from "../../../components/customTypography"
import { phaseQueries } from "../../../networking/networking"
import { Phase } from "../../../types/genericTypes"
import { hasPhasesDuplicateNames } from "../../../utils"
import { getPhase } from "../../dashboard/components/DashboardProductCard"
import { NewTemplateFormInput } from "../Templates"
import { TemplatePhaseRow } from "./TemplatePhaseRow"


interface props {
	register: UseFormRegister<NewTemplateFormInput>,
	setFormStep: React.Dispatch<React.SetStateAction<1 | 2 | 3>>
	control: Control<NewTemplateFormInput, unknown>
	sortedPhasesIDs: UniqueIdentifier[]
	watch: UseFormWatch<NewTemplateFormInput>
	setSortedPhasesIDs: React.Dispatch<React.SetStateAction<UniqueIdentifier[]>>
	getValues: UseFormGetValues<NewTemplateFormInput>
}

export const TemplateFormPhases = (props: props) => {
	// const [sortedPhasesIDs, setSortedPhasesIDs] = useState<UniqueIdentifier[]>([])
	const [isIncomplete, setIsIncomplete] = useState(true)

	const { data: phases = [], isFetching: isPhasesFetching } = useQuery(
		phaseQueries.listPhases.name,
		phaseQueries.listPhases.fn,
		{
			select: data => _.orderBy(data, "order", "asc"),
			onSuccess: data => {
				props.setSortedPhasesIDs(data.map(phase => phase.id))
			}
		}
	)

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event
		if (over) {
			if (active.id !== over.id) {
				props.setSortedPhasesIDs(ids => {
					const activeIndex = ids.indexOf(active.id)
					const overIndex = ids.indexOf(over.id)
					return arrayMove(ids, activeIndex, overIndex)
				})
			}
		}
	}

	const watchPhases = props.watch("phases")
	const getValuesPhases = props.getValues("phases")


	useEffect(() => {
		const phases = _.filter(Object.values(getValuesPhases), { "selected": true })

		if (phases.length > 0) {
			const incompleteField = _.find(phases, (phase) => phase.duration < 6 || phase.duration === undefined || isNaN(phase.duration) || (phase.id > 8 && phase.name.length === 0))
			setIsIncomplete(Boolean(incompleteField))
		} else {
			setIsIncomplete(true)
		}
	}, [props.getValues()])

	useEffect(() => {
		// Serve per ordinare le fasi.
		// Usa prima l'ordinamento predefinito dalla tabella delle fasi, poi usa l'ordine personalizzato della fase nel prodotto
		// La logica di questa funzione non è spiegabile agli esseri umani. Sembra funzionare, accontentiamoci
		if (isPhasesFetching) return
		const orderedPhases = Array<Phase | undefined>(phases.length).fill(undefined)

		phases.forEach((phase, index) => {
			const productPhase = phases.find(p => p.id === phase.id)
			if (!productPhase) {
				orderedPhases[index] = phase
			}
		})
		_.sortBy(phases, "order").forEach(phase => {
			const firstEmptyPhase = orderedPhases.findIndex(p => p === undefined)
			if (firstEmptyPhase === -1) {
				console.log("errore, non dovrei essere qui")
				return
			}
			orderedPhases[firstEmptyPhase] = {
				id: phase.id,
				name: phase.name || "",
				order: phase.order,
			}
		})
		props.setSortedPhasesIDs(orderedPhases.map(phase => phase?.id || 0))
	}, [isPhasesFetching, phases])

	return (
		<>
			<Grid container direction="column" spacing={2}>
				<Grid item container justifyContent="space-between" xs={12} paddingX={1} sx={{ marginBottom: 2 }}>
					<Grid item>
						<DashboardCardTitleTypography>Phases</DashboardCardTitleTypography>
					</Grid>
					<Grid item>
						<DashboardCardTitleTypography>Duration</DashboardCardTitleTypography>
					</Grid>
				</Grid>

				<DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
					<SortableContext items={props.sortedPhasesIDs}>
						{_.sortBy(phases, item => props.sortedPhasesIDs.indexOf(item.id)).map((phase) =>
							<Grid item xs={12} key={phase.id} sx={{ marginBottom: 1 }}>
								{/* TODO: passare l'immagine corretta */}
								<TemplatePhaseRow
									control={props.control}
									register={props.register}
									icon={getPhase(phase.name.toLowerCase())}
									phase={phase}
									setIsIncomplete={setIsIncomplete}
								/>
							</Grid>
						)}
					</SortableContext>
				</DndContext>
			</Grid>
			{hasPhasesDuplicateNames(phases, watchPhases) &&
				<Grid item>
					<Alert severity="error"> This phase exists as a standard phase, please select it from the list</Alert>
				</Grid>
			}
			<Grid item container justifyContent="center" spacing={2} sx={{ marginTop: 3 }}>
				<Grid item>
					<CustomButton text="Back" action={() => props.setFormStep(1)} />
				</Grid>
				<Grid item>
					<CustomButton primary text="Next" action={() => props.setFormStep(3)} disabled={isIncomplete || hasPhasesDuplicateNames(phases, watchPhases)} />
				</Grid>
			</Grid>
		</>
		// </Grid>
		// </Grid>
	)
}
