// import AddIcon from "@mui/icons-material/Add"
import { Chip, Grid, styled } from "@mui/material"

import { ButtonTypography, ParagraphTypography } from "../../../components/customTypography"

interface listProps {
	sectionTitle: string,
	list?: string[],
	onChipClick?: React.Dispatch<React.SetStateAction<string | undefined>>,
	selectedChip?: string
	clickable?: boolean
}

export const List = (props: listProps) => {
	const MainContainerGrid = styled(Grid)(({ theme }) => ({
		height: "auto",
		borderLeft: `3px solid ${theme.palette.primary.main}`,
		alignItems: "center",
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		justifyContent: "flex-start",
		gap: theme.spacing(1)
	})) as typeof Grid

	const StyledChip = styled(Chip)(({ theme }) => ({
		backgroundColor: "white",
		border: `1px solid ${theme.palette.text.secondary}`,
		borderRadius: "10px",
		cursor: props.clickable === false ? "default" : "pointer"
	})) as typeof Chip

	const StyledSelectedChip = styled(Chip)(({ theme }) => ({
		backgroundColor: theme.palette.primary.light,
		borderRadius: "10px",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.primary.light,
		}
	})) as typeof Chip


	return (
		<Grid container sx={{ marginBottom: 2 }}>
			<ParagraphTypography sx={{ marginBottom: 2 }}>{props.sectionTitle}</ParagraphTypography>
			<MainContainerGrid container>
				{props.list?.map((listItem, i) =>
					<Grid key={`${listItem + i}`} item>
						{props.selectedChip === listItem ?
							<StyledSelectedChip label={<ButtonTypography color="text.primary">{listItem}</ButtonTypography>} onDelete={undefined} clickable={props.clickable === false ? false : true} onClick={() => { props.onChipClick && props.onChipClick(listItem) }} />
							:
							<StyledChip label={<ButtonTypography color="text.secondary">{listItem}</ButtonTypography>} onDelete={undefined} clickable={props.clickable === false ? false : true} onClick={() => { props.onChipClick && props.onChipClick(listItem) }} />
						}
					</Grid>
				)}
				{/* <AddIcon
					color="primary"
					sx={{
						fontSize: "33px",
						cursor: "pointer"
					}} /> */}
			</MainContainerGrid >
		</Grid>
	)
}
