import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined"
import { Dialog, DialogContent, DialogProps, DialogTitle, ModalProps } from "@mui/material"
import { PropsWithChildren } from "react"

import { DashboardCardTitleTypography, H1Typography } from "./customTypography"

interface Props {
	open: boolean
	onClose?: ModalProps["onClose"]
	title?: React.ReactElement | string,
	subtitle?: string
	dialogProps?: DialogProps
	colored?: boolean
	removeCloseIcon?: boolean
}

const CustomDialog = (props: PropsWithChildren<Props>) =>
	<Dialog
		fullWidth
		maxWidth="md"
		open={props.open}
		onClose={props.onClose}
		disableEscapeKeyDown
		{...props.dialogProps}
	>
		{!props.removeCloseIcon &&
			<HighlightOffOutlinedIcon
				sx={{
					cursor: "pointer",
					position: "absolute",
					top: 10,
					right: 10
				}}
				onClick={e => props.onClose && props.onClose(e, "backdropClick")}
			/>
		}
		{props.title ?
			<DialogTitle textAlign="center" marginTop={4} marginBottom={3}>
				<H1Typography color={props.colored ? "#D8702E" : undefined}>{props.title}</H1Typography>
				{props.subtitle ?
					<DashboardCardTitleTypography
						color="text.secondary"
						sx={{ marginTop: "10px" }}
					>{props.subtitle}</DashboardCardTitleTypography>
					: undefined}
			</DialogTitle>
			: undefined
		}
		<DialogContent>
			{props.children}
		</DialogContent>
	</Dialog>

export default CustomDialog
