import { closestCenter, DndContext, DragEndEvent, UniqueIdentifier } from "@dnd-kit/core"
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { Grid } from "@mui/material"
import _ from "lodash"
import { Control, UseFormRegister } from "react-hook-form"
import { useQuery } from "react-query"

import { questionsQueries } from "../../../networking/networking"
import { NewTemplateFormInput } from "../Templates"
import { TemplateQuestionRow } from "./TemplateQuestionRow"

interface props {
	register: UseFormRegister<NewTemplateFormInput>,
	setFormStep: React.Dispatch<React.SetStateAction<1 | 2 | 3>>
	control: Control<NewTemplateFormInput, unknown>
	sortedQuestionsIDs: UniqueIdentifier[]
	setSortedQuestionsIDs: React.Dispatch<React.SetStateAction<UniqueIdentifier[]>>
}
export const TemplateFormQuestions = (props: props) => {
	// const [sortedQuestionsIDs, setSortedQuestionsIDs] = useState<UniqueIdentifier[]>([])

	const { data: questions = [] } = useQuery(
		questionsQueries.listQuestions.name,
		questionsQueries.listQuestions.fn,
		{
			onSuccess: data => {
				props.setSortedQuestionsIDs(data.map(question => question.id))
			}
		}
	)

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event
		if (over) {
			if (active.id !== over.id) {
				props.setSortedQuestionsIDs(ids => {
					const activeIndex = ids.indexOf(active.id)
					const overIndex = ids.indexOf(over.id)
					return arrayMove(ids, activeIndex, overIndex)
				})
			}
		}
	}

	return (
		<>
			<Grid container direction="row">
				<DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
					<SortableContext items={props.sortedQuestionsIDs} strategy={verticalListSortingStrategy}>
						{_.sortBy(questions, item => props.sortedQuestionsIDs.indexOf(item.id)).filter(question => question.company_id === null).map((question, i, items) => {
							return (
								<Grid container item key={question.id} sx={{ borderBottom: i !== items.length - 1 ? "1px solid black" : undefined }}>
									<TemplateQuestionRow control={props.control} register={props.register} question={question} />
								</Grid>
							)
						})}
					</SortableContext>
				</DndContext>
			</Grid>
		</>
	)
}
