import { Grid } from "@mui/material"
import { AxiosError } from "axios"
import _ from "lodash"
import { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import { CustomButton } from "../../../../components/buttons/CustomButton"
import SubmitButton from "../../../../components/buttons/submitButton"
import { projectConfigurationQueries, projectQueries, templatesQueries } from "../../../../networking/networking"
import { useOperatorStore, useSnackbarStore, useUserStore } from "../../../../stateManagement"
import { userCanEditProject } from "../../../../utils"
import { TemplateCard } from "./components/TemplateCard"



const ConfigureProjectTemplate = () => {
	const [selectedTemplateId, setSelectedTemplateId] = useState<number>()
	const isUserOperator = useOperatorStore().isUserOperator
	const userRole = useUserStore((state) => state.userRole)
	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)
	const { project_id = "" } = useParams()

	const { data: templates = [] } = useQuery(
		templatesQueries.listTemplates.name,
		templatesQueries.listTemplates.fn,
		{
			select: templates => _.orderBy(templates, ["created_at"], ["desc"])
		}
	)

	const { data: project } = useQuery(
		[projectQueries.getProject.name, parseInt(project_id)],
		() => projectQueries.getProject.fn(parseInt(project_id)),
		{
			onSuccess: (data) => {
				if (data.template_id) {
					setSelectedTemplateId(data.template_id)
				}
			}
		}
	)

	const userCanEdit = project ? userCanEditProject(project, userRole) : false

	const navigate = useNavigate()

	const selectTemplateMutation = useMutation(
		projectConfigurationQueries.selectTemplate.name,
		projectConfigurationQueries.selectTemplate.fn,
		{
			onSuccess: () => {
				navigateToNextPage()
			},
			onError: (error: AxiosError) => {
				openSnackbar("error", error?.response?.status === 400 ? error?.response?.data as string : "An error occurred while selecting the template")
			}

		})

	const handleSelectedTemplateClick = () => {
		if (project?.products_count === 0 && selectedTemplateId) {
			selectTemplateMutation.mutate({
				project_id: parseInt(project_id),
				template_id: selectedTemplateId
			})
		}
		navigateToNextPage()
	}

	const handleNoSelectedTemplateClick = () => {
		if (project?.products_count === 0 && project.template_id !== selectedTemplateId) {
			selectTemplateMutation.mutate({
				project_id: parseInt(project_id),
				template_id: null
			})
		} else {
			navigateToNextPage()
		}
	}

	const navigateToNextPage = () => {
		navigate(`/projects/${project_id}/products`)
	}

	const handleTemplateCardClick = (template_id: number) => {
		if (selectedTemplateId === template_id) {
			setSelectedTemplateId(undefined)
		} else {
			setSelectedTemplateId(template_id)
		}
	}

	return (
		<Grid container spacing={3}>
			<Grid item container spacing={3}>
				{templates.map(template => (
					<Grid item key={template.id}>
						<TemplateCard
							template={template}
							selected={selectedTemplateId === template.id}
							disableSelection={isUserOperator || project?.products_count !== 0 || !userCanEdit}
							handleClick={() => handleTemplateCardClick(template.id)}
							onUseTemplate={() => selectTemplateMutation.mutate({
								project_id: parseInt(project_id),
								template_id: template.id
							})}
						/>
					</Grid>
				))}
			</Grid>
			<Grid item container justifyContent="flex-end" alignItems="flex-end" spacing={2}>
				<Grid item>
					{isUserOperator || !userCanEdit ?
						<CustomButton
							text="Next"
							primary
							action={navigateToNextPage}
						/>
						:
						<SubmitButton
							text="Use Selected"
							disabled={selectedTemplateId === undefined}
							onClick={handleSelectedTemplateClick}
						/>
					}
				</Grid>
				{(!isUserOperator && userCanEdit) &&
					<Grid item>
						<CustomButton
							text="No template"
							disabled={selectedTemplateId !== undefined}
							action={handleNoSelectedTemplateClick}
						/>
					</Grid>
				}
			</Grid>
		</Grid>
	)
}

export default ConfigureProjectTemplate
