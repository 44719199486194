import {
	Grid,
	Paper,
} from "@mui/material"
import {styled} from "@mui/material/styles"
import {Outlet} from "react-router-dom"

import cerchio_svg_legno from "../assets/backgrounds/cerchio_svg_legno.svg"
import croce_svg_abete from "../assets/backgrounds/croce_svg_abete.svg"
import croce_svg_legno from "../assets/backgrounds/croce_svg_legno.svg"
import frecce_svg from "../assets/backgrounds/frecce_svg.svg"
import Vector from "../assets/backgrounds/Vector.svg"
import thimus_wizard_logo from "../assets/logos/thimus_wizard_logo.svg"

//---------------STYLE --------------------------
const StyledGrid = styled(Grid)(() => ({
	position: "relative",
	minHeight: "100vh",
	backgroundImage: `url(${Vector})`,
	backgroundSize: "cover",
	backgroundRepeat: "no-repeat",
	overflow: "hidden"
})) as typeof Grid

const LoginPaper = styled(Paper)(({ theme }) => ({
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	minHeight: "70%",
	minWidth: "60%",
	padding: "70px 30px",
	[theme.breakpoints.down("sm")]: {
		minWidth: "100%",
		minHeight: "100%",
	},
})) as typeof Paper


const ArrowIconStyle: object = {
	maxHeight: "160px",
	position: "absolute",
	top: 60,
	left: -50,
}

const lightCrossIconStyle: object = {
	maxHeight: "300px",
	position: "absolute",
	bottom: -50,
	left: -50,
}

const darkCrossIconStyle: object = {
	maxHeight: "300px",
	position: "absolute",
	top: -50,
	right: -50,
}

const circleIconStyle: object = {
	maxHeight: "350px",
	position: "absolute",
	bottom: 80,
	right: -200,
}

const logoStyle: object = {
	maxWidth: "60%",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)"
}
//-----------------------------------

export default function AuthLayout () {
	return (
		<Grid container>
			{/* LEFT SIDE */}
			<Grid item xs={12} md={6} style={{ position: "relative" }}>
				<LoginPaper sx={{ boxShadow: "1px 12px 15px 5px rgba(216,112,46,0.1)" }} >
					<Outlet />
				</LoginPaper>
			</Grid>

			{/* RIGHT SIDE */}
			<StyledGrid item xs={0} md={6}>
				{/* circle SVG */}
				<img
					alt="svg icon"
					src={cerchio_svg_legno}
					style={circleIconStyle}
				/>
				{/* croce light SVG */}
				<img
					alt="svg icon"
					src={croce_svg_abete}
					style={darkCrossIconStyle}
				/>
				{/* croce dark SVG */}
				<img
					alt="svg icon"
					src={croce_svg_legno}
					style={lightCrossIconStyle}
				/>
				{/* arrows SVG */}
				<img
					alt="svg icon"
					src={frecce_svg}
					style={ArrowIconStyle}
				/>
				<img
					alt="svg icon"
					src={thimus_wizard_logo}
					style={logoStyle}
				/>
			</StyledGrid>

		</Grid>
	)
}
