import { Grid, TextField } from "@mui/material"
import { UseFormRegister, UseFormWatch } from "react-hook-form"

import { CustomButton } from "../../../components/buttons/CustomButton"
import { ParagraphTypography } from "../../../components/customTypography"
import { NewTemplateFormInput } from "../Templates"

interface props {
	register: UseFormRegister<NewTemplateFormInput>,
	setFormStep: React.Dispatch<React.SetStateAction<1 | 2 | 3>>,
	watch: UseFormWatch<NewTemplateFormInput>
}

export const TemplateFormGeneral = (props: props) => {
	const name = props.watch("name")
	const description = props.watch("description")

	return (
		<Grid container alignItems="center" spacing={2}>
			{/* NAME */}
			<Grid item container>
				<Grid item xs={3}>
					<ParagraphTypography>Name*</ParagraphTypography>
				</Grid>
				<Grid item xs={9}>
					<TextField
						{...props.register("name")}
						placeholder="Write the template name here"
						fullWidth
					/>
				</Grid>
			</Grid>

			{/* DESCRIPTION */}
			<Grid item container>
				<Grid item xs={3}>
					<ParagraphTypography>Description*</ParagraphTypography>
				</Grid>
				<Grid item xs={9}>
					<TextField
						{...props.register("description")}
						defaultValue=""
						placeholder="Write the template description here"
						fullWidth
						multiline
						minRows={4}
						maxRows={6}
					/>
				</Grid>
			</Grid>
			<Grid item container justifyContent="center" marginTop={4}>
				<Grid item>
					<CustomButton text="Next" primary action={() => { props.setFormStep(2) }} disabled={!name || !description} />
				</Grid>
			</Grid>
		</Grid>
	)
}
