import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material"
import React, { useState } from "react"
import { Control, Controller } from "react-hook-form"

import { ParagraphTypography, ProjectMenuTitleTypography } from "../../../../../components/customTypography"
import { LanguageButton } from "../../../../../components/LanguageButton"
import { language, Question } from "../../../../../types/genericTypes"
import { NewAnswerFormInput } from "../../TesterSurveyLayout"

interface questionProps {
	question: Question
	language: language
	control?: Control<NewAnswerFormInput> | undefined
	preview?: boolean
	getValues: () => NewAnswerFormInput
}

//---------------STYLE --------------------------


// ---------------------------------


export const TesterMultiAnswer = (props: questionProps) => {
	const [currentLang, setCurrentLang] = useState<language>(props.language)

	const handleCheck = (checkedId: number) => {
		const { multiple_answer_response: ids } = props.getValues()
		const newIds = ids?.includes(checkedId)
			? ids?.filter((id) => id !== checkedId)
			: [...(ids ?? []), checkedId]
		return newIds
	}

	return (
		<>
			<Grid
				item
				container
				justifyContent="center"
				flexDirection="column"
				padding={2}
				rowGap={1}
			>
				{props.question.questions_i18n.filter(question => question.language === currentLang).map((question) =>
					<ProjectMenuTitleTypography textAlign="center" key={question.value}>{question.value}</ProjectMenuTitleTypography>
				)}
				<ParagraphTypography textAlign="center">Choose more than one answer</ParagraphTypography>
				{props.preview &&
					<Grid item container gap={1} justifyContent="center">
						{props.question.questions_i18n && props.question.questions_i18n.map(item =>
							<React.Fragment key={item.language}>
								<Grid item>
									<LanguageButton
										language={item.language}
										action={() => setCurrentLang(item.language)}
										isSelected={item.language === currentLang}
									/>
								</Grid>
							</React.Fragment >
						)}
					</Grid>
				}
			</Grid>
			<Grid
				marginX="10%"
				marginBottom={3}
				sx={{
					border: "1px solid #CCCCCC",
					borderStyle: "dashed"
				}}
			/>
			<Grid item container justifyContent="center">
				<Grid item container>
					<FormGroup>
						{props.question.possible_answers.map((possible_answer) => {
							return <React.Fragment key={possible_answer.label}>
								{possible_answer.possible_answers_i18n?.filter(answer => answer.language === currentLang).map((answer) => {
									return props.control ?
										<Controller
											key={answer.possible_answer_id}
											name="multiple_answer_response"
											control={props.control}
											rules={{ required: true }}
											render={({ field }) =>
												<FormControlLabel
													value={answer.possible_answer_id}
													name="multiple_answer_response"
													checked={field.value?.includes(answer.possible_answer_id) || false}
													control={
														<Checkbox
															onChange={() => field.onChange(handleCheck(answer.possible_answer_id))}
														/>
													}
													label={answer.value}
													labelPlacement="end"
												/>
											}
										/>
										:
										<FormControlLabel
											value={answer.possible_answer_id}
											name="multiple_answer_response"
											control={<Checkbox />}
											label={answer.value}
											labelPlacement="end"
										/>
								})}
							</React.Fragment>
						})}
					</FormGroup>
				</Grid>
			</Grid>
		</>
	)
}
