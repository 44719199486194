import { CircularProgress, Grid } from "@mui/material"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import { CustomButton } from "../../../components/buttons/CustomButton"
import SubmitButton from "../../../components/buttons/submitButton"
import { operatorQueries, projectQueries } from "../../../networking/networking"
import { useOperatorStore, useUserStore } from "../../../stateManagement"
import { userCanEditProject } from "../../../utils"
import UserCard from "../../users/components/userCard"


const ConfigureProjectOperators = () => {
	const [selectedOperatorsID, setSelectedOperatorsID] = useState<number[]>([])

	const { project_id = "" } = useParams()
	const navigate = useNavigate()
	const isUserOperator = useOperatorStore().isUserOperator
	const userRole = useUserStore((state) => state.userRole)

	const { data: operatorsUsers = [], isFetching: isOperatorsFetching } = useQuery(
		operatorQueries.listOperators.name,
		operatorQueries.listOperators.fn,
	)

	const { data: originalSelectedOperators = [], isFetching: isOriginalSelectedOperatorsFetching } = useQuery(
		[projectQueries.listProjectOperators.name, parseInt(project_id)],
		() => projectQueries.listProjectOperators.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id)
		}
	)

	const { data: project, isFetching: isProjectFetching } = useQuery(
		[projectQueries.getProject.name, parseInt(project_id)],
		() => projectQueries.getProject.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id)
		}
	)

	const userCanEdit = project ? userCanEditProject(project, userRole) : false


	const patchProjectOperatorsMutation = useMutation(
		projectQueries.patchProjectOperators.name,
		projectQueries.patchProjectOperators.fn,
		{
			onSuccess: () => {
				// TODO: da modificare, non disabilitare un intera sezione, ma far andare l'utente in template e al massimo non fa nulla!
				navigateToNextPage()
			}
		}
	)

	const handleOperatorCardClick = (selectedOperatorCardID: number) => {
		if (selectedOperatorsID.includes(selectedOperatorCardID)) {
			setSelectedOperatorsID(selectedOperatorsID.filter(operator => operator !== selectedOperatorCardID))
		} else {
			setSelectedOperatorsID([...selectedOperatorsID, selectedOperatorCardID])
		}
	}
	const handleSelectedOperatorsSubmit = () => {
		const nowSelected = selectedOperatorsID.map(operator => parseInt(operator.toString()))
		const originalSelected = originalSelectedOperators.map(po => po.operator_id)
		if (_.isEqual(new Set(nowSelected), new Set(originalSelected))) {
			navigateToNextPage()
		} else {
			patchProjectOperatorsMutation.mutate({
				project_id: parseInt(project_id),
				operators: nowSelected
			})
		}
	}

	useEffect(() => {
		if (originalSelectedOperators.length > 0) {
			setSelectedOperatorsID(originalSelectedOperators.map(po => po.operator_id))
		}
	}, [originalSelectedOperators])

	if (isProjectFetching || isOperatorsFetching || isOriginalSelectedOperatorsFetching) {
		return (
			<Grid container justifyContent="center"><CircularProgress /></Grid>
		)
	}

	const navigateToNextPage = (pId = parseInt(project_id)) => {
		navigate(`/projects/${pId}/template`)
	}

	return (
		<Grid container spacing={3}>
			<Grid item container spacing={4}>
				{operatorsUsers.filter(operator => operator.company_id === project?.company_id).map((operator) => {
					const user = {
						Username: operator.fullname,
						Attributes: [{
							Name: "name",
							Value: operator.fullname
						},
						{
							Name: "email",
							Value: operator.email
						}],
						Groups: [{
							GroupName: "operator",
							Description: "description"
						}],
						UserCreateDate: operator.created_at,
						UserLastModifiedDate: operator.updated_at,
						Enabled: true,
						UserStatus: "test"
					}
					return (
						<Grid item key={operator.id}>
							<UserCard
								selected={selectedOperatorsID.includes(operator.id)}
								handleClick={() => handleOperatorCardClick(operator.id)}
								user={user}
								disableEdit
								disableDelete
								disableSelection={isUserOperator || !userCanEdit}
							/>
						</Grid>
					)
				})}
			</Grid>
			<Grid item container justifyContent="flex-end" alignContent="flex-end">
				<Grid item>
					{isUserOperator || !userCanEdit ?
						<CustomButton
							text="Next"
							primary
							action={() => navigateToNextPage()}
						/>
						:
						<SubmitButton
							text="Next"
							disabled={selectedOperatorsID.length === 0}
							onClick={handleSelectedOperatorsSubmit}
						/>
					}
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ConfigureProjectOperators
