import { SvgIcon, SvgIconProps } from "@mui/material"

const imageIcon = {
	overflow: "visible"
}
export function ConceptIcon(props: SvgIconProps) {
	return (
		<SvgIcon
			{...props}
			sx={{
				...props.sx,
				...imageIcon
			}}
		>
			<path d="M27.28 25.696L25.4561 21.688C26.5361 19.9359 27.0881 17.92 27.0881 15.856C27.0881 9.66398 22.0481 4.59998 15.8321 4.59998C9.6161 4.59998 4.6001 9.63998 4.6001 15.856C4.6001 22.072 9.6401 27.112 15.8561 27.112C17.9201 27.112 19.9361 26.536 21.6881 25.4799L25.6961 27.2799C25.8641 27.352 26.0321 27.3999 26.2001 27.3999C26.5121 27.3999 26.8241 27.2799 27.0401 27.0399C27.4001 26.6799 27.4961 26.152 27.2801 25.696L27.28 25.696ZM26.32 26.32C26.2961 26.3439 26.2 26.416 26.104 26.3679L21.856 24.4239C21.784 24.4 21.712 24.376 21.64 24.376C21.544 24.376 21.448 24.4 21.376 24.448C19.72 25.504 17.824 26.08 15.856 26.08C10.192 26.08 5.60802 21.496 5.60802 15.856C5.60802 10.216 10.192 5.60805 15.856 5.60805C21.52 5.60805 26.08 10.192 26.08 15.856C26.08 17.824 25.528 19.72 24.448 21.376C24.352 21.52 24.352 21.7121 24.424 21.856L26.368 26.104C26.416 26.224 26.368 26.2961 26.32 26.3201L26.32 26.32Z" />
			<path d="M10.8641 13.576C9.9761 13.576 9.2561 14.296 9.2561 15.1839V16.5279C9.2561 17.4159 9.9761 18.1359 10.8641 18.1359C11.752 18.1359 12.472 17.4159 12.472 16.5279V15.1839C12.4721 14.296 11.7521 13.576 10.8641 13.576ZM11.4641 16.528C11.4641 16.864 11.2001 17.128 10.8641 17.128C10.528 17.128 10.2641 16.864 10.2641 16.528V15.184C10.2641 14.848 10.528 14.584 10.8641 14.584C11.2001 14.584 11.4641 14.848 11.4641 15.184V16.528Z" />
			<path d="M15.856 13.576C14.968 13.576 14.248 14.296 14.248 15.1839V16.5279C14.248 17.4159 14.968 18.1359 15.856 18.1359C16.744 18.1359 17.464 17.4159 17.464 16.5279V15.1839C17.44 14.296 16.72 13.576 15.856 13.576ZM16.432 16.528C16.432 16.864 16.168 17.128 15.832 17.128C15.496 17.128 15.232 16.864 15.232 16.528V15.184C15.232 14.848 15.496 14.584 15.832 14.584C16.168 14.584 16.432 14.848 16.432 15.184V16.528Z" />
			<path d="M20.824 13.576C19.9361 13.576 19.2161 14.296 19.2161 15.1839V16.5279C19.2161 17.4159 19.9361 18.1359 20.824 18.1359C21.712 18.1359 22.432 17.4159 22.432 16.5279V15.1839C22.4321 14.296 21.7121 13.576 20.824 13.576ZM21.424 16.528C21.424 16.864 21.16 17.128 20.824 17.128C20.488 17.128 20.224 16.864 20.224 16.528V15.184C20.224 14.848 20.488 14.584 20.824 14.584C21.16 14.584 21.424 14.848 21.424 15.184V16.528Z" />
		</SvgIcon>
	)
}
