import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined"
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined"
import LunchDiningOutlinedIcon from "@mui/icons-material/LunchDiningOutlined"
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined"
import { Divider, Grid, Paper, styled, useTheme } from "@mui/material"
import _ from "lodash"
import { useQuery } from "react-query"
import { NavLink, NavLinkProps, Outlet, useMatches, useParams } from "react-router-dom"

import { CubeIcon } from "../../../components/customIcons/CubeIcon"
import { DefaultTextTypography, ProjectMenuSubtitleTypography, ProjectMenuTitleTypography } from "../../../components/customTypography"
import { Toolbar, ToolbarConfig } from "../../../components/Toolbar"
import { projectQueries } from "../../../networking/networking"
import { useOperatorStore } from "../../../stateManagement"


const CustomPaper = styled(Paper)(({ theme }) => ({
	backgroundColor: "white",
	overflowY: "auto",
	padding: theme.spacing(3),

	[theme.breakpoints.up("md")]: {
		height: "calc(100vh - 150px)",
	},

})) as typeof Paper

const StepperGrid = styled(Grid)(({ theme }) => ({
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "center",
	[theme.breakpoints.up("md")]: {
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
})) as typeof Grid

interface StepperItemGridProps {
	disabled?: boolean
}
const StepperItemGrid = styled(Grid, {
	shouldForwardProp: (prop) => prop !== "disabled"
})<StepperItemGridProps>(({ theme, disabled }) => ({
	cursor: disabled ? undefined : "pointer",
	"&:hover": {
		color: disabled ? undefined : theme.palette.primary.main
	},
	[theme.breakpoints.down("md")]: {
		width: "initial"
	},
}))

const CustomNavLink = (props: NavLinkProps & { disabled?: boolean }): JSX.Element | null => {
	const theme = useTheme()

	if (props.disabled) {
		if (props.children) {
			if (typeof props.children === "function") {
				return <div>{props.children({
					isActive: false,
					isPending: false,
					isTransitioning: false
				})}</div>
			}
			return <div>{props.children}</div>
		}
		return null
	}

	return (
		<NavLink
			{...props}
			style={{
				textDecoration: "none",
				color: theme.palette.text.primary,
				...props.style,
			}}
		/>
	)
}


const ConfigureProjectLayout = () => {
	//TODO: AGGIUNGERE FADE AL PAPER QUANDO IL CONTENUTO E' IN OVERFLOW

	const { project_id = "" } = useParams()

	const matches = useMatches()

	const { data: project } = useQuery(
		[projectQueries.getProject.name, parseInt(project_id)],
		() => projectQueries.getProject.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id)
		}
	)
	const toolbarConfig: ToolbarConfig = {
		title: project ? `Project ${project.name}` : "New Project",
		subtitle: "Let’s create something!",
	}
	const isUserOperator = useOperatorStore().isUserOperator

	const disableTemplate = () => {
		if (!isUserOperator) {
			if (project?.id && project?.products_count > 0) {
				return true
			} else if (!project_id) {
				return true
			}
		}
		return false
	}

	// Dovesse rompersi qui, molto probabilmente è perché non è stato messo il parametro handle durante la creazione della route in createBrowserRouter
	const [[title, subtitle]] = matches
		.filter((match) => Boolean(_.get(match.handle, "title")))
		.map((match) => ([_.get(match.handle, "title"), _.get(match.handle, "subtitle")]))

	return (
		<Grid container padding={3}>
			<Grid item xs={12} >
				<Toolbar config={toolbarConfig} />
			</Grid>
			<Grid item container spacing={2}>
				{/* STEPPER */}
				<Grid
					item
					xs={12}
					md={2}
				>
					<CustomPaper>
						<Grid
							container
							spacing={2}
							alignContent="flex-start"
						>
							<Grid item>
								<ProjectMenuTitleTypography>Settings</ProjectMenuTitleTypography>
								<ProjectMenuSubtitleTypography color="text.secondary" marginTop={1}>Project settings</ProjectMenuSubtitleTypography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<StepperGrid
								item
								container
								spacing={2}
							>
								{/* general */}
								<Grid item>
									<CustomNavLink
										to={"/projects/" + (project_id || "new") + "/general"}
									>
										{({ isActive }) => (
											<StepperItemGrid disabled={!project_id} container spacing={1} direction="column" alignItems="center">
												<Grid item>
													<CubeIcon color={isActive ? "primary" : undefined} sx={{ fontSize: "30px" }} />
												</Grid>
												<Grid item>
													<DefaultTextTypography color={isActive ? "primary" : undefined}>General</DefaultTextTypography>
												</Grid>
											</StepperItemGrid>
										)}
									</CustomNavLink>
								</Grid>

								{/* operators */}
								<Grid item>
									<CustomNavLink
										to={"/projects/" + project_id + "/operators"}
										disabled={!project_id}
									>
										{({ isActive }) => (
											<StepperItemGrid container disabled={!project_id} spacing={1} direction="column" alignItems="center">
												<Grid item>
													<AccountCircleOutlinedIcon color={isActive ? "primary" : undefined} sx={{ fontSize: "30px" }} />
												</Grid>
												<Grid item>
													<DefaultTextTypography color={isActive ? "primary" : undefined}>Operators</DefaultTextTypography>
												</Grid>
											</StepperItemGrid>
										)}
									</CustomNavLink>
								</Grid>

								{/* templates */}
								<Grid item>
									<CustomNavLink
										to={"/projects/" + project_id + "/template"}
										disabled={disableTemplate()}
									>
										{({ isActive }) => (
											<StepperItemGrid container disabled={disableTemplate()} spacing={1} direction="column" alignItems="center">
												<Grid item>
													<PostAddOutlinedIcon color={isActive ? "primary" : undefined} sx={{ fontSize: "30px" }} />
												</Grid>
												<Grid item>
													<DefaultTextTypography color={isActive ? "primary" : undefined}>Templates</DefaultTextTypography>
												</Grid>
											</StepperItemGrid>
										)}
									</CustomNavLink>
								</Grid>

								{/* products */}
								<Grid item>
									<CustomNavLink
										to={"/projects/" + project_id + "/products"}
										disabled={!project_id}
									>
										{({ isActive }) => (
											<StepperItemGrid container disabled={!project_id} spacing={1} direction="column" alignItems="center">
												<Grid item>
													<LunchDiningOutlinedIcon color={isActive ? "primary" : undefined} sx={{ fontSize: "30px" }} />
												</Grid>
												<Grid item>
													<DefaultTextTypography color={isActive ? "primary" : undefined}>Products</DefaultTextTypography>
												</Grid>
											</StepperItemGrid>
										)}
									</CustomNavLink>
								</Grid>

								{/* testers */}
								<Grid item>
									<CustomNavLink
										to={"/projects/" + project_id + "/testers"}
										disabled={!project_id}
									>
										{({ isActive }) => (
											<StepperItemGrid container disabled={!project_id} spacing={1} direction="column" alignItems="center">
												<Grid item>
													<GroupsOutlinedIcon color={isActive ? "primary" : undefined} sx={{ fontSize: "30px" }} />
												</Grid>
												<Grid item>
													<DefaultTextTypography color={isActive ? "primary" : undefined}>Testers</DefaultTextTypography>
												</Grid>
											</StepperItemGrid>
										)}
									</CustomNavLink>
								</Grid>
							</StepperGrid>
						</Grid>
					</CustomPaper>
				</Grid>
				{/* MAIN */}
				<Grid
					item
					xs={12}
					md={10}
				>
					<CustomPaper>
						<Grid
							container
							spacing={2}
							alignContent="flex-start"
						>
							<Grid item>
								<ProjectMenuTitleTypography>{title}</ProjectMenuTitleTypography>
								<ProjectMenuSubtitleTypography color="text.secondary" marginTop={1}>{subtitle}</ProjectMenuSubtitleTypography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Outlet />
							</Grid>
						</Grid>
					</CustomPaper>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ConfigureProjectLayout
