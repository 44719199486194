import { CancelOutlined, Warning } from "@mui/icons-material"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import {
	Card,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	FormGroup,
	Grid,
	IconButton,
	MenuItem,
	Select,
	styled,
	Tooltip,
	Typography
} from "@mui/material"
import { Fragment, useEffect } from "react"

import { ReactComponent as Omega } from "../../../../assets/icons/testerPhaseIcons/omega.svg"
import { ReactComponent as VolumeUp } from "../../../../assets/icons/testerPhaseIcons/volume_up.svg"
import { CustomButton } from "../../../../components/buttons/CustomButton"
import { BatteryIcon } from "../../../../components/customIcons/BatteryIcon"
import { BrainIcon, getColor } from "../../../../components/customIcons/BrainIcon"
import { ParagraphTypography, TitleTypography } from "../../../../components/customTypography"
import { Eeg, ProjectTester } from "../../../../types/genericTypes"
import {
	BatteryLevelEEGErrorType, BatteryLevelSIOErrorType,
	CheckImpedanceErrorType, CheckImpedanceType,
	FindEEGEEGErrorType, FindEEGSIOErrorType,
} from "../TesterConnection"

interface props {
	EEGs: Eeg[],
	tester: ProjectTester,
	selectedEEG: Eeg | undefined,
	selectedEEGsIdForOtherTesters: number[],
	selectEEG: (eeg: Eeg | undefined, tester_id: number) => void,
	checked: boolean
	setChecked: (value: boolean) => void
	findEeg: (eeg: Eeg) => void
	findEEGErrors: FindEEGEEGErrorType["data"][] | FindEEGSIOErrorType["data"][]
	batteryValue: number | undefined
	batteryLevelErrors: BatteryLevelEEGErrorType["data"][] | BatteryLevelSIOErrorType["data"][]
	check_impedance: (eeg: Eeg) => void,
	impedance_values: CheckImpedanceType["values"] | "waiting" | CheckImpedanceErrorType["data"][]
	rssiValue: number | "waiting" | undefined
}

// --------------Style-----------------

const TesterCardStyle = styled(Card)(({ theme }) => ({
	position: "relative",
	width: "100%",
	height: 115,
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	borderLeft: `7px solid ${theme.palette.error.main}`,
	paddingLeft: theme.spacing(5),
	paddingRight: theme.spacing(5),
})) as typeof Card

const selectedTesterCard = {
	borderLeftColor: "#5DAD5A",
	backgroundColor: "#DAD9D9"
}

const StyledIconButton = styled(IconButton)(
	{
		width: 40,
		height: 40,
		borderRadius: 12,
		backgroundColor: "#FFFFFF",
		boxShadow: "0px 4px 8px 3px #00000026",
	}
) as typeof IconButton
//----------------------------
export const TesterConnectionCard = (props: props) => {
	const handleSelectEeg = (eeg_id: number) => {
		const eeg = props.EEGs.find(eeg => eeg.id == eeg_id)
		props.selectEEG(eeg, props.tester.local_tester_id)
	}

	const handleCheckImpedance = () => {
		if (!props.selectedEEG) return
		props.check_impedance(props.selectedEEG)
	}

	// useEffect che cambia in base alla props.impedance_values, che esegue la logica se è ready o meno e modifica lo state Selected
	useEffect(() => {
		if (!props.impedance_values) return

		const impedanceAreOk = (): boolean => {
			if (Array.isArray(props.impedance_values) || props.impedance_values === "waiting") return false
			const colors = Object.keys(props.impedance_values).map(key => getColor(props.impedance_values, key as keyof CheckImpedanceType["values"]))
			// se anche un solo sensore/referenza è nera o rossa, allora le impedenze NON sono OK
			return !colors.some(color => ["black", "red"].includes(color))
		}

		const isAllImpedanceOk = impedanceAreOk()
		console.log("setChecked", isAllImpedanceOk)

		props.setChecked(isAllImpedanceOk)
	}, [props.impedance_values])


	const handleFirstBtnClick = () => {
		if (!props.selectedEEG) return
		props.findEeg(props.selectedEEG)
	}

	// eeg > suono > battery > impedance > brain > text > checkbox

	return (
		<Grid container>
			<Grid item>
				<TitleTypography marginLeft={1}>
					{props.tester.local_tester_id}
				</TitleTypography>
			</Grid>
			<TesterCardStyle sx={props.checked ? selectedTesterCard : undefined}>
				<Grid item container wrap="nowrap" gap={2}>
					<Grid item>
						<Select
							displayEmpty
							fullWidth
							value={props.selectedEEG?.id || -1}
							onChange={(event) => handleSelectEeg(parseInt(event.target.value + ""))}
							// defaultValue={props.EEGs[0].id}
							sx={{
								width: "180px",
								height: "40px",
								borderRadius: "4px",
								backgroundColor: props.checked ? "white" : "#CFD8DC"
							}}
						>
							<MenuItem value={-1}>Select an EEG</MenuItem>
							{props.EEGs.map((EEG) =>
								<MenuItem
									key={EEG.id}
									value={EEG.id}
									disabled={props.selectedEEGsIdForOtherTesters.includes(EEG.id)}
								>
									{EEG.id + (EEG.mac_address ? " (connected)" : " (disconnected)")}
								</MenuItem>
							)}
						</Select>
					</Grid>
					<Grid item container justifyContent="center" width="auto">
						<Tooltip
							title={Boolean(props.findEEGErrors.length) && `Errors: ${props.findEEGErrors.map(e => e.raw).join(", ")}`}
							placement="top-start"
						>
							<StyledIconButton
								disabled={!props.selectedEEG}
								onClick={handleFirstBtnClick}
								size="large"
							>
								<VolumeUp />
							</StyledIconButton>
						</Tooltip>
					</Grid>
					<Grid item container width="24px">
						{props.selectedEEG?.mac_address &&
							<Tooltip
								title={
									Boolean(props.batteryValue || props.batteryLevelErrors.length) && <Fragment>
										{props.batteryValue ? <Typography variant="body2">{props.batteryValue}%</Typography> : undefined}
										{props.batteryLevelErrors.length ? <Typography variant="body2">Errors: {props.batteryLevelErrors.map(e => e.raw).join(", ")}</Typography> : undefined}
									</Fragment>
								}
								placement="top-start"
							>
								<Grid item container alignItems="center">
									<BatteryIcon batteryValue={props.batteryValue} />
								</Grid>
							</Tooltip>
						}
					</Grid>
				</Grid>
				<Grid container justifyContent="center">
					<Tooltip
						title={Array.isArray(props.impedance_values) && props.impedance_values.length ? <Typography variant="body2">Errors: {props.impedance_values.map(e => e.raw).join(", ")}</Typography> : undefined}
						placement="top-start"
					>
						<CustomButton
							primary
							text={props.impedance_values === "waiting" ? "" : "Impedance"}
							disabled={!props.selectedEEG || !props.selectedEEG.mac_address || props.impedance_values === "waiting"}
							icon={props.impedance_values === "waiting" ?
								<Grid container justifyContent="center" alignItems="center">
									<CircularProgress
										size={24}
									/>
								</Grid>
								: Array.isArray(props.impedance_values) ? <Warning fill="white" /> : <Omega fill="white" />
							}
							action={handleCheckImpedance}
						/>
					</Tooltip>
				</Grid>
				<Grid item container alignItems="center" wrap="nowrap">
					<Grid item>
						{props.EEGs && (
							<BrainIcon
								sensors={props.impedance_values}
								rssi={props.rssiValue}
							/>
						)}
					</Grid>
					<Grid item>
						<IconButton>
							{props.checked ? <CheckCircleOutlineIcon color="success" fontSize="small" /> : <CancelOutlined color="error" fontSize="small" />}
						</IconButton>
					</Grid>
					<Grid item>
						{props.checked ? <ParagraphTypography>Ready to go</ParagraphTypography> : <ParagraphTypography>Not ready to go</ParagraphTypography>}
					</Grid>
				</Grid>
				<Grid item>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									checked={Boolean(props.checked)}
									onChange={(event) => props.setChecked(event.target.checked)}
									disabled={!props.selectedEEG?.mac_address}
								/>
							}
							label="Select"
						/>
					</FormGroup>
				</Grid>
			</TesterCardStyle>
		</Grid>
	)
}
