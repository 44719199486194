import { CircularProgress, Grid } from "@mui/material"
import _ from "lodash"
import { useQuery } from "react-query"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"

import { projectQueries, testersQueries } from "../../networking/networking"


const TesterSectionLayout = () => {
	const { project_id = "", local_tester_id = "" } = useParams()
	const navigate = useNavigate()
	const location = useLocation()

	const {
		data: single_session,
		isLoading: isSingleSessionLoading,
	} = useQuery(
		[testersQueries.getCurrentSingleSession.name, parseInt(project_id), parseInt(local_tester_id)],
		() => testersQueries.getCurrentSingleSession.fn(parseInt(project_id), parseInt(local_tester_id)),
		{
			enabled: Boolean(project_id) && Boolean(local_tester_id),
			onSuccess: (data) => {
				console.log("progress ss", "single_session", data)
				if (!data || data.completed_at) {
					console.log("progress ss", "!single_session || single_session.completed_at => redirect /end", data)
					return navigate("end")
				}
			}
		}
	)

	const { isLoading: isProjectLoading } = useQuery(
		[projectQueries.getProject.name, parseInt(project_id)],
		() => projectQueries.getProject.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id) && Boolean(single_session),
			onSuccess: project => {
				console.log("progress p", "project", project)
				if (!single_session) {
					console.log("progress p", "never here")
					// questo if dovrebbe essere inutile perché questa query viene eseguita solo se single_session esiste
					return navigate("end")
				}
				if (!project) {
					console.log("progress p", "!project => redirect /end")
					return navigate("end")
				}
				if (project.tester_mode) {
					console.log("progress p", "project.tester_mode")
					if (!single_session?.calibration_completed) {
						console.log("progress p", "project.tester_mode && !single_session?.calibration_completed")
						if (!location.pathname.includes("session/calibration")) {
							console.log("progress p", "project.tester_mode && !single_session?.calibration_completed && !location.pathname.includes('session/calibration') => redirect /welcome")
							return navigate("welcome")
						}
					} else if (!single_session.baseline_completed) {
						console.log("progress p", "project.tester_mode && !single_session.baseline_completed => redirect /session/baseline")
						return navigate("session/baseline")
					} else if (single_session?.current_product_id_to_respond !== null) {
						console.log("progress p", `project.tester_mode && single_session?.current_product_id_to_respond !== null => redirect /survey/${single_session.current_product_id_to_respond}/${single_session.current_question_to_respond?.id}`)
						return navigate(`survey/${single_session.current_product_id_to_respond}/${single_session.current_question_to_respond?.id}`)
					} else {
						console.log("progress p", `project.tester_mode => redirect /session/${single_session.current_product_id}/${single_session.current_phase_id}`)
						return navigate(`session/${single_session.current_product_id}/${single_session.current_phase_id}`)
					}
				} else {
					console.log("progress p", "!project.tester_mode", "ss:", single_session, "ss.current_product_id_to_respond:", single_session?.current_product_id_to_respond)
					if (single_session?.current_product_id_to_respond === null) {
						console.log("progress p", "!project.tester_mode && single_session?.current_product_id_to_respond === null => redirect /welcome")
						return navigate("welcome")
					} else {
						console.log("progress p",`!project.tester_mode => redirect /survey/${single_session.current_product_id_to_respond}/${single_session.current_question_to_respond?.id}`)
						return navigate(`survey/${single_session.current_product_id_to_respond}/${single_session.current_question_to_respond?.id}`)
					}
				}
			}
		}
	)

	const { isLoading: isProductsLoading } = useQuery(
		[projectQueries.getProducts.name, parseInt(project_id)],
		() => projectQueries.getProducts.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id),
			select: data => data.map(product => ({
				...product,
				phases: _.orderBy(product.phases, "order", "asc"),
				questions: _.orderBy(product.questions, "order", "asc")
			}))
		}
	)

	if (isSingleSessionLoading || isProjectLoading || isProductsLoading) {
		return <Grid container justifyContent="center"><CircularProgress /></Grid>
	}

	return <Outlet />

}

export default TesterSectionLayout