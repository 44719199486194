import { FormControlLabel, Grid, List, Radio, RadioGroup } from "@mui/material"
import React, { useState } from "react"
import { Control, Controller } from "react-hook-form"

import { ParagraphTypography, ProjectMenuTitleTypography } from "../../../../../components/customTypography"
import { LanguageButton } from "../../../../../components/LanguageButton"
import { language, Question } from "../../../../../types/genericTypes"
import { NewAnswerFormInput } from "../../TesterSurveyLayout"

interface questionProps {
	question: Question
	language: language
	control?: Control<NewAnswerFormInput>
	preview?: boolean
}

export const TesterRatingIndex = (props: questionProps) => {
	const [currentLang, setCurrentLang] = useState<language>(props.language)

	const ratingScale: number[] = []
	if ((props.question.min_value !== null && props.question.max_value !== null)) {
		for (let i = props.question.min_value; i <= props.question.max_value; i++) {
			ratingScale.push(i)
		}
	}

	const description = props.question.questions_i18n?.filter(description => description.language === currentLang).find(description => description.min_value_description)


	return (
		<>
			<Grid
				item
				container
				justifyContent="center"
				flexDirection="column"
				rowGap={1}
				padding={2}
			>
				<Grid item >
					{props.question.questions_i18n.filter(question => question.language === currentLang).map((question) =>
						<ProjectMenuTitleTypography textAlign="center" key={question.language}>{question.value}</ProjectMenuTitleTypography>
					)}
				</Grid>
				<Grid item>
					<ParagraphTypography textAlign="center">Choose a value from {props.question.min_value} (min) to {props.question.max_value} (max)</ParagraphTypography>
				</Grid>
				{props.preview &&
					<Grid item container gap={1} justifyContent="center">
						{props.question.questions_i18n && props.question.questions_i18n.map(item =>
							<React.Fragment key={item.language}>
								<Grid item>
									<LanguageButton
										language={item.language}
										action={() => setCurrentLang(item.language)}
										isSelected={item.language === currentLang}
									/>
								</Grid>
							</React.Fragment >
						)}
					</Grid>
				}
			</Grid>
			<Grid
				margin="3% 10%"
				sx={{
					border: "1px solid #CCCCCC",
					borderStyle: "dashed"
				}}
			/>

			<Grid item container justifyContent="center" gap={1} marginY={3}>
				<List
					sx={{
						display: "flex",
						overflowX: "auto",
						flexDirection: "row",
						justifyContent: "center"
					}}
				>
					{ratingScale && (props.question.min_value !== null && props.question.max_value !== null) && (
						ratingScale.map(number =>
							props.control ?
								<Controller
									key={number}
									name="rating_index_response"
									control={props.control}
									rules={{ required: true }}
									render={({ field }) => (
										<RadioGroup
											aria-label="answer"
											{...field}
											onChange={(e) => field.onChange(parseInt(e.target.value))}
										>
											<FormControlLabel
												value={number}
												checked={field.value === number}
												control={
													<Radio
														icon={<ProjectMenuTitleTypography>{number}</ProjectMenuTitleTypography>}
														checkedIcon={<ProjectMenuTitleTypography color="white">{number}</ProjectMenuTitleTypography>}
														disableRipple
														sx={{
															backgroundColor: field.value === number ? "#D8702E" : "white",
															width: "60px",
															height: "70px",
															borderRadius: "4px",
															boxShadow: "0px 2px 6.25px 0px #00000030",
															justifyContent: "center",
														}}
													/>
												}
												label={(ratingScale[0] === number ? description?.min_value_description : "") || (ratingScale[ratingScale.length - 1] === number ? description?.max_value_description : "")}
												labelPlacement="bottom"
											/>
										</RadioGroup>
									)}
								/>
								:
								<RadioGroup
									aria-label="answer"
									key={number}
								>
									<FormControlLabel
										value={number}
										control={
											<Radio
												icon={<ProjectMenuTitleTypography>{number}</ProjectMenuTitleTypography>}
												checkedIcon={<ProjectMenuTitleTypography color="#D8702E">{number}</ProjectMenuTitleTypography>}
												disableRipple
												sx={{
													backgroundColor: "white",
													width: "60px",
													height: "70px",
													borderRadius: "4px",
													boxShadow: "0px 2px 6.25px 0px #00000030",
													justifyContent: "center",
												}}
											/>
										}
										label={(ratingScale[0] === number ? description?.min_value_description : "") || (ratingScale[ratingScale.length - 1] === number ? description?.max_value_description : "")}
										labelPlacement="bottom"
									/>
								</RadioGroup>
						)
					)}
				</List>
			</Grid>
		</>
	)
}
