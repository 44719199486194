import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { Checkbox, Grid, IconButton } from "@mui/material"
import { useState } from "react"
import { Control, Controller, UseFormRegister, useWatch } from "react-hook-form"

import CustomDialog from "../../../../../components/CustomDialog"
import { ParagraphTypography } from "../../../../../components/customTypography"
// import { LanguageButton } from "../../../../../components/LanguageButton"
import { useOperatorStore } from "../../../../../stateManagement"
import { Project, Question } from "../../../../../types/genericTypes"
import { TesterEvaluationGrid } from "../../../../testerView/testerSurvey/questionary/questionType/TesterEvaluationGrid"
import { TesterMultiAnswer } from "../../../../testerView/testerSurvey/questionary/questionType/TesterMultiAnswer"
import { TesterRatingIndex } from "../../../../testerView/testerSurvey/questionary/questionType/TesterRatingIndex"
import { TesterSingleAnswer } from "../../../../testerView/testerSurvey/questionary/questionType/TesterSingleAnswer"
import { TesterVasScale } from "../../../../testerView/testerSurvey/questionary/questionType/TesterVasScale"
import { NewAnswerFormInput } from "../../../../testerView/testerSurvey/TesterSurveyLayout"
import { editModalModel } from "../ConfigureProjectProducts"
import { QuestionsFormValues } from "./ConfigureProductQuestionsForm"
interface props {
	question: Question,
	control: Control<QuestionsFormValues>,
	register: UseFormRegister<QuestionsFormValues>
	editMode: editModalModel
	templateId: number | null | undefined
	userCanEdit?: boolean
	disabled?: boolean
	project: Project
}

const dragIconStyle = {
	color: "text.secondary",
	cursor: "grab",
	"&:active": {
		cursor: "grabbing",
	},
	"&:focus": {
		outline: "none"
	}
}


export const QuestionRow = (props: props) => {
	const isUserOperator = useOperatorStore().isUserOperator
	const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)

	const isSelected = useWatch({
		control: props.control,
		name: `${props.question.id}.selected`
	})
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: props.question.id,
		disabled: !isSelected || Boolean(props.templateId) || !props.userCanEdit,
	})
	const draggableStyle = {
		transform: CSS.Transform.toString(transform),
		transition,
	}

	const isCheckboxDisabled = (checked: boolean, isDirty: boolean) => {
		if (props.disabled) return true
		if (!props.userCanEdit) {
			return true
		} else if (isUserOperator) {
			if (isDirty) {
				return false
			} else {
				if (checked) {
					return false
				} else {
					return true
				}
			}
		} else if (props.editMode) {
			if (props.templateId) {
				return true
			} else {
				return false
			}
		} else {
			return false
		}
	}

	const questionI18n = props.question.questions_i18n.find(i18n => i18n.language === "en") || props.question.questions_i18n[0]

	const handlePreviewModal = () => {
		switch (props.question.type) {
			case "evaluation_grid":
				return (
					<TesterEvaluationGrid
						question={props.question}
						language={props.project.language}
					/>
				)
			case "multiple_answers":
				return (
					<TesterMultiAnswer
						question={props.question}
						language={props.project.language}
						getValues={function (): NewAnswerFormInput {
							throw new Error("Function not implemented.")
						}}
					/>
				)
			case "rating_index":
				return (
					<TesterRatingIndex
						question={props.question}
						language={props.project.language}
					/>
				)
			case "single_answer":
				return (
					<TesterSingleAnswer
						question={props.question}
						language={props.project.language}
					/>
				)
			case "vas_scale":
				return (
					<TesterVasScale
						question={props.question}
						language={props.project.language}
					/>
				)
			default:
				break
		}
	}

	return (
		<Grid container ref={setNodeRef} flexWrap="nowrap" style={draggableStyle} alignItems="center">
			<Grid item display="flex">
				<DragIndicatorIcon
					sx={dragIconStyle}
					{...attributes}
					{...listeners}
				/>
			</Grid>
			<Grid item>
				<IconButton
					size="small"
					onClick={() => setIsPreviewModalOpen(true)}
				>
					<VisibilityIcon fontSize="small" />
				</IconButton>
			</Grid>
			<Grid item>
				<Controller
					control={props.control}
					name={`${props.question.id}.selected`}
					render={({ field, fieldState }) => <Checkbox {...field} checked={!!field.value} disabled={isCheckboxDisabled(!!field.value, fieldState.isDirty)} />}
				/>
			</Grid>
			<Grid item>
				<ParagraphTypography
					marginX={2}
					sx={{
						overflow: "hidden",
						textOverflow: "ellipsis",
					}}
				>
					{questionI18n.value}
				</ParagraphTypography>
			</Grid>

			{/* <Grid item container gap={1} width="fit-content">
				{props.question.questions_i18n.map((q) =>
					<Grid item key={q.language}>
						<LanguageButton
							language={q.language}
							disableClick
						/>
					</Grid>
				)}
			</Grid> */}
			<CustomDialog
				open={isPreviewModalOpen}
				onClose={() => { setIsPreviewModalOpen(false) }}
			>
				{handlePreviewModal()}
			</CustomDialog>
		</Grid>
	)
}
