import { SvgIcon, SvgIconProps } from "@mui/material"

const imageIcon = {
	overflow: "visible"
}
export function SmellIcon(props: SvgIconProps) {
	return (
		<SvgIcon
			{...props}
			sx={{
				...props.sx,
				...imageIcon
			}}
		>
			<path d="M20.1101 12.7C16.9601 8.92 13.6301 2.23 13.6001 2.14C13.4201 1.78005 13.0001 1.63005 12.6401 1.81002C12.2802 1.99 12.1302 2.41002 12.3101 2.76997C12.4301 3.06997 15.7301 9.69997 19.0001 13.63C21.1902 16.27 22.1801 18.19 22.0301 19.45C21.9702 19.9299 21.7601 20.29 21.3401 20.62C19.6001 21.9099 17.0501 21.37 15.4301 20.8C16.5701 20.44 17.2301 20.4099 17.2301 20.4099C17.6201 20.3799 17.9501 20.05 17.9201 19.6599C17.9201 19.2699 17.5602 18.9399 17.1701 18.9699C17.0801 18.9699 15.1001 19.0599 11.9801 20.7099C11.3502 21.0399 10.8101 21.0699 10.4502 20.8299C10.0902 20.5899 9.9101 20.1399 9.9402 19.6C9.97018 19.2099 10.1802 18.25 11.5002 17.65C11.8601 17.47 12.0101 17.05 11.8601 16.69C11.6801 16.3301 11.2601 16.1801 10.9002 16.3301C9.4902 16.96 8.62018 18.16 8.50015 19.5101C8.41011 20.5601 8.8601 21.5501 9.6402 22.0601C9.91023 22.2401 10.3902 22.4501 10.9902 22.4501C11.4402 22.4501 12.0102 22.3301 12.6402 22.0001C12.9102 21.8501 13.1803 21.7301 13.4502 21.6101C14.1702 21.97 16.3602 22.9301 18.6402 22.9301C19.8701 22.9301 21.1302 22.6601 22.1802 21.8501C22.9302 21.2801 23.3502 20.5601 23.4401 19.6902C23.68 17.92 22.6001 15.7299 20.1101 12.6999L20.1101 12.7Z" />
			<path d="M13.6001 25.3001C13.7801 24.9401 13.6301 24.4901 13.2701 24.3401C12.9102 24.1601 12.4901 24.3101 12.3102 24.6701C11.8602 25.5701 11.8602 26.68 12.3102 27.5801C12.5502 28.09 12.5502 28.69 12.3102 29.2001C12.1302 29.5601 12.2802 30.0101 12.6401 30.1601C12.7302 30.22 12.8501 30.22 12.9701 30.22C13.2401 30.22 13.5102 30.07 13.6301 29.8C14.0801 28.9 14.0801 27.7901 13.6301 26.89C13.36 26.41 13.36 25.81 13.6001 25.3001Z" />
			<path d="M17.05 25.3001C17.23 24.9401 17.08 24.4901 16.7201 24.3401C16.3601 24.1601 15.9101 24.3101 15.7601 24.6701C15.3101 25.5701 15.3101 26.68 15.7601 27.5801C16.0001 28.09 16.0001 28.69 15.7601 29.2001C15.5801 29.5601 15.7301 30.0101 16.0901 30.1601C16.1801 30.22 16.3 30.22 16.4201 30.22C16.6901 30.22 16.9601 30.07 17.08 29.8C17.53 28.9 17.53 27.7901 17.08 26.89C16.81 26.41 16.81 25.81 17.05 25.3001Z" />
			<path d="M20.5001 25.3001C20.6801 24.9401 20.5301 24.4901 20.1701 24.3401C19.8102 24.1601 19.3902 24.3101 19.2102 24.6701C18.7602 25.5701 18.7602 26.68 19.2102 27.5801C19.4502 28.09 19.4502 28.69 19.2102 29.2001C19.0302 29.5601 19.1802 30.0101 19.5402 30.1601C19.6302 30.22 19.7501 30.22 19.8701 30.22C20.1402 30.22 20.4102 30.07 20.5301 29.8C20.9801 28.9 20.9801 27.7901 20.5301 26.89C20.2601 26.41 20.2601 25.81 20.5001 25.3001Z" />
		</SvgIcon>
	)
}
