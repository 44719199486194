import { Grid } from "@mui/material"
import { Outlet } from "react-router-dom"


export const TesterModeLayout = () => {

	return (
		<Grid
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
			sx={{
				display: "flex",
				backgroundColor: "#8B8B8B",
				alignContent: "center",
				height: "100vh"
			}}
		>
			<Outlet/>
		</Grid>
	)
}
