
import CircleIcon from "@mui/icons-material/Circle"
import { Grid, List, ListItem, } from "@mui/material"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import _ from "lodash"
import { useEffect, useRef, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { CustomButton } from "../../../components/buttons/CustomButton"
import { OmegaIcon } from "../../../components/customIcons/OmegaIcon"
import { StyledModal } from "../../../components/StyledModal"
import { Toolbar, ToolbarConfig } from "../../../components/Toolbar"
import { CreateSessionParam, eegQueries, projectQueries } from "../../../networking/networking"
import { socket } from "../../../networking/socket"
import { useSnackbarStore } from "../../../stateManagement"
import { Eeg, ProjectTester } from "../../../types/genericTypes"
import { TesterConnectionCard } from "./components/TesterConnectionCard"

export type ErrorType = {
	eeg_id: number,
	data: {
		raw: number,
		readable: string,
		debug?: string
	}
}

export type BatteryLevelType = {
	eeg_id: number,
	battery_level: number
}
export type BatteryLevelSIOErrorType = ErrorType
export type BatteryLevelEEGErrorType = ErrorType

export type FindEEGSIOErrorType = ErrorType
export type FindEEGEEGErrorType = ErrorType

export type RssiType = {
	eeg_id: number,
	rssi: number
}
export type CheckImpedanceType = {
	eeg_id: number,
	values: {
		channel_1: number,
		channel_2: number,
		channel_3: number,
		channel_4: number,
		channel_5: number,
		channel_6: number,
		reference_resistance: number,
	}
}
export type CheckImpedanceErrorType = ErrorType


export const TesterConnection = () => {
	const [impedance_values, setImpedance_values] = useState<{ [eeg_id: number]: CheckImpedanceType["values"] | "waiting" | CheckImpedanceErrorType["data"][] }>({})
	const impedance_timeouts = useRef<{ [eeg_id: number]: number }>({})
	const [testerEegs, setTesterEegs] = useState<{ [local_tester_id: number]: Eeg }>({})
	const [testersEnable, setTestersEnable] = useState<{ [local_tester_id: number]: boolean }>({})
	const [batteryValue, setBatteryValue] = useState<{ [eeg_id: number]: number }>({})
	const [rssiValue, setRssiValue] = useState<{ [eeg_id: number]: number | "waiting" }>({})
	const [batteryLevelError, setBatteryLevelError] = useState<{ [eeg_id: number]: BatteryLevelEEGErrorType["data"][] | BatteryLevelSIOErrorType["data"][] | undefined }>({})
	const [findEEGError, setFindEEGError] = useState<{ [eeg_id: number]: FindEEGEEGErrorType["data"][] | FindEEGSIOErrorType["data"][] | undefined }>({})
	const [isConfirmTestersModalOpen, setIsConfirmTestersModalOpen] = useState(false)

	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)

	const navigate = useNavigate()
	const { project_id = "" } = useParams()

	const location = useLocation()

	// i tester vengono passati tramite react-router
	const projectTesters: ProjectTester[] = location.state

	const { data: project } = useQuery(
		[projectQueries.getProject.name, parseInt(project_id)],
		() => projectQueries.getProject.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id),
		}
	)

	const { data: eegs = [] } = useQuery(
		eegQueries.listEEGs.name,
		eegQueries.listEEGs.fn,
		{
			refetchInterval: 5000
		}
	)

	const createSessionsMutation = useMutation(
		[projectQueries.createSessions.name, parseInt(project_id)],
		(sessions: CreateSessionParam[]) => projectQueries.createSessions.fn(parseInt(project_id), sessions),
		{
			onSuccess: () => {
				navigate(`/projects/${project_id}/testersSession`)
			}
		}

	)
	const run_impedances_check = (idEegs: number[]) => {
		socket.emit("get_impedance", idEegs) //sto calcolando le impedenze
		setImpedance_values(old => {
			const newValue = { ...old }
			for (const idEeg of idEegs) {
				newValue[idEeg] = "waiting"
			}
			return newValue
		})
		setRssiValue(old => {
			const newValue = { ...old }
			for (const idEeg of idEegs) {
				newValue[idEeg] = "waiting"
			}
			return newValue
		})
		idEegs.forEach(eeg_id => {
			impedance_timeouts.current[eeg_id] = window.setTimeout(() => {
				setImpedance_values(old => ({
					...old,
					[eeg_id]: [{
						raw: 0,
						readable: "BE non ha risposto"
					}],
				}))
				openSnackbar("error", "Backend didn't respond")
				impedance_timeouts.current = _.omit(impedance_timeouts.current, eeg_id)
			}, 25000)
		})
	}

	const toolbarConfig: ToolbarConfig = {
		title: `Project ${project?.name}`,
		subtitle: "Here you can associate the EEG to each tester and then check the impedances.",
		buttons: [{
			name: "Check Impedances",
			icon: <OmegaIcon />,
			action: () => {
				const idEegs = _.map(testerEegs, (eeg) => eeg.id)
				run_impedances_check(idEegs)
			},
			// azione disabilitata se non ci sono eeg collegati o se ci sono eeg che stanno già calcolando le impedenze
			disabled: _.filter(testerEegs, (eeg) => Boolean(eeg.mac_address)).length === 0 || Object.keys(impedance_timeouts.current).length > 0
		}]
	}

	const testers_enabled = Object.keys(testersEnable).filter((local_tester_id) => testersEnable[parseInt(local_tester_id)])
	const handleCreateSession = () => {
		createSessionsMutation.mutate(
			_.map(
				_.pickBy(testerEegs, (eeg, local_tester_id) => testers_enabled.includes(local_tester_id)),
				(testerEeg, tester_id) => ({
					local_tester_id: parseInt(tester_id),
					eeg_id: testerEeg.id
				})
			)
		)
	}

	useEffect(() => {
		function onGetBatteryLevel(value: BatteryLevelType) {
			console.log("battery level value", value)
			setBatteryValue(old => ({
				...old,
				[value.eeg_id]: value.battery_level
			}))
		}
		function onBatteryLevelSIOError(value: BatteryLevelSIOErrorType) {
			console.log("battery level sio error", value)
			setBatteryLevelError(old => ({
				...old,
				[value.eeg_id]: (old[value.eeg_id] || []).concat(value.data)
			}))
		}
		function onBatteryLevelEEGError(value: BatteryLevelEEGErrorType) {
			console.log("battery level eeg error", value)
			setBatteryLevelError(old => ({
				...old,
				[value.eeg_id]: (old[value.eeg_id] || []).concat(value.data)
			}))
		}

		function onFindEEGSIOError(value: FindEEGSIOErrorType) {
			console.log("find eeg sio error", value)
			setFindEEGError(old => ({
				...old,
				[value.eeg_id]: (old[value.eeg_id] || []).concat(value.data)
			}))
		}
		function onFindEEGEEGError(value: FindEEGEEGErrorType) {
			console.log("find eeg eeg error", value)
			setFindEEGError(old => ({
				...old,
				[value.eeg_id]: (old[value.eeg_id] || []).concat(value.data)
			}))
		}

		function onGetRssiValue(value: RssiType) {
			console.log("rssi value", value)
			setRssiValue(old => ({
				...old,
				[value.eeg_id]: value.rssi
			}))
		}
		function onCheckImpedance(value: CheckImpedanceType) {
			console.log("impedance value", value)
			window.clearTimeout(impedance_timeouts.current[value.eeg_id])
			impedance_timeouts.current = _.omit(impedance_timeouts.current, value.eeg_id)
			setImpedance_values(old => ({
				...old,
				[value.eeg_id]: value.values
			}))
		}
		function onCheckImpedanceEEGError(value: CheckImpedanceErrorType) {
			console.log("impedance error", value)
			window.clearTimeout(impedance_timeouts.current[value.eeg_id])
			impedance_timeouts.current = _.omit(impedance_timeouts.current, value.eeg_id)
			setImpedance_values(old => {
				const old_item = old[value.eeg_id]
				return ({
					...old,
					[value.eeg_id]: Array.isArray(old_item) ? old_item.concat(value.data) : [value.data],
				})
			})
		}

		socket.connect()
		socket.on("battery_level", onGetBatteryLevel)
		socket.on("battery_level_sio_error", onBatteryLevelSIOError)
		socket.on("battery_level_eeg_error", onBatteryLevelEEGError)

		socket.on("find_eeg_sio_error", onFindEEGSIOError)
		socket.on("find_eeg_eeg_error", onFindEEGEEGError)

		socket.on("rssi_value", onGetRssiValue)
		socket.on("check_impedance", onCheckImpedance)
		socket.on("get_impedance_eeg_error", onCheckImpedanceEEGError)

		return () => {
			socket.off("battery_level", onGetBatteryLevel)
			socket.off("battery_level_sio_error", onBatteryLevelSIOError)
			socket.off("battery_level_eeg_error", onBatteryLevelEEGError)

			socket.off("find_eeg_sio_error", onFindEEGSIOError)
			socket.off("find_eeg_eeg_error", onFindEEGEEGError)

			socket.off("rssi_value", onGetRssiValue)
			socket.off("check_impedance", onCheckImpedance)
			socket.off("get_impedance_eeg_error", onCheckImpedanceEEGError)

			socket.disconnect()
		}
	}, [])

	const handleEegChange = (eeg: Eeg | undefined, tester_id: number) => {
		if (eeg) {
			setTesterEegs(old => ({
				...old,
				[tester_id]: eeg
			}))
			if (eeg.mac_address) {
				socket.emit("get_battery_level", { eeg_id: eeg.id })
			}
			setBatteryLevelError(old => _.omit(old, eeg.id))
		} else {
			setTesterEegs(old => _.omit(old, tester_id))
		}
	}

	return (
		<Grid container padding={3}>
			<Toolbar config={toolbarConfig} />
			<Grid item container gap={2}>

				{projectTesters.map((projectTester) =>
					<Grid item xs={12} container key={projectTester.local_tester_id}>
						<TesterConnectionCard
							EEGs={eegs}
							tester={projectTester}
							selectEEG={(eeg, tester_id) => handleEegChange(eeg, tester_id)}
							selectedEEG={testerEegs[projectTester.local_tester_id]}
							selectedEEGsIdForOtherTesters={_.filter(testerEegs, (eeg, local_tester_id) => projectTester.local_tester_id !== parseInt(local_tester_id)).map(eeg => eeg.id)}
							checked={testersEnable[projectTester.local_tester_id]}
							setChecked={(value) => setTestersEnable(old => ({
								...old,
								[projectTester.local_tester_id]: value
							}))}
							batteryValue={batteryValue[testerEegs[projectTester.local_tester_id]?.id]}
							batteryLevelErrors={batteryLevelError[testerEegs[projectTester.local_tester_id]?.id] || []}
							findEeg={(eeg) => {
								setFindEEGError(old => _.omit(old, eeg.id))
								socket.emit("find_eeg", { eeg_id: eeg.id })
							}}
							findEEGErrors={findEEGError[testerEegs[projectTester.local_tester_id]?.id] || []}
							check_impedance={eeg => run_impedances_check([eeg.id])}
							impedance_values={impedance_values[testerEegs[projectTester.local_tester_id]?.id]}
							rssiValue={rssiValue[testerEegs[projectTester.local_tester_id]?.id]}
						/>
					</Grid>
				)}
			</Grid>
			<Grid item justifyContent="flex-end" padding={3} position="fixed" bottom={0} right={0}>
				<CustomButton
					text="Start"
					action={() => setIsConfirmTestersModalOpen(true)}
					disabled={Object.values(testersEnable).filter(v => v).length === 0}
				/>
			</Grid>
			<StyledModal
				isOpen={isConfirmTestersModalOpen}
				handleClose={() => { setIsConfirmTestersModalOpen(false) }}
				title={`Do you want to start a session with the following ${testers_enabled.length} testers of ${projectTesters.length}?`}
				rightButton={
					{
						name: "Confirm",
						action: () => handleCreateSession()
					}
				}
				leftButton={
					{
						name: "Cancel",
						action: () => setIsConfirmTestersModalOpen(false)
					}
				}
			>
				<List sx={{ width: "100%" }}>
					{testers_enabled.map(tester => <ListItem key={tester}>
						<ListItemIcon><CircleIcon /></ListItemIcon>
						<ListItemText>{tester}</ListItemText>
					</ListItem>)}
				</List>
			</StyledModal>
		</Grid>
	)
}
