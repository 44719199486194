import { Grid, styled } from "@mui/material"
interface PhaseIconProps {
	icon: JSX.Element,
	isSelected?: boolean
}


const PhaseIcon = (props: PhaseIconProps) => {
	const iconStyle = {
		color: props.isSelected ? "#D8702E" : "#DEDEDE",
		marginRight: "8px"
	}
	const PhaseIconStyledGrid = styled(Grid)(({ theme }) => ({
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "50px",
		height: "50px",
		backgroundColor: props.isSelected ? theme.palette.primary.light : undefined,
		border: props.isSelected ? `2px solid ${theme.palette.primary.main}` : undefined,
		borderRadius: "6px"
	})) as typeof Grid
	// const iconStyle = {
	// 	position: "absolute",
	// 	top: "50%",
	// 	left: "50%",
	// 	transform: "translate(-50%,-50%)",
	// 	color: props.isSelected ? "#D8702E" : "#DEDEDE"
	// }
	// const PhaseIconStyledGrid = styled(Grid)(({ theme }) => ({
	// 	position: "relative",
	// 	width: "50px",
	// 	height: "50px",
	// 	backgroundColor: props.isSelected ? theme.palette.primary.light : undefined,
	// 	border: props.isSelected ? `2px solid ${theme.palette.primary.main}` : undefined,
	// 	borderRadius: "6px"
	// })) as typeof Grid
	return (
		<PhaseIconStyledGrid>
			<Grid item sx={iconStyle}>
				{props.icon}
			</Grid>
		</PhaseIconStyledGrid>
	)
}

export default PhaseIcon