import { InfoOutlined } from "@mui/icons-material"
import { Grid, List, ListItem, SvgIcon, SvgIconProps, Tooltip, } from "@mui/material"

import { ENVIRONMENT } from "../../constants"
import { CheckImpedanceErrorType, CheckImpedanceType } from "../../pages/projects/activeProject/TesterConnection"



const imageIcon = {
	overflow: "visible",
	fill: "none",
	width: "100px",
	height: "100px"
}

interface BrainIconProps {
	sensors: CheckImpedanceType["values"] | "waiting" | CheckImpedanceErrorType["data"][]
	rssi: number | "waiting" | undefined
}

export const getColor = (sensors: BrainIconProps["sensors"], channelName: keyof CheckImpedanceType["values"]) => {
	if (sensors === "waiting" || !sensors || Array.isArray(sensors))
		return "grey"
	const sensorState = sensors[channelName]
	switch (true) {
		case (sensorState >= 40000 && sensorState <= 99999):
			return "yellow"
		case (sensorState >= 2000 && sensorState <= 39999):
			return "green"
		case sensorState >= 100000:
			return "red"
		case (sensorState >= 0 && sensorState <= 1999):
			return ENVIRONMENT === "TBOX_PRODUCTION" ? "green" : "black"
		default:
			return "grey"
	}
}
const getTitle = (sensors: BrainIconProps["sensors"], rssi: BrainIconProps["rssi"]) => {
	if (!sensors && !rssi) return undefined

	const getSensorsTitle = () => {
		if (Array.isArray(sensors))
			return <ListItem>Impedance: Error</ListItem>
		switch (sensors) {
			case "waiting":
				return <ListItem>Impedance: waiting...</ListItem>
			default:
				return <>
					<ListItem>F7: {(sensors.channel_4 / 1000).toFixed(1)} kΩ</ListItem>
					<ListItem>Fp1: {(sensors.channel_3 / 1000).toFixed(1)} kΩ</ListItem>
					<ListItem>Fp2: {(sensors.channel_2 / 1000).toFixed(1)} kΩ</ListItem>
					<ListItem>F8: {(sensors.channel_1 / 1000).toFixed(1)} kΩ</ListItem>
					<ListItem>Ref1 (R): {(sensors.reference_resistance / 1000).toFixed(1)} kΩ</ListItem>
					<ListItem>Ref2 (L): {(sensors.channel_5 / 1000).toFixed(1)} kΩ</ListItem>
				</>
		}
	}
	const getRssiTitle = () => {
		if (rssi === "waiting") {
			return <ListItem>RSSI: waiting...</ListItem>
		} else {
			return <ListItem>RSSI: {rssi}</ListItem>
		}
	}

	return <List dense>
		{getSensorsTitle()}
		{getRssiTitle()}
	</List>
}


export function BrainIcon(props: (SvgIconProps & BrainIconProps)) {
	return (
		<Grid container sx={{ position: "relative" }}>
			<Grid item>

				<Tooltip
					placement="top-end"
					sx={{
						position: "absolute",
						left: "-5px",
						top: "8px"
					}}
					title={getTitle(props.sensors, props.rssi)}
				>
					<InfoOutlined fontSize="small" />
				</Tooltip>
			</Grid>
			<Grid item>
				<SvgIcon
					{...props}
					sx={{
						...props.sx,
						...imageIcon
					}}
				>
					<svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clipPath="url(#clip0_108_742)">
							<path d="M14.2114 104.11V98.0367C14.2113 96.2812 14.5571 94.5429 15.2289 92.921C15.9006 91.2991 16.8854 89.8254 18.1267 88.584C19.368 87.3427 20.8416 86.3581 22.4635 85.6864C24.0854 85.0146 25.8237 84.6688 27.5793 84.6689H31.4072V80.7921C31.4072 77.1399 32.858 73.6374 35.4404 71.0548C38.0228 68.4722 41.5255 67.0211 45.1777 67.021H48.3679V60.5241C48.3679 60.4571 48.3679 60.3955 48.3679 60.3312C48.4569 51.0545 57.5822 44.5326 66.4832 47.1475C68.1893 47.6487 69.6872 48.6892 70.7528 50.1128C71.8183 51.5365 72.3941 53.2668 72.3941 55.0451V79.4037C72.3946 83.1088 71.2368 86.7211 69.0827 89.7356C66.9286 92.75 63.8861 95.0156 60.3807 96.2154" stroke="#989799" strokeWidth="1.28601" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M30.7876 84.6326C33.4741 84.6326 36.0505 85.6999 37.9502 87.5996C39.8498 89.4992 40.917 92.0755 40.917 94.762V97.0327" stroke="#989799" strokeWidth="1.28601" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M47.6893 67.0291C49.0195 67.029 50.3366 67.291 51.5655 67.8C52.7945 68.3089 53.9111 69.0549 54.8517 69.9955C55.7923 70.936 56.5385 72.0527 57.0475 73.2817C57.5566 74.5106 57.8186 75.8277 57.8186 77.1578V79.4289" stroke="#989799" strokeWidth="1.28601" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M72.3949 79.3988V104.107" stroke="#989799" strokeWidth="1.28601" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M35.8519 97.0327H42.8392C44.4635 97.0327 46.0214 97.6779 47.17 98.8265C48.3186 99.9751 48.9638 101.533 48.9638 103.157" stroke="#989799" strokeWidth="1.28601" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M51.2627 79.4289H58.25C59.8743 79.4289 61.4322 80.0742 62.5807 81.2228C63.7293 82.3714 64.3746 83.9292 64.3746 85.5536" stroke="#989799" strokeWidth="1.28601" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M61.1823 69.5983C64.0244 69.5983 66.3284 67.2943 66.3284 64.4522C66.3284 61.6101 64.0244 59.3062 61.1823 59.3062C58.3402 59.3062 56.0363 61.6101 56.0363 64.4522C56.0363 67.2943 58.3402 69.5983 61.1823 69.5983Z" fill={getColor(props.sensors, "channel_3")} />
							<path d="M42.4079 92.7835C45.25 92.7835 47.554 90.4795 47.554 87.6374C47.554 84.7953 45.25 82.4913 42.4079 82.4913C39.5658 82.4913 37.2618 84.7953 37.2618 87.6374C37.2618 90.4795 39.5658 92.7835 42.4079 92.7835Z" fill={getColor(props.sensors, "channel_4")} />
							<path d="M134.826 104.343V98.2699C134.826 94.7243 133.418 91.3239 130.91 88.8168C128.403 86.3097 125.003 84.9014 121.458 84.9014H117.63V81.0245C117.63 77.3724 116.179 73.8697 113.596 71.2873C111.014 68.7048 107.511 67.2541 103.859 67.2541H100.669V60.7569C100.669 60.6899 100.669 60.6286 100.669 60.5643C100.58 51.2876 91.4546 44.7657 82.5537 47.3812C80.8475 47.8824 79.3494 48.9226 78.2839 50.3463C77.2183 51.77 76.6424 53.5005 76.6426 55.2788V79.6365C76.642 83.3416 77.7999 86.9542 79.954 89.9687C82.1081 92.9832 85.1507 95.2488 88.6561 96.4485" stroke="#989799" strokeWidth="1.28601" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M118.249 84.8683C116.919 84.8683 115.602 85.1302 114.373 85.6392C113.144 86.1482 112.027 86.8941 111.087 87.8347C110.146 88.7752 109.4 89.892 108.891 91.1209C108.382 92.3498 108.12 93.6669 108.12 94.9971V97.2684" stroke="#989799" strokeWidth="1.28601" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M101.347 67.2642C98.6609 67.2642 96.0845 68.3313 94.1849 70.2309C92.2852 72.1305 91.218 74.7071 91.218 77.3936V79.6646" stroke="#989799" strokeWidth="1.28601" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M76.642 79.6346V104.343" stroke="#989799" strokeWidth="1.28601" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M113.184 97.2684H106.197C104.572 97.2684 103.015 97.9137 101.866 99.0623C100.717 100.211 100.072 101.769 100.072 103.393" stroke="#989799" strokeWidth="1.28601" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M97.7741 79.662H90.7875C89.1632 79.662 87.6053 80.3073 86.4567 81.4559C85.3081 82.6045 84.6628 84.1624 84.6628 85.7867" stroke="#989799" strokeWidth="1.28601" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M87.7419 69.5983C90.584 69.5983 92.8879 67.2943 92.8879 64.4522C92.8879 61.6101 90.584 59.3062 87.7419 59.3062C84.8998 59.3062 82.5958 61.6101 82.5958 64.4522C82.5958 67.2943 84.8998 69.5983 87.7419 69.5983Z" fill={getColor(props.sensors, "channel_2")} />
							<path d="M106.516 92.7835C109.358 92.7835 111.662 90.4795 111.662 87.6374C111.662 84.7953 109.358 82.4913 106.516 82.4913C103.673 82.4913 101.37 84.7953 101.37 87.6374C101.37 90.4795 103.673 92.7835 106.516 92.7835Z" fill={getColor(props.sensors, "channel_1")} />
						</g>
						<path d="M61.1461 124.292C63.9881 124.292 66.2921 121.988 66.2921 119.146C66.2921 116.304 63.9881 114 61.1461 114C58.304 114 56 116.304 56 119.146C56 121.988 58.304 124.292 61.1461 124.292Z" fill={getColor(props.sensors, "channel_5")} />
						<path d="M88.1461 124.292C90.9881 124.292 93.2921 121.988 93.2921 119.146C93.2921 116.304 90.9881 114 88.1461 114C85.304 114 83 116.304 83 119.146C83 121.988 85.304 124.292 88.1461 124.292Z" fill={getColor(props.sensors, "reference_resistance")} />
						<defs>
							<rect width="121.954" height="59.1793" fill="white" transform="translate(13.5416 45.8333)" />
						</defs>
					</svg>
				</SvgIcon>
			</Grid>
		</Grid>
	)
}
