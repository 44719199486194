import { Button, styled, SvgIconProps } from "@mui/material"

interface buttonProps {
	text: string,
	action?: () => void,
	icon?: React.ReactElement<SvgIconProps>,
	primary?: boolean,
	style?: object
	disabled?: boolean
}


export const CustomButton = (props: buttonProps) => {

	const CustomButton = styled(Button)(() => ({
		lineHeight: "15px",
		width: "150px",
		height: "45px",
		boxSizing: "border-box",
		backgroundColor: "white",
		color: "black",
		boxShadow: "none",
		outline: "solid 1px black",
		outlineOffset: "-1px",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "black",
			color: "white"
		},
		...props.style,
	})) as typeof Button


	return (
		<CustomButton
			disabled={props.disabled}
			startIcon={props.icon}
			sx={props.primary ? {
				backgroundColor: "black",
				color: "white"
			} : undefined}
			style={props.disabled ? {
				backgroundColor: "#7f7f7f",
				color: "white",
				outline: 0
			} : undefined}
			onClick={props.action}
		>
			{props.text}
		</CustomButton>

	)
}
