import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"

import { authQueries } from "../../networking/networking"
import { useFullScreenStore, useOperatorStore } from "../../stateManagement"

export default function SignOut() {
	const navigate = useNavigate()
	const persistOpStore = useOperatorStore()
	const { handle } = useFullScreenStore()
	useQuery(
		authQueries.signOut.name,
		authQueries.signOut.fn,
		{
			onError: console.error,
			onSuccess: details => {
				console.log("signOut details", details)
				if (persistOpStore.isUserOperator) {
					persistOpStore.setIsUserOperator(false)
					persistOpStore.setOperatorCompany(undefined)
					// TODO: invalidare tutte le query
				}
				handle?.exit()
				navigate("/auth/signIn", { replace: true })
			}
		})

	return null
}
