import { CircularProgress, Grid } from "@mui/material"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { projectQueries, queryClient, testersQueries } from "../../networking/networking"
import { socket } from "../../networking/socket"
import { PhaseProduct, Product } from "../../types/genericTypes"
import TesterModeSession from "./TesterModeSession"

interface Props {
	baseline?: boolean
}

const baselinePhase: PhaseProduct = {
	id: -1,
	product_id: -1,
	phase_id: -1,
	duration: 60,
	name: "Baseline",
	order: 1,
	marker_code: "baseline",
	phase: {
		id: -1,
		name: "Baseline",
		order: -1,
		description_i18n: [
			{
				id: -1,
				phase_id: -1,
				language: "en",
				value: "When you are ready, take a comfortable position and stay still with your eyes open, keeping your gaze on the \"+\" on the screen.\n" +
					"Stay still in this position until you receive the next instructions from the operator.\n" +
					"\nPress the PLAY button to begin.",
				company_id: null
			},
			{
				id: -2,
				phase_id: -1,
				language: "it",
				value: "Quando pronto, assumi una posizione comoda e resta immobile a occhi aperti, mantenendo lo sguardo sul \"+\" sullo schermo.\n" +
					"Rimani in questa posizione finchè non riceverai le prossime istruzioni dall'operatore.\n" +
					"\nPremi PLAY per iniziare.",
				company_id: null
			},
			{
				id: -3,
				phase_id: -1,
				language: "es",
				value: "Cuando esté listo, tome una posición cómoda y permanezca inmóvil con los ojos abiertos, manteniendo la mirada en el \"+\" en la pantalla.\n" +
					"Permanezca en esta posición hasta que reciba las siguientes instrucciones por parte del operador.\n" +
					"\nPulse PLAY para comenzar.",
				company_id: null
			},
			{
				id: -4,
				phase_id: -1,
				language: "de",
				value: "Wenn Sie bereit sind, nehmen Sie eine bequeme Position ein, halten Sie die Augen offen und richten Sie Ihren Blick auf das \"+\" auf dem Bildschirm. \n" +
					"Bleiben Sie in dieser Position, bis Sie die nächste Anweisung vom Operator erhalten. \n" +
					"\nDrücken Sie die Taste PLAY, um zu beginnen.",
				company_id: null
			},
			{
				id: -5,
				phase_id: -1,
				language: "fr",
				value: "Lorsque vous êtes prêt, prenez une position confortable et restez immobile, les yeux ouverts, et fixez le « + » qui apparaît sur l'écran.\n" +
					"Restez immobile dans cette position jusqu'à ce que vous recevez les prochaines instructions de l'opérateur.\n" +
					"\nAppuyez sur PLAY pour commencer.",
				company_id: null
			}
		]
	}
}
const productPhase = (product: Product) => ({
	id: 0,
	product_id: product.id,
	phase_id: 0,
	duration: 0,
	name: "Product",
	order: 0,
	marker_code: "",
	phase: {
		id: 0,
		name: "Product",
		order: 0,
		description_i18n: [
			{
				id: 0,
				phase_id: 0,
				language: "en",
				value: "Product " + product.product_id + "\n\nPress the PLAY button to begin.",
				company_id: null
			},
			{
				id: 0,
				phase_id: 0,
				language: "it",
				value: "Prodotto " + product.product_id + "\n\nPremi il pulsante PLAY per iniziare.",
				company_id: null
			},
			{
				id: 0,
				phase_id: 0,
				language: "es",
				value: "Producto " + product.product_id + "\n\nPulsa la tecla PLAY cuando esté listo.",
				company_id: null
			},
			{
				id: 0,
				phase_id: 0,
				language: "de",
				value: "Produkt " + product.product_id + "\n\nDrücken Sie die LEERTASTE, um zu beginnen.",
				company_id: null
			},
			{
				id: 0,
				phase_id: 0,
				language: "fr",
				// TODO: replace with FR translation
				value: "Produit " + product.product_id + "\n\nAppuyez sur la BARRE D'ESPACE pour commencer.",
				company_id: null
			},
		]
	}
} as PhaseProduct)


const TesterMode = (props: Props) => {
	// project_id e local_tester_id ci sono sempre
	// product_id e phase_id solo in alcune pagine
	const {
		project_id = "",
		local_tester_id = "",
		product_id = "",
		phase_id = "",
	} = useParams()
	const navigate = useNavigate()
	const location = useLocation()

	const [showProductPage, setShowProductPage] = useState(true)

	const isPhaseRunning = location.pathname.endsWith("running")

	const { data: project, isFetching: isProjectFetching } = useQuery(
		[projectQueries.getProject.name, parseInt(project_id)],
		() => projectQueries.getProject.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id),
			onSuccess: project => {
				if (!project.tester_mode) {
					navigate(
						`/tester/${local_tester_id}/project/${project_id}/survey`,
						{ replace: true }
					)
				}
			}
		}
	)

	const { data: products = [] } = useQuery(
		[projectQueries.getProducts.name, parseInt(project_id)],
		() => projectQueries.getProducts.fn(parseInt(project_id)),
		{
			select: data => data.map(product => ({
				...product,
				phases: _.orderBy(product.phases, "order", "asc"),
				questions: _.orderBy(product.questions, "order", "asc")
			}) as Product)
		}
	)

	const { data: single_session } = useQuery(
		[testersQueries.getCurrentSingleSession.name, parseInt(project_id), parseInt(local_tester_id)],
		() => testersQueries.getCurrentSingleSession.fn(parseInt(project_id), parseInt(local_tester_id)),
		{
			enabled: Boolean(local_tester_id),
		}
	)

	useEffect(() => {
		function onPhaseStart(value: { local_tester_id: number, single_session_id: number, product_id: number, phase_id: number }) {
			console.log("onPhaseStart", value)
			setShowProductPage(false)
			if (value.local_tester_id !== parseInt(local_tester_id)) return
			navigate("./running", { relative: "path" })
		}
		async function onPhaseEnd(value: { local_tester_id: number, single_session_id: number, product_id: number, phase_id: number }) {
			console.log("onPhaseEnd", value)
			if (value.local_tester_id !== parseInt(local_tester_id)) return
			await queryClient.invalidateQueries(testersQueries.getCurrentSingleSession.name)
			await queryClient.invalidateQueries([projectQueries.getProject.name, parseInt(project_id)])
		}

		socket.connect()
		socket.on("phase_start", onPhaseStart)
		socket.on("phase_end", onPhaseEnd)

		return () => {
			socket.disconnect()
			socket.off("phase_start", onPhaseStart)
			socket.off("phase_end", onPhaseEnd)
		}
	}, [])

	useEffect(() => {
		setShowProductPage(true)
	}, [product_id])

	if (isProjectFetching || !project) {
		return (
			<Grid container justifyContent="center"><CircularProgress /></Grid>
		)
	}

	const currentProduct = products.find(p => p.id === parseInt(product_id))
	const getPhase = () => {
		if (props.baseline) {
			return baselinePhase
		}
		const phaseIndex = currentProduct?.phases.findIndex(pp => pp.id === parseInt(phase_id))
		if (currentProduct && phaseIndex === 0 && showProductPage) {
			return productPhase(currentProduct)
		}
		return currentProduct?.phases.find(pp => pp.id === parseInt(phase_id))
	}

	const currentPhase = getPhase()
	if (!currentPhase) return <p>Never here</p>

	const handlePlayAction = () => {
		if (currentPhase.id === 0) {
			setShowProductPage(false)
			return
		}
		if (isPhaseRunning) {
			console.warn("NEVER HERE", "Il pulsante play è visibile solo se la fase non è running")
			return
		}
		if (!single_session) {
			console.warn("NEVER HERE", "no single_session")
			return
		}
		socket.emit("start_phase", {
			"eeg_id": single_session.eeg_id,
			"single_session_id": single_session.id
		})
	}


	return (
		<TesterModeSession
			phase={currentPhase}
			language={project.language}
			product_id={currentProduct?.product_id || ""}
			company_id={project.company_id}
			playAction={handlePlayAction}
			isPhaseInProgress={isPhaseRunning}
		/>
	)
}

export default TesterMode