import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import { Avatar, Box, Card, CardContent, Grid } from "@mui/material"
import React, { useState } from "react"

import templateCardIcon from "../../../../../assets/icons/templateCardIcon.svg"
import { CustomButton } from "../../../../../components/buttons/CustomButton"
import CustomDialog from "../../../../../components/CustomDialog"
import { DashboardCardParagraphTypography, ParagraphTypography, UserRoleTypography } from "../../../../../components/customTypography"
import { Template } from "../../../../../types/genericTypes"
import { StyledIconButton } from "../../../../users/components/userCard"

interface templateCardProps {
	template: Template
	disableSelection?: boolean
	selected?: boolean
	handleClick?: React.MouseEventHandler<HTMLDivElement> | undefined
	onDelete?: () => void
	onEdit?: () => void
	onUseTemplate?: () => void
}

//--------------------- STYLE ------------------------
const cardStyle = {
	backgroundColor: "#fff",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "flex-start",
	width: 300,
	height: 200,
}
const cardSelected = {
	backgroundColor: "#D8D7D7",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "flex-start",
	width: 300,
	height: 200,
}

const coloredStripeStyle = {

	backgroundColor: "#F5C39C",
	width: "inherit",
	height: "inherit",
	padding: "0 8px 0 16px"
}
const coloredStripeStyleSelected = {

	backgroundColor: "#C69A72",
	width: "inherit",
	height: "inherit",
	padding: "0 8px 0 16px",

}

const avatarStyle = {
	height: 80,
	width: 80,
	backgroundColor: "#F5C39C",
}
const avatarStyleSelected = {
	height: 80,
	width: 80,
	backgroundColor: "#C69A72",
}
// -----------------------------------------------------

export const TemplateCard = (props: templateCardProps) => {
	const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false)

	return (
		<>
			<Card
				sx={props.selected ? cardSelected : cardStyle}
				onClick={props.disableSelection ? undefined : props.handleClick}
				style={props.disableSelection ? { cursor: "not-allowed" } : undefined}
			>
				<Grid container height="35px">
					<Box sx={props.selected ? coloredStripeStyleSelected : coloredStripeStyle} >
						{props.onDelete || props.onEdit ?
							<Grid
								container
								justifyContent="flex-end"
								alignItems="center"
								sx={{
									height: "100%",
									paddingRight: 1
								}}
							>
								{props.onEdit &&
									<StyledIconButton
										onClick={props.onEdit}
										sx={{ marginRight: 1 }}
									>
										<EditIcon
											sx={{
												fontSize: "16px",
											}}
										/>
									</StyledIconButton>
								}
								{props.onDelete &&
									<StyledIconButton onClick={props.onDelete}>
										<DeleteIcon
											sx={{
												fontSize: "16px"
											}}
										/>
									</StyledIconButton>
								}
							</Grid>
							: undefined
						}
					</Box>
				</Grid>
				<CardContent
					sx={{
						height: "100%",
						width: "100%"
					}}
				>
					<Grid
						container
						// alignItems="center"
						sx={{
							height: "100%",
						}}
					>
						<Grid item xs={5}>
							<Avatar
								sx={props.selected ? avatarStyleSelected : avatarStyle}
								alt="Profile Picture"
							>
								<img src={templateCardIcon} alt="template-icon" style={{ height: "60%" }} />
							</Avatar>
						</Grid>
						<Grid item container xs={7} direction="column" justifyContent="space-between">
							<Grid item container direction="column" spacing={1}>
								<Grid item>
									<UserRoleTypography
										sx={{
											overflow: "hidden",
											wordBreak: "break-word",
											display: "-webkit-box",
											WebkitLineClamp: "2",
											WebkitBoxOrient: "vertical",
										}}
									>
										{props.template.name}
									</UserRoleTypography>
								</Grid>
								<Grid item>
									<DashboardCardParagraphTypography
										sx={{
											overflow: "hidden",
											textOverflow: "ellipsis",
											display: "-webkit-box",
											WebkitLineClamp: "3",
											WebkitBoxOrient: "vertical",
										}}
									>{props.template.description}</DashboardCardParagraphTypography>
								</Grid>
							</Grid>
							<Grid item textAlign="end">
								<UserRoleTypography
									onClick={(e) => {
										e.stopPropagation()
										setIsInfoDialogOpen(true)
									}}
									sx={{
										textDecoration: "underline",
										cursor: "pointer",
									}}
								>
									More info
								</UserRoleTypography>
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
			<CustomDialog
				title="Template Details"
				subtitle={props.template.name}
				open={isInfoDialogOpen}
				onClose={() => { setIsInfoDialogOpen(false) }}
			>
				<Grid container spacing={2} direction="column">
					<Grid item >
						<ParagraphTypography>{props.template.description}</ParagraphTypography>
					</Grid>
					{props.onUseTemplate &&
						<Grid item container justifyContent="center">
							<CustomButton disabled={props.disableSelection} text="Use Template" action={props.onUseTemplate} primary />
						</Grid>
					}
				</Grid>
			</CustomDialog>
		</>
	)
}
