import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import {
	Card,
	CardActions,
	CardContent,
	CardMedia,
	styled
} from "@mui/material"

import { endpoint } from "../../../App"
import { CompanyNameTypography } from "../../../components/customTypography"
import { Company } from "../../../types/genericTypes"
import { StyledIconButton } from "../../users/components/userCard"

interface propsType {
	company: Company,
	onEdit: () => void,
	onDelete: () => void
}
//---------------STYLE --------------------------

const cardStyle = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "space-between",
	width: 300,
	height: 200
}

const cardActionsStyle = {
	marginTop: "8px",
	justifyContent: "flex-end",
	width: "inherit",
	paddingRight: "15px"
}
const cardMediaStyle = {
	height: 50,
	width: "calc(100% - 150px)",
	objectFit: "contain"
}
//-----------------------------------
export default function CompanyCard(props: propsType) {
	const StyledCardContent = styled(CardContent)(
		{
			display: "flex",
			height: props.company.logo ? 72 : "100%",
			alignItems: "center",
			padding: "0 16px 0 16px",
		}
	) as typeof CardContent

	//const con lo stile
	return (
		<Card sx={cardStyle}>
			<CardActions sx={cardActionsStyle} >
				<StyledIconButton onClick={props.onEdit}>
					<EditIcon sx={{ fontSize: "16px" }} />
				</StyledIconButton>
				<StyledIconButton onClick={props.onDelete} >
					<DeleteIcon sx={{ fontSize: "16px" }} />
				</StyledIconButton>
			</CardActions>
			{props.company.logo &&
				<CardMedia
					sx={cardMediaStyle}
					component="img"
					image={`${endpoint}/api/medias/default/${props.company.logo.file_id}`}
					alt={props.company.name + " logo"}
				/>
			}
			<StyledCardContent>
				<CompanyNameTypography
					variant="h5"
					sx={{
						wordBreak: "break-word",
						textAlign: "center"
					}}
				>
					{props.company.name}
				</CompanyNameTypography>
			</StyledCardContent>
		</Card >
	)
}