import DeleteIcon from "@mui/icons-material/Delete"
import { Grid, IconButton, styled, TextField } from "@mui/material"
import _ from "lodash"
import { UseFormGetValues, UseFormRegister } from "react-hook-form"

import { ParagraphTypography } from "../../../components/customTypography"
import { language } from "../../../types/genericTypes"
import { NewQuestionFormInput } from "../Questions"

interface MultipleAnswerProps {
	currentLang: language
	removeAnswer: (index: number) => void
	registerField: UseFormRegister<NewQuestionFormInput>,
	index: number
	fieldIndex: number
	getValue: UseFormGetValues<NewQuestionFormInput>
}

// ------- style ---------
const StyledTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "c6c6c6",
	},
	"& .MuiOutlinedInput-root": {
		backgroundColor: "#F6F6F6",
		"& fieldset": {
			borderColor: "#E1E1E1",
		},
		"&:hover fieldset": {
			borderColor: "#E1E1E1",
		},
	},
}) as typeof TextField



export const MultipleAnswer = (props: MultipleAnswerProps) => {
	return (
		<Grid
			item
			container
			justifyContent="flex-start"
			alignItems="center"
			marginBottom={3}
		>
			<Grid item xs={1.5}>
				<ParagraphTypography textAlign="left">
					Answer #{props.fieldIndex + 1}
				</ParagraphTypography>
			</Grid>
			<Grid item xs={4.5}>
				<StyledTextField
					fullWidth
					{...props.registerField(`dataQuestion.possible_answers.${props.fieldIndex}.translations.${props.index}.value`)}
					required={true}
					placeholder={props.currentLang === "en" ? "What's the best piece of advice you ever received?" : _.find(props.getValue(`dataQuestion.possible_answers.${props.fieldIndex}.translations`), t => t.language == "en")?.value}
				/>
				<StyledTextField
					sx={{ display: "none" }}
					{...props.registerField(`dataQuestion.possible_answers.${props.fieldIndex}.translations.${props.index}.language`, { value: props.currentLang })}
				/>
			</Grid>
			<Grid item xs={1}>
				<ParagraphTypography textAlign="center">
					Label
				</ParagraphTypography>
			</Grid>
			<Grid item xs={4}>
				<StyledTextField
					fullWidth
					disabled={props.index > 0}
					{...props.registerField(`dataQuestion.possible_answers.${props.fieldIndex}.label`)}
					defaultValue="label"
					required={true}
					placeholder="Insert the question laber here"
				/>
			</Grid>
			<Grid item xs={1} textAlign="center">
				<IconButton
					size="small"
					onClick={() => props.removeAnswer(props.fieldIndex)}
				>
					<DeleteIcon />
				</IconButton>
			</Grid>
		</Grid>
	)
}
