import { InfoOutlined } from "@mui/icons-material"
import BarChartIcon from "@mui/icons-material/BarChart"
// import FastForwardIcon from "@mui/icons-material/FastForward"
import GroupsIcon from "@mui/icons-material/Groups"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import StopCircleIcon from "@mui/icons-material/StopCircle"
import {
	Alert,
	Card, CardContent, CardHeader,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	Paper,
	styled,
	Tooltip,
	Typography
} from "@mui/material"
import ListItemText from "@mui/material/ListItemText"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { ReactComponent as EegIcon } from "../../../../assets/icons/testerSession Icons/eegIcon.svg"
import { ReactComponent as OtherPhaseIcon } from "../../../../assets/phases_icons/otherPhaseIcon.svg"
import { AfterTasteIcon } from "../../../../components/customIcons/AfterTasteIcon"
import { BaselineIcon } from "../../../../components/customIcons/BaselineIcon"
import { CalibrationIcon } from "../../../../components/customIcons/CalibrationIcon"
import { ChewIcon } from "../../../../components/customIcons/ChewIcon"
import { ConceptIcon } from "../../../../components/customIcons/ConceptIcon"
import { LookIcon } from "../../../../components/customIcons/LookIcon"
import { QuestionIcon } from "../../../../components/customIcons/QuestionIcon"
import { SmellIcon } from "../../../../components/customIcons/SmellIcon"
import { SwallowIcon } from "../../../../components/customIcons/SwallowIcon"
import { TasteIcon } from "../../../../components/customIcons/TasteIcon"
import { TouchIcon } from "../../../../components/customIcons/TouchIcon"
import { TitleTypography } from "../../../../components/customTypography"
import ProgressBar from "../../../../components/ProgressBar"
import { projectQueries } from "../../../../networking/networking"
import { socket } from "../../../../networking/socket"
import { PhaseProduct, Product, SingleSession } from "../../../../types/genericTypes"
import { phaseProductSorter } from "../../../../utils"
import { EEGErrorValueType } from "../TestersSession"
import PhaseIcon from "./PhaseIcon"
import { TesterChart } from "./TesterChart"

type Props = {
	single_session: SingleSession
	handle_start_phase: () => void
	handle_stop_acquisition: () => void
	phase_progress: number,
	session_completed: boolean
	isCalibrationRunning: boolean
	eegErrorValue: EEGErrorValueType[] | undefined
}

interface PhaseStartType {
	phase_id: number
	product_id: number
	local_tester_id: number
	single_session_id: number
}

const StyledGridStripe = styled(Grid)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	backgroundColor: theme.palette.primary.main,
	minHeight: "40px",
	// gap: theme.spacing(1),
	padding: `0 ${theme.spacing(2)}`,
	// justifyContent: "space-between"
})) as typeof Grid

const CustomPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(1),
	height: "100%",
})) as typeof Paper


const CustomChip = styled(Typography)(({ theme }) => ({
	border: `1px solid ${theme.palette.primary.main}`,
	borderRadius: "8px",
	backgroundColor: theme.palette.primary.light,
	padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
	maxWidth: "fit-content",
})) as typeof Typography

const phase_icon_mapping: { [key: number]: JSX.Element } = {
	[-3]: (
		<QuestionIcon
			style={{
				marginRight: "16px",
				marginBottom: "16px"
			}}
		/>
	),
	[-2]: <CalibrationIcon />,
	[-1]: <BaselineIcon />,
	[1]: <ConceptIcon />,
	[2]: <LookIcon />,
	[3]: <TouchIcon />,
	[4]: <SmellIcon />,
	[5]: <ChewIcon />,
	[6]: <TasteIcon />,
	[7]: <SwallowIcon />,
	[8]: <AfterTasteIcon />,
	[9]: (
		<OtherPhaseIcon
			style={{
				marginLeft: "8px",
				marginTop: "8px"
			}}
		/>
	)
}

const setupProduct: Product = {
	id: -1,
	product_id: "Setup",
	description: null,
	category: "",
	subcategory: "",
	typology: "",
	product: "",
	project_id: -1,
	random_order: false,
	order: -1,  // rank
	image: null,  // picture

	phases: [
		{
			product_id: -1,
			id: -2,
			phase_id: -2,
			duration: 5,
			name: "Calibrate",
			order: 0,
			marker_code: "",
			phase: {
				id: -2,
				name: "Calibrate",
				order: 0,
				description_i18n: undefined
			}
		},
		{
			product_id: -1,
			id: -1,
			phase_id: -1,
			duration: 60,
			name: "Baseline",
			order: 1,
			marker_code: "pre_eo",
			phase: {
				id: -1,
				name: "Baseline",
				order: 1,
				description_i18n: undefined
			}
		}
	],
	questions: []
}

const emptyPhaseProduct: PhaseProduct = {
	product_id: NaN,
	id: NaN,
	phase_id: NaN,
	duration: NaN,
	name: "",
	order: NaN,
	marker_code: "",
	phase: {
		id: NaN,
		name: "",
		order: NaN,
		description_i18n: undefined
	}
}

const graph_time_window = 5
const eeg_sampling_frequency = 250

type EegRecordItem = number|null
export type EegRecord = [number, EegRecordItem, EegRecordItem, EegRecordItem, EegRecordItem]

const TesterSessionField = (props: Props) => {
	const { project_id = "" } = useParams()
	const [data, setData] = useState<EegRecord[]>(Array.from({ length: eeg_sampling_frequency * graph_time_window }).map((_, i) => ([i, null, null, null, null])))
	const [eegStreamingError, setEegStreamingError] = useState<({ raw: number, readable: string } | null)>()

	const { data: productsList = [], isFetching: isProductsListFetching } = useQuery(
		[projectQueries.getProducts.name, parseInt(project_id)],
		() => projectQueries.getProducts.fn(parseInt(project_id)),
		{
			select: data => {
				data.sort((p1, p2) => {
					return props.single_session.ordered_products_ids.indexOf(p1.id) - props.single_session.ordered_products_ids.indexOf(p2.id)
				})
				return [setupProduct, ...data.map(product => {
					if (product.questions.length === 0) return product
					// Se ci sono domande associate al prodotto, mostro una fase fake "Survey"
					return {
						...product,
						phases: [...product.phases, {
							product_id: product.id,
							id: -3,
							phase_id: -3,
							duration: 1,
							name: "Survey",
							order: product.phases.length + 1,
							marker_code: "survey",
							phase: {
								id: -3,
								name: "Survey",
								order: 1,
								description_i18n: undefined
							}
						} as PhaseProduct]

					}
				})]
			}
		}
	)

	useEffect(() => {
		function onSensorData(value: { eeg_id: number, single_session_id: number, data: EegRecord }) {
			if (value.single_session_id !== props.single_session.id) return
			setData(old => {
				const [last_record] = old.slice(-1)
				let missingRecords = 0
				// se il timestamp dell'ultimo record che ho ricevuto è diverso dal timestamp - 1 del record attuale
				if (last_record[1] !== null && last_record[0] !==  value.data[0]-1) {
					console.error("error, non incrementale", value.data)
					missingRecords = value.data[0] - last_record[0] - 1
				}
				return ([
					...old.slice(1 + missingRecords),
					...Array.from({length: missingRecords}).map((_, i) => ([last_record[0] + 1 + i, NaN, NaN, NaN, NaN] as EegRecord)),
					value.data
				])
			})
		}
		function onEegStreamingStateUpdate(value: { eeg_id: number, single_session_id: number, data: { raw: number, readable: string } }) {
			if (value.single_session_id !== props.single_session.id) return
			console.log("onEegStreamingStateUpdate", value.single_session_id, value.data)
			setEegStreamingError(value.data)
		}
		function onPhaseStart(value: PhaseStartType) {
			if (value.single_session_id !== props.single_session.id) return
			console.log("onPhaseStart", value)
			setData(old => {
				const last_record = old[old.length - 1]
				return Array.from({length: eeg_sampling_frequency * graph_time_window})
					.map((_, i) => ([last_record[0]+i, null, null, null, null]))
			})
		}

		socket.on("update_sensor_data", onSensorData)
		socket.on("eeg_streaming_state", onEegStreamingStateUpdate)
		socket.on("phase_start", onPhaseStart)

		return () => {
			socket.off("update_sensor_data", onSensorData)
			socket.off("eeg_streaming_state", onEegStreamingStateUpdate)
			socket.off("phase_start", onPhaseStart)
		}
	}, [])

	const handle_start_phase = () => {
		props.handle_start_phase()
	}

	if (isProductsListFetching) {
		return <Grid item container justifyContent="center"><CircularProgress /></Grid>
	}

	const current_product_index = productsList.findIndex(product => product.id === props.single_session.current_product_id)
	const current_phase_index = productsList[current_product_index].phases.findIndex(phase_product => phase_product.id === props.single_session.current_phase_id)
	let emptyPhasesToAddBefore = 0
	let emptyPhasesToAddAfter = 0
	if (productsList.length > 0) {
		const phases_length = productsList[current_product_index].phases.length
		if (current_phase_index === 0) emptyPhasesToAddBefore = 2
		else if (current_phase_index === 1) emptyPhasesToAddBefore = 1

		if (current_phase_index === phases_length - 1) emptyPhasesToAddAfter = 2
		else if (current_phase_index === phases_length - 2) emptyPhasesToAddAfter = 1
	}

	const current_question_product_to_respond = productsList[current_product_index].questions.find(question => question.question_id === props.single_session.current_question_to_respond?.id)

	const isPhaseInProgress = props.phase_progress > 0 && props.phase_progress < 100

	const isSessionCompleted = props.session_completed || props.single_session.completed_at != null

	return (
		<Grid
			item
			container
			sx={{
				borderRadius: "4px",
				backgroundColor: "#DDDDDD",
				overflow: "hidden"
			}}
		>
			<StyledGridStripe item xs={12}>
				{/* TESTER INFO */}
				<Grid item container xs={3} alignItems="center">
					<Grid item sx={{ marginRight: 1 }}>
						<GroupsIcon
							sx={{
								color: "white",
								verticalAlign: "bottom",
							}}
						/>
					</Grid>
					<Grid item>
						<TitleTypography>
							Tester N° {props.single_session.local_tester_id}
						</TitleTypography>
					</Grid>
				</Grid>

				{/* EEG INFO */}
				<Grid item container xs={3} alignItems="center" gap={1}>
					<Grid item>
						<EegIcon
							style={{
								verticalAlign: "bottom",
							}}
						/>
					</Grid>
					<Grid item>
						<TitleTypography>
							{props.single_session.eeg_id}
						</TitleTypography>
					</Grid>
				</Grid>

				{/* STOP SESSION */}
				{!isSessionCompleted && <Grid item container xs={6} alignItems="center" justifyContent="flex-end">
					<Grid item>
						<TitleTypography>
							Stop Tester Session
						</TitleTypography>
					</Grid>
					<Grid item sx={{ marginLeft: 1 }}>
						<IconButton
							onClick={props.handle_stop_acquisition}
							sx={{
								verticalAlign: "bottom",
								cursor: "pointer"
							}}
						>
							<StopCircleIcon />
						</IconButton>
					</Grid>
				</Grid>}
			</StyledGridStripe >
			{props.eegErrorValue &&
			<Grid container paddingX={2} paddingTop={1} gap={1}>
				{props.eegErrorValue.map((eegErrorValue, i) => (
					<Grid item key={i}>
						<Tooltip
							title={<><Typography>{eegErrorValue.source}</Typography><Typography variant="body2">{eegErrorValue.readable}</Typography>{eegErrorValue.debug && <Typography variant="caption">{eegErrorValue.debug}</Typography>}</>}
							placement="left"
						>
							<Alert
								variant="filled"
								severity="warning"
								sx={{
									backgroundColor: theme => theme.palette.error.light,
									color: "rgba(0, 0, 0, 0.87)"
								}}
							>
								{eegErrorValue.raw}
							</Alert>
						</Tooltip>
					</Grid>
				))}
			</Grid>
			}

			{!isSessionCompleted &&
				<Grid
					item
					container
					spacing={2}
					padding={2}
					paddingTop={1}
					minWidth={950}
					position="relative"
				>
					<Grid item xs={4} xl={3}>
						{/* PAPER SX */}
						<CustomPaper>
							<Grid container direction="column" gap={1}>
								{/* Products */}
								<Grid item container justifyContent="space-between" alignItems="center">
									<Grid item xs={3}>
										<TitleTypography>
										Products
										</TitleTypography>
									</Grid>
									<Grid item container xs={2} alignItems="center" justifyContent="center" >
										<Tooltip
											title={
												<List dense disablePadding>
													{productsList.slice(1).map((product, index) =>
														<ListItem key={product.product_id} alignItems="flex-start">
															<ListItemText>{index + 1}°&ensp;{product.product_id}</ListItemText>
														</ListItem>
													)}
												</List>
											}
										>
											<InfoOutlined color="primary" />
										</Tooltip>
									</Grid>
									<Grid item xs={5}>
										<CustomChip noWrap>
											{productsList[current_product_index].product_id}
										</CustomChip>
									</Grid>
									<Grid item xs={2} textAlign="end">
										{/*	<IconButton*/}
										{/*		size="small"*/}
										{/*		disabled={props.phase_progress > 0 && props.phase_progress < 100}*/}
										{/*		onClick={props.handle_start_phase}*/}
										{/*		sx={{*/}
										{/*			backgroundColor: theme => theme.palette.primary.main,*/}
										{/*		}}*/}
										{/*	>*/}
										{/*		<FastForwardIcon color="action"/>*/}
										{/*	</IconButton>*/}
									</Grid>
								</Grid>
								<Divider />
								{/* Phases */}
								<Grid item container justifyContent="space-between" alignItems="center">
									<Grid item xs={5}>
										<TitleTypography>
										Phases
										</TitleTypography>
									</Grid>
									<Grid item xs={5}>
										<Tooltip title={productsList[current_product_index].phases[current_phase_index]?.name}>
											<CustomChip noWrap>
												{productsList[current_product_index].phases[current_phase_index]?.name}
											</CustomChip>
										</Tooltip>
									</Grid>
									<Grid item xs={2} textAlign="end">
										<IconButton
											size="small"
											disabled={isPhaseInProgress || props.single_session.current_phase_id === -3}
											onClick={handle_start_phase}
											sx={{
												backgroundColor: theme => theme.palette.primary.main,
											}}
										>
											<PlayArrowIcon color="action" />
										</IconButton>
									</Grid>
								</Grid>
								{/* PHASES */}
								<Grid item container>
									{Array.from({ length: emptyPhasesToAddBefore }, () => emptyPhaseProduct)
										.concat(productsList[current_product_index].phases.sort(phaseProductSorter))
										.concat(Array.from({ length: emptyPhasesToAddAfter }, () => emptyPhaseProduct))
										.slice(current_phase_index >= 2 ? current_phase_index - 2 : 0, current_phase_index >= 2 ? current_phase_index + 2 + 1 : 4 + 1)
										.map((phase_product, index) => (
											<Grid item xs key={index}>
												{phase_product &&
												<PhaseIcon
													icon={phase_icon_mapping[phase_product.phase_id]}
													isSelected={phase_product.id === props.single_session.current_phase_id}
												/>
												}
											</Grid>
										))}
								</Grid>
								{/* PROGRESS BAR */}
								<Grid item>
									<ProgressBar
										progress={props.phase_progress}
										current_product_id_to_respond={props.single_session.current_product_id_to_respond}
										current_question_order_to_respond={current_question_product_to_respond?.order}
										totalQuestions={productsList[current_product_index].questions.length}
									/>
								</Grid>
							</Grid>
						</CustomPaper>
					</Grid>
					<Grid item xs={8} xl={9}>
						{/* PAPER DX */}
						<CustomPaper>
							<Grid item container alignItems="center">
								<Grid item>
									<BarChartIcon color="primary" sx={{ marginRight: 1 }} />
								</Grid>
								<Grid item>
									<TitleTypography>
									RAW Signals
									</TitleTypography>
								</Grid>
							</Grid>

							{/* CHARTS */}
							<Grid container>
								{!props.single_session.calibration_completed ? <Grid container sx={{ height: "200px" }} alignItems="center" justifyContent="center">
									{props.isCalibrationRunning ? <CircularProgress /> : undefined}
								</Grid> : <>
									<Grid item container sx={{ height: "50px" }}>
										<TesterChart
											name="F7"
											data={isPhaseInProgress ? data : []}
											dataKey={x => x[4]}
											stroke="#D0AA22"
										/>
									</Grid>
									<Grid item container sx={{ height: "50px" }}>
										<TesterChart
											name="Fp1"
											data={isPhaseInProgress ? data : []}
											dataKey={x => x[3]}
											stroke="#6DA544"
										/>
									</Grid>
									<Grid item container sx={{ height: "50px" }}>
										<TesterChart
											name="Fp2"
											data={isPhaseInProgress ? data : []}
											dataKey={x => x[2]}
											stroke="#EF3355"
										/>
									</Grid>
									<Grid item container sx={{ height: "50px" }}>
										<TesterChart
											name="F8"
											data={isPhaseInProgress ? data : []}
											dataKey={x => x[1]}
											stroke="#6200EE"
											// showAxis
										/>
									</Grid>
								</>}
							</Grid>
						</CustomPaper>
					</Grid>
					{eegStreamingError && eegStreamingError.raw !== 0 &&
						<Grid
							position="absolute"
							left="50%"
							top="50%"
							minWidth="50%"
							style={{ transform: "translate(-50%, -50%)" }}
						>
							<Card>
								<CardHeader title="EEG status - Error" />
								<CardContent>
									<Typography>{eegStreamingError?.readable}</Typography>
								</CardContent>
							</Card>
						</Grid>}
				</Grid>
			}
			{isSessionCompleted &&
				<Grid
					item
					container
					padding={2}
					justifyContent="center"
					alignItems="center"
				>
					<Typography>Current tester session completed.</Typography>
				</Grid>
			}
		</Grid >

	)
}

export default TesterSessionField
