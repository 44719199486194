import { Grid } from "@mui/material"

import cerchio_svg_legno from "../../../assets/backgrounds/cerchio_svg_legno.svg"
import croce_svg_abete from "../../../assets/backgrounds/croce_svg_abete.svg"
import facebook from "../../../assets/icons/facebook.svg"
import instagram from "../../../assets/icons/instagram.svg"
import linkedin from "../../../assets/icons/linkedin.svg"
import location_canada from "../../../assets/icons/location_canada.svg"
import location_italy from "../../../assets/icons/location_italy.svg"
import location_us from "../../../assets/icons/location_us.svg"
import thimus_logo_xl from "../../../assets/icons/thimus_logo_xl.svg"
import { DefaultTextTypography, H1Typography } from "../../../components/customTypography"
import {ContactsLabel} from "../ContactsLabel"

export const Credits = () => {
	return (
		<Grid
			container
			style={{
				position: "absolute",
				height: "100%",
				width: "100%",
				overflow: "hidden",
			}}
		>
			<img
				alt="logo-icon"
				src={cerchio_svg_legno}
				style={{
					height: "600px",
					position: "absolute",
					top: "-320px",
					right: "-50px"
				}}
			/>

			<img
				alt="logo-icon"
				src={croce_svg_abete}
				style={{
					height: "300px",
					position: "absolute",
					top: "200px",
					left: "-50px",
					opacity: "0.2"
				}}
			/>
			<Grid
				item
				container
				sx={{ zIndex: 999 }}
			>
				<Grid
					item
					sx={{
						marginLeft: 5,
						marginTop: 5,
						marginBottom: 5
					}}
					xs={12}
				>
					<img
						alt="logo-icon"
						src={thimus_logo_xl}
						style={{
							height: "120px"
						}}
					/>
				</Grid>
				<Grid
					item
					container
				>
					{/* SX COLUMN */}
					<Grid item container justifyContent="center" xs={6}>
						<Grid
							item
							xs={12}
							sx={{
								marginBottom: 5,
								marginLeft: 5
							}}
						>
							<H1Typography>Let&apos;s get in touch</H1Typography>
						</Grid>
						<Grid item container sx={{ paddingLeft: 5 }}>
							{/* linkedin */}
							<Grid item container alignItems="center">
								<Grid item sx={{ marginRight: 2 }}>
									<img
										alt="logo-icon"
										src={linkedin}
										style={{
											height: "70px"
										}}
									/>
								</Grid>
								<Grid item>
									<DefaultTextTypography>linkedin.com/company/thimus</DefaultTextTypography>
								</Grid>
							</Grid>
							{/* facebook */}
							<Grid item container alignItems="center">
								<Grid item sx={{ marginRight: 2 }}>
									<img
										alt="logo-icon"
										src={facebook}
										style={{
											height: "70px"
										}}
									/>
								</Grid>
								<Grid item>
									<DefaultTextTypography>facebook.com/ThimusHumanizingNeuroscience</DefaultTextTypography>
								</Grid>
							</Grid>
							{/* instagram */}
							<Grid item container alignItems="center">
								<Grid item sx={{ marginRight: 2 }}>
									<img
										alt="logo-icon"
										src={instagram}
										style={{
											height: "70px"
										}}
									/>
								</Grid>
								<Grid item>
									<DefaultTextTypography>instagram.com/thimus._</DefaultTextTypography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{/* DX COLUMN */}
					<Grid item container justifyContent="center" xs={6}>
						<Grid
							item
							xs={12}
							sx={{
								marginBottom: 5,
								marginLeft: 5
							}}
						>
							<H1Typography>Where we are</H1Typography>
						</Grid>
						<Grid item container sx={{ paddingLeft: 4 }}>
							{/* italy */}
							<Grid item container alignItems="center">
								<Grid item sx={{ marginRight: 2 }}>
									<img
										alt="logo-icon"
										src={location_italy}
										style={{
											height: "70px"
										}}
									/>
								</Grid>
								<Grid item>
									<DefaultTextTypography><b>Italy</b>: Via Monte Nero 23, 25128 Brescia</DefaultTextTypography>
								</Grid>
							</Grid>
							{/* canada */}
							<Grid item container alignItems="center">
								<Grid item sx={{ marginRight: 2 }}>
									<img
										alt="logo-icon"
										src={location_canada}
										style={{
											height: "70px"
										}}
									/>
								</Grid>
								<Grid item>
									<DefaultTextTypography><b>Canada</b>: 401-417 Rue Saint-Pierre, Montréal H2Y 2M4</DefaultTextTypography>
								</Grid>
							</Grid>
							{/* usa */}
							<Grid item container alignItems="center">
								<Grid item sx={{ marginRight: 2 }}>
									<img
										alt="logo-icon"
										src={location_us}
										style={{
											height: "70px"
										}}
									/>
								</Grid>
								<Grid item>
									<DefaultTextTypography><b>USA</b>: 160 Greentree Drive Suite 101, Dover, DE 19904</DefaultTextTypography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{/* Footer */}
				<ContactsLabel />
			</Grid>
		</Grid >
	)
}
