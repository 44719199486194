import { SvgIcon, SvgIconProps } from "@mui/material"

const imageIcon = {
	overflow: "visible"
}
export function TasteIcon(props: SvgIconProps) {
	return (
		<SvgIcon
			{...props}
			sx={{
				...props.sx,
				...imageIcon
			}}
		>
			<path d="M8.5 16.15H10C10 18.0498 10.8447 19.8045 12.2663 20.9645C13.3206 21.8249 14.6241 22.3001 16.0001 22.3001C17.3921 22.3001 18.7098 21.8137 19.7701 20.9348C21.1704 19.7739 22.0001 18.033 22.0001 16.15H23.5001C23.5001 18.4826 22.4692 20.6455 20.7274 22.0895C19.401 23.189 17.7456 23.8001 16.0001 23.8001C14.2746 23.8001 12.6371 23.2031 11.3181 22.1266C9.54978 20.6836 8.50013 18.5034 8.50013 16.1501L8.5 16.15Z" />
			<path d="M8.5 11.2C8.5 10.7858 8.83575 10.45 9.25 10.45C9.66425 10.45 10 10.7858 10 11.2V16.3C10 16.7143 9.66425 17.05 9.25 17.05C8.83575 17.05 8.5 16.7143 8.5 16.3V11.2Z" />
			<path d="M22 11.2C22 10.7858 22.3358 10.45 22.75 10.45C23.1643 10.45 23.5 10.7858 23.5 11.2V16.3C23.5 16.7143 23.1643 17.05 22.75 17.05C22.3358 17.05 22 16.7143 22 16.3V11.2Z" />
			<path d="M15.2502 12.4C15.2502 11.9857 15.586 11.65 16.0002 11.65C16.4145 11.65 16.7502 11.9857 16.7502 12.4V17.5C16.7502 17.9142 16.4145 18.25 16.0002 18.25C15.586 18.25 15.2502 17.9142 15.2502 17.5V12.4Z" />
			<path d="M5.36144 9.40851C5.02549 9.16623 4.94951 8.69749 5.19171 8.36144C5.434 8.02549 5.90274 7.94951 6.23879 8.19171C6.27101 8.21495 6.33967 8.2629 6.44259 8.33185C6.61789 8.44933 6.82267 8.58097 7.05469 8.72315C7.72072 9.13145 8.46769 9.54035 9.27637 9.92177C11.5697 11.0035 13.8884 11.6501 16.0714 11.6501C18.2528 11.6501 20.5429 11.0044 22.7901 9.92412C23.5824 9.54327 24.312 9.13507 24.9606 8.72755C25.1865 8.58565 25.3857 8.4543 25.5558 8.33712C25.6556 8.26837 25.7221 8.22082 25.753 8.19787C26.0856 7.95099 26.5554 8.02052 26.8022 8.35314C27.0491 8.68576 26.9796 9.15549 26.647 9.40236C26.6021 9.43566 26.5213 9.49357 26.4066 9.57248C26.2195 9.70139 26.0028 9.84426 25.7587 9.99768C25.0644 10.4339 24.2859 10.8695 23.4399 11.2761C21.0064 12.4458 18.5084 13.1501 16.0712 13.1501C13.6356 13.1501 11.1129 12.4467 8.63618 11.2785C7.77533 10.8724 6.98101 10.4375 6.27056 10.0021C6.02075 9.84896 5.79888 9.70638 5.60708 9.57776C5.4897 9.49905 5.40698 9.44153 5.36118 9.40852L5.36144 9.40851Z" />
		</SvgIcon>
	)
}
