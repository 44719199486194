import {
	FileDownload as FileDownloadIcon,
	FileUpload as FileUploadIcon,
} from "@mui/icons-material"
import { CircularProgress, Grid, Typography } from "@mui/material"
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid"
import { AxiosError } from "axios"
import dayjs from "dayjs"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import { CustomButton } from "../../../components/buttons/CustomButton"
import SubmitButton from "../../../components/buttons/submitButton"
import CustomDataGridToolbar from "../../../components/CustomDataGridToolbar"
import { NewTesterForm } from "../../../components/NewTesterForm"
import { StyledDropzone } from "../../../components/StyledDropzone"
import { StyledModal } from "../../../components/StyledModal"
import { projectQueries, testersQueries } from "../../../networking/networking"
import { useOperatorStore, useSnackbarStore, useUserStore } from "../../../stateManagement"
import { Tester } from "../../../types/genericTypes"
import { userCanEditProject } from "../../../utils"

const columns: GridColDef[] = [
	{
		field: "global_id",
		headerName: "Global ID",
		width: 120,
		hideable: false

	},
	{
		field: "first_name",
		headerName: "First name",
		width: 120,
	},
	{
		field: "last_name",
		headerName: "Last name",
		width: 120,
	},
	{
		field: "date_of_birth",
		headerName: "Date of Birth",
		type: "date",
		width: 120,
		valueGetter: ({ value }) => value && new Date(value),
		// valueFormatter: ({ value }) => value ? value.toLocaleDateString() : "",
		valueFormatter: ({ value }) => value ? `${value.toLocaleDateString()} (${dayjs().diff(dayjs(value), "year")})` : "",
	},
	{
		field: "gender_external_format",
		headerName: "Gender",
		width: 120,
	},
	{
		field: "country_external_format",
		headerName: "Country",
		width: 120,
	},
	{
		field: "ethnicity_external_format",
		headerName: "Ethnicity",
		width: 120,
	},
	{
		field: "eduction_external_format",
		headerName: "Education",
		width: 120,
	},
	{
		field: "professional_sector_external_format",
		headerName: "Professional Sector",
		width: 120,
	},
	{
		field: "diet_external_format",
		headerName: "Diet",
		width: 120,
	},
	{
		field: "smoker_external_format",
		headerName: "Smoker",
		width: 120,
	},
	{
		field: "phone_number",
		headerName: "Number",
		width: 120,
	},
	{
		field: "email_address",
		headerName: "Email",
		width: 120,
	},
	{
		field: "group",
		headerName: "Group",
		width: 120,
	},
]


const ConfigureProjectTesters = () => {
	const [selectedTestersId, setSelectedTestersId] = useState<GridRowSelectionModel>([])
	const [uploadedFile, setUploadedFile] = useState<File[]>([])
	const [isUploadTestersModalOpen, setIsUploadTestersModalOpen] = useState(false)
	const [isNewTesterModalOpen, setIsNewTesterModalOpen] = useState(false)
	const userRole = useUserStore((state) => state.userRole)

	const navigate = useNavigate()
	const { project_id = "" } = useParams()
	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)
	const isUserOperator = useOperatorStore().isUserOperator

	const { data: testers = [], refetch: refetchTesters, isLoading: isTestersLoading } = useQuery(
		testersQueries.listTesters.name,
		testersQueries.listTesters.fn,
	)

	const { data: project } = useQuery(
		[projectQueries.getProject.name, parseInt(project_id)],
		() => projectQueries.getProject.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id)
		}
	)

	const userCanEdit = project ? userCanEditProject(project, userRole) : false

	const { data: originalSelectedTesters = [], refetch: refetchOriginalSelectedTesters, isLoading: isOriginalSelectedTestersLoading } = useQuery(
		[projectQueries.listProjectTesters.name, parseInt(project_id)],
		() => projectQueries.listProjectTesters.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id)
		}
	)

	const uploadFileMutation = useMutation(
		testersQueries.uploadTestersFile.name,
		testersQueries.uploadTestersFile.fn,
		{
			onSuccess: (data) => {
				setIsUploadTestersModalOpen(false)
				refetchTesters().then(() =>
					setSelectedTestersId(originalSelectedTesters.map(p => p.tester_id).concat(data.tester_ids))
				)
				if (data.inserted === 0 && data.updated === 0 && data.failed > 0) {
					openSnackbar("error", `There are ${data.failed} tester invalid`)
				} else if (data.inserted > 0 && data.updated === 0 && data.failed > 0) {
					openSnackbar("warning", `${data.inserted} testers successfully imported and ${data.failed} tester invalid`)
				} else if (data.inserted === 0 && data.updated > 0 && data.failed > 0) {
					openSnackbar("warning", `${data.updated} testers successfully updated and ${data.failed} tester invalid`)
				} else if (data.inserted > 0 && data.updated > 0 && data.failed > 0) {
					openSnackbar("warning", `${data.inserted} tester successfully imported ${data.inserted} testers successfully updated and ${data.failed} tester invalid`)
				} else if (data.failed === 0) {
					openSnackbar("success", "Tester are successfully imported/updated")
				}
			},
			onError: (error: AxiosError) => {
				console.error("errore upload excel", error)
				setIsUploadTestersModalOpen(false)
				openSnackbar("error", error?.response?.status === 400 ? "No tester was correctly defined" : "Error uploading testers file")
			}
		}
	)

	const patchProjectTestersMutation = useMutation(
		projectQueries.patchProjectTesters.name,
		projectQueries.patchProjectTesters.fn,
		{
			onSuccess: () => {
				refetchOriginalSelectedTesters()
				navigate("/projects")
			}
		}
	)

	const downloadTemplateFileMutation = useMutation(
		testersQueries.downloadTemplateFile.name,
		testersQueries.downloadTemplateFile.fn,
		{
			onSuccess: (response) => {
				const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
				const blobURL = window.URL.createObjectURL(blob)
				const tempLink = document.createElement("a")
				tempLink.style.display = "none"
				tempLink.href = blobURL
				tempLink.download = "SampleTester.xlsx"
				tempLink.setAttribute("target", "_blank")
				document.body.appendChild(tempLink)
				tempLink.click()
				document.body.removeChild(tempLink)
				window.URL.revokeObjectURL(blobURL)
				openSnackbar("success", "Template successfully downloaded")
			},
			onError: (data) => {
				console.error(data)
				openSnackbar("error", "An error occurred while downloading the file")
			}
		}
	)


	useEffect(() => {
		if (originalSelectedTesters.length > 0) {
			setSelectedTestersId(originalSelectedTesters.map(pt => pt.tester_id))
		}
	}, [originalSelectedTesters])

	const handleNextClick = () => {
		const nowSelected = selectedTestersId.map(tester => parseInt(tester.toString()))
		const originalSelected = originalSelectedTesters.map(pt => pt.tester_id)
		if (_.isEqual(new Set(nowSelected), new Set(originalSelected))) {
			navigate("/projects")
		} else {
			patchProjectTestersMutation.mutate({
				project_id: parseInt(project_id),
				testers: nowSelected
			})
		}
	}

	const uploadFileHandler = () => {
		setUploadedFile([])
		setIsUploadTestersModalOpen(true)
	}

	const onTesterCreateSuccess = (tester: Tester) => {
		refetchTesters().then(() =>
			setSelectedTestersId(ts => [...ts, tester.global_id])
		)

	}

	const newTesterHandler = () => {
		setIsNewTesterModalOpen(true)
	}

	const filteredTesters = testers.filter(operator => operator.company_id === project?.company_id)
	return (
		<>
			<Grid container spacing={3}>
				<Grid item container justifyContent="space-between" alignItems="center">
					<Grid item>
						{uploadFileMutation.data &&
							<Typography>
								Added: {uploadFileMutation.data.inserted} - Updated: {uploadFileMutation.data.updated} - <span style={{ color: "#B3261E" }}>Invalid: {uploadFileMutation.data.failed}</span>
							</Typography>
						}
					</Grid>
					<Grid item container justifyContent="flex-end" spacing={2} >
						{!isUserOperator && userCanEdit &&
							<>
								<Grid item>
									<CustomButton text="Upload file" primary icon={<FileUploadIcon />} action={uploadFileHandler} />
								</Grid>
								<Grid item>
									<CustomButton text="template file" icon={<FileDownloadIcon />} action={() => downloadTemplateFileMutation.mutate()} />
								</Grid>
							</>
						}
						{userCanEdit &&
							<Grid item>
								<CustomButton text="New tester" action={newTesterHandler} />
							</Grid>
						}
					</Grid>
				</Grid>
				<Grid
					item
					style={{
						// minHeight: 300,
						height: "50vh",
						width: "100%",
					}}
				>
					{isOriginalSelectedTestersLoading || isTestersLoading ?
						<Grid container justifyContent="center" alignItems="center">
							<CircularProgress
								size={24}
							/>
						</Grid>
						:
						<DataGrid
							rows={filteredTesters}
							columns={columns}
							// autoPageSize
							// autoHeight
							slots={{
								toolbar: CustomDataGridToolbar,
							}}
							checkboxSelection
							isRowSelectable={() => userCanEdit}
							disableRowSelectionOnClick
							getRowId={(tester) => tester.global_id}
							onRowSelectionModelChange={(selectedTesterIDs) => setSelectedTestersId(selectedTesterIDs)}
							rowSelectionModel={selectedTestersId}
						/>
					}
				</Grid>
				<Grid item container justifyContent="flex-end" alignItems="flex-end" spacing={2}>
					{project && userCanEdit &&
						<Grid item>
							<SubmitButton text="Finish" onClick={handleNextClick} />
						</Grid>
					}
				</Grid>
			</Grid>

			<StyledModal isOpen={isUploadTestersModalOpen} handleClose={() => setIsUploadTestersModalOpen(false)} title="Tester upload">
				<Grid container direction="column" justifyContent="center" spacing={4} sx={{ marginBottom: 3 }}>
					<Grid item>
						<StyledDropzone
							acceptedFileFormats={{ "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"] }}
							file={uploadedFile}
							setFile={setUploadedFile}
						/>
					</Grid>
					<Grid item textAlign="center">
						<SubmitButton
							text="Upload"
							disabled={!uploadedFile || !uploadedFile.length}
							onClick={() => {
								if (uploadedFile && project) {
									uploadFileMutation.mutate({
										company_id: project.company_id,
										file: uploadedFile[0]
									})
								}
							}}
						/>
					</Grid>
				</Grid>
			</StyledModal>
			<NewTesterForm
				open={isNewTesterModalOpen}
				onClose={() => setIsNewTesterModalOpen(false)}
				company_id={project?.company_id || -1}
				onTesterCreateSuccess={onTesterCreateSuccess}
				project_id={parseInt(project_id)}
			/>
		</>
	)
}

export default ConfigureProjectTesters
