import { SvgIcon, SvgIconProps } from "@mui/material"

const imageIcon = {
	overflow: "visible"
}
export function CubeIcon(props: SvgIconProps) {
	return (
		<SvgIcon
			{...props}
			sx={{
				...props.sx,
				...imageIcon
			}}
		>
			<path d="M2.1665 18.2651L10.4998 23.0984V13.5651L2.1665 8.73177V18.2651ZM12.4998 23.0984L20.8332 18.2651V8.73177L12.4998 13.5651V23.0984ZM3.2665 7.09844L11.4998 11.8651L19.7332 7.09844L11.4998 2.33177L3.2665 7.09844ZM0.166504 19.4318V7.93177C0.166504 7.50955 0.271837 7.11488 0.482504 6.74777C0.694059 6.38155 0.988726 6.08733 1.3665 5.8651L10.2998 0.731771C10.6776 0.509549 11.0776 0.398438 11.4998 0.398438C11.9221 0.398438 12.3221 0.509549 12.6998 0.731771L21.6332 5.8651C22.0109 6.08733 22.3056 6.38155 22.5172 6.74777C22.7278 7.11488 22.8332 7.50955 22.8332 7.93177V18.0651C22.8332 18.4873 22.7278 18.882 22.5172 19.2491C22.3056 19.6153 22.0109 19.9095 21.6332 20.1318L12.6998 25.2651C12.3221 25.4873 11.9221 25.5984 11.4998 25.5984C11.0776 25.5984 10.6776 25.4873 10.2998 25.2651L0.166504 19.4318Z" />
		</SvgIcon>
	)
}
