import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"
import {Button, Grid, styled, SvgIconProps, SxProps, useMediaQuery, useTheme} from "@mui/material"

import { DashboardCardParagraphTypography, TitleTypography } from "./customTypography"


interface Button {
	name: string;
	icon?: React.ReactElement<SvgIconProps>;
	action: () => void;
	primary?: boolean,
	disabled?: boolean,
	danger?: boolean,
	hide?: boolean,
	isNotificationIconActive?: boolean
}

export interface ToolbarConfig {
	title: string;
	subtitle?: string;
	buttons?: Button[];
}

interface props {
	config: ToolbarConfig,
	sx?: SxProps
}


export const Toolbar = (props: props) => {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down("md"))

	const ToolBarGrid = styled(Grid)(({ theme }) => ({
		minHeight: "70px",
		justifyContent: "space-between",
		alignItems: "center",
		borderLeft: `3px solid ${theme.palette.primary.main}`
	})) as typeof Grid

	const StyledButton = styled(Button)(() => ({
		boxSizing: "border-box",
		backgroundColor: "white",
		color: "black",
		boxShadow: "none",
		outline: "solid 1px black",
		outlineOffset: "-1px",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "black",
			color: "white"
		}
	})) as typeof Button

	const dangerButtonStyle = {
		backgroundColor: "#FF9595",
		border: "none",
		outline: "none"
	}

	return (
		<ToolBarGrid
			container
			sx={{
				marginBottom: 4,
				paddingLeft: 2,
				...props.sx
			}}
		>
			<Grid item xs={12} md={6} mb={isMobile ? 1 : undefined}>
				<TitleTypography>{props.config.title}</TitleTypography>
				{props.config.subtitle &&
					<DashboardCardParagraphTypography>{props.config.subtitle}</DashboardCardParagraphTypography>
				}
			</Grid>
			{props.config.buttons &&
				<Grid container item xs={12} md={6} spacing={1} justifyContent={isMobile ? "flex-start" : "flex-end"}>
					{props.config.buttons.filter(button => !button.hide).map((button) =>
						<Grid item key={button.name}>
							<StyledButton
								size="small"
								style={button.primary ? {
									backgroundColor: "black",
									color: "white"
								} : button.danger ? dangerButtonStyle : button.disabled ? { outline: "none" } : undefined}
								onClick={button.action}
								startIcon={button.icon}
								variant="contained"
								disabled={button.disabled}
							>
								{button.name}
								{button.isNotificationIconActive &&
									<FiberManualRecordIcon color="primary" />
								}
							</StyledButton>
						</Grid>
					)}
				</Grid>
			}
		</ToolBarGrid>
	)
}
