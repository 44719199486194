import { Grid, Slider, TextField } from "@mui/material"
import _ from "lodash"
import { useState } from "react"
import { UseFormRegister, UseFormWatch } from "react-hook-form"

import { DefaultTextTypography } from "../../../components/customTypography"
import { language } from "../../../types/genericTypes"
import { NewQuestionFormInput } from "../Questions"

interface SliderProps {
	registerField: UseFormRegister<NewQuestionFormInput>,
	index: number
	watch: UseFormWatch<NewQuestionFormInput>
	currentLang: language
}


export const VASScale = (props: SliderProps) => {
	const [value, setValue] = useState(0)

	const minValueDescription = props.watch(`dataQuestion.translations.${props.index}.min_value_description`)
	const maxValueDescription = props.watch(`dataQuestion.translations.${props.index}.max_value_description`)
	const minValue = props.watch("dataQuestion.min_value")
	const maxValue = props.watch("dataQuestion.max_value")
	const minValueDescriptionEn = _.find(props.watch("dataQuestion.translations"), t => t.language == "en")?.min_value_description
	const maxValueDescriptionEn = _.find(props.watch("dataQuestion.translations"), t => t.language == "en")?.max_value_description

	return (
		<>
			<Grid
				item
				container
				justifyContent="center"
				sx={{ marginBottom: 3 }}
			>
				<Grid item width={516}>
					<DefaultTextTypography>
						This is the values that the User will see to provide the answer.
						You can set the MIN value and the MAX value here.
					</DefaultTextTypography>
				</Grid>
			</Grid>

			<Grid item container justifyContent="center" spacing={2} marginBottom={3}>
				{/* MIN VALUE*/}
				<Grid item xs={6}>
					<TextField
						{...props.registerField(
							"dataQuestion.min_value",
							{
								valueAsNumber: true,
							}
						)}
						disabled={props.index > 0}
						label="Min Value"
						variant="outlined"
						fullWidth
					/>
				</Grid>
				{/* MAX VALUE*/}
				<Grid item xs={6} marginBottom={2}>
					<TextField
						{...props.registerField(
							"dataQuestion.max_value",
							{
								valueAsNumber: true,
							}
						)}
						disabled={props.index > 0}
						label="Max Value"
						variant="outlined"
						fullWidth
					/>
				</Grid>
				{/* MIN VALUE DESCRIPTION */}
				<Grid item xs={6}>
					<TextField
						{...props.registerField(`dataQuestion.translations.${props.index}.min_value_description`)}
						label="Min Value Description"
						placeholder={props.currentLang === "en" ? "Min Value Description" : minValueDescriptionEn}
						variant="outlined"
						fullWidth
					/>
				</Grid>
				{/* MAX VALUE DESCRIPTION */}
				<Grid item xs={6}>
					<TextField
						{...props.registerField(`dataQuestion.translations.${props.index}.max_value_description`)}
						label="Max Value Description"
						placeholder={props.currentLang === "en" ? "Max Value Description" : maxValueDescriptionEn}
						variant="outlined"
						fullWidth
					/>
				</Grid>
				{minValue !== undefined && maxValue !== undefined ?
					<Grid
						item
						container
						justifyContent="center"
						sx={{
							margin: 3,
							width: "10cm"
						}}
					>
						<Slider
							value={value}
							onChange={(event, v) => setValue(_.isArray(v) ? v[0] : v)}
							step={0.1}
							marks={[
								{
									value: minValue,
									label: minValueDescription
								},
								{
									value: maxValue,
									label: maxValueDescription
								}]}
							min={minValue}
							max={maxValue}
							slotProps={{
								markLabel: { style: { color: "#202020" } }
							}}
						/>
					</Grid>
					: undefined}
			</Grid>
		</>
	)
}
