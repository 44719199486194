import {
	Checkbox,
	CircularProgress,
	Grid,
	MenuItem,
	Select,
	styled,
	TextField,
} from "@mui/material"
import { DateTimePicker, DateTimeValidationError } from "@mui/x-date-pickers"
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers"
import { AxiosError } from "axios"
import dayjs, { Dayjs } from "dayjs"
import { useMemo, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import { ReactComponent as FranceIcon } from "../../../assets/icons/languages/france.svg"
import { ReactComponent as GermanyIcon } from "../../../assets/icons/languages/germany.svg"
import { ReactComponent as ItalyIcon } from "../../../assets/icons/languages/italy.svg"
import { ReactComponent as SpainIcon } from "../../../assets/icons/languages/spain.svg"
import { ReactComponent as UkIcon } from "../../../assets/icons/languages/uk.svg"
import { CustomButton } from "../../../components/buttons/CustomButton"
import SubmitButton from "../../../components/buttons/submitButton"
import { DefaultTextTypography, ParagraphTypography } from "../../../components/customTypography"
import { StyledDropzone } from "../../../components/StyledDropzone"
import { companyQueries, projectQueries, tboxQueries } from "../../../networking/networking"
import { useOperatorStore, useSnackbarStore, useUserStore } from "../../../stateManagement"
import { language } from "../../../types/genericTypes"
import { companiesSorter, tboxesSorter, userCanEditProject } from "../../../utils"

interface NewProjectFormInput {
	name?: string,
	company_id: number | "",
	tbox_id: number | "",
	place?: string,
	image?: File,
	max_testers_per_session: number | "",
	tester_mode?: boolean,
	start_datetime?: Dayjs,
	language: language,
}

//---------------STYLE---------------
const StyledTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "c6c6c6",
	},
	"& .MuiOutlinedInput-root": {
		backgroundColor: "#F6F6F6",
		"& fieldset": {
			borderColor: "#E1E1E1",
		},
		"&:hover fieldset": {
			borderColor: "#E1E1E1",
		},
	}
}) as typeof TextField

const StyledSelect = styled(Select)({
	height: "56px",
	// overflow: "hidden",
	borderRadius: "3px",
	backgroundColor: "#f1f1f1",
})

const iconStyle = {
	height: 16,
	width: 16,
}
//-----------------------------------
const ConfigureProjectGeneral = () => {

	const [error, setError] = useState<DateTimeValidationError | null>(null)
	const [uploadedFile, setUploadedFile] = useState<File[]>([])
	const { project_id = "" } = useParams()
	const navigate = useNavigate()
	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)
	const isUserOperator = useOperatorStore().isUserOperator
	const userRole = useUserStore((state) => state.userRole)

	const errorMessage = useMemo(() => {
		switch (error) {
			case "disablePast": {
				return "Date must not be in the past"
			}

			default: {
				return ""
			}
		}
	}, [error])

	const { data: project, isFetching: isProjectFetching } = useQuery(
		[projectQueries.getProject.name, parseInt(project_id)],
		() => projectQueries.getProject.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id),
			onSuccess: data => {
				resetProject({
					name: data.name,
					company_id: data.company_id,
					tbox_id: data.tbox_id,
					place: data.place || undefined,
					// image: data.image || undefined,
					max_testers_per_session: data.max_testers_per_session,
					tester_mode: data.tester_mode,
					start_datetime: data.start_datetime,
					language: data.language
				})
				if (data.image) {
					setUploadedFile([new File([], data.image.filename)])
				}
			}
		}
	)

	const userCanEdit = project ? userCanEditProject(project, userRole) : true

	const { data: companies = [], isFetching: isCompaniesFetching } = useQuery(
		companyQueries.listCompanies.name,
		companyQueries.listCompanies.fn,
		{
			select: data => data.sort(companiesSorter)
		}
	)

	const { data: TBoxes = [], isFetching: isTBoxesFetching } = useQuery(
		tboxQueries.listTBoxes.name,
		tboxQueries.listTBoxes.fn,
		{
			select: data => data.sort(tboxesSorter)
		}
	)

	const {
		register: registerNewProject,
		handleSubmit: handleSubmitNewProject,
		control: newProjectControl,
		watch: newProjectWatch,
		formState: { dirtyFields },
		setValue: setValueNewProject,
		reset: resetProject,
		resetField
	} = useForm<NewProjectFormInput>({
		defaultValues: {
			name: "",
			company_id: "",
			tbox_id: "",
			place: "",
			image: undefined,
			max_testers_per_session: "",
			tester_mode: false,
			start_datetime: undefined,
			language: "en",
		}
	})
	const watchNewProjectCompanyField = newProjectWatch("company_id")
	const watchNewProjectTboxIdField = newProjectWatch("tbox_id")

	const createProjectMutation = useMutation(
		projectQueries.createProject.name,
		projectQueries.createProject.fn,
		{
			onSuccess: (data) => {
				navigateToNextPage(data.id)
			},
			onError: (error: AxiosError) => {
				console.log("error name already taken", error?.response?.data as string)
				openSnackbar("error", error?.response?.status === 400 ? error?.response?.data as string : "An error occurred while creating the Project")
			}
		}
	)

	const updateProjectMutation = useMutation(
		[projectQueries.updateProject.name, parseInt(project_id)],
		projectQueries.updateProject.fn(parseInt(project_id)),
		{
			onSuccess: () => {
				navigate(`/projects/${project_id}/operators`)
			},
			onError: (error: AxiosError) => {
				openSnackbar("error", error?.response?.status === 400 ? error?.response?.data as string : "An error occurred while updating the Project")
			}
		}
	)

	const onNewProjectSubmit: SubmitHandler<NewProjectFormInput> = data => {
		if (data.name && data.company_id && data.tbox_id && data.max_testers_per_session && data.tester_mode !== undefined && data.start_datetime) {
			createProjectMutation.mutate({
				name: data.name,
				company_id: data.company_id,
				tbox_id: data.tbox_id,
				place: data.place,
				image: data.image,
				max_testers_per_session: data.max_testers_per_session,
				tester_mode: data.tester_mode,
				start_datetime: data.start_datetime,
				language: data.language,
			})
		}
	}
	const onEditProjectSubmit: SubmitHandler<NewProjectFormInput> = data => {
		if (data.company_id !== "" && data.tbox_id !== "" && data.max_testers_per_session !== "") {
			updateProjectMutation.mutate({
				name: dirtyFields.name ? data.name : undefined,
				company_id: dirtyFields.company_id ? data.company_id : undefined,
				tbox_id: dirtyFields.tbox_id ? data.tbox_id : undefined,
				place: dirtyFields.place ? data.place : undefined,
				image: dirtyFields.image ? (uploadedFile.length > 0 ? uploadedFile[0] : null) : undefined,
				max_testers_per_session: dirtyFields.max_testers_per_session ? data.max_testers_per_session : undefined,
				tester_mode: dirtyFields.tester_mode ? data.tester_mode : undefined,
				start_datetime: dirtyFields.start_datetime ? data.start_datetime : undefined,
				language: dirtyFields.language ? data.language : undefined,
			})
		}
	}

	const navigateToNextPage = (pId: number = parseInt(project_id)) => {
		navigate(`/projects/${pId}/operators`)
	}

	const setUploadedFileAction = (files: File[]) => {
		setUploadedFile(files)
		setValueNewProject("image", files[0], { shouldDirty: true })
	}

	if (isProjectFetching || isCompaniesFetching || isTBoxesFetching) {
		return <CircularProgress />
	}

	const languageList = [
		{
			language: "en",
			flag: <UkIcon style={iconStyle} />
		},
		{
			language: "it",
			flag: <ItalyIcon style={iconStyle} />
		},
		{
			language: "es",
			flag: <SpainIcon style={iconStyle} />
		},
		{
			language: "de",
			flag: <GermanyIcon style={iconStyle} />
		},
		{
			language: "fr",
			flag: <FranceIcon style={iconStyle} />
		},
	]

	const minDate = dayjs.utc()

	return (
		<form onSubmit={handleSubmitNewProject(project_id ? onEditProjectSubmit : onNewProjectSubmit)}>
			<Grid container direction="column" justifyContent="center" spacing={4}>
				{/* Project Name */}
				<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
					<Grid item xs={3}>
						<ParagraphTypography>Project Name*</ParagraphTypography>
					</Grid>
					<Grid item xs={9} md={7} xl={5}>
						<StyledTextField
							{...registerNewProject("name")}
							fullWidth
							required={true}
							placeholder="Write the name of your amazing project here"
							disabled={isUserOperator || !userCanEdit}
						/>
					</Grid>
				</Grid>
				{/* Company */}
				<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
					<Grid item xs={3}>
						<ParagraphTypography>Company*</ParagraphTypography>
					</Grid>
					<Grid item xs={9} md={7} xl={5}>
						<Controller
							name="company_id"
							control={newProjectControl}
							disabled={isUserOperator || !userCanEdit || Boolean(project_id)}
							render={({ field }) => (
								<StyledSelect
									displayEmpty
									required
									fullWidth
									/*sx={{
										// overflow: "hidden",
										borderRadius: "3px",
										backgroundColor: "#f1f1f1",
									}}*/
									{...field}
								>
									<MenuItem value="" disabled>
										<DefaultTextTypography color="#424242">Select a Company</DefaultTextTypography>
									</MenuItem>
									{companies.map((company) =>
										<MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
									)}
								</StyledSelect>
							)}

						/>
					</Grid>
				</Grid>

				{/* TBOX */}
				<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
					<Grid item xs={3}>
						<ParagraphTypography>TBox*</ParagraphTypography>
					</Grid>
					<Grid item xs={9} md={7} xl={5}>
						<Controller
							name="tbox_id"
							control={newProjectControl}
							disabled={!userCanEdit || isUserOperator}
							render={({ field }) => (
								<StyledSelect
									displayEmpty
									fullWidth
									required
									/*sx={{
										// overflow: "hidden",
										borderRadius: "3px",
										backgroundColor: "#f1f1f1",
									}}*/
									{...field}
									onChange={(e) => {
										field.onChange(e.target.value)
										resetField("max_testers_per_session", { defaultValue: "" })
									}}
								>
									<MenuItem value="" disabled>
										<DefaultTextTypography color="#424242">Select a Tbox</DefaultTextTypography>
									</MenuItem>
									{TBoxes.filter(tbox => tbox.company_id === watchNewProjectCompanyField).map((tbox) =>
										<MenuItem key={tbox.id} value={tbox.id}>{tbox.name}</MenuItem>
									)}
								</StyledSelect>
							)}
						/>
					</Grid>
				</Grid>
				{/* date */}
				<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
					<Grid item xs={3}>
						<ParagraphTypography>Project Start*</ParagraphTypography>
					</Grid>
					<Grid item xs={9} md={7} xl={5}>
						<Controller
							name="start_datetime"
							control={newProjectControl}
							disabled={isUserOperator || !userCanEdit}
							rules={{
								required: true,
								validate: value => {
									if (value) {
										if (value >= minDate) {
											return
										} else {
											return "Date must be in the future"
										}
									}
								}
							}}
							render={({ field }) => (
								<DateTimePicker
									{...field}
									timezone="UTC"
									value={field.value || null}
									sx={{ backgroundColor: "#f1f1f1" }}
									label="Select the start date (UTC)"
									disablePast={!field.disabled}
									format="YYYY-MM-DD hh:mm A"
									onError={(newError) => setError(newError)}
									viewRenderers={{
										hours: renderTimeViewClock,
										minutes: renderTimeViewClock,
									}}
									slotProps={{
										textField: {
											fullWidth: true,
											required: true,
											helperText: errorMessage,
											FormHelperTextProps: {
												sx: {
													backgroundColor: "#fff",
													margin: 0,
													paddingLeft: 2
												}
											},
										}
									}}
								/>
							)}
						/>

					</Grid>
				</Grid>

				<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
					<Grid item xs={3}>
						<ParagraphTypography>Language*</ParagraphTypography>
					</Grid>
					<Grid item xs={9} md={7} xl={5}>
						<Controller
							name="language"
							control={newProjectControl}
							disabled={isUserOperator || !userCanEdit}
							render={({ field }) => (
								<StyledSelect
									displayEmpty
									required
									fullWidth
									{...field}
								>
									<MenuItem value="" disabled>
										<DefaultTextTypography color="#424242">Select a Language</DefaultTextTypography>
									</MenuItem>
									{languageList.map((language) =>
										<MenuItem
											key={language.language}
											value={language.language}
											sx={{ height: "40px" }}
										>
											<Grid container alignItems="center">
												{language.flag}
												<Grid item>
													<DefaultTextTypography marginLeft={1}>{language.language.toUpperCase()}</DefaultTextTypography>
												</Grid>
											</Grid>
										</MenuItem>
									)}
								</StyledSelect>
							)}
						/>
					</Grid>
				</Grid>

				{/* Location */}
				<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
					<Grid item xs={3}>
						<ParagraphTypography>Location*</ParagraphTypography>
					</Grid>
					<Grid item xs={9} md={7} xl={5}>
						<StyledTextField
							{...registerNewProject("place")}
							disabled={isUserOperator || !userCanEdit}
							placeholder="Where is the test location?"
							fullWidth
							required
						/>
					</Grid>
				</Grid>

				{/* Image */}
				{(!isUserOperator || userCanEdit) &&
					<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
						<Grid item xs={3}>
							<ParagraphTypography>Image</ParagraphTypography>
						</Grid>
						<Grid item xs={9} md={7} xl={5}>
							<StyledDropzone
								acceptedFileFormats={{
									"image/jpeg": [".jpeg"],
									"image/jpg": [".jpg"],
									"image/png": [".png"]
								}}
								file={uploadedFile}
								setFile={setUploadedFileAction}
							/>
						</Grid>
					</Grid>
				}

				{/* max_testers_per_session */}
				<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
					<Grid item xs={3}>
						<ParagraphTypography>Max testers per session*</ParagraphTypography>
					</Grid>
					<Grid item xs={9} md={7} xl={5}>
						<Controller
							name="max_testers_per_session"
							control={newProjectControl}
							disabled={isUserOperator || !userCanEdit}
							render={({ field }) => {
								const selectedTbox_maxConcurrentTesters = TBoxes.find(tbox => tbox.id === watchNewProjectTboxIdField)?.max_concurrent_testers || 3
								return (
									<StyledSelect
										displayEmpty
										fullWidth
										required
										{...field}
									>
										<MenuItem value="" disabled>
											<DefaultTextTypography color="#424242">Select testers amount</DefaultTextTypography>
										</MenuItem>
										{Array.from({
											length: watchNewProjectTboxIdField ? selectedTbox_maxConcurrentTesters : 3
										}, (__, i) => i + 1).map((n) =>
											<MenuItem key={n} value={n}>{n}</MenuItem>
										)}
									</StyledSelect>
								)
							}}
						/>
					</Grid>
				</Grid>
				<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
					<Grid item xs={3}>
						<ParagraphTypography>Tester Mode</ParagraphTypography>
					</Grid>
					<Grid item xs={9} md={7} xl={5}>
						<Controller
							name="tester_mode"
							control={newProjectControl}
							render={({ field }) => <Checkbox {...field} checked={!!field.value} disabled={isUserOperator || !userCanEdit} />}
						/>
					</Grid>
				</Grid>
				<Grid item container justifyContent="flex-end">
					<Grid item>
						{isUserOperator || !userCanEdit ?
							<CustomButton
								text="Next"
								primary
								action={() => navigateToNextPage()}
							/>
							:
							<SubmitButton
								text={project_id ? "Save" : "Create"}
							/>
						}
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

export default ConfigureProjectGeneral
