import { AlertColor } from "@mui/material"
import { FullScreenHandle } from "react-full-screen"
import { create } from "zustand"
import {persist} from "zustand/middleware"

import { REACT_APP_MOCK } from "./constants"
import {CognitoGroup} from "./networking/types"
import { userRole } from "./types/genericTypes"


interface UserStore {
	userName: string | undefined
	userRole: userRole
	userCompany: CognitoGroup | undefined
	setUserName(username: string | undefined): void
	setUserRole(role: userRole): void
	setUserCompany(userCompany: CognitoGroup): void
}
interface SnackbarStore {
	open: boolean
	severity: AlertColor
	message: string
	openSnackbar(severity: AlertColor, message: string): void
	closeSnackbar(): void
}

interface FullScreenStore {
	handle: FullScreenHandle | undefined
	setHandle: (handle: FullScreenHandle) => void
}

interface operatorStore {
	isUserOperator: boolean,
	operatorCompany: number | undefined
	operatorName: string
	setIsUserOperator: (operator: boolean) => void
	setOperatorCompany(operatorCompany: number|undefined): void
	setOperatorName(operatorName: string): void
}

export const useOperatorStore = create<operatorStore>()(
	persist(
		(set) => ({
			isUserOperator: REACT_APP_MOCK,
			operatorCompany: REACT_APP_MOCK ? 1 : undefined,
			operatorName: REACT_APP_MOCK ? "me" : "",
			setIsUserOperator: (isUserOperator) => set({isUserOperator}),
			setOperatorCompany: (operatorCompany) => set({operatorCompany}),
			setOperatorName: (operatorName: string) => set({operatorName})
		}),
		{
			name: "thimus-operator"
		}
	)
)

export const useUserStore = create<UserStore>()(
	persist(
		(set) => ({
			userRole: REACT_APP_MOCK ? "operator" : "",
			userCompany: undefined,
			userName: "",
			setUserRole: (userRole: userRole) => set({ userRole }),
			setUserCompany: (userCompany: CognitoGroup) => set({userCompany}),
			setUserName: (userName: string | undefined) => set({userName})
		}),
		{
			name: "thimus-user"
		}
	)
)

export const useSnackbarStore = create<SnackbarStore>()(
	(set) => ({
		open: false,
		severity: "info",
		message: "",
		openSnackbar:(severity, message) => set({
			open: true,
			severity,
			message
		}),
		closeSnackbar: () => set({open:false})
	})
)

export const useFullScreenStore = create<FullScreenStore>()(
	(set) => ({
		handle: undefined,
		setHandle: (handle) => set({handle})
	})
)
