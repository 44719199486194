import { styled, Typography } from "@mui/material"

export const XLTypography = styled(Typography)(
	{
		fontWeight: 600,
		fontSize: "32px",
		lineHeight: "26px",
		letterSpacing: " 3.83234px",
	}
) as typeof Typography
export const H1Typography = styled(Typography)(
	{
		fontWeight: 600,
		fontSize: "24px",
		lineHeight: "26px",
		letterSpacing: " 3.83234px",
	}
) as typeof Typography
export const H2Typography = styled(Typography)(
	{
		fontWeight: 500,
		fontSize: "16px",
		lineHeight: "26px",
		letterSpacing: "1.91617px",
	}
) as typeof Typography
export const SecondaryActionTypography = styled(Typography)(
	{
		fontWeight: 500,
		fontSize: "16px",
		lineHeight: "26px",
		letterSpacing: " 1.91617px",
	}
) as typeof Typography
export const DefaultTextTypography = styled(Typography)(
	{
		fontWeight: 400,
		fontSize: "16px",
		lineHeight: "24px",
	}
) as typeof Typography
export const TitleTypography = styled(Typography)(
	{
		fontWeight: 600,
		fontSize: "16px",
		lineHeight: "24px",
	}
) as typeof Typography
export const ParagraphTypography = styled(Typography)(
	{
		fontWeight: 400,
		fontSize: "14px",
		lineHeight: "21px",
	}
) as typeof Typography
export const ButtonTypography = styled(Typography)(
	{
		fontWeight: 500,
		fontSize: "14px",
		lineHeight: "21px",
	}
) as typeof Typography
export const DashboardCardTitleTypography = styled(Typography)(
	{
		fontWeight: 600,
		fontSize: "14px",
		lineHeight: "21px",
	}
) as typeof Typography
export const DashboardCardParagraphTypographyBold = styled(Typography)(
	{
		fontWeight: 500,
		fontSize: "12px",
		lineHeight: "18px",
	}
) as typeof Typography
export const DashboardCardParagraphTypography = styled(Typography)(
	{
		fontWeight: 400,
		fontSize: "12px",
		lineHeight: "18px",
	}
) as typeof Typography
export const UserRoleTypography = styled(Typography)(
	{
		fontWeight: 600,
		fontSize: "12px",
		lineHeight: "18px",
	}
) as typeof Typography
export const SideMenuVoiceTypography = styled(Typography)(
	{
		fontWeight: 500,
		fontSize: "16px",
		lineHeight: "26px",
		letterSpacing: "1.91617px"
	}
) as typeof Typography
export const ProjectMenuTitleTypography = styled(Typography)(
	{
		fontWeight: 600,
		fontSize: "24px",
		lineHeight: "25.54px",
	}
) as typeof Typography
export const ProjectMenuSubtitleTypography = styled(Typography)(
	{
		fontWeight: 600,
		fontSize: "14px",
		lineHeight: "26px",
	}
) as typeof Typography
export const CompanyNameTypography = styled(Typography)(
	{
		fontWeight: 300,
		fontSize: "24px",
		lineHeight: "36px",
	}
) as typeof Typography
export const ChartTitle = styled(Typography)(
	{
		fontWeight: 400,
		fontSize: "18px",
		lineHeight: "24px",
	}
) as typeof Typography
