import { SvgIcon, SvgIconProps } from "@mui/material"

const imageIcon = {
	overflow: "visible"
}
export function TouchIcon(props: SvgIconProps) {
	return (
		<SvgIcon
			{...props}
			sx={{
				...props.sx,
				...imageIcon
			}}
		>
			<path d="M21.3999 10.408C20.7039 10.408 20.0799 10.672 19.5999 11.104C19.1199 10.672 18.4959 10.408 17.7999 10.408C17.1039 10.408 16.4799 10.672 15.9999 11.104C15.5199 10.672 14.8959 10.408 14.1999 10.408C13.8639 10.408 13.5759 10.456 13.2879 10.576V6.808C13.2879 5.32004 12.0879 4.12 10.5999 4.12C9.11186 4.12 7.91187 5.32 7.91187 6.808V19.792C7.91187 24.256 11.5359 27.88 15.9999 27.88C20.4639 27.88 24.0879 24.256 24.0879 19.792V13.096C24.0879 11.632 22.8879 10.408 21.3999 10.408L21.3999 10.408ZM22.3359 19.792C22.3359 23.272 19.5039 26.128 15.9999 26.128C12.5199 26.128 9.66392 23.296 9.66392 19.792V6.80803C9.66392 6.30405 10.0719 5.87201 10.5999 5.87201C11.1039 5.87201 11.536 6.27997 11.536 6.80803V15.016C11.536 15.496 11.9199 15.904 12.4239 15.904C12.9039 15.904 13.3119 15.52 13.3119 15.016V13.096C13.3119 12.592 13.7198 12.16 14.2239 12.16C14.7279 12.16 15.136 12.568 15.136 13.096V15.016C15.136 15.496 15.5199 15.904 16.0239 15.904C16.5279 15.904 16.9119 15.52 16.9119 15.016V13.096C16.9119 12.592 17.3198 12.16 17.8479 12.16C18.3519 12.16 18.7599 12.568 18.7599 13.096V15.016C18.7599 15.496 19.1439 15.904 19.6479 15.904C20.1279 15.904 20.5359 15.52 20.5359 15.016V13.096C20.5359 12.592 20.9438 12.16 21.4719 12.16C21.9759 12.16 22.4079 12.568 22.4079 13.096L22.3359 19.792Z" />
		</SvgIcon >
	)
}
