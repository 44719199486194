import { Chip } from "@mui/material"

import { ReactComponent as FranceIcon } from "../../../../../assets/icons/languages/france.svg"
import { ReactComponent as GermanyIcon } from "../../../../../assets/icons/languages/germany.svg"
import { ReactComponent as ItalyIcon } from "../../../../../assets/icons/languages/italy.svg"
import { ReactComponent as SpainIcon } from "../../../../../assets/icons/languages/spain.svg"
import { ReactComponent as UkIcon } from "../../../../../assets/icons/languages/uk.svg"
import { language } from "../../../../../types/genericTypes"

interface props {
	language: language
	noBorder?: boolean
}

const iconStyle = {
	height: 16,
	width: 16,
	marginLeft: 6,
}
const availableIconLanguages = {
	"it": <ItalyIcon style={iconStyle} />,
	"en": <UkIcon style={iconStyle} />,
	"es": <SpainIcon style={iconStyle} />,
	"de": <GermanyIcon style={iconStyle} />,
	"fr": <FranceIcon style={iconStyle} />
}

export const LanguagesButtons = (props: props) => {
	return (
		<Chip
			variant="outlined"
			label={props.language.toUpperCase()}
			avatar={availableIconLanguages[props.language]}
			sx={{
				minWidth: 7.5,
				height: 24,
				borderRadius: 1,
				borderColor: "#3F3F3F",
			}}
			style={props.noBorder ? { border: 0 } : undefined}
		/>
	)
}
