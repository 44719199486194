import {Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, XAxis} from "recharts"
import {DataKey} from "recharts/types/util/types"

import {EegRecord} from "./TesterSessionField"

interface Props {
	name: string;
	stroke: string;
	data: EegRecord[]
	dataKey: DataKey<EegRecord>
	showAxis?: boolean
}

const margin = {
	top: 0,
	bottom: 0,
	left: 5,
	right: 5,
}
export const TesterChart = (props: Props) => {
	return (
		<ResponsiveContainer width="100%" height="100%">
			<LineChart
				width={300}
				height={50}
				data={props.data}
				margin={margin}
			>
				<XAxis
					type="number"
					dataKey={data => data[0]}
					domain={[(dataMin: number) => dataMin, (dataMax: number) => dataMax]}
					hide={!props.showAxis}
					interval="preserveStartEnd"
					tickCount={6}
					tickFormatter={value => Math.floor((value+1) / 250).toString()}
				/>
				<Legend layout="vertical" verticalAlign="middle" align="left" iconSize={0} width={50} />
				<Line type="monotone" dataKey={props.dataKey} stroke={props.stroke} strokeWidth={1} dot={false} name={props.name} isAnimationActive={false} />
				{props.data.filter(data => typeof props.dataKey === "function" && isNaN(props.dataKey(data)) ).map(data => (
					<ReferenceLine
						key={data[0]}
						x={data[0]}
						stroke="black"
					/>
				))}
			</LineChart>
		</ResponsiveContainer>
	)
}

