import PlayCircleIcon from "@mui/icons-material/PlayCircle"
import { CircularProgress, Grid, IconButton } from "@mui/material"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import { projectQueries, queryClient, testersQueries } from "../../networking/networking"
import { socket } from "../../networking/socket"
import { Product } from "../../types/genericTypes"
import { TesterSurveyIntro } from "./testerSurvey/TesterSurveyIntro"


interface PhaseEndType {
	phase_id: number
	product_id: number
	local_tester_id: number
	single_session_id: number
}

export const TesterSectionWelcome = () => {
	const { project_id = "", local_tester_id = "" } = useParams()
	const navigate = useNavigate()
	const [isCalibrationRunning, setIsCalibrationRunning] = useState(false)
	const [isCalibrationEventSent, setIsCalibrationEventSent] = useState(false)

	const {
		data: single_session,
		isLoading: isSingleSessionLoading,
	} = useQuery(
		testersQueries.getCurrentSingleSession.name,
		() => testersQueries.getCurrentSingleSession.fn(parseInt(project_id), parseInt(local_tester_id)),
		{
			enabled: Boolean(local_tester_id),
			onSuccess: () => {
				// newAnswerReset()
			},
		}
	)

	const { data: products = [], isFetching: isProductsFetching } = useQuery(
		projectQueries.getProducts.name,
		() => projectQueries.getProducts.fn(parseInt(project_id)),
		{
			select: data => data.map(product => ({
				...product,
				questions: _.orderBy(product.questions, "order", "asc")
			}) as Product)
		}
	)

	const { data: project, isFetching: isProjectFetching } = useQuery(
		[projectQueries.getProject.name, parseInt(project_id)],
		() => projectQueries.getProject.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id),
		}
	)

	const handleStartButton = async () => {
		if (project?.tester_mode) {
			if (!single_session) {
				console.log("NEVER BE HERE", "no, single_session")
				return
			}

			setIsCalibrationEventSent(true)
			socket.emit("start_phase", {
				"eeg_id": single_session.eeg_id,
				"single_session_id": single_session.id
			})

		} else {
			await queryClient.invalidateQueries(testersQueries.getCurrentSingleSession.name)
			navigate(`../survey/${single_session?.current_product_id_to_respond}/${single_session?.current_question_to_respond?.id}`)
		}
	}

	useEffect(() => {
		console.log("isCalibrationRunning", isCalibrationRunning)
	}, [isCalibrationRunning])

	useEffect(() => {
		function onStartAcquisitionResponse(value: { eeg_id: number, file_path: string }) {
			console.log("onStartAcquisitionResponse", value, single_session)
			if (!single_session) return
			if (value.eeg_id !== single_session.eeg_id) return
			setIsCalibrationRunning(true)
		}
		async function onPhaseEnd(value: PhaseEndType) {
			if (value.local_tester_id !== parseInt(local_tester_id)) {
				return
			}
			await queryClient.invalidateQueries(testersQueries.getCurrentSingleSession.name)
			await queryClient.invalidateQueries([projectQueries.getProject.name, parseInt(project_id)])
		}

		socket.connect()
		socket.on("start_acquisition_response", onStartAcquisitionResponse)
		socket.on("phase_end", onPhaseEnd)

		return () => {
			socket.off("start_acquisition_response", onStartAcquisitionResponse)
			socket.off("phase_end", onPhaseEnd)
			socket.disconnect()
		}
	}, [])

	if (isSingleSessionLoading || isProductsFetching || isProjectFetching || !project) {
		return <Grid container justifyContent="center"><CircularProgress /></Grid>
	}
	const currentProductToRespond = products.find(product => product.id === single_session?.current_product_id_to_respond)

	const renderText = () => {
		if (project?.tester_mode) {
			return <TesterSurveyIntro product={undefined} testerMode language={project.language} localTesterID={local_tester_id} />
		}

		if (single_session && !single_session?.completed_at) {
			if (single_session?.current_product_id_to_respond === null)
				return <TesterSurveyIntro product={undefined} language={project.language} localTesterID={local_tester_id} />
			else
				return <TesterSurveyIntro product={currentProductToRespond?.product_id} language={project.language} localTesterID={local_tester_id} />
		}
	}

	const renderButton = () => {
		if (project?.tester_mode) {
			if (isCalibrationRunning) {
				return <CircularProgress />
			}
			return <Grid item textAlign="center">
				<IconButton
					size="small"
					onClick={handleStartButton}
					disabled={isCalibrationEventSent}
				>
					<PlayCircleIcon
						color={isCalibrationEventSent ? "disabled" : "primary"}
						sx={{
							fontSize: 70,
						}}
					/>
				</IconButton>
			</Grid>
		}

		if (single_session?.running && single_session?.current_product_id_to_respond === null) {
			return undefined
		}

		return (
			<Grid item textAlign="center">
				<IconButton
					size="small"
					onClick={handleStartButton}
				>
					<PlayCircleIcon
						color="primary"
						sx={{
							fontSize: 70,
						}}
					/>
				</IconButton>
			</Grid>
		)
	}

	return <Grid container justifyContent="center">
		<Grid item xs>
			{renderText()}
		</Grid>
		<Grid item container justifyContent="center" marginTop={3}>
			{renderButton()}
		</Grid>
	</Grid>
}
