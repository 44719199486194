import {API, Auth} from "aws-amplify"
import axios from "axios"
import BBPromise from "bluebird"
import dayjs, { Dayjs } from "dayjs"
import _ from "lodash"
import { QueryClient } from "react-query"

import {REACT_APP_BE_ADDRESS, TBOX_MODE} from "../constants"
import {
	AnalysisStatus,
	Answer,
	BEFile,
	Classification,
	Company,
	Eeg, evaluation_grid_option,
	language,
	long_data_item,
	Operator,
	Phase,
	PossibleAnswer,
	Product,
	Project,
	ProjectOperator,
	ProjectTester,
	Question, SingleSession,
	TBox,
	Template,
	Tester,
	timeline_item,
	translation
} from "../types/genericTypes"
import {
	singleSessionResponseFormatter,
	tBoxResponseFormatter,
} from "../utils"
import {
	CognitoGroup,
	completeNewPasswordData,
	confirmationData,
	forgotPasswordData,
	forgotPasswordSubmitData,
	loginData,
	ReceivedCognitoUser,
	signUpData
} from "./types"


interface Query {
	headers: {
		"Content-Type": string,
		Authorization: string
	},
}
interface GetQuery extends Query {
	queryStringParameters?: object,
}
interface PostQuery extends Query {
	body?: object | FormData,
}
interface PatchQuery extends Query {
	body?: object | FormData,
}
interface DeleteQuery extends Query {
	body?: object,
}
interface AdminQueryParams extends GetQuery {
	queryStringParameters?: GetQuery["queryStringParameters"] & {
		limit?: number,
		token?: string | undefined,
	},
}
interface ListGroupsForUserAdminQueryParams extends AdminQueryParams {
	queryStringParameters?: AdminQueryParams["queryStringParameters"] & {
		username: string,
	},
}

interface newOperatorParams {
	fullname: string,
	password: string,
	company_id: string,
	email: string,
	image?: File,
}

interface addTogroupParams {
	username: string,
	group: string
}

interface updateOperatorsParams {
	fullname?: string,
	password?: string,
	company_id?: number,
	email?: string,
	image?: File,
}

interface userAttribute {
	Name: string,
	Value: string,
}

interface newProjectParams {
	company_id: number,
	tbox_id: number,
	name: string,
	start_datetime: Dayjs,
	place?: string,
	image?: File,
	max_testers_per_session: number,
	tester_mode: boolean,
	language: language
}
export interface editProjectParams {
	company_id?: number,
	tbox_id?: number,
	name?: string,
	start_datetime?: Dayjs,
	place?: string,
	image?: File | null,
	max_testers_per_session?: number,
	tester_mode?: boolean,
	notes?: string | null,
	tester_notes?: string | null,
	language?: language | null,
	notesEegMT14?: string | null,
	notesEegMT23?: string | null,
	notesEegMT39?: string | null,
	notesEegMT45?: string | null,
	notesDeclarativeMT14?: string | null,
	notesDeclarativeMT23?: string | null,
	notesDeclarativeMT39?: string | null,
	notesDeclarativeMT45?: string | null,
	notesTimelineMT14?: string | null,
	notesTimelineMT23?: string | null,
	notesTimelineMT39?: string | null,
	notesTimelineMT45?: string | null,
	notesAverage?: string | null,
}

interface projectNoteParams {
	noteName: string
	note: string
}

interface newProductParams {
	project_id: number,
	product_id: string,
	description?: string,
	classification_id: number,
	image?: File,
	random_order: boolean,
	order: number
}
interface updatedProductParams {
	project_id: number,
	product_id: number,
	product: {
		product_id?: string,
		description?: string,
		classification_id?: number,
		image?: File | null,
		random_order?: boolean,
		order?: number | undefined
	}
}
interface productPhasesParams {
	project_id: number,
	product_id: number,
	phases: {
		[key: string]: {
			duration: number,
			order: number,
			name?: string,
			image?: File | BEFile | undefined,
			description?: string | undefined
		}
	}
}
interface productQuestionsParams {
	project_id: number,
	product_id: number,
	questions: {
		id: number,
		order: number,
	}[]
}
interface projectTestersParams {
	project_id: number,
	testers: number[],
}
interface projectOperatorsParams {
	project_id: number,
	operators: number[],
}
export interface CreateSessionParam {
	local_tester_id: number
	eeg_id: number
}

interface newQuestionParams {
	label: string,
	type: string,
	company_id?: number,
	horizontal?: boolean | null,
	min_value?: number,
	max_value?: number,
	translations: translation[]
	possible_answers?: PossibleAnswer[]
	evaluation_grid_options?: evaluation_grid_option | null
}

type templatePhase = {
	phase_id: number
	template_phase_name: string
	duration: number
	order: number
}

type templateQuestion = {
	question_id: number
	order: number
}
interface newTemplateParams {
	name: string,
	description: string,
	phases: templatePhase[]
	questions: templateQuestion[]
}

interface updateTemplateParams {
	id: number,
	name: string,
	description: string,
	phases: templatePhase[]
	questions: templateQuestion[]
}
export interface PhaseDescriptionEdit {
	phase_id: number
	company_id?: number | undefined
	language: language
	value: string
}

const parseProjectFromBE = (project: Project): Project => ({
	...project,
	created_at: new Date(project.created_at + "Z"),
	updated_at: new Date(project.updated_at + "Z"),
	start_datetime: dayjs(project.start_datetime).utc()
})


export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
})

const axiosInstance = axios.create({
	baseURL: REACT_APP_BE_ADDRESS,
	withCredentials: true
})

axiosInstance.interceptors.response.use(response => {
	return response
}, (error) => {
	if (error.response?.status === 401) {
		window.location.assign("/auth/signIn")
	}
	return Promise.reject(error)
})

export const authQueries = {
	signIn: {
		name: "signIn",
		fn: async (data: loginData) => await Auth.signIn(data.username, data.password),
	},
	signUp: {
		name: "signUp",
		fn: async (data: signUpData) => {
			const createdUser = await Auth.signUp({
				username: data.email,
				password: data.password,
				attributes: {
					// profilePicture: data.profilePicture,
					email: data.email,
					name: data.fullName
				},
				autoSignIn: {
					enabled: true,
				}
			})
			return createdUser
			/*const apiName = "AdminQueries"
			const path = "/confirmUserSignUp"
			const params: PostQuery = {
				body: {
					"username": createdUser.user.getUsername(),
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				}
			}
			return await API.post(apiName, path, params)*/
		},
	},
	confirmUserSignUp: {
		name: "confirmUserSignUp",
		fn: async (data: confirmationData) => {
			const confirmedUser = await Auth.confirmSignUp(data.email, data.code)
			return confirmedUser
		}
	},
	signInAsOperator: {
		name: "signInAsOperator",
		fn: async (data: loginData) => {
			const res = await axiosInstance.post("/api/login_operator", {
				email: data.username,
				password: data.password
			})
			return res.data
		}
	},
	signInAsTester: {
		name: "signInAsTester",
		fn: async (local_tester_id: string) => {
			try {
				const resp = await axiosInstance.post<SingleSession>("/api/login_tester", { local_tester_id })
				return Promise.resolve(resp.data)
			} catch (e) {
				return Promise.reject(e)
			}
		}
	},
	completeNewPassword: {
		name: "completeNewPassword",
		fn: (data: completeNewPasswordData) => Auth.completeNewPassword(
			data.user,
			data.newPassword,
		),
	},
	forgotPassword: {
		name: "forgotPassword",
		fn: (data: forgotPasswordData) => Auth.forgotPassword(data.username)
	},
	forgotPasswordSubmit: {
		name: "forgotPasswordSubmit",
		fn: (data: forgotPasswordSubmitData) => Auth.forgotPasswordSubmit(
			data.username,
			data.code,
			data.newPassword,
		),
	},
	signOut: {
		name: "signOut",
		fn: async () => {
			if(TBOX_MODE) {
				return await axiosInstance.post("/api/logout")
			} else {
				return await Auth.signOut()
			}
		},

	},
}

export const groupsQueries = {
	listGroups: {
		// Restituisce la lista di tutti gruppi esistenti
		// TODO: restituire solo i gruppi che l'utente loggato può vedere
		name: "listGroups",
		fn: async (): Promise<Array<CognitoGroup>> => {
			const groups: Array<CognitoGroup> = []
			const apiName = "AdminQueries"
			const path = "/listGroups"

			let token = undefined
			do {
				const params: AdminQueryParams = {
					queryStringParameters: {
						// se metto 0 aws si incazza male:
						// Value '0' at 'maxItems' failed to satisfy constraint: Member must have value greater than or equal to 1
						// anche se la documentazione di amplify dice che posso mettere un numero tra 0 e 60 (https://docs.amplify.aws/cli/auth/admin/#admin-queries-api)
						limit: 50,
						token: token,
					},
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const {NextToken, Groups} = await API.get(apiName, path, params)
				token = NextToken
				if (Groups) {
					groups.push(...Groups)
				}

			} while ( token !== undefined)

			return Promise.resolve(groups)
		}
	}
}

export const userQueries = {
	adminCreateUser: {
		name: "adminCreateUser",

		fn: async (data: signUpData) => {
			const apiName = "AdminQueries"
			const path = "/createUser"
			const params = {
				body: {
					username: data.email,
					password: data.password,
					email: data.email,
					fullName: data.fullName,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				}
			}
			const newUser = await API.post(apiName, path, params)
			return Promise.resolve(newUser)
		}
	},
	listGroupsForUser: {
		// Restituisce la lista di gruppi a cui appartiene l'utente attualmente loggato
		name: "listGroupsForUser",
		fn: async (): Promise<Array<CognitoGroup>> => {
			const groups: Array<CognitoGroup> = []
			const apiName = "AdminQueries"
			const path = "/listGroupsForUser"

			let token = undefined
			do {
				const params: ListGroupsForUserAdminQueryParams = {
					queryStringParameters: {
						username: (await Auth.currentAuthenticatedUser()).username,
						// se metto 0 aws si incazza male:
						// Value '0' at 'maxItems' failed to satisfy constraint: Member must have value greater than or equal to 1
						// anche se la documentazione di amplify dice che posso mettere un numero tra 0 e 60 (https://docs.amplify.aws/cli/auth/admin/#admin-queries-api)
						limit: 50,
						token: token,
					},
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const {NextToken, Groups} = await API.get(apiName, path, params)
				token = NextToken
				if (Groups) {
					groups.push(...Groups)
				}

			} while ( token !== undefined)

			return Promise.resolve(groups)
		}
	},
	listUsersWithGroups: {
		// Restituisce la lista di tutti gli utenti esistenti con anche gruppi a cui appartengono
		name: "listUsersWithGroups",
		fn: async (): Promise<Array<ReceivedCognitoUser>> => {
			if (TBOX_MODE)
				return []
			const users: Array<ReceivedCognitoUser> = []
			const apiName = "AdminQueries"
			const path = "/listUsersWithGroups"

			let token = undefined
			do {
				const params: AdminQueryParams = {
					queryStringParameters: {
						// se metto 0 aws si incazza male:
						// Value '0' at 'maxItems' failed to satisfy constraint: Member must have value greater than or equal to 1
						// anche se la documentazione di amplify dice che posso mettere un numero tra 0 e 60 (https://docs.amplify.aws/cli/auth/admin/#admin-queries-api)
						limit: 50,
						token: token,
					},
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const {NextToken, Users} = await API.get(apiName, path, params)
				token = NextToken
				if (Users) {
					users.push(...Users)
				}

			} while ( token !== undefined)

			return Promise.resolve(users)
		}
	},
	addToGroup: {
		name: "addToGroup",
		fn: async function addToGroup({username, group}: addTogroupParams) {
			const apiName = "AdminQueries"
			const path = "/addUserToGroup"
			const myInit = {
				body: {
					"username": username,
					"groupname": group
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				}
			}
			return await API.post(apiName, path, myInit)
		}
	},
	removeFromGroup: {
		name: "removeFromGroup",
		fn: async function removeUserFromGroup({username, group}: addTogroupParams) {
			const apiName = "AdminQueries"
			const path = "/removeUserFromGroup"
			const myInit = {
				body: {
					"username": username,
					"groupname": group
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				}
			}
			return await API.post(apiName, path, myInit)
		}
	},
	deleteUser: {
		name: "deleteUser",
		fn: async ({username}: {username: string}) => {
			const apiName = "AdminQueries"
			const path = "/deleteUser"
			const params: DeleteQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: {
					username
				}
			}
			const response = await API.del(apiName, path, params)
			console.log("deleteUser response", response)
			return Promise.resolve(response)
		}
	},
	updateUser: {
		name: "updateUser",
		fn: async ({username, attributes}: {username: string, attributes: userAttribute[] }) => {
			const apiName = "AdminQueries"
			const path = "/updateUserAttributes"

			const params: PostQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: {
					username,
					attributes
				}
			}
			const response: Company = await API.patch(apiName, path, params)
			console.log("updateOperator response", response)
			return Promise.resolve(response)
		}
	},
}

export const projectQueries = {
	createProject: {
		name: "createProject",
		fn: async ({company_id, tbox_id, name, start_datetime, place, image, max_testers_per_session, tester_mode, language}: newProjectParams) => {
			const apiName = "AdminQueries"
			const path = "/api/projects"

			const bodyFormData = new FormData()
			bodyFormData.append("company_id", company_id.toString())
			bodyFormData.append("tbox_id", tbox_id.toString())
			bodyFormData.append("name", name)
			console.log(start_datetime, typeof start_datetime)
			bodyFormData.append("start_datetime", start_datetime.toISOString())
			bodyFormData.append("max_testers_per_session", max_testers_per_session.toString())
			bodyFormData.append("tester_mode", tester_mode.toString())
			bodyFormData.append("language", language)
			if (place) {
				bodyFormData.append("place", place)
			}
			if (image) {
				bodyFormData.append("image", image, image.name)
			}
			const params: PostQuery = {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: bodyFormData,
			}
			const response: Project = await API.post(apiName, path, params)
			return Promise.resolve(parseProjectFromBE(response))
		}
	},
	listProjects: {
		name: "listProjects",
		fn: async () => {
			const apiName = "AdminQueries"
			const path = "/api/projects"

			if(TBOX_MODE) {
				const resp = await axiosInstance.get<Project[]>(path)
				return Promise.resolve(resp.data.map(parseProjectFromBE))
			} else {
				const params: GetQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const response: Project[] = await API.get(apiName, path, params)
				return Promise.resolve(response.map(parseProjectFromBE))
			}
		}
	},
	updateProject: {
		name: "updateProject",
		fn: (project_id: number) => async (params: editProjectParams) => {
			const {
				company_id, tbox_id, name, start_datetime, place, image, max_testers_per_session, tester_mode, notes, tester_notes, language, notesEegMT14, notesEegMT23, notesEegMT39, notesEegMT45, notesDeclarativeMT14, notesDeclarativeMT23, notesDeclarativeMT39, notesDeclarativeMT45, notesTimelineMT14, notesTimelineMT23, notesTimelineMT39, notesTimelineMT45, notesAverage
			} = params
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}`

			const bodyFormData = new FormData()
			if (company_id) {
				bodyFormData.append("company_id", company_id.toString())
			}
			if (tbox_id) {
				bodyFormData.append("tbox_id", tbox_id.toString())
			}
			if (name) {
				bodyFormData.append("name", name)
			}
			if (start_datetime) {
				bodyFormData.append("start_datetime", start_datetime.toISOString())
			}
			if (max_testers_per_session) {
				bodyFormData.append("max_testers_per_session", max_testers_per_session.toString())
			}
			if (tester_mode !== undefined) {
				bodyFormData.append("tester_mode", tester_mode.toString())
			}
			if (language) {
				bodyFormData.append("language", language)
			}
			if (place) {
				bodyFormData.append("place", place)
			}
			if (image) {
				bodyFormData.append("image", image, image.name)
			} else if (image === null) {
				bodyFormData.append("image", new File([], ""))
			}
			if (notes != undefined) {
				bodyFormData.append("notes", notes)
			}
			if (tester_notes != undefined) {
				bodyFormData.append("tester_notes", tester_notes)
			}
			if (notesEegMT14 != undefined) {
				bodyFormData.append("notesEegMT14", notesEegMT14)
			}
			if (notesEegMT23 != undefined) {
				bodyFormData.append("notesEegMT14", notesEegMT23)
			}
			if (notesEegMT39 != undefined) {
				bodyFormData.append("notesEegMT14", notesEegMT39)
			}
			if (notesEegMT45 != undefined) {
				bodyFormData.append("notesEegMT14", notesEegMT45)
			}
			if (notesDeclarativeMT14 != undefined) {
				bodyFormData.append("notesEegMT14", notesDeclarativeMT14)
			}
			if (notesDeclarativeMT23 != undefined) {
				bodyFormData.append("notesEegMT14", notesDeclarativeMT23)
			}
			if (notesDeclarativeMT39 != undefined) {
				bodyFormData.append("notesEegMT14", notesDeclarativeMT39)
			}
			if (notesDeclarativeMT45 != undefined) {
				bodyFormData.append("notesEegMT14", notesDeclarativeMT45)
			}
			if (notesTimelineMT14 != undefined) {
				bodyFormData.append("notesEegMT14", notesTimelineMT14)
			}
			if (notesTimelineMT23 != undefined) {
				bodyFormData.append("notesEegMT14", notesTimelineMT23)
			}
			if (notesTimelineMT39 != undefined) {
				bodyFormData.append("notesEegMT14", notesTimelineMT39)
			}
			if (notesTimelineMT45 != undefined) {
				bodyFormData.append("notesEegMT14", notesTimelineMT45)
			}
			if (notesAverage != undefined) {
				bodyFormData.append("notesEegMT14", notesAverage)
			}
			if (TBOX_MODE) {
				const resp = await axiosInstance.patch<Project>(path, bodyFormData, {
					headers: {
						"Content-Type": "multipart/form-data",
					}
				})
				return Promise.resolve(parseProjectFromBE(resp.data))
			} else {
				const params: PatchQuery = {
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					},
					body: bodyFormData,
				}
				const response: Project = await API.patch(apiName, path, params)
				return Promise.resolve(parseProjectFromBE(response))
			}
		}
	},
	deleteProject: {
		name: "deleteProject",
		fn: async (project_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}`
			const params: DeleteQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
			}
			const response = await API.del(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	getProject: {
		name: "getProject",
		fn: async (project_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}`

			if(TBOX_MODE) {
				const resp = await axiosInstance.get<Project>(path)
				return Promise.resolve(parseProjectFromBE(resp.data))
			} else {
				const params: GetQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const response: Project = await API.get(apiName, path, params)
				return Promise.resolve(parseProjectFromBE(response))
			}
		}
	},

	getProducts:{
		name: "getProducts",
		fn: async (project_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/products`

			if(TBOX_MODE) {
				const resp = await axiosInstance.get<Product[]>(path)
				return Promise.resolve(resp.data)
			} else {
				const params: AdminQueryParams = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const response: Product[] = await API.get(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	},
	getSingleProduct : {
		name: "getSingleProduct",
		fn: async (project_id: number, product_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/products/${product_id}`

			const params: GetQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				}
			}
			const response: Product = await API.get(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	createProduct: {
		name: "createProduct",
		fn: async ({project_id, product_id, description, classification_id, image, random_order, order }: newProductParams) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/products`

			const bodyFormData = new FormData()
			bodyFormData.append("project_id", project_id.toString())
			bodyFormData.append("product_id", product_id.toString())
			bodyFormData.append("classification_id", classification_id.toString())
			bodyFormData.append("random_order", random_order.toString())
			bodyFormData.append("order", order.toString())
			if (image) {
				bodyFormData.append("image", image, image.name)
			}
			if(description) {
				bodyFormData.append("description", description)
			}

			const params: PostQuery = {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: bodyFormData,
			}
			const response: Product = await API.post(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	updateProduct: {
		name: "updateProduct",
		fn: async ({project_id, product_id, product}: updatedProductParams) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/products/${product_id}`

			const bodyFormData = new FormData()
			if (product.product_id) {
				bodyFormData.append("product_id", product.product_id.toString())
			}
			if (product.classification_id) {
				bodyFormData.append("classification_id", product.classification_id.toString())
			}
			if (product.random_order) {
				bodyFormData.append("random_order", product.random_order.toString())
			}
			if (product.order) {
				bodyFormData.append("order", product.order.toString())
			}
			if (product.image) {
				bodyFormData.append("image", product.image, product.image.name)
			} else if(product.image === null) {
				bodyFormData.append("image", new File([], ""))
			}
			if(product.description) {
				bodyFormData.append("description", product.description)
			}

			if(TBOX_MODE) {
				const resp = await axiosInstance.patch<Project>(path, bodyFormData)
				return Promise.resolve(parseProjectFromBE(resp.data))
			} else {
				const params: PatchQuery = {
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					},
					body: bodyFormData,
				}
				const response: Project = await API.patch(apiName, path, params)
				return Promise.resolve(parseProjectFromBE(response))
			}
		}
	},
	deleteProduct: {
		name: "deleteProduct",
		fn: async ({project_id, product_id}: {project_id: number, product_id: number}) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/products/${product_id}`

			if(TBOX_MODE) {
				await axiosInstance.delete(path)
			} else {
				const params: DeleteQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				await API.del(apiName, path, params)
			}
			return Promise.resolve()
		}
	},
	patchProductPhases: {
		name: "patchProductPhases",
		fn: async ({project_id, product_id, phases}: productPhasesParams) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/products/${product_id}/phases`

			const toBase64 = (file: File) => new Promise<string | ArrayBuffer | null>((resolve, reject) => {
				const reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = () => resolve(reader.result)
				reader.onerror = reject
			})

			const updated_phases = await BBPromise.props(
				_.mapValues(phases,async (value) => ({
					...value,
					image: value.image instanceof File ? value.image.name + "@" + await toBase64(value.image) : value.image as string | ArrayBuffer | null | BEFile | undefined
				}))
			)
			console.log(updated_phases)
			if(TBOX_MODE) {
				const resp = await axiosInstance.patch<Product>(path, { phases: updated_phases })
				return Promise.resolve(resp.data)
			} else {
				const params: PatchQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					},
					body: {
						phases: updated_phases
					}
				}
				const response: Product = await API.patch(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	},
	patchProductQuestions: {
		name: "patchProductQuestions",
		fn: async ({project_id, product_id, questions}: productQuestionsParams) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/products/${product_id}/questions`

			if(TBOX_MODE) {
				const resp = await axiosInstance.patch<Product>(path, { questions })
				return Promise.resolve(resp.data)
			} else {
				const params: PatchQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					},
					body: {
						questions
					}
				}
				const response: Product = await API.patch(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	},
	patchProjectTesters: {
		name: "patchProjectTesters",
		fn: async ({project_id, testers}: projectTestersParams) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/testers`

			if(TBOX_MODE) {
				const resp = await axiosInstance.patch<Project>(path, {testers: testers})
				return Promise.resolve(parseProjectFromBE(resp.data))
			} else {
				const params: PatchQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					},
					body: {
						testers: testers
					}
				}
				const response: Project = await API.patch(apiName, path, params)
				return Promise.resolve(parseProjectFromBE(response))
			}
		}
	},
	listProjectTesters: {
		name: "listProjectTesters",
		fn: async (project_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/testers`

			if(TBOX_MODE) {
				const resp = await axiosInstance.get<ProjectTester[]>(path)
				return Promise.resolve(resp.data)
			} else {
				const params: GetQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const response: ProjectTester[] = await API.get(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	},
	patchProjectOperators: {
		name: "patchProjectOperators",
		fn: async ({project_id, operators}: projectOperatorsParams) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/operators`
			const params: PatchQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: {
					operators
				}
			}
			const response: Product = await API.patch(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	listProjectOperators: {
		name: "listProjectOperators",
		fn: async (project_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/operators`

			if(TBOX_MODE) {
				const resp = await axiosInstance.get<ProjectOperator[]>(path)
				return Promise.resolve(resp.data)
			} else {
				const params: GetQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const response: ProjectOperator[] = await API.get(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	},
	patchProjectNote: {
		name: "patchProjectNote",
		fn: (project_id: number) => async ({ noteName, note }: projectNoteParams) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/notes/${noteName}`
			const params: PatchQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: {
					field: note
				}
			}
			const response: Project = await API.patch(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	createSessions: {
		name: "createSessions",
		fn: async (project_id: number, sessions: CreateSessionParam[]) => {
			const path = `/api/projects/${project_id}/sessions`
			const resp = await axiosInstance.post<SingleSession[]>(path, {
				sessions
			})
			return Promise.resolve(resp.data)
		}
	},
	listSessions: {
		name: "listSessions",
		fn: async (project_id: number, running: boolean) => {
			const path = `/api/projects/${project_id}/sessions?running=${running}`
			const resp = await axiosInstance.get<SingleSession[]>(path)
			return Promise.resolve<SingleSession[]>(resp.data)
		}
	},
	terminateSession: {
		name: "terminateSession",
		fn: async ({project_id, session_id}: {project_id: number, session_id: number}) => {
			const path = `/api/projects/${project_id}/sessions/${session_id}`
			const resp = await axiosInstance.delete<SingleSession>(path)
			return Promise.resolve<SingleSession>(resp.data)
		}
	},
	downloadProjectSettingsFile: {
		name: "downloadProjectSettingsFile",
		fn: async (project_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/download/project_settings_file`
			const params = {
				headers: {
					Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				responseType: "blob",
				response: true
			}
			const response = await API.get(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	downloadProjectTestersListFile: {
		name: "downloadProjectTestersListFile",
		fn: async (project_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/download/project_testers_file`
			const params = {
				headers: {
					Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				responseType: "blob",
				response: true
			}
			const response = await API.get(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	downloadDemographicFile: {
		name: "downloadDemographicFile",
		fn: async (project_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/download/project_demographic_file`
			const params = {
				headers: {
					Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				responseType: "blob",
				response: true
			}
			const response = await API.get(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	downloadSurveyFile: {
		name: "downloadSurveyFile",
		fn: async (project_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/download/questionnaireEEG_file`
			const params = {
				headers: {
					Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				responseType: "blob",
				response: true
			}
			const response = await API.get(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	downloadEEGData: {
		name: "downloadEEGData",
		fn: async (project_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/download/eeg_data`
			const params = {
				headers: {
					Accept: "application/zip",
					Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				// responseType: "blob",
				// response: true
			}
			const response: string = await API.get(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	downloadStatsOutput: {
		name: "downloadStatsOutput",
		fn: async (project_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/download/stats_output`
			const params = {
				headers: {
					Accept: "application/zip",
					Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
			}
			const response: string = await API.get(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	setCompleted: {
		name: "setCompleted",
		fn: async (project_id: number) => {
			const path = `/api/projects/${project_id}/completed`
			const resp = await axiosInstance.post<Project>(path)
			return Promise.resolve(parseProjectFromBE(resp.data))
		}
	},
	getLongData: {
		name: "getLongData",
		fn: async (project_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/analysis/long_data`

			if(TBOX_MODE) {
				const resp = await axiosInstance.get(path)
				return Promise.resolve<long_data_item[]>(resp.data)
			} else {
				const params: AdminQueryParams = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const response: long_data_item[] = await API.get(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	},
	getTimeline: {
		name: "getTimeline",
		fn: async (project_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/projects/${project_id}/analysis/timeline`

			if(TBOX_MODE) {
				const resp = await axiosInstance.get(path)
				return Promise.resolve<timeline_item[]>(resp.data)
			} else {
				const params: AdminQueryParams = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const response: timeline_item[] = await API.get(apiName, path, params)
				return Promise.resolve(response)
			}

		}
	}
}

export const operatorQueries = {
	listOperators: {
		name: "listOperators",
		fn: async () => {
			const apiName = "AdminQueries"
			const path = "/api/operators"

			if(TBOX_MODE) {
				const resp = await axiosInstance.get<Operator[]>(path)
				return Promise.resolve(resp.data)
			} else {
				const params: AdminQueryParams = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const response: Operator[] = await API.get(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	},
	createOperator: {
		name: "createOperator",
		fn: async ({fullname, password, company_id, email, image}: newOperatorParams) => {
			const apiName = "AdminQueries"
			const path = "/api/operators"

			const bodyFormData = new FormData()
			bodyFormData.append("email", email)
			bodyFormData.append("password", password)
			bodyFormData.append("fullname", fullname)
			bodyFormData.append("company_id", company_id)
			if (image) {
				bodyFormData.append("image", image, image.name)
			}
			const params: PostQuery = {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: bodyFormData,
			}
			const response: Operator = await API.post(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	updateOperator: {
		name: "updateOperator",
		fn: async ({id, operator}: {id: number, operator: updateOperatorsParams}) => {
			const apiName = "AdminQueries"
			const path = `/api/operators/${id}`

			const bodyFormData = new FormData()
			if (operator.email) {
				bodyFormData.append("email", operator.email)
			}
			if (operator.password) {
				bodyFormData.append("password", operator.password)
			}
			if (operator.fullname) {
				bodyFormData.append("fullname", operator.fullname)
			}
			if (operator.company_id) {
				bodyFormData.append("company_id", operator.company_id.toString())
			}
			if (operator.image) {
				bodyFormData.append("image", operator.image, operator.image.name)
			}
			const params: PostQuery = {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: bodyFormData
			}
			const response: Operator = await API.patch(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	deleteOperator: {
		name: "deleteOperator",
		fn: async (operator_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/operators/${operator_id}`
			const params: DeleteQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
			}
			const response = await API.del(apiName, path, params)
			return Promise.resolve(response)
		}
	},
}

export const companyQueries = {
	listCompanies: {
		name: "listCompanies",
		fn: async () => {
			const apiName = "AdminQueries"
			const path = "/api/companies"

			if(TBOX_MODE) {
				const resp = await axiosInstance.get<Company[]>(path)
				return Promise.resolve(resp.data)
			} else {
				const params: GetQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const response: Company[] = await API.get(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	},
	createCompany: {
		name: "createCompany",
		fn: async ({name, logo}: {name: string, logo?: File}) => {
			const apiName = "AdminQueries"
			const path = "/api/companies"

			const bodyFormData = new FormData()
			bodyFormData.append("name", name)
			if (logo) {
				bodyFormData.append("logo", logo, logo.name)
			}
			const params: PostQuery = {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: bodyFormData,
			}
			const response: Company = await API.post(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	updateCompany: {
		name: "updateCompany",
		fn: async ({id, company}: {id: number, company: { name?: string, logo?: File | null }}) => {
			const apiName = "AdminQueries"
			const path = `/api/companies/${id}`

			const bodyFormData = new FormData()
			if (company.name) {
				bodyFormData.append("name", company.name)
			}
			if (company.logo) {
				bodyFormData.append("logo", company.logo, company.logo.name)
			} else if(company.logo === null) {
				bodyFormData.append("logo", new File([], ""))
			}
			const params: PostQuery = {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: bodyFormData,
			}
			const response: Company = await API.patch(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	deleteCompany: {
		name: "deleteCompany",
		fn: async (company_id: number, forced = false) => {
			const apiName = "AdminQueries"
			const path = `/api/companies/${company_id}?forced_delete=${forced}`

			const params: DeleteQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
			}
			const response = await API.del(apiName, path, params)
			return Promise.resolve(response)
		}
	}
}

export const tboxQueries = {
	listTBoxes: {
		name: "listTBoxes",
		fn: async () => {
			const apiName = "AdminQueries"
			const path = "/api/tboxes"

			if(TBOX_MODE) {
				const resp = await axiosInstance.get<TBox[]>(path)
				return Promise.resolve(resp.data)
			} else {
				const params: GetQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const response: TBox[] = await API.get(apiName, path, params)
				return Promise.resolve(response.map(tBoxResponseFormatter))
			}
		}
	},
	createTBox: {
		name: "createTBox",
		fn: async (tbox: {name: string, max_concurrent_testers?: number, company_id?: number}) => {
			const apiName = "AdminQueries"
			const path = "/api/tboxes"

			const params: PostQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: {
					name: tbox.name,
					max_concurrent_testers: tbox.max_concurrent_testers,
					company_id: tbox.company_id,
				},
			}
			const response: TBox = await API.post(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	updateTBox: {
		name: "updateTBox",
		fn: async ({id, tbox}: {id: number, tbox: {name?: string, max_concurrent_testers?: number, company_id?: number}}) => {
			const apiName = "AdminQueries"
			const path = `/api/tboxes/${id}`

			const params: PostQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: {
					name: tbox.name,
					max_concurrent_testers: tbox.max_concurrent_testers,
					company_id: tbox.company_id,
				},
			}
			const response: TBox = await API.patch(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	deleteTBox: {
		name: "deleteTBox",
		fn: async (tbox_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/tboxes/${tbox_id}`

			const params: DeleteQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
			}
			const response = await API.del(apiName, path, params)
			return Promise.resolve(response)
		}
	}
}

export const testersQueries = {
	listTesters: {
		name: "listTesters",
		fn: async () => {
			const apiName = "AdminQueries"
			const path = "/api/testers"

			if(TBOX_MODE) {
				const resp = await axiosInstance.get<Tester[]>(path)
				return Promise.resolve(resp.data)
			} else {
				const params: GetQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const response: Tester[] = await API.get(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	},
	createTester: {
		name: "createTester",
		fn: async (tester: {
			company_id: number,
			project_id?: number
			first_name: string,
			last_name: string,
			date_of_birth: dayjs.Dayjs,
			gender: string,
			ethnicity?: string,
			education?: string,
			professional_sector?: string,
			diet?: string,
			smoker?: string,
			country?: string,
			group?: string,
			phone_number?: string,
			email_address?: string,
		}) => {
			const apiName = "AdminQueries"
			const path = "/api/testers"

			if(TBOX_MODE) {
				const resp = await axiosInstance.post<Tester>(path, {
					company_id: tester.company_id,
					project_id: tester.project_id,
					first_name: tester.first_name,
					last_name: tester.last_name,
					date_of_birth: tester.date_of_birth.format("YYYY-MM-DD"),
					gender: tester.gender,
					ethnicity: tester.ethnicity,
					education: tester.education,
					professional_sector: tester.professional_sector,
					diet: tester.diet,
					smoker: tester.smoker,
					country: tester.country,
					group: tester.group,
					phone_number: tester.phone_number,
					email_address: tester.email_address,
				})
				return Promise.resolve(resp.data)
			} else {
				const params: PostQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					},
					body: {
						company_id: tester.company_id,
						project_id: tester.project_id,
						first_name: tester.first_name,
						last_name: tester.last_name,
						date_of_birth: tester.date_of_birth.format("YYYY-MM-DD"),
						gender: tester.gender,
						ethnicity: tester.ethnicity,
						education: tester.education,
						professional_sector: tester.professional_sector,
						diet: tester.diet,
						smoker: tester.smoker,
						country: tester.country,
						group: tester.group,
						phone_number: tester.phone_number,
						email_address: tester.email_address,
					},
				}
				const response: Tester = await API.post(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	},
	updateTester: {
		name: "updateTester",
		fn: async ({id, tester}: {id: number, tester: {
			first_name?: string,
			last_name?: string,
			date_of_birth?: Date,
			gender?: string,
			ethnicity?: string,
			education?: string,
			professional_sector?: string,
			diet?: string,
			smoker?: string,
			country?: string,
			group?: string
			phone_number?: string,
			email_address?: string,
		}}) => {
			const apiName = "AdminQueries"
			const path = `/api/testers/${id}`

			if(TBOX_MODE) {
				const resp = await axiosInstance.patch<Tester>(path,
					{
						first_name: tester.first_name,
						last_name: tester.last_name,
						date_of_birth: tester.date_of_birth,
						gender: tester.gender,
						ethnicity: tester.ethnicity,
						education: tester.education,
						professional_sector: tester.professional_sector,
						diet: tester.diet,
						smoker: tester.smoker,
						country: tester.country,
						group: tester.group,
						phone_number: tester.phone_number,
						email_address: tester.email_address,
					})
				return Promise.resolve(resp.data)
			} else {
				const params: PostQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					},
					body: {
						first_name: tester.first_name,
						last_name: tester.last_name,
						date_of_birth: tester.date_of_birth,
						gender: tester.gender,
						ethnicity: tester.ethnicity,
						education: tester.education,
						professional_sector: tester.professional_sector,
						diet: tester.diet,
						smoker: tester.smoker,
						country: tester.country,
						group: tester.group,
						phone_number: tester.phone_number,
						email_address: tester.email_address,
					},
				}
				const response: Tester = await API.patch(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	},
	deleteTester: {
		name: "deleteTester",
		fn: async (global_tester_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/testers/${global_tester_id}`

			if(TBOX_MODE) {
				const resp = await axiosInstance.delete(path)
				return Promise.resolve(resp.data)
			} else {
				const params: DeleteQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					},
				}
				const response = await API.del(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	},
	uploadTestersFile: {
		name: "uploadTestersFile",
		fn: async ({company_id, file}: { company_id: number, file: File }) => {
			const apiName = "AdminQueries"
			const path = "/api/testers/import"

			const bodyFormData = new FormData()
			bodyFormData.append("company_id", company_id.toString())
			bodyFormData.append("tester_file", file, file.name)
			const params: PostQuery = {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: bodyFormData
			}
			const response: {
				inserted: number,
				updated: number,
				failed: number,
				tester_ids: number[]
			} = await API.post(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	downloadTemplateFile: {
		name: "downloadTemplateFile",
		fn: async () => {
			const apiName = "AdminQueries"
			const path = "/api/testers/download_sample"

			const params = {
				headers: {
					"Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				responseType: "blob",
				response: true
			}
			const response = await API.get(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	getCurrentSingleSession: {
		name: "getCurrentSingleSession",
		fn: async (project_id: number, local_tester_id: number) => {
			const path = `/api/testers/${project_id}/current_session/${local_tester_id}`
			const resp = await axiosInstance.get(path)
			return Promise.resolve<SingleSession | null>(resp.data)
		}
	}
}

export const sessionsQueries = {
	listSessions:{
		name: "listSessions",
		fn: async () => {
			const apiName = "AdminQueries"
			const path = "/api/sessions"

			if(TBOX_MODE) {
				const res = await axiosInstance.get<SingleSession[]>(path)
				return Promise.resolve(res.data.map(singleSessionResponseFormatter))
			} else {
				const params: GetQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const resp: SingleSession[] = await API.get(apiName, path, params)
				return Promise.resolve(resp.map(singleSessionResponseFormatter))
			}
		}
	},
	getSessionsById: {
		name: "getSessionsById",
		fn: async (project_id: number, session_ids: number[]) => {
			const path = `/api/projects/${project_id}/multiple_sessions?${session_ids.map(id => "ids="+id).join("&")}`
			const resp = await axiosInstance.get<SingleSession[]>(path)
			return Promise.resolve(resp.data)
		}
	},
	deleteSessions: {
		name: "deleteSession",
		fn: async  (session_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/sessions/${session_id}`

			if(TBOX_MODE) {
				const res = await axiosInstance.delete(path)
				return Promise.resolve(res.data)
			} else {
				const params: DeleteQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					},
				}
				const response = await API.del(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	}
}

export const classificationQueries = {
	listClassifications: {
		name: "listClassifications",
		fn: async () => {
			const apiName = "AdminQueries"
			const path = "/api/classifications"

			if(TBOX_MODE) {
				const resp = await axiosInstance.get<Classification[]>(path)
				return Promise.resolve(resp.data)
			} else {
				const params: GetQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const response: Classification[] = await API.get(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	},
	uploadClassificationsFile: {
		name: "uploadClassificationsFile",
		fn: async (file: File) => {
			const apiName = "AdminQueries"
			const path = "/api/classifications/imports"

			const bodyFormData = new FormData()
			bodyFormData.append("classification", file, file.name)
			const params: PostQuery = {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: bodyFormData
			}
			const response: Classification[] = await API.post(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	downloadClassificationsFile: {
		name: "downloadClassificationsFile",
		fn: async () => {
			const apiName = "AdminQueries"
			const path = "/api/classifications/imports"

			const params = {
				headers: {
					"Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				responseType: "blob",
				response: true
			}
			const response = await API.get(apiName, path, params)
			return Promise.resolve(response)
		}
	},
}

export const templatesQueries = {
	listTemplates: {
		name: "listTemplates",
		fn: async () => {
			const apiName= "AdminQueries"
			const path = "/api/templates"

			if(TBOX_MODE) {
				const resp = await axiosInstance.get<Template[]>(path)
				return Promise.resolve(resp.data)
			} else {
				const params: GetQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const response: Template[] = await API.get(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	},
	createTemplate: {
		name: "createTemplate",
		fn: async ({name, description, phases, questions}: newTemplateParams) => {
			const apiName = "AdminQueries"
			const path = "/api/templates"


			const params: PostQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: {
					name,
					description,
					phases,
					questions
				}
			}
			const response: Template = await API.post(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	deleteTemplate: {
		name: "deleteTemplate",
		fn: async (template_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/templates/${template_id}`

			const params: DeleteQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
			}
			const response = await API.del(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	updateTemplate: {
		name: "updateTemplate",
		fn: async ({name, description, phases, questions,id}: updateTemplateParams) => {
			const apiName = "AdminQueries"
			const path = `/api/templates/${id}`

			const params: PostQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: {
					name,
					description,
					phases,
					questions
				}
			}
			const response: Question = await API.put(apiName, path, params)
			return Promise.resolve(response)
		}
	},
}

export const projectConfigurationQueries = {
	selectTemplate: {
		name: "selectTemplate",
		fn: async ({ project_id, template_id}: { project_id: number, template_id: number | null }) => {
			const apiName= "AdminQueries"
			const path = `/api/projects/${project_id}/templates`

			const params: PatchQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: {template_id}
			}
			const response: Template = await API.patch(apiName, path, params)
			return Promise.resolve(response)
		}
	}
}

export const phaseQueries = {
	listPhases: {
		name: "listPhases",
		fn: async () => {
			const apiName= "AdminQueries"
			const path = "/api/phases"

			if(TBOX_MODE) {
				const resp = await axiosInstance.get<Phase[]>(path)
				return Promise.resolve(resp.data)
			} else {
				const params: GetQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const response: Phase[] = await API.get(apiName, path, params)
				return Promise.resolve<Phase[]>(response)
			}
		}
	},
	patchPhase: {
		name: "patchPhase",
		fn: async ({id, description_i18n}: {id: number, description_i18n: PhaseDescriptionEdit[]}) => {
			if (TBOX_MODE) throw Error("Non implementato sulla tbox")
			const apiName = "AdminQueries"
			const path = `/api/phases/${id}`

			const params: PostQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: { description_i18n },
			}
			const response: Phase = await API.patch(apiName, path, params)
			return Promise.resolve(response)
		}
	}
}

export const eegQueries = {
	listEEGs: {
		name: "listEEGs",
		fn: async () => {
			const path = "/api/eeg"
			console.log("TBOX_MODE ", TBOX_MODE)
			if(TBOX_MODE) {
				const resp = await axiosInstance.get(path)
				const eegs: Eeg[] = resp.data
				return Promise.resolve(eegs)
			} else {
				throw Error("Not implemented in the cloud")
			}
		}
	}
}

export const questionsQueries = {
	listQuestions: {
		name: "listQuestions",
		fn: async () => {
			const apiName = "AdminQueries"
			const path = "/api/questions"

			if(TBOX_MODE) {
				const resp = await axiosInstance.get<Question[]>(path)
				return Promise.resolve(resp.data)
			} else {
				const params: AdminQueryParams = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const response: Question[] = await API.get(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	},
	getQuestionsForProduct: {
		name: "getQuestionsForProduct",
		fn: async ({ product_id }: { product_id: number }) => {
			const apiName = "AdminQueries"
			const path = `/api/questions/${product_id}`

			if(TBOX_MODE) {
				const resp = await axiosInstance.get<Question[]>(path)
				return Promise.resolve(resp.data)
			} else {
				const params: AdminQueryParams = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const response: Question[] = await API.get(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	},
	createQuestion: {
		name: "createQuestion",
		fn: async (  { label, type, min_value, max_value, translations, possible_answers, evaluation_grid_options, company_id, horizontal }: newQuestionParams) => {
			const apiName = "AdminQueries"
			const path = "/api/questions"

			const params: PostQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: {
					label,
					type,
					min_value,
					max_value,
					translations,
					possible_answers,
					evaluation_grid_options,
					company_id,
					horizontal
				},
			}
			const response: Question = await API.post(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	updateQuestion: {
		name: "updateQuestion",
		fn: async ({id, question}: {id: number, question: newQuestionParams}) => {
			const apiName = "AdminQueries"
			const path = `/api/questions/${id}`

			const params: PostQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
				body: question,

			}
			const response: Question = await API.put(apiName, path, params)
			return Promise.resolve(response)
		}
	},
	deleteQuestion: {
		name: "deleteQuestion",
		fn: async (question_label_id: number) => {
			const apiName = "AdminQueries"
			const path = `/api/questions/${question_label_id}`

			const params: DeleteQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
			}
			const response = await API.del(apiName, path, params)
			return Promise.resolve(response)
		}
	}
}

export const analysisQueries = {
	analysisStart: {
		name: "analysisStart",
		fn: async (project_id: number) => {
			const apiName= "AdminQueries"
			const path = `/api/projects/${project_id}/analysis_start`
			if(TBOX_MODE) {
				throw Error("Not implemented in the cloud")
			} else {
				const params: GetQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				await API.get(apiName, path, params)
				return Promise.resolve()
			}
		}
	},
	analysisStatus: {
		name: "analysisStatus",
		fn: async (project_id: number) => {
			const apiName= "AdminQueries"
			const path = `/api/projects/${project_id}/analysis_status`
			if(TBOX_MODE) {
				throw Error("Not implemented in the cloud")
			} else {
				const params: GetQuery = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
					}
				}
				const response: AnalysisStatus = await API.get(apiName, path, params)
				return Promise.resolve(response)
			}
		}
	}
}

export const testersAnswers = {
	listAnswers: {
		name: "listAnswers",
		fn: async () => {
			const apiName = "AdminQueries"
			const path = "/api/answers"

			const params: GetQuery = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				}
			}

			const response: Answer[] = await API.get(apiName, path, params)
			return Promise.resolve(response)
		}
	},

	createAnswer: {
		name: "createAnswer",
		fn: async(answer: {
			single_session_id: number,
			product_id: number,
			question_id: number,
			single_answer_response?: number | null,
			multiple_answer_response?: number[] | null,
			rating_index_response?: number | null,
			vas_scale_response?: number | null,
			evaluation_grid_response?: {[k: string]: string} | null
		}) => {
			const path = "/api/answers"
			const params= {
				single_session_id: answer.single_session_id,
				product_id: answer.product_id,
				question_id: answer.question_id,
				single_answer_response: answer.single_answer_response === null ? undefined : answer.single_answer_response,
				multiple_answer_response: answer.multiple_answer_response === null ? undefined : answer.multiple_answer_response,
				rating_index_response: answer.rating_index_response === null ? undefined : answer.rating_index_response,
				vas_scale_response: answer.vas_scale_response === null ? undefined : answer.vas_scale_response,
				evaluation_grid_response: answer.evaluation_grid_response === null ? undefined : answer.evaluation_grid_response,
			}
			const response: Answer = await axiosInstance.post(path, params)
			return Promise.resolve(response)
		}
	}
}