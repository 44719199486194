import { Grid, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"

import fascia from "../../assets/backgrounds/T pattern_fascia.svg"
import { ParagraphTypography } from "../../components/customTypography"

const SubtitleTypography = styled(Typography)(({ theme }) => ({
	color: theme.palette.primary.main
})) as typeof Typography

const BoxedTypography = styled(ParagraphTypography)(() => ({
	backgroundColor: "#f1c7b9",
	borderRadius: 30,
	padding: "5px 20px",
})) as typeof Typography

export default function PADescription () {
	return (
		<Grid
			container
			direction="column"
			sx={{
				backgroundImage: `url("${fascia}")`,
				// 72 x 65 = 40 x 36
				backgroundSize: "40px 37px",
			}}
		>
			<Grid item margin={2}>
				<Typography variant="h3" fontWeight="bold">IMPLICIT MEASUREMENTS</Typography>
			</Grid>
			<Grid item container direction="column" sx={{backgroundColor: "white"}} marginBottom={3} px={2} paddingBottom={1} xs>
				<Grid item container>
					<Grid item container xs={7} justifyContent="center">
						<Typography variant="h6" fontWeight="bold">DEFINITION OF THE VALIDATED METRICS</Typography>
					</Grid>
					<Grid item container xs={5} justifyContent="center">
						<Typography variant="h6" fontWeight="bold">IN THE CONTEXT OF FOOD</Typography>
					</Grid>
				</Grid>
				<Grid item container>
					<Grid item container xs={7} direction="column" gap={4}>
						<Grid item>
							<SubtitleTypography>Frontal asymmetry</SubtitleTypography>
							<ParagraphTypography>The natural balance of approach and avoidance of an individual about a product. An increase in brain activity recorded in the right prefrontal area is associated with negative emotions, while left prefrontal activity is associated with positive emotions (Palmiero & Piccardi, 2017).</ParagraphTypography>
						</Grid>
						<Grid item>
							<SubtitleTypography>Cognitive workload</SubtitleTypography>
							<ParagraphTypography>The amount of brain resources required for an individual to complete a task (Chikhi, Matton & Blanchet, 2022). The cognitive workload decreases as individuals engage in more familiar tasks or elements (Miller & Boyle, 2017).</ParagraphTypography>
						</Grid>
						<Grid item>
							<SubtitleTypography>Relax</SubtitleTypography>
							<ParagraphTypography>Index of experienced and perceived mental and emotional well being and relaxation levels (Teplan et al., 2009).</ParagraphTypography>
						</Grid>
						<Grid item>
							<SubtitleTypography>Engagement</SubtitleTypography>
							<ParagraphTypography>A state of full engagement achieved by a person when she is immersed in an activity and in a state of concentration and satisfaction (Csikszentmihalyi, 1997). The engagement shows a link with the concept of attention, however they are separate mental states.</ParagraphTypography>
						</Grid>
					</Grid>
					<Grid item container xs={5} direction="column" gap={2} paddingX="5%">
						<Grid item justifyContent="center">
							<SubtitleTypography textAlign="center">Approach/Avoidance</SubtitleTypography>
							<BoxedTypography>Indicates the hedonic response to stimuli before/during/after a food experience.</BoxedTypography>
						</Grid>
						<Grid item justifyContent="center">
							<SubtitleTypography textAlign="center">Mental Effort</SubtitleTypography>
							<BoxedTypography>Allows for the evaluation of food through the identifying level familairity/novelty of certain food characteristics.</BoxedTypography>
						</Grid>
						<Grid item justifyContent="center">
							<SubtitleTypography textAlign="center">Well-being</SubtitleTypography>
							<BoxedTypography>Exhibits the ease of certain food experiences when interacting or consuming a product.</BoxedTypography>
						</Grid>
						<Grid item justifyContent="center">
							<SubtitleTypography textAlign="center">Engaged Interaction</SubtitleTypography>
							<BoxedTypography>Highlights the ability for a food product/experience to establish and maintain a certain emotional connection with the participant.</BoxedTypography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}