import { Alert, AlertColor, Snackbar } from "@mui/material"

interface SnackbarProps {
	open: boolean
	handleClose: () => void
	severity: AlertColor
	message: string
}
const getSnackbarColor = (severity: string) => {
	if (severity === "info") {
		return "#F6F6F6"
	} else if (severity === "warning") {
		return "#FFE27C"
	} else if (severity === "error") {
		return "#FE8E8E"
	} else if (severity === "success") {
		return "#72E778"
	}
}
const CustomSnackbar = (props: SnackbarProps) => {
	const color = getSnackbarColor(props.severity)
	return (
		<Snackbar
			open={props.open}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center"
			}}
			autoHideDuration={6000}
			onClose={props.handleClose}
		>
			<Alert
				icon={false}
				onClose={props.handleClose}
				severity={props.severity}
				sx={{
					width: "100%",
					backgroundColor: color
				}}
			>
				{props.message}
			</Alert>
		</Snackbar >
	)
}
export default CustomSnackbar