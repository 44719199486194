import { Amplify } from "aws-amplify"
import React from "react"
import ReactDOM from "react-dom/client"

import App from "./App"
import awsExports from "./aws-exports"
import {
	ENVIRONMENT,
	REACT_APP_BE_ADDRESS,
	REACT_APP_MOCK,
	REACT_APP_TBOX,
	REACT_APP_TBOX_ENVIRONMENT,
	TBOX_MODE
} from "./constants"
import mock from "./networking/mock"
import reportWebVitals from "./reportWebVitals"

Amplify.configure(awsExports)
console.info("REACT_APP_TBOX", REACT_APP_TBOX)
console.info("REACT_APP_TBOX_ENVIRONMENT", REACT_APP_TBOX_ENVIRONMENT)
console.info("REACT_APP_BE_ADDRESS", REACT_APP_BE_ADDRESS)
console.info("REACT_APP_MOCK", REACT_APP_MOCK)
console.info("TBOX_MODE", TBOX_MODE)
console.info("ENVIRONMENT", ENVIRONMENT)

if (REACT_APP_MOCK) {
	mock()
}

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
)
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
