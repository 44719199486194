import { EmailOutlined } from "@mui/icons-material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined"
import { Avatar, Grid, styled } from "@mui/material"
import { useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { ChartTitle, DashboardCardParagraphTypography, ParagraphTypography, TitleTypography } from "../../../components/customTypography"
import { operatorQueries, projectQueries } from "../../../networking/networking"
import { Operator, Project } from "../../../types/genericTypes"

interface props {
	project: Project
}

const iconStyle = {
	fontSize: "20px",
	marginRight: "5px"
}
export const IntroPage = (props: props) => {
	const { project_id = "" } = useParams()
	const [operatorsIds, setOperatorsIds] = useState<number[]>([])
	const [operators, setOperators] = useState<Operator[]>([])

	const StyledAvatar = styled(Avatar)(() => ({
		height: "70px",
		width: "70px",
	})) as typeof Avatar

	const { isFetching: isProjectOperatorsFetching } = useQuery(
		[projectQueries.listProjectOperators.name, parseInt(project_id)],
		() => projectQueries.listProjectOperators.fn(parseInt(project_id)),
		{
			onSuccess: (data) => {
				const ids: number[] = []
				data.map((operator) => {
					ids.push(operator.operator_id)
				})
				setOperatorsIds(ids)
			},
			enabled: Boolean(project_id)
		}
	)

	const { isFetching: isOperatorsListFetching } = useQuery(
		operatorQueries.listOperators.name,
		operatorQueries.listOperators.fn,
		{
			onSuccess: (data) => {
				const operators: Operator[] = []
				data.map((operator) => {
					if (operatorsIds.includes(operator.id)) {
						operators.push(operator)
					}
				})
				setOperators(operators)
			},
			enabled: operatorsIds.length > 0

		}
	)

	return (
		<Grid
			item
			container
			sx={{
				padding: 3,
				paddingTop: 0,
				height: "100%"
			}}
		>
			<Grid item xs={12} sx={{ marginBottom: 2 }}>
				<TitleTypography>Creator</TitleTypography>
			</Grid>
			<Grid item container alignItems="center">
				<StyledAvatar sx={{ marginRight: 3 }} />
				<Grid item container sx={{ width: "initial" }}>
					<Grid item xs={12} sx={{ marginBottom: 2 }}>
						<ChartTitle>{props.project.created_by}</ChartTitle>
					</Grid>
					<Grid item container alignItems="center" justifyContent="space-between" xs={12}>
						<Grid item container alignItems="center" width="max-content">
							<PlayCircleFilledWhiteOutlinedIcon
								color="primary"
								sx={iconStyle}
							/>
							<DashboardCardParagraphTypography>{`Created: ${props.project.created_at.toISOString().split("T")[0]}`} </DashboardCardParagraphTypography>
						</Grid>
						<Grid item container alignItems="center" width="max-content">
							<EmailOutlined
								color="primary"
								sx={iconStyle}
							/>
							<DashboardCardParagraphTypography>{`Created By: ${props.project.created_email_by}`} </DashboardCardParagraphTypography>
						</Grid>
						<Grid item container alignItems="center" width="max-content">
							<EditOutlinedIcon
								color="primary"
								sx={iconStyle}
							/>
							<DashboardCardParagraphTypography>{`Last edited: ${props.project.updated_at.toISOString().split("T")[0]}`} </DashboardCardParagraphTypography>
						</Grid>

					</Grid>
				</Grid>
			</Grid>

			{/* OPERATORS */}
			<Grid item container sx={{ marginTop: 2 }} gap={2}>
				<Grid item xs={12} sx={{ marginBottom: 2 }}>
					<TitleTypography>Operators</TitleTypography>
				</Grid>
				{!isOperatorsListFetching && !isProjectOperatorsFetching && operators.length === 0 ?
					<ParagraphTypography>No operators for this project</ParagraphTypography>
					:
					operators.map((operator) =>
						<Grid key={operator.id} xs={3} item container alignItems="center" sx={{ flexWrap: "nowrap" }}>
							<StyledAvatar sx={{ marginRight: 3 }} />
							<Grid item container sx={{ width: "initial" }}>
								<Grid item xs={12} sx={{ marginBottom: 2 }}>
									<ChartTitle>{operator.fullname}</ChartTitle>
								</Grid>

							</Grid>
						</Grid>
					)}

			</Grid>
		</Grid>
	)
}



