import { CognitoUser } from "@aws-amplify/auth"
import { VisibilityOff } from "@mui/icons-material"
import Visibility from "@mui/icons-material/Visibility"
import {
	Button,
	CircularProgress,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	TextField,
	Typography
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { AxiosError } from "axios"
import React, { useState } from "react"
import { useMutation } from "react-query"
import { Link as ReactRouterLink, useNavigate } from "react-router-dom"

import { TBOX_MODE } from "../../constants"
import { authQueries } from "../../networking/networking"
import { useFullScreenStore, useOperatorStore, useUserStore } from "../../stateManagement"

//---------------STYLE --------------------------
const TitleTypography = styled(Typography)(({ theme }) => ({
	width: "100%",
	color: theme.palette.primary.main,
})) as typeof Typography

//-----------------------------------

const Login = () => {
	const navigate = useNavigate()
	const [step, setStep] = useState("SIGNIN")
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [showPassword, setShowPassword] = React.useState(false)
	const [repeatPassword, setRepeatPassword] = useState("")
	const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>(undefined)
	const [testerError, setTesterError] = useState("")
	const [error, setError] = useState("")
	const [passwordError, setPasswordError] = useState("")
	const persistOpStore = useOperatorStore()
	const setUserRole = useUserStore((state) => state.setUserRole)
	const { handle } = useFullScreenStore()

	const handleClickShowPassword = () => setShowPassword((show) => !show)
	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	const signIn = useMutation({
		mutationKey: authQueries.signIn.name,
		mutationFn: authQueries.signIn.fn,
		onError: (signInError: AxiosError) => {
			console.error("errore qui", signInError)
			setError(signInError.message)
		},
		onSuccess: (cognitoUser: CognitoUser) => {
			console.log("signIn response", cognitoUser)
			if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
				setStep("NEW_PASSWORD_REQUIRED")
				setCognitoUser(cognitoUser)
				setPassword("")
				setRepeatPassword("")
			} else {
				navigate("/", { replace: true })
			}
		},
	})

	const completeNewPassword = useMutation({
		mutationKey: authQueries.completeNewPassword.name,
		mutationFn: authQueries.completeNewPassword.fn,
		onError: (error: AxiosError) => {
			if (error.code === "InvalidPasswordException") {
				setPasswordError("Password must contain at least 8 characters, including an uppercase letter, a lowercase letter, a number, and a symbol.")
			} else {
				setPasswordError(error.message)
			}
		},
		onSuccess: cognitoUser => {
			console.log("completeNewPassword response", cognitoUser)
			navigate("/users", { replace: true })
		}
	})

	const loginAsTester = useMutation(
		authQueries.signInAsTester.name,
		authQueries.signInAsTester.fn,
		{
			onSuccess: (data) => {
				setUserRole("tester")
				navigate(
					`/tester/${data.local_tester_id}/project/${data.project_id}`,
					{ replace: true }
				)
			},
			onError: (error: AxiosError<string>) => {
				setTesterError(error.response?.data || "Generic login error")
			}
		}
	)

	const loginAsOperator = useMutation(
		authQueries.signInAsOperator.name,
		authQueries.signInAsOperator.fn,
		{
			onSuccess: (user_data) => {
				localStorage.setItem("company_group_name", user_data.company_group_name)
				persistOpStore.setIsUserOperator(true)
				persistOpStore.setOperatorCompany(user_data.company_id)
				persistOpStore.setOperatorName(user_data.fullname)
				setUserRole("operator")
				navigate("/", { replace: true })
			}
		}
	)

	const handleSubmitSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
		if (TBOX_MODE) {
			if (!password) {
				event.preventDefault()
				console.log("login as tester")
				loginAsTester.mutate(email, {
					onSuccess: () => {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						handle?.enter(event)
					}
				})
			} else {
				console.log("mlogin as operator")
				const data = {
					username: email,
					password: password
				}
				event.preventDefault()
				loginAsOperator.mutate(data)
			}

		} else {
			event.preventDefault()
			signIn.mutate({
				username: email,
				password
			})
		}
	}

	const handleSubmitNewPasswordRequired = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (!cognitoUser) {
			console.warn("Impossible", cognitoUser)
			return
		}
		completeNewPassword.mutate({
			user: cognitoUser,
			newPassword: repeatPassword
		})
	}

	if (step === "SIGNIN") return (
		<form onSubmit={handleSubmitSignIn}>
			<Grid container justifyContent="center">
				<Grid item sx={{ marginBottom: "30px" }}>
					<TitleTypography gutterBottom variant="h5" sx={{ fontWeight: "bold" }}> Login </TitleTypography>
				</Grid>
				{/* username */}
				<Grid item xs={12} sx={{ marginBottom: "30px" }}>
					<Typography gutterBottom> User name</Typography>
					<TextField
						fullWidth
						variant="outlined"
						type={TBOX_MODE ? "text" : "email"}
						autoComplete={TBOX_MODE ? undefined : "email"}
						error={Boolean(error) || Boolean(testerError)}
						helperText={testerError}
						value={email}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
					/>
				</Grid>
				{/* password */}
				<Grid item xs={12}>
					<Typography gutterBottom> Password</Typography>
					<TextField
						fullWidth
						variant="outlined"
						type={showPassword ? "text" : "password"}
						autoComplete="current-password"
						InputProps={{
							endAdornment:
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
						}}
						error={Boolean(error)}
						helperText={error}
						value={password}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
					/>
				</Grid>

				{/* options */}
				{!TBOX_MODE &&
					<Grid item container alignItems="center" sx={{ marginTop: "40px" }} justifyContent="flex-end">
						<Grid item >
							<Link component={ReactRouterLink} to="/auth/forgotPassword" underline="hover" sx={{ fontWeight: "bold" }}>Forgot password?</Link>
						</Grid>
					</Grid>
				}

				{/* submit button */}
				<Button
					type="submit"
					variant="contained"
					disabled={signIn.isLoading || !email || (!TBOX_MODE && !password)}
					sx={{
						marginTop: "40px",
						width: "100%",
						padding: "15px 0"
					}}
				>
					{signIn.isLoading ? <CircularProgress size={25} /> : "Log in"}
				</Button>
			</Grid>
		</form>)

	if (step === "NEW_PASSWORD_REQUIRED") return (
		<form onSubmit={handleSubmitNewPasswordRequired}>
			<Grid container justifyContent="center">
				<Grid item sx={{ marginBottom: "30px" }}>
					<TitleTypography gutterBottom variant="h5" sx={{ fontWeight: "bold" }}> Complete registration </TitleTypography>
				</Grid>
				{/* new password */}
				<Grid item xs={12} sx={{ marginBottom: "30px" }}>
					<Typography gutterBottom> New password</Typography>
					<TextField
						fullWidth
						variant="outlined"
						type="password"
						autoComplete="new-password"
						value={password}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
					/>
				</Grid>
				{/* repeat new password */}
				<Grid item xs={12}>
					<Typography gutterBottom> Repeat password</Typography>
					<TextField
						fullWidth
						variant="outlined"
						type="password"
						autoComplete="new-password"
						error={Boolean(password) && Boolean(repeatPassword) && password !== repeatPassword}
						helperText={(Boolean(password) && Boolean(repeatPassword) && password !== repeatPassword) ? "Passwords don't match" : <span style={{ color: "#FF9595" }}>{passwordError}</span>}
						value={repeatPassword}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRepeatPassword(event.target.value)}
					/>
				</Grid>

				{/* submit button */}
				<Button
					type="submit"
					variant="contained"
					disabled={completeNewPassword.isLoading || !password || !repeatPassword || password !== repeatPassword}
					sx={{
						marginTop: "40px",
						width: "100%",
						padding: "15px 0"
					}}
				>
					{completeNewPassword.isLoading ? <CircularProgress size={25} /> : "Confirm"}
				</Button>
			</Grid>
		</form>)

	return (<p>Step non previsto {step}</p>)
}

export default Login
