import { Grid } from "@mui/material"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import { CustomButton } from "../../components/buttons/CustomButton"
import { H1Typography } from "../../components/customTypography"
import { projectQueries } from "../../networking/networking"
import { Project } from "../../types/genericTypes"

type TextType = {
	[key in Project["language"]]: string
}

const endText: TextType = {
	en: "Thank you for participating!",
	de: "",
	es: "",
	fr: "",
	it: "",
}

export const TesterSectionEnd = () => {
	const {
		project_id = "",
	} = useParams()
	const navigate = useNavigate()

	const { data: project } = useQuery(
		[projectQueries.getProject.name, parseInt(project_id)],
		() => projectQueries.getProject.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id),
		}
	)


	const handleFinishButton = () => {
		navigate("/auth/signOut")
	}

	return <Grid container justifyContent="center">
		<Grid item marginY={3}>
			<H1Typography textAlign="center">{endText[project?.language || "en"]}</H1Typography>
		</Grid>
		<Grid item container justifyContent="center" marginTop={3}>
			<CustomButton
				text="Finish"
				action={handleFinishButton}
				style={{
					width: "260px",
					height: "45px",
					backgroundColor: "black",
					color: "white",
					marginBottom: "24px",
				}}
			/>
		</Grid>
	</Grid>
}
