import { SvgIcon, SvgIconProps } from "@mui/material"

const imageIcon = {
	overflow: "visible"
}
export function LookIcon(props: SvgIconProps) {
	return (
		<SvgIcon
			{...props}
			sx={{
				...props.sx,
				...imageIcon
			}}
		>
			<path d="M29.3378 15.5405C29.1486 15.1891 24.1216 7 16.2297 7C8.33782 7 3.3108 15.1892 3.12161 15.5405C2.95946 15.8108 2.95946 16.1622 3.12161 16.4595C3.31076 16.8108 8.33782 25 16.2297 25C24.1216 25 29.1486 16.8108 29.3378 16.4595C29.5269 16.1622 29.5269 15.8379 29.3378 15.5406V15.5405ZM16.2297 23.2432C10.3378 23.2432 6.06755 17.6486 4.93242 16C6.0675 14.3514 10.3378 8.75675 16.2297 8.75675C22.1216 8.75675 26.3918 14.3513 27.527 16C26.3918 17.6486 22.1216 23.2432 16.2297 23.2432Z" />
			<path d="M16.2297 10.027C12.9324 10.027 10.2567 12.7027 10.2567 16C10.2567 19.2973 12.9324 21.973 16.2297 21.973C19.527 21.973 22.2026 19.2973 22.2026 16C22.2026 12.7027 19.527 10.027 16.2297 10.027ZM16.2297 20.1892C13.9054 20.1892 12.0405 18.2973 12.0405 16C12.0405 13.6757 13.9324 11.8108 16.2297 11.8108C18.527 11.8108 20.4189 13.7027 20.4189 16C20.4189 18.3243 18.554 20.1892 16.2297 20.1892Z" />
			<path d="M16.2297 13.7027C14.9594 13.7027 13.9324 14.7298 13.9324 16C13.9324 17.2702 14.9594 18.2973 16.2297 18.2973C17.4999 18.2973 18.527 17.2702 18.527 16C18.527 14.7298 17.4999 13.7027 16.2297 13.7027Z" />
		</SvgIcon>
	)
}
