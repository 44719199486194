import { Grid, IconButton, styled } from "@mui/material"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { unstable_usePrompt, useNavigate, useParams } from "react-router-dom"

import { CustomButton } from "../../../components/buttons/CustomButton"
import CustomDialog from "../../../components/CustomDialog"
import { PlayButtonIcon } from "../../../components/customIcons/PlayButtonIcon"
import { TitleTypography } from "../../../components/customTypography"
import { Toolbar, ToolbarConfig } from "../../../components/Toolbar"
import { projectQueries, sessionsQueries } from "../../../networking/networking"
import { socket } from "../../../networking/socket"
import { sessionsSorter } from "../../../utils"
import TesterSessionField from "./components/TesterSessionField"
import { ErrorType } from "./TesterConnection"


// --------------Style-----------------
const StyledIconButton = styled(IconButton)(
	{
		marginLeft: 5,
		width: 50,
		height: 50,
		// zIndex: 999,
		borderRadius: 25,
		color: "black",
		boxShadow: "0px 4px 8px 3px #00000026",
		"&:disabled": {
			background: "#C5C5C5",
		}
	}
) as typeof IconButton

//------------------------------------

type OnPhaseProgressParams = {
	eeg_id: number,
	single_session_id: number,
	product_id: number,
	phase_id: number,
	progress: number,
}

type OnEndSessionParams = {
	eeg_id: number,
	single_session_id: number
}
type StartPhaseSIOErrorType = ErrorType
type StartPhaseEEGErrorType = ErrorType
type StopAcquisitionSIOErrorType = ErrorType
type StopAcquisitionEEGErrorType = ErrorType
export type EEGErrorValueType = ErrorType["data"] & { source: string };


const TestersSession = () => {
	const [phaseProgress, setPhaseProgress] = useState<{ [eeg_id: number]: number }>({})
	const [sessionsCompleted, setSessionCompleted] = useState<{ [eeg_id: number]: boolean }>({})
	const [isEegInAcquisition, setIsEegInAcquisition] = useState<{ [eeg_id: number]: boolean }>({})
	const [isCalibrationRunning, setIsCalibrationRunning] = useState<{ [eeg_id: number]: boolean }>({})
	const [eegErrorValue, setEegErrorValue] = useState<{ [eeg_id: number]: EEGErrorValueType[] | undefined }>({})

	const EXIT_PAGE_MESSAGE = "There are running sessions, are you sure to leave this page?"

	const navigate = useNavigate()

	const { project_id = "" } = useParams()
	const { data: project } = useQuery(
		[projectQueries.getProject.name, parseInt(project_id)],
		() => projectQueries.getProject.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id),
		}
	)
	const { data: running_single_sessions = [], isLoading: isRunningSessionsLoading } = useQuery(
		[projectQueries.listSessions.name, parseInt(project_id), true],
		() => projectQueries.listSessions.fn(parseInt(project_id), true),
		{
			enabled: Boolean(project_id),
		}
	)

	const { data: single_sessions = [], refetch: refetchSingleSessions, isLoading: isSingleSessionsLoading } = useQuery(
		[sessionsQueries.getSessionsById.name, parseInt(project_id), _.map(running_single_sessions, "id")],
		() => sessionsQueries.getSessionsById.fn(parseInt(project_id), _.map(running_single_sessions, "id")),
		{
			select: data => data.sort(sessionsSorter),
			enabled: Boolean(project_id) && running_single_sessions.length > 0
		}
	)

	const isLoading = isRunningSessionsLoading || isSingleSessionsLoading



	const toolbarConfig: ToolbarConfig = {
		title: `Project ${project?.name}`,
		subtitle: "Current session:"
	}

	const handleGlobalSessionControlPlay = () => {
		// posso premere il play globale se c'è almeno una sessione ferma
		// eseguo il play su tutte le sessioni ferme
		//   sessione ferma = non è in acquisizione OR (in acquisizione AND (progresso == 0 OR progresso == 100))
		single_sessions.filter(ss => !isEegInAcquisition[ss.eeg_id] || (phaseProgress[ss.eeg_id] === 0 || phaseProgress[ss.eeg_id] === 100) || ss.current_phase_id === -3).map(single_session => {
			if (!single_session.calibration_completed) {
				setIsCalibrationRunning(old => ({
					...old,
					[single_session.eeg_id]: true
				}))
			}
			socket.emit("start_phase", {
				"eeg_id": single_session.eeg_id,
				"single_session_id": single_session.id
			})
		})
	}

	unstable_usePrompt({
		message: EXIT_PAGE_MESSAGE,
		when: single_sessions.some(session => session.completed_at === null)
	})

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			event.preventDefault()
			event.returnValue = EXIT_PAGE_MESSAGE
			console.log("GIGI")
		}

		if (single_sessions.some(session => session.completed_at === null)) {
			window.addEventListener("beforeunload", handleBeforeUnload)
		}

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload)
		}
	}, [single_sessions])


	// useEffect(() => {
	// 	return () => {
	// 		console.log("unmount", JSON.stringify(single_sessions))
	// 		single_sessions.filter(session => session.completed_at === null).forEach((session) => {
	// 			terminateSessionMutation.mutate({
	// 				project_id: Number(project_id),
	// 				session_id: session.id
	// 			})
	// 		})
	// 	}
	// }, [location, single_sessions])


	useEffect(() => {
		function onStartAcquisitionResponse(value: { eeg_id: number, file_path: string }) {
			console.log("onStartAcquisitionResponse", value)
			setIsEegInAcquisition(old => ({
				...old,
				[value.eeg_id]: true
			}))
		}
		function onPhaseProgress(value: OnPhaseProgressParams) {
			setPhaseProgress(old => ({
				...old,
				[value.eeg_id]: value.progress
			}))
			if (value.progress === 100) {
				window.setTimeout(() => {
					refetchSingleSessions().then(() => {
						setPhaseProgress(old => ({
							...old,
							[value.eeg_id]: 0
						}))
					})
					// socket.emit("send_real_time_data",  {single_session_id: value.single_session_id})
				}, 400)
			}
		}

		function onStartPhaseSIOError(value: StartPhaseSIOErrorType) {
			console.log("start phase sio error", value)
			setEegErrorValue(old => ({
				...old,
				[value.eeg_id]: [...(old[value.eeg_id] || []), {
					...value.data,
					source: "start_phase_sio_error"
				}]
			}))
		}

		function onStartPhaseEEGError(value: StartPhaseEEGErrorType) {
			console.log("start phase eeg error", value)
			setEegErrorValue(old => ({
				...old,
				[value.eeg_id]: [...(old[value.eeg_id] || []), {
					...value.data,
					source: "start_phase_eeg_error"
				}]
			}))
		}

		function onEndSession(value: OnEndSessionParams) {
			refetchSingleSessions()
			setSessionCompleted(old => ({
				...old,
				[value.single_session_id]: true
			}))
			setIsEegInAcquisition(old => ({
				...old,
				[value.eeg_id]: false
			}))
		}

		function onTesterAnswers(value: { single_session_id: number }) {
			console.log("value", value)
			refetchSingleSessions()
		}

		function onStopAcquisitionSIOError(value: StopAcquisitionSIOErrorType) {
			console.log("stop acquisition sio error", value)
			setEegErrorValue(old => ({
				...old,
				[value.eeg_id]: [...(old[value.eeg_id] || []), {
					...value.data,
					source: "start_phase_sio_error"
				}]
			}))
		}

		function onStopAcquisitionEEGError(value: StopAcquisitionEEGErrorType) {
			console.log("stop acquisition eeg error", value)
			setEegErrorValue(old => ({
				...old,
				[value.eeg_id]: [...(old[value.eeg_id] || []), {
					...value.data,
					source: "stop_acquisition_eeg_error"
				}]
			}))
		}

		socket.connect()
		socket.on("start_acquisition_response", onStartAcquisitionResponse)
		socket.on("phase_progress", onPhaseProgress)
		socket.on("start_phase_sio_error", onStartPhaseSIOError)
		socket.on("start_phase_eeg_error", onStartPhaseEEGError)

		socket.on("tester_has_answered", onTesterAnswers)

		socket.on("end_session", onEndSession)
		socket.on("stop_acquisition_sio_error", onStopAcquisitionSIOError)
		socket.on("stop_acquisition_eeg_error", onStopAcquisitionEEGError)

		return () => {
			socket.off("start_acquisition_response", onStartAcquisitionResponse)
			socket.off("phase_progress", onPhaseProgress)
			socket.off("start_phase_sio_error", onStartPhaseSIOError)
			socket.off("start_phase_eeg_error", onStartPhaseEEGError)

			socket.off("tester_has_answered", onTesterAnswers)

			socket.off("end_session", onEndSession)
			socket.off("stop_acquisition_sio_error", onStopAcquisitionSIOError)
			socket.off("stop_acquisition_eeg_error", onStopAcquisitionEEGError)

			socket.disconnect()
		}
	}, [])


	return (
		<Grid container padding={3} >
			<Grid item container justifyContent="space-between">
				<Grid item xs={6}>
					<Toolbar config={toolbarConfig} />
				</Grid>
				<Grid
					item
					container
					flexWrap="nowrap"
					xs
					alignItems="center"
					spacing={1}
					marginBottom={4}
					paddingRight={1}
					justifyContent="flex-end"
					alignContent="center"
				>
					<Grid item>
						<TitleTypography>Session Control</TitleTypography>
					</Grid>
					<Grid item>
						<StyledIconButton
							// posso premere il play globale se tutte le fasi sono ferme
							// non posso premere il play globale se almeno una fase è avviata
							// fase avviata = progresso > 0 && progresso < 100
							disabled={single_sessions.some((single_session) => phaseProgress[single_session.eeg_id] > 0 && phaseProgress[single_session.eeg_id] < 100) || single_sessions.some(single_session => single_session.current_product_id_to_respond !== null)}
							onClick={handleGlobalSessionControlPlay}
						>
							<PlayButtonIcon />
						</StyledIconButton>
					</Grid>
					{/* <Grid item>
						<StyledIconButton
							// posso premere lo stop globale se almeno una sessione è avviata
							// non posso premere lo stop globale se tutte le sessioni sono ferme
							//   sessione ferma = non è in acquisizione OR (in acquisizione AND (progresso == 0 OR progresso == 100))
							disabled={single_sessions.every((single_session) => !isEegInAcquisition[single_session.eeg_id] || (phaseProgress[single_session.eeg_id] === 0 || phaseProgress[single_session.eeg_id] === 100))}
							onClick={handleGlobalSessionControlStop}>
							<StopButton />
						</StyledIconButton>
					</Grid> */}
				</Grid>
			</Grid>
			<Grid container gap={2} direction="column">
				{single_sessions.map(single_session => {
					const single_s = {
						...single_session,
						current_product_id: single_session.current_product_id || -1,
						current_phase_id: single_session.current_phase_id || (single_session.calibration_completed ? -1 : -2),
					}
					if (single_session.current_product_id_to_respond) {
						single_s.current_product_id_to_respond = single_session.current_product_id_to_respond
						single_s.current_phase_id = -3
					}
					return (
						<TesterSessionField
							isCalibrationRunning={Boolean(isCalibrationRunning[single_session.eeg_id])}
							key={single_session.id}
							single_session={single_s}
							handle_start_phase={() => {
								if (!single_session.calibration_completed) {
									setIsCalibrationRunning(old => ({
										...old,
										[single_session.eeg_id]: true
									}))
								}
								socket.emit("start_phase", {
									"eeg_id": single_session.eeg_id,
									"single_session_id": single_session.id
								})
								setEegErrorValue(old => _.omit(old, single_session.eeg_id))
							}}
							handle_stop_acquisition={() => socket.emit("stop_acquisition", {
								"eeg_id": single_session.eeg_id,
								"single_session_id": single_session.id
							})}
							phase_progress={phaseProgress[single_session.eeg_id] || 0}
							session_completed={sessionsCompleted[single_session.eeg_id]}
							eegErrorValue={eegErrorValue[single_session.eeg_id]}
						/>
					)
				})}
			</Grid>
			<CustomDialog
				open={!isLoading && single_sessions.length > 0 && single_sessions.every(single_session => single_session.completed_at !== null)}
				title="All tester phases completed"
				removeCloseIcon
			>
				<Grid container justifyContent="center">
					<Grid item marginTop={1}>
						<CustomButton text="Return to tester list" action={() => navigate("/projects/" + project_id + "/testersSelection")} />
					</Grid>
				</Grid>
			</CustomDialog>
		</Grid>
	)
}

export default TestersSession
