import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import DeleteIcon from "@mui/icons-material/Delete"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import EditIcon from "@mui/icons-material/Edit"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import {
	Avatar,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Chip,
	Grid,
	IconButton,
	styled,
	Tooltip
} from "@mui/material"
import _ from "lodash"

import { endpoint } from "../../../../App"
import { ReactComponent as AftertasteIcon } from "../../../../assets/icons/phases icons/aftertasteIcon.svg"
import { ReactComponent as ChewIcon } from "../../../../assets/icons/phases icons/chewIcon.svg"
import { ReactComponent as ConceptIcon } from "../../../../assets/icons/phases icons/conceptIcon.svg"
import { ReactComponent as LookIcon } from "../../../../assets/icons/phases icons/lookIcon.svg"
import { ReactComponent as OtherPhaseIcon } from "../../../../assets/icons/phases icons/otherPhaseIcon.svg"
import { ReactComponent as SmellIcon } from "../../../../assets/icons/phases icons/smellIcon.svg"
import { ReactComponent as SwallowIcon } from "../../../../assets/icons/phases icons/swallowIcon.svg"
import { ReactComponent as TasteIcon } from "../../../../assets/icons/phases icons/tasteIcon.svg"
import { ReactComponent as TouchIcon } from "../../../../assets/icons/phases icons/touchIcon.svg"
import { RandomizeIcon } from "../../../../components/customIcons/RandomizeIcon"
import { DashboardCardParagraphTypographyBold, TitleTypography, UserRoleTypography } from "../../../../components/customTypography"
import { Product, Project } from "../../../../types/genericTypes"
import { getProductRandomImage } from "../../../../utils"

interface propsType {
	project?: Project
	disableDrag: boolean,
	product: Product,
	onEdit: () => void,
	onDelete?: () => void,
	isUserOperator: boolean,
	userCanEdit?: boolean
}
//--------------- STYLE --------------------------
const cardStyle = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "flex-start",
	width: 300,
	height: 200,
}
const tooltipStyle = {
	position: "absolute",
	height: "20px",
	width: "20px",
	bottom: "-5px",
	right: 0,
	cursor: "pointer",
	backgroundColor: "white",
	"&:hover": {
		backgroundColor: "white"
	}
}
const dragIconStyle = {
	color: "text.secondary",
	marginRight: 1,
	cursor: "grab",
	"&:active": {
		cursor: "grabbing",

	},
	"&:focus": {
		outline: "none"
	}
}
const StyledIconButton = styled(IconButton)(
	{
		padding: 0,
		width: 16,
		height: 11,
		zIndex: 999
	}
) as typeof IconButton
//-----------------------------------

export const getPhase = (phase: string) => {
	if (phase.includes("aftertaste")) {
		return <AftertasteIcon />
	} else if (phase.includes("chew")) {
		return <ChewIcon />
	} else if (phase.includes("concept")) {
		return <ConceptIcon />
	} else if (phase.includes("look")) {
		return <LookIcon />
	} else if (phase.includes("smell")) {
		return <SmellIcon />
	} else if (phase.includes("swallow")) {
		return <SwallowIcon />
	} else if (phase.includes("taste")) {
		return <TasteIcon />
	} else if (phase.includes("touch")) {
		return <TouchIcon />
	} return <OtherPhaseIcon />
}

const handleProductColor = (productCategory: string) => {
	switch (productCategory) {
		case "Non-Alcoholic Beverages":
			return "#9AD2DB"
		case "Alcoholic beverages":
			return "#FFE394"
		case "Food":
			return "#B4D094"
		default:
			return "#BFCEAE"
	}
}

export default function ProductCard(props: propsType) {

	const coloredStripeStyle =
	{
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		backgroundColor: handleProductColor(props.product.category),
		width: "inherit",
		height: "inherit",
		padding: 1
	}
	const avatarStyle = {
		height: 80,
		width: 80,
		border: "6px solid",
		borderColor: handleProductColor(props.product.category),
		backgroundColor: "white"
	}

	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: props.product.id,
		disabled: props.disableDrag
	})

	const draggableStyle = {
		transform: CSS.Transform.toString(transform),
		transition
	}


	return (
		<Grid ref={setNodeRef} style={draggableStyle} sx={{ zIndex: 0 }} >
			<Card sx={cardStyle}>
				<Grid container height="35px">
					<Box sx={coloredStripeStyle}>
						<DragIndicatorIcon sx={dragIconStyle} {...attributes} {...listeners} />
						<Grid item container alignItems="center" >
							{props.product.random_order ?
								<Grid
									item
									sx={{
										width: "18px",
										height: "18px",
										marginRight: 0.5
									}}
								>
									<RandomizeIcon />
								</Grid>
								: ""
							}
							<Grid item>
								<UserRoleTypography>
									{`#${props.product.order}`}
								</UserRoleTypography>
							</Grid>
						</Grid>
						<CardActions
							sx={{
								justifyContent: "flex-end",
								width: "inherit",
								// paddingRight: 0
							}}
						>

							{props.project &&
								<StyledIconButton onClick={props.onEdit}>
									{props.userCanEdit ?
										<EditIcon
											sx={{
												fontSize: "16px",
											}}
										/>
										:
										<RemoveRedEyeIcon
											sx={{
												fontSize: "16px",
											}}
										/>
									}
								</StyledIconButton>
							}



							{props.onDelete &&
								<StyledIconButton onClick={props.onDelete} >
									<DeleteIcon
										sx={{
											fontSize: "16px",
										}}
									/>
								</StyledIconButton>
							}
						</CardActions>
					</Box>
				</Grid>
				<CardContent
					sx={{
						height: "100%",
						width: "100%",
					}}
				>
					<Grid
						container
						alignItems="center"
						sx={{
							height: "100%",
						}}
					>
						<Grid item container justifyContent="center" xs={5} >
							<Grid
								item
								sx={{
									position: "relative",
									display: "inline-block"
								}}
							>
								<Avatar
									sx={avatarStyle}
									src={props.product.image ? `${endpoint}/api/medias/default/${props.product.image.file_id}` : undefined}
									alt="Product image"
								>
									{!props.product.image && getProductRandomImage(props.product.id)}
								</Avatar>
								<Tooltip
									title={props.product.description}
									arrow
									sx={tooltipStyle}
								>
									<IconButton
										size="small"
									>
										< InfoOutlinedIcon
											fontSize="small"
											sx={{
												color: "#484848",
												fontSize: "15px"
											}}
										/>
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
						<Grid item container justifyContent="flex-start" xs={7} display="block" >
							<Grid item>
								<TitleTypography
									sx={{
										overflowWrap: "break-word",
										overflowY: "auto",
										maxHeight: "3rem"
									}}
								>
									{props.product.product_id}
								</TitleTypography>
							</Grid>
							<Grid item>
								<Tooltip
									title={props.product.category + " > " + props.product.subcategory + " > " + props.product.typology + " > " + props.product.product}
									arrow
								>
									<Button sx={{ padding: 0 }}>
										<DashboardCardParagraphTypographyBold color="#2B2B2B" sx={{ textDecoration: "underline" }}>
											Classification
										</DashboardCardParagraphTypographyBold>
									</Button>
								</Tooltip>
							</Grid>
						</Grid>
						<Grid item container justifyContent="center" spacing={1} sx={{ marginTop: 3 }}>
							{props.product.configured ?
								_.orderBy(props.product.phases, ["order"], ["asc"]).map((phase) =>
									<Tooltip key={phase.phase_id} title={phase.name}>
										<Grid item >
											{getPhase(phase.name?.toLowerCase() || "otherPhase")}
										</Grid>
									</Tooltip>
								)
								:
								<Chip icon={<InfoOutlinedIcon color="primary" />} label="Invalid configuration" sx={{ backgroundColor: "#FFD3D3" }} size="small" />
							}
						</Grid>
					</Grid>
				</CardContent>
			</Card >

		</Grid >

	)
}