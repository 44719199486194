import { SvgIcon } from "@mui/material"

export function OmegaIcon() {
	return (
		<SvgIcon
			inheritViewBox
			sx={{
				width: 10,
				height: 11,
			}}
		>
			<path d="M10.75 8.37454V9.12454C10.75 9.22399 10.7105 9.31938 10.6401 9.3897C10.5698 9.46005 10.4744 9.49954 10.375 9.49954L7.74996 9.49954C7.65051 9.49954 7.55512 9.46004 7.4848 9.3897C7.41445 9.31938 7.37496 9.224 7.37496 9.12454V7.50834C7.37605 7.40002 7.4239 7.29748 7.50621 7.22709C7.95742 6.82127 8.27492 6.28811 8.4168 5.69809C8.55871 5.10805 8.51832 4.48889 8.30098 3.92229C8.08364 3.35569 7.69957 2.86829 7.19948 2.52449C6.6994 2.1807 6.10678 1.99664 5.49998 1.99664C4.89318 1.99664 4.30048 2.1807 3.80048 2.52449C3.3004 2.86828 2.91634 3.35566 2.69898 3.92229C2.48164 4.48889 2.44125 5.10809 2.58316 5.69809C2.72504 6.28813 3.04254 6.82129 3.49375 7.22709C3.57605 7.29748 3.62391 7.40002 3.625 7.50834L3.625 9.12454C3.625 9.22399 3.58551 9.31938 3.51516 9.3897C3.44485 9.46005 3.34946 9.49954 3.25 9.49954H0.625C0.525547 9.49954 0.43016 9.46004 0.35984 9.3897C0.289488 9.31938 0.25 9.224 0.25 9.12454L0.25 8.37454C0.25 8.27508 0.289492 8.1797 0.35984 8.10938C0.430152 8.03902 0.52554 7.99954 0.625 7.99954H1.9525C1.98965 8.00122 2.02402 7.97985 2.03898 7.94583C2.05391 7.91176 2.04637 7.872 2.02 7.84578C1.35832 7.04512 0.9975 6.03829 1 4.99959C0.999375 4.18935 1.21754 3.39399 1.63141 2.69739C2.04532 2.00087 2.63961 1.42898 3.35151 1.04208C4.06346 0.655245 4.86661 0.467825 5.67621 0.499545C6.79481 0.584233 7.84601 1.06696 8.63931 1.86024C9.43255 2.65348 9.91531 3.70475 10 4.82335C10.0451 5.92165 9.68321 6.99785 8.98381 7.84585C8.95744 7.87206 8.9499 7.91182 8.96483 7.94588C8.97979 7.97991 9.01416 8.00128 9.05131 7.9996H10.3751C10.4746 7.9996 10.5699 8.03909 10.6403 8.10944C10.7106 8.17975 10.7501 8.27514 10.7501 8.3746L10.75 8.37454Z" fill=""/>
		</SvgIcon>
	)
}