import { SvgIcon, SvgIconProps } from "@mui/material"

const imageIcon = {
	overflow: "visible"
}
export function AfterTasteIcon(props: SvgIconProps) {
	return (
		<SvgIcon
			{...props}
			sx={{
				...props.sx,
				...imageIcon
			}}
		>
			<path fillRule="evenodd" clipRule="evenodd" d="M18.7378 3.67348L19.5267 2.88458L20.6404 3.99828L19.8515 4.78721C20.4015 5.5596 20.725 6.50449 20.725 7.525C20.725 10.1345 18.6095 12.25 16 12.25C13.3905 12.25 11.275 10.1345 11.275 7.525C11.275 5.18376 12.9779 3.24021 15.2125 2.86528V1.75H16.7875V2.86528C17.5055 2.98573 18.1684 3.26808 18.7378 3.67349L18.7378 3.67348ZM16 4.37495C14.2603 4.37495 12.85 5.78527 12.85 7.52495C12.85 9.26462 14.2603 10.6749 16 10.6749C17.7397 10.6749 19.15 9.26462 19.15 7.52495C19.15 5.78527 17.7397 4.37495 16 4.37495ZM16.7875 8.04995H15.2125V5.42495H16.7875V8.04995Z" />
			<path d="M8 20.1601H9.6C9.6 22.1865 10.501 24.0582 12.0174 25.2955C13.142 26.2132 14.5324 26.7201 16.0001 26.7201C17.4849 26.7201 18.8905 26.2013 20.0214 25.2638C21.515 24.0255 22.4001 22.1686 22.4001 20.16H24.0001C24.0001 22.6482 22.9005 24.9552 21.0425 26.4955C19.6277 27.6683 17.862 28.3201 16.0001 28.3201C14.1596 28.3201 12.4129 27.6833 11.006 26.5351C9.11976 24.9959 8.00013 22.6703 8.00013 20.1601L8 20.1601Z" />
			<path d="M8 14.88C8 14.4382 8.35813 14.08 8.8 14.08C9.24187 14.08 9.6 14.4382 9.6 14.88V20.32C9.6 20.7619 9.24187 21.12 8.8 21.12C8.35813 21.12 8 20.7619 8 20.32V14.88Z" />
			<path d="M22.3999 14.88C22.3999 14.4382 22.758 14.08 23.1999 14.08C23.6418 14.08 23.9999 14.4382 23.9999 14.88V20.32C23.9999 20.7619 23.6418 21.12 23.1999 21.12C22.758 21.12 22.3999 20.7619 22.3999 20.32V14.88Z" />
			<path d="M15.2002 16.1599C15.2002 15.7181 15.5583 15.3599 16.0002 15.3599C16.4421 15.3599 16.8002 15.7181 16.8002 16.1599V21.5999C16.8002 22.0418 16.4421 22.3999 16.0002 22.3999C15.5583 22.3999 15.2002 22.0418 15.2002 21.5999V16.1599Z" />
			<path d="M4.65222 12.969C4.29387 12.7106 4.21283 12.2106 4.47117 11.8522C4.72961 11.4938 5.2296 11.4128 5.58805 11.6711C5.62243 11.6959 5.69566 11.7471 5.80545 11.8206C5.99243 11.9459 6.21086 12.0863 6.45836 12.238C7.16878 12.6735 7.96556 13.1097 8.82814 13.5165C11.2744 14.6704 13.7476 15.3601 16.0761 15.3601C18.403 15.3601 20.8457 14.6713 23.2428 13.519C24.0879 13.1128 24.8661 12.6774 25.558 12.2427C25.7989 12.0913 26.0114 11.9512 26.1929 11.8262C26.2994 11.7529 26.3702 11.7022 26.4032 11.6777C26.758 11.4144 27.2591 11.4885 27.5224 11.8433C27.7857 12.1981 27.7116 12.6992 27.3568 12.9625C27.309 12.998 27.2227 13.0598 27.1004 13.1439C26.9008 13.2814 26.6697 13.4338 26.4093 13.5975C25.6687 14.0628 24.8383 14.5274 23.9359 14.9611C21.3402 16.2089 18.6757 16.9601 16.0759 16.9601C13.478 16.9601 10.7871 16.2098 8.14528 14.9637C7.22704 14.5305 6.37976 14.0667 5.62194 13.6022C5.35548 13.4389 5.11882 13.2868 4.91424 13.1496C4.78903 13.0656 4.7008 13.0043 4.65195 12.9691L4.65222 12.969Z" />
		</SvgIcon>
	)
}
