import { Cell, Pie, PieChart } from "recharts"

import { chartData } from "../types/genericTypes"

interface chartProps {
	data?: chartData[]
	pdfMode?: boolean
}

interface chartPercentuals {
	cx: number,
	cy: number,
	midAngle: number,
	innerRadius: number,
	outerRadius: number,
	percent: number,
	index: number
}

export const CustomPieChart = (props: chartProps) => {

	const RADIAN = Math.PI / 180
	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: chartPercentuals) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5
		const x = cx + radius * Math.cos(-midAngle * RADIAN)
		const y = cy + radius * Math.sin(-midAngle * RADIAN)

		return (
			<text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
				{(percent * 100).toFixed(0) === "0" ? undefined : `${(percent * 100).toFixed(0)}%`}
			</text>
		)
	}
	return (
		<PieChart width={200} height={200}>
			<Pie
				isAnimationActive={!props.pdfMode}
				data={props.data}
				cx="50%"
				cy="50%"
				labelLine={false}
				label={renderCustomizedLabel}
				outerRadius={80}
				fill="#8884d8"
				dataKey="value"
			>

				{props.data?.map((entry, index) => (
					<Cell key={`cell-${index}`} fill={entry.color} />
				))}
			</Pie>
		</PieChart>
	)
}
