import LogoutIcon from "@mui/icons-material/Logout"
import PlayCircleIcon from "@mui/icons-material/PlayCircle"
import { Grid, IconButton, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import awsmobile from "../../aws-exports"
import { REACT_APP_BE_ADDRESS, TBOX_MODE } from "../../constants"
import { PhaseProduct, Product, Project } from "../../types/genericTypes"
export const endpoint = TBOX_MODE ? REACT_APP_BE_ADDRESS : awsmobile.aws_cloud_logic_custom.find(el => el.name === "AdminQueries")?.endpoint

interface props {
	isPhaseInProgress: boolean
	language: Project["language"]
	product_id: Product["product_id"]
	company_id: number
	phase: PhaseProduct
	playAction?: () => void
}
const getPrePhaseText = (phase: PhaseProduct, language: Project["language"], product_id: Product["product_id"], company_id: number) => {
	// Cerco la traduzione per la lingua "language" e per la specifica "company"
	let db_translation = phase.phase.description_i18n?.find(pd => pd.language === language && pd.company_id === company_id)?.value
	if (db_translation) return db_translation
	// Non esiste una traduzione per questa lingua e per questa company
	// Cerco la traduzione per la lingua "language" (senza specificare la company)
	db_translation = phase.phase.description_i18n?.find(pd => pd.language === language)?.value
	if (db_translation) return db_translation
	// Non esiste una traduzione per questa lingua
	if (phase.phase_id >= 9) { // OTHER phase
		return phase.description || "MISSING OTHER PHASE DESCRIPTION"
	}
	switch (phase.phase_id) {
		case 1: // concept
			return "Product " + product_id + "\n\nPress the PLAY button when you are ready to begin."
		case 2: // look
			return "Look at the product in front of you without touching it.\n\nPress the PLAY button to begin."
		case 3: // touch
			return "Touch the product.\n\nPress the PLAY button to begin."
		case 4: // smell
			return "Bring the product close to your nose and smell it, while standing as still as possible.\n\nWhile you smell it, keep your eyes open and fixed on the \"+\" in front of you.\n\nPress the PLAY button to begin."
		case 5: // chew
			return "You can taste the product.\n\nPress the PLAY button to begin."
		case 6: // taste
			return "Keep the product in your mouth while standing as still as possible.\n\nPress the PLAY button and wait for the next instructions."
		case 7: // swallow
			return "You can keep chewing and then swallow.\n\nPress the PLAY button to begin."
		case 8: // aftertaste
			return "Once your mouth is empty, focus on the sensation that is left on your mouth.\n\nPress the PLAY button to begin."
		default:
			return phase.description || "MISSING DESCRIPTION"
	}
}
const getRunningText = (phase: PhaseProduct, language: Project["language"]) => {
	const phasesWithPlusIcon = [
		-1, //baseline
		3, // Touch
		4, // Smell
		6, // Taste
		7, // Swallow
		8, // Aftertaste
		// >= 9 // OTHER
	]
	if (phasesWithPlusIcon.includes(phase.phase_id) || phase.phase_id >= 9) return "+"
	type tipo = {
		[key in Project["language"]]: string
	}
	switch (phase.phase_id) {
		case 1: { // concept
			return phase.description || ""
		}
		case 2: { // look
			const txt: tipo = {
				"en": "Look at the product",
				"it": "Osserva il prodotto",
				"es": "Mira el producto",
				"de": "Beobachten Sie das Produkt",
				"fr": "Observer le produit"
			}
			return txt[language]
		}
		case 5: { // chew
			const txt: tipo = {
				"en": "Taste the product",
				"it": "Assaggia il prodotto",
				"es": "Prueba el producto",
				"de": "das Produkt probieren",
				"fr": "Déguster le produit"
			}
			return txt[language]
		}
		default:
			return "NEVER HERE"
	}
}

const TesterModeSession = (props: props) => {
	// Serve per evitare che il tester faccia click 2 volte per la stessa fase
	// a volte, quando si avvia una fase con "start_phase", la risposta non arriva istantaneamente.
	// Allora disattivo il pulsante. Quando cambia la fase, il pulsante si riattiva
	const [isButtonPressed, setIsButtonPressed] = useState(false)
	const navigate = useNavigate()

	const handleLogout = () => {
		navigate("/auth/signOut")
	}

	let text
	if (props.isPhaseInProgress) {
		text = getRunningText(props.phase, props.language)
	} else {
		text = getPrePhaseText(props.phase, props.language, props.product_id, props.company_id)
	}

	useEffect(() => {
		setIsButtonPressed(false)
	}, [props.phase])
	return (
		<Grid container justifyContent="center" overflow="auto">
			<Grid
				item
				container
				direction="column"
				justifyContent="center"
				width="70%"
			>
				<Grid item textAlign="center">
					<Typography
						sx={{ fontSize: 40 }}
						color="white"
						dangerouslySetInnerHTML={{ __html: text.replaceAll("\n", "<br/>") }}
					/>
				</Grid>
				{props.isPhaseInProgress && props.phase.phase_id === 1 && props.phase.image &&
					<Grid item textAlign="center">
						<img
							src={`${endpoint}/api/medias/default/${props.phase.image.file_id}`}
							alt={props.phase.image.filename}
							style={{
								maxWidth: "100%",
								maxHeight: "70vh"
							}}
						/>
					</Grid>
				}
				{!props.isPhaseInProgress &&
					<Grid item textAlign="center">
						<IconButton
							size="small"
							onClick={() => {
								setIsButtonPressed(true)
								props.playAction && props.playAction()
							}}
							disabled={isButtonPressed}
						>
							<PlayCircleIcon
								sx={{
									fontSize: 70,
									color: "white"
								}}
							/>
						</IconButton>
					</Grid>
				}
			</Grid>
			{!props.isPhaseInProgress &&
				<Grid
					item
					position="fixed"
					left={1}
					bottom={2}
				>
					<IconButton
						onClick={() => handleLogout()}
					>
						<Tooltip title="Logout" >
							<LogoutIcon fontSize="large" />
						</Tooltip>
					</IconButton>
				</Grid>
			}
		</Grid>
	)
}

export default TesterModeSession