import { SvgIcon, SvgIconProps } from "@mui/material"

const imageIcon = {
	overflow: "visible"
}
export function CalibrationIcon(props: SvgIconProps) {
	return (
		<SvgIcon
			{...props}
			sx={{
				...props.sx,
				...imageIcon
			}}
		>
			<path d="M12 4.00004V1.33337H20V4.00004H12ZM14.6667 18.6667H17.3333V10.6667H14.6667V18.6667ZM16 29.3334C14.3556 29.3334 12.8056 29.0167 11.35 28.3834C9.89444 27.75 8.62222 26.8889 7.53333 25.8C6.44444 24.7112 5.58333 23.4389 4.95 21.9834C4.31667 20.5278 4 18.9778 4 17.3334C4 15.6889 4.31667 14.1389 4.95 12.6834C5.58333 11.2278 6.44444 9.9556 7.53333 8.86671C8.62222 7.77782 9.89444 6.91671 11.35 6.28337C12.8056 5.65004 14.3556 5.33337 16 5.33337C17.3778 5.33337 18.7 5.5556 19.9667 6.00004C21.2333 6.44449 22.4222 7.08893 23.5333 7.93337L25.4 6.06671L27.2667 7.93337L25.4 9.80004C26.2444 10.9112 26.8889 12.1 27.3333 13.3667C27.7778 14.6334 28 15.9556 28 17.3334C28 18.9778 27.6833 20.5278 27.05 21.9834C26.4167 23.4389 25.5556 24.7112 24.4667 25.8C23.3778 26.8889 22.1056 27.75 20.65 28.3834C19.1944 29.0167 17.6444 29.3334 16 29.3334ZM16 26.6667C18.5778 26.6667 20.7778 25.7556 22.6 23.9334C24.4222 22.1112 25.3333 19.9112 25.3333 17.3334C25.3333 14.7556 24.4222 12.5556 22.6 10.7334C20.7778 8.91115 18.5778 8.00004 16 8.00004C13.4222 8.00004 11.2222 8.91115 9.4 10.7334C7.57778 12.5556 6.66667 14.7556 6.66667 17.3334C6.66667 19.9112 7.57778 22.1112 9.4 23.9334C11.2222 25.7556 13.4222 26.6667 16 26.6667Z"/>
		</SvgIcon >
	)
}
