import { closestCenter, DndContext, DragEndEvent, UniqueIdentifier } from "@dnd-kit/core"
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { CircularProgress, Grid } from "@mui/material"
import _ from "lodash"
import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { CustomButton } from "../../../../../components/buttons/CustomButton"
import SubmitButton from "../../../../../components/buttons/submitButton"
import CustomDialog from "../../../../../components/CustomDialog"
import { DashboardCardTitleTypography, ParagraphTypography } from "../../../../../components/customTypography"
import { projectQueries, queryClient, questionsQueries, templatesQueries } from "../../../../../networking/networking"
import { useSnackbarStore } from "../../../../../stateManagement"
import { Product, Project, Question } from "../../../../../types/genericTypes"
import { editModalModel } from "../ConfigureProjectProducts"
import { QuestionRow } from "./QuestionRow"

interface props {
	setFormPhase: React.Dispatch<React.SetStateAction<1 | 2 | 3>>
	closeModal: () => void
	product: Product
	project: Project
	editMode: editModalModel
	userCanEdit?: boolean
}

export interface QuestionsFormValues {
	[id: string]: {
		selected: boolean
	}
}


export const ConfigureProductQuestionsForm = (props: props) => {
	const [sortedQuestionsIDs, setSortedQuestionsIDs] = useState<UniqueIdentifier[]>([])
	const [isModifiedTemplateAlertDialogOpen, setIsModifiedTemplateAlertDialogOpen] = useState(false)
	const [formData, setformData] = useState<QuestionsFormValues>()
	const { project_id = "" } = useParams()
	const sorted_question_product = props.product.questions.sort((a, b) => a.order - b.order).map(val => val.question_id)
	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)

	const { data: questions = [], isFetching: isQuestionsFetching } = useQuery(
		questionsQueries.listQuestions.name,
		questionsQueries.listQuestions.fn,
		{
			onSuccess: data => {
				setSortedQuestionsIDs(data.filter(question => (question.company_id === null || question.company_id === props.project.company_id))
					.sort((a, b) => {
						if (sorted_question_product.includes(a.id) && sorted_question_product.includes(b.id))
							return sorted_question_product.indexOf(a.id) - sorted_question_product.indexOf(b.id)
						else if (sorted_question_product.includes(a.id))
							return -1
						else return 1
					}).map(question => question.id))
			}
		}
	)

	const { data: templates = [], isFetching: isTemplatesFetching } = useQuery(
		templatesQueries.listTemplates.name,
		templatesQueries.listTemplates.fn,
		{
			onSuccess: () => {
				console.log(templates)
			}
		}
	)

	const patchProductQuestionsMutation = useMutation(
		projectQueries.patchProductQuestions.name,
		projectQueries.patchProductQuestions.fn,
		{
			onSuccess: () => {
				props.closeModal()
				queryClient.invalidateQueries([projectQueries.getProducts.name, parseInt(project_id)])
			},
			onError: () => {
				openSnackbar("error", "An error occurred while updating product questions")
			}
		}
	)

	const {
		control,
		register,
		handleSubmit: handleSubmitQuestions,
	} = useForm<QuestionsFormValues>({
		defaultValues: props.product.questions.reduce(
			(accumulator, currentValue) => {
				return {
					...accumulator,
					[currentValue.question_id]: {
						selected: true
					}
				}
			},
			{}
		)
	})

	const onQuestionsSubmit: SubmitHandler<QuestionsFormValues> = (data) => {
		setformData(data)
		const selectedQuestions = _.pickBy(data, (value) => value.selected)
		const selectedSortedQuestionsIDs = sortedQuestionsIDs.filter(id => Object.keys(selectedQuestions).includes(id.toString()))
		const finalQuestions = Object.keys(selectedQuestions).map((key) => ({
			id: parseInt(key),
			order: selectedSortedQuestionsIDs.findIndex(id => id.toString() === key) + 1
		}))
		if (_.isEqualWith(
			_.sortBy(props.product.questions, "order"),
			_.sortBy(finalQuestions, "order"),
			(question_a, question_b) => {
				if (_.isArray(question_a)) return undefined
				return question_a.question_id === question_b.id && question_a.order === question_b.order
			}
		)) {
			props.closeModal()
		} else {
			if (props.project?.template_id) {
				setIsModifiedTemplateAlertDialogOpen(true)
			} else {
				patchProductQuestionsMutation.mutate({
					project_id: parseInt(project_id),
					product_id: props.product.id,
					questions: finalQuestions
				})
			}
		}
	}

	const confirmEditTemplate = (data: QuestionsFormValues | undefined) => {
		const selectedQuestions = _.pickBy(data, (value) => value.selected)
		const selectedSortedQuestionsIDs = sortedQuestionsIDs.filter(id => Object.keys(selectedQuestions).includes(id.toString()))
		const finalQuestions = Object.keys(selectedQuestions).map((key) => ({
			id: parseInt(key),
			order: selectedSortedQuestionsIDs.findIndex(id => id.toString() === key) + 1
		}))
		patchProductQuestionsMutation.mutate({
			project_id: parseInt(project_id),
			product_id: props.product.id,
			questions: finalQuestions
		})
	}

	//DRAG & DROP
	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event
		if (over) {
			if (active.id !== over.id) {
				setSortedQuestionsIDs(ids => {
					const activeIndex = ids.indexOf(active.id)
					const overIndex = ids.indexOf(over.id)
					return arrayMove(ids, activeIndex, overIndex)
				})
			}
		}
	}

	if (isQuestionsFetching || isTemplatesFetching) {
		return <CircularProgress />
	}

	const handleModifiedTemplateDialogClose = () => {
		setIsModifiedTemplateAlertDialogOpen(false)
	}

	let arrayQuestions: Question[] = []

	if (props.project.template_id) {
		const currentTemplate = templates.find(template => template.id === props.project.template_id)
		if (currentTemplate) {
			arrayQuestions = currentTemplate.questions.map(template_question => template_question.question)
		}
	} else {
		arrayQuestions = questions.filter(question => _.some(question.questions_i18n, { language: props.project.language }))
		arrayQuestions = _.sortBy(arrayQuestions, item => sortedQuestionsIDs.indexOf(item.id)).filter(question => (question.company_id === null || question.company_id === props.project?.company_id))
	}
	// const singleQuestion: Question = _.get(_.find(templateQuestions, _.property("question")), "question", {} as Question)
	return (
		<>
			<form onSubmit={handleSubmitQuestions(onQuestionsSubmit)}>
				<Grid container direction="column" gap={1}>
					<DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
						<SortableContext items={sortedQuestionsIDs} strategy={verticalListSortingStrategy}>
							{arrayQuestions.length > 0 ? arrayQuestions.map((question) => (
								<Grid item key={question.id}>
									<QuestionRow
										project={props.project}
										control={control}
										register={register}
										question={question}
										editMode={props.editMode}
										templateId={props.project.template_id}
										userCanEdit={props.userCanEdit}
										disabled={!_.some(question.questions_i18n, { language: props.project.language })}
									/>
								</Grid>
							)) : <ParagraphTypography>There are no questions in the selected language</ParagraphTypography>
							}
						</SortableContext>
					</DndContext>

					<Grid item container justifyContent="center" spacing={2} marginTop={2}>
						<Grid item>
							<CustomButton text="Back" action={() => props.setFormPhase(2)} />
						</Grid>
						{props.userCanEdit &&
							<Grid item>
								<SubmitButton text="Save" />
							</Grid>
						}
					</Grid>

				</Grid>
			</form>

			<CustomDialog open={isModifiedTemplateAlertDialogOpen} onClose={() => { handleModifiedTemplateDialogClose() }} title="Attention!" >
				<Grid container justifyContent="center">
					<Grid item sx={{ marginBottom: 5 }}>
						<DashboardCardTitleTypography>You are trying to modify a template. Changes will be applied to all products in the project.</DashboardCardTitleTypography>
					</Grid>
					<Grid item container justifyContent="flex-end" gap={2}>
						<Grid item>
							<CustomButton text="Cancel" primary action={() => { handleModifiedTemplateDialogClose() }} />
						</Grid>
						<Grid item>
							<CustomButton text="Save" action={() => { confirmEditTemplate(formData) }} />
						</Grid>
					</Grid>
				</Grid>
			</CustomDialog>
		</>
	)
}
