import { Settings } from "@mui/icons-material"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import { Box, CircularProgress, Grid, Pagination, Paper, Stack, Step, StepLabel, Stepper } from "@mui/material"
import _ from "lodash"
import { useEffect, useRef, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { useReactToPrint } from "react-to-print"

import { endpoint } from "../../App"
import dashboard_cover from "../../assets/backgrounds/dashboard_cover.svg"
import { DashboardCardParagraphTypography, DashboardCardTitleTypography, H1Typography } from "../../components/customTypography"
import { Toolbar, ToolbarConfig } from "../../components/Toolbar"
import { TBOX_MODE } from "../../constants"
import { analysisQueries, companyQueries, projectQueries, questionsQueries, tboxQueries } from "../../networking/networking"
import { useSnackbarStore } from "../../stateManagement"
import { PhaseProduct } from "../../types/genericTypes"
import { productsSorter } from "../../utils"
import { AdditionalNotes } from "../dashboard/components/AdditionalNotes"
import { Cover } from "../dashboard/components/Cover"
import { Credits } from "../dashboard/components/Credits"
import { DashboardLabel } from "../dashboard/components/DashboardLabel"
import { DashboardProducts } from "../dashboard/components/DashboardProducts"
import { IntroPage } from "../dashboard/components/IntroPage"
import { TestersCharts } from "../dashboard/components/TestersCharts"
import { TestersRecap } from "../dashboard/components/TestersRecap"
import { PADashboardSimpleBarChart } from "./PADashboardSimpleBarChart"
import { PADashboardTable } from "./PADashboardTable"
import PADescription from "./PADescription"


export interface CW_data {
	name: number
	phase_code: string
	values: {
		[prod_id: string]: number
	}
}

const steps = ["Submitted", "Starting", "Running", "Completed"]

export const PostAnalyticsDashboard = () => {
	const { project_id = "" } = useParams()
	const [page, setPage] = useState(1)
	const [pageTitle, setPageTitle] = useState("")
	const [languages, setLanguages] = useState<string[]>([])
	const [phases, setPhases] = useState<string[]>([])
	const [productIds, setProductIds] = useState<string[]>([])
	const [isFirstAnalysisCheckDone, setIsFirstAnalysisCheckDone] = useState(false)
	const [isStepperVisible, setIsStepperVisible] = useState(false)
	const [activeStep, setActiveStep] = useState(0)
	const [productPhasesList, setProductPhasesList] = useState<PhaseProduct[]>([])

	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)
	const componentRef = useRef(null)
	const handlePrint = useReactToPrint({
		content: () => componentRef.current
	})

	const { data: project, isFetching: isProjectFetching, isLoading: isProjectLoading, refetch: refetchProject } = useQuery(
		[projectQueries.getProject.name, parseInt(project_id)],
		() => projectQueries.getProject.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id),
			onSuccess: data => {
				setLanguages([data.language])
			}
		}
	)

	const { data: company, isLoading: isCompanyLoading } = useQuery(
		[companyQueries.listCompanies.name],
		companyQueries.listCompanies.fn,
		{
			enabled: Boolean(project),
			select: data => {
				return data.find(c => c.id === project?.company_id)
			}
		}
	)

	const { data: products = [], isFetching: isProductsFetching } = useQuery(
		[projectQueries.getProducts.name, parseInt(project_id)],
		() => projectQueries.getProducts.fn(parseInt(project_id)),
		{
			select: data => data.sort(productsSorter),
			enabled: Boolean(project_id)
		}
	)

	const { data: questions = [] } = useQuery(
		questionsQueries.listQuestions.name,
		questionsQueries.listQuestions.fn,
	)

	const likeOverallchartScale = {
		minValue: questions.find((question) => question.label === "like_overall")?.min_value || 0,
		maxValue: questions.find((question) => question.label === "like_overall")?.max_value || 100
	}

	const familiarityChartScale = {
		minValue: questions.find((question) => question.label === "familiarity")?.min_value || 0,
		maxValue: questions.find((question) => question.label === "familiarity")?.max_value || 100
	}

	const purchaseChartScale = {
		minValue: questions.find((question) => question.label === "purchase")?.min_value || 0,
		maxValue: questions.find((question) => question.label === "purchase")?.max_value || 100
	}

	const consumptionChartScale = {
		minValue: questions.find((question) => question.label === "consumption")?.min_value || 0,
		maxValue: questions.find((question) => question.label === "consumption")?.max_value || 100
	}

	const { data: projectTesters = [], isFetching: isProjectTestersFetching } = useQuery(
		[projectQueries.listProjectTesters.name, parseInt(project_id)],
		() => projectQueries.listProjectTesters.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id)
		}
	)

	const testersWithCompletedSession = projectTesters.filter(tcs => tcs.session?.uncompleted === false)

	const { data: longData } = useQuery(
		[projectQueries.getLongData, parseInt(project_id)],
		() => projectQueries.getLongData.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id)
		}
	)

	const { data: timeline } = useQuery(
		[projectQueries.getTimeline, parseInt(project_id)],
		() => projectQueries.getTimeline.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id)
		}
	)

	const { data: analysisStatus, refetch: refetchAnalysisStatus } = useQuery(
		[analysisQueries.analysisStatus.name, parseInt(project_id)],
		() => analysisQueries.analysisStatus.fn(parseInt(project_id)),
		{
			onSuccess: (data) => {
				if (data !== "NOT_JOB") {
					setIsStepperVisible(true)
				}
				switch (data) {
					case "SUBMITTED":
						setActiveStep(0)
						break
					case "STARTING":
						setActiveStep(1)
						break
					case "RUNNING":
						setActiveStep(2)
						break
					case "SUCCEEDED":
						//salto uno step per mostrare la spunta nel chip dello stepper
						setActiveStep(4)
						if (isFirstAnalysisCheckDone) {
							openSnackbar("success", "Analysis succeeded")
						}
						break
					case "FAILED":
						setActiveStep(3)
						if (isFirstAnalysisCheckDone) {
							openSnackbar("error", "Analysis failed")
						}
						break
					default:
						break
				}
				setIsFirstAnalysisCheckDone(true)
			},
			onError: (error: { response?: { status: number, data: string } }) => {
				console.error(error)
				if (error.response) {
					openSnackbar("error", error.response.data)
				}
			},
			refetchInterval: (data) => {
				if (data === undefined) return 5000
				else if (["FAILED", "SUCCEEDED", "NOT_JOB"].includes(data)) return false
				else return 5000
			},
			enabled: Boolean(project_id)
		}
	)

	const { data: projectTbox, isFetching: isProjectTboxFetching } = useQuery(
		tboxQueries.listTBoxes.name,
		tboxQueries.listTBoxes.fn,
		{
			select: data => {
				if (!project) return undefined
				return _.find(data, { id: project.tbox_id })
			},
			enabled: Boolean(project)
		}
	)

	const updateProjectMutation = useMutation(
		[projectQueries.updateProject.name, parseInt(project_id)],
		projectQueries.updateProject.fn(parseInt(project_id)),
		{
			onSuccess: () => {
				refetchProject()
				openSnackbar("success", "Project notes saved successfully")
			},
			onError: () => {
				openSnackbar("error", "An error occurred while updating the Project")
			}
		}
	)

	const updateProjectNotesMutation = useMutation(
		[projectQueries.patchProjectNote.name, parseInt(project_id)],
		projectQueries.patchProjectNote.fn(parseInt(project_id)),
		{
			onSuccess: () => {
				refetchProject()
				openSnackbar("success", "Project notes saved successfully")
			},
			onError: () => {
				openSnackbar("error", "An error occurred while updating the Project")
			}
		}
	)

	const analysisStartMutation = useMutation(
		[analysisQueries.analysisStart.name, parseInt(project_id)],
		() => analysisQueries.analysisStart.fn(parseInt(project_id)),
		{
			onSuccess: () => {
				refetchAnalysisStatus()
			}
		}
	)

	useEffect(() => {
		const temporaryProductIds: string[] = []
		const temporaryPhases: string[] = []
		longData?.forEach(element => {
			if (!temporaryPhases.includes(element.phase_code)) {
				temporaryPhases.push(element.phase_code)
			}
			if (!temporaryProductIds.includes(element.product_id)) {
				temporaryProductIds.push(element.product_id)
			}
		})
		setPhases(temporaryPhases)
		setProductIds(temporaryProductIds)
	}, [longData])

	// per i primi 4 grafici (quelli a barre)
	const [MT14data, setMT14data] = useState<{ name: string, [k: string]: string | undefined }[]>([])
	const [MT23data, setMT23data] = useState<{ name: string, [k: string]: string | undefined }[]>([])
	const [MT39data, setMT39data] = useState<{ name: string, [k: string]: string | undefined }[]>([])
	const [MT45data, setMT45data] = useState<{ name: string, [k: string]: string | undefined }[]>([])

	// per i secondi 4 grafici (quelli a barre)
	const [likeOverallData, setlikeOverallData] = useState<{ name: string, [k: string]: string | undefined }[]>([{ name: "Overall Liking" }])
	const [consumptionData, setConsumptionData] = useState<{ name: string, [k: string]: string | undefined }[]>([{ name: "Consumption intention" }])
	const [purchaseData, setPurchaseData] = useState<{ name: string, [k: string]: string | undefined }[]>([{ name: "Purchase intention" }])
	const [familiarityData, setFamiliarityData] = useState<{ name: string, [k: string]: string | undefined }[]>([{ name: "Purchase intention" }])

	// per gli ultimi 4 grafici (quelli a linee)
	const [CW_MT14data, setCW_MT14data] = useState<CW_data[]>([])
	const [CW_MT23data, setCW_MT23data] = useState<CW_data[]>([])
	const [CW_MT39data, setCW_MT39data] = useState<CW_data[]>([])
	const [CW_MT45data, setCW_MT45data] = useState<CW_data[]>([])

	useEffect(() => {
		if (longData && phases && productIds) {
			//primi 4 grafici
			const temporaryMT14data: { name: string, [k: string]: string | undefined }[] = []
			const temporaryMT23data: { name: string, [k: string]: string | undefined }[] = []
			const temporaryMT39data: { name: string, [k: string]: string | undefined }[] = []
			const temporaryMT45data: { name: string, [k: string]: string | undefined }[] = []
			for (const phase_code of phases) {
				const phase_name = _.get(products, [0, "phases"], []).find(phase => phase.marker_code === phase_code)?.name || phase_code
				const newDataLenghtMT14 = temporaryMT14data.push({ name: phase_name })
				const newDataLenghtMT23 = temporaryMT23data.push({ name: phase_name })
				const newDataLenghtMT39 = temporaryMT39data.push({ name: phase_name })
				const newDataLenghtMT45 = temporaryMT45data.push({ name: phase_name })

				for (const product_id of productIds) {
					const filteredListProductByPhase = longData?.filter(product => product.product_id === product_id && product.phase_code === phase_code)
					const avgValueMT14 = Number(_.meanBy(filteredListProductByPhase, "MT14").toFixed(4))
					const avgValueMT23 = Number(_.meanBy(filteredListProductByPhase, "MT23").toFixed(4))
					const avgValueMT39 = Number(_.meanBy(filteredListProductByPhase, "MT39").toFixed(4))
					const avgValueMT45 = Number(_.meanBy(filteredListProductByPhase, "MT45").toFixed(4))

					_.set(temporaryMT14data, [newDataLenghtMT14 - 1, product_id], avgValueMT14)
					_.set(temporaryMT23data, [newDataLenghtMT23 - 1, product_id], avgValueMT23)
					_.set(temporaryMT39data, [newDataLenghtMT39 - 1, product_id], avgValueMT39)
					_.set(temporaryMT45data, [newDataLenghtMT45 - 1, product_id], avgValueMT45)

				}
			}

			setMT14data(temporaryMT14data)
			setMT23data(temporaryMT23data)
			setMT39data(temporaryMT39data)
			setMT45data(temporaryMT45data)

			//secondi 4 grafici
			const temporaryLikeOverallData: { name: string, [k: string]: string | undefined }[] = [{ name: "Overall Liking" }]
			const temporaryConsumptionData: { name: string, [k: string]: string | undefined }[] = [{ name: "Consumption intention" }]
			const temporaryPurchaseData: { name: string, [k: string]: string | undefined }[] = [{ name: "Purchase intention" }]
			const temporaryFamiliarityData: { name: string, [k: string]: string | undefined }[] = [{ name: "Familiarity" }]
			for (const product_id of productIds) {
				const filteredListProductByProductId = longData?.filter(product => product.product_id === product_id)
				const avgLikeOverall = Number(_.meanBy(filteredListProductByProductId, "like_overall").toFixed(4))
				const avgConsumptionIntention = Number(_.meanBy(filteredListProductByProductId, "consumption").toFixed(4))
				const avgPurchaseIntention = Number(_.meanBy(filteredListProductByProductId, "purchase").toFixed(4))
				const avgFamialirity = Number(_.meanBy(filteredListProductByProductId, "familiarity").toFixed(4))

				_.set(temporaryLikeOverallData, [0, product_id], avgLikeOverall)
				_.set(temporaryConsumptionData, [0, product_id], avgConsumptionIntention)
				_.set(temporaryPurchaseData, [0, product_id], avgPurchaseIntention)
				_.set(temporaryFamiliarityData, [0, product_id], avgFamialirity)

			}
			setlikeOverallData(temporaryLikeOverallData)
			setConsumptionData(temporaryConsumptionData)
			setPurchaseData(temporaryPurchaseData)
			setFamiliarityData(temporaryFamiliarityData)
		}
	}, [longData, phases, productIds])

	useEffect(() => {
		//per gli ultimi 4 grafici (linee)
		if (timeline && products) {
			const temporaryCW_MT14data: CW_data[] = []
			const temporaryCW_MT23data: CW_data[] = []
			const temporaryCW_MT39data: CW_data[] = []
			const temporaryCW_MT45data: CW_data[] = []
			products.forEach(product => {
				let phasesDurationSum = 0
				setProductPhasesList(_.sortBy(product.phases, "order"))
				for (let i = 0; i < _.sortBy(product.phases, "order").length; i++) {
					const phaseProduct = _.sortBy(product.phases, "order")[i]
					const filteredListProductByPhase = timeline.filter(item => item.product_id.toLowerCase() == product.product_id.toLowerCase() && item.phase_code === phaseProduct.marker_code)

					const timestamps = new Set(_.map(filteredListProductByPhase, "timeline"))
					const timestampsGlobal = Array.from(timestamps).map(t => t + phasesDurationSum)

					timestampsGlobal.forEach(timestamp => {

						let timestamp_index_MT14 = temporaryCW_MT14data.findIndex(item => item.name === timestamp && item.phase_code === phaseProduct.marker_code)
						let timestamp_index_MT23 = temporaryCW_MT23data.findIndex(item => item.name === timestamp && item.phase_code === phaseProduct.marker_code)
						let timestamp_index_MT39 = temporaryCW_MT39data.findIndex(item => item.name === timestamp && item.phase_code === phaseProduct.marker_code)
						let timestamp_index_MT45 = temporaryCW_MT45data.findIndex(item => item.name === timestamp && item.phase_code === phaseProduct.marker_code)

						if (timestamp_index_MT14 === -1) {
							timestamp_index_MT14 = temporaryCW_MT14data.push({
								name: timestamp,
								phase_code: phaseProduct.marker_code,
								values: {}
							})
							timestamp_index_MT14--
						}

						if (timestamp_index_MT23 === -1) {
							timestamp_index_MT23 = temporaryCW_MT23data.push({
								name: timestamp,
								phase_code: phaseProduct.marker_code,
								values: {}
							})
							timestamp_index_MT23--
						}

						if (timestamp_index_MT39 === -1) {
							timestamp_index_MT39 = temporaryCW_MT39data.push({
								name: timestamp,
								phase_code: phaseProduct.marker_code,
								values: {}
							})
							timestamp_index_MT39--
						}

						if (timestamp_index_MT45 === -1) {
							timestamp_index_MT45 = temporaryCW_MT45data.push({
								name: timestamp,
								phase_code: phaseProduct.marker_code,
								values: {}
							})
							timestamp_index_MT45--
						}

						const filteredListProductByTimestamp = timeline.filter(item => item.product_id.toLowerCase() === product.product_id.toLowerCase() && item.phase_code === phaseProduct.marker_code && item.timeline === timestamp - phasesDurationSum)

						const avgCWMT14 = Number(_.meanBy(filteredListProductByTimestamp, "MT14").toFixed(4))
						const avgCWMT23 = Number(_.meanBy(filteredListProductByTimestamp, "MT23").toFixed(4))
						const avgCWMT39 = Number(_.meanBy(filteredListProductByTimestamp, "MT39").toFixed(4))
						const avgCWMT45 = Number(_.meanBy(filteredListProductByTimestamp, "MT45").toFixed(4))
						temporaryCW_MT14data[timestamp_index_MT14].values[product.product_id] = avgCWMT14
						temporaryCW_MT23data[timestamp_index_MT23].values[product.product_id] = avgCWMT23
						temporaryCW_MT39data[timestamp_index_MT39].values[product.product_id] = avgCWMT39
						temporaryCW_MT45data[timestamp_index_MT45].values[product.product_id] = avgCWMT45
					})

					phasesDurationSum += phaseProduct.duration
				}
			})
			setCW_MT14data(temporaryCW_MT14data)
			setCW_MT23data(temporaryCW_MT23data)
			setCW_MT39data(temporaryCW_MT39data)
			setCW_MT45data(temporaryCW_MT45data)
		}
	}, [timeline, products])

	useEffect(() => {
		switch (page) {
			case 1:
				setPageTitle("")
				break
			case 2:
				setPageTitle("Project Overview")
				break
			case 3:
				setPageTitle("Testers Overview")
				break
			case 4:
				setPageTitle("Testers Charts")
				break
			case 5:
				setPageTitle("Products")
				break
			case 6:
				setPageTitle("")
				break
			case 7:
				setPageTitle("")
				break
			case 8:
				setPageTitle("")
				break
			case 9:
				setPageTitle("")
				break
			case 10:
				setPageTitle("")
				break
			case 11:
				setPageTitle("")
				break
			case 12:
				setPageTitle("Full Experience Time-Line")
				break
			case 13:
				setPageTitle("Full Experience Time-Line")
				break
			case 14:
				setPageTitle("Full Experience Time-Line")
				break
			case 15:
				setPageTitle("Full Experience Time-Line")
				break
			case 16:
				setPageTitle("Declarative Data Chart")
				break
			case 17:
				setPageTitle("Declarative Data Chart")
				break
			case 18:
				setPageTitle("Declarative Data Chart")
				break
			case 19:
				setPageTitle("Declarative Data Chart")
				break
			case 20:
				setPageTitle("Results")
				break
			case 21:
				setPageTitle("")
				break
			default:
				break
		}
	}, [page])

	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value)
	}

	const isStepFailed = (step: number) => {
		if (analysisStatus === "FAILED") {
			return step === 3
		}
	}

	const handleAnalysisStart = () => {
		setIsStepperVisible(true)
		analysisStartMutation.mutate()
	}

	const toolbarConfig: ToolbarConfig = {
		title: "Projects",
		subtitle: "Manage projects from here",
		buttons: [
			{
				name: "Start analysis",
				icon: ["SUBMITTED", "PENDING", "RUNNABLE", "STARTING", "RUNNING", undefined].includes(analysisStatus) ? <CircularProgress size={18} /> : <Settings />,
				disabled: ["SUBMITTED", "PENDING", "RUNNABLE", "STARTING", "RUNNING", undefined].includes(analysisStatus),
				action: () => handleAnalysisStart(),
				hide: TBOX_MODE || endpoint.includes("prod") || ["SUCCEEDED", "FAILED", undefined].includes(analysisStatus) || !project?.completed,
				primary: true
			},
			{
				name: "Download PDF",
				icon: <FileDownloadIcon />,
				action: () => handlePrint(),
				disabled: isProjectFetching || !projectTbox
			},
		]
	}

	if (isProjectLoading || isProductsFetching || isProjectTestersFetching || isProjectTboxFetching || !projectTbox || !project || isCompanyLoading) {
		return <Grid container padding={3}>
			<Toolbar config={toolbarConfig} sx={{ marginBottom: !TBOX_MODE && isStepperVisible ? 1 : 4 }} />
			<Grid container justifyContent="center"><CircularProgress />
			</Grid>
		</Grid>
	}

	return (
		<Grid container padding={3}>
			<Toolbar config={toolbarConfig} sx={{ marginBottom: !TBOX_MODE && isStepperVisible ? 1 : 4 }} />
			{/* ---- STEPPER ---- */}
			{!TBOX_MODE && isStepperVisible &&
				<Box
					sx={{
						width: "100%",
						paddingBottom: 2
					}}
				>
					<DashboardCardTitleTypography textAlign="center" color="primary">Analysis status</DashboardCardTitleTypography>
					<Stepper activeStep={activeStep} sx={{ marginX: 10 }}>
						{steps.map((label, index) => {
							const stepProps: { completed?: boolean } = {}
							const labelProps: {
								optional?: React.ReactNode
								error?: boolean
							} = {}
							if (isStepFailed(index)) {
								labelProps.error = true
							}

							return (
								<Step key={label} {...stepProps}>
									<StepLabel {...labelProps}><DashboardCardParagraphTypography>{label}</DashboardCardParagraphTypography></StepLabel>
								</Step>
							)
						})}
					</Stepper>
				</Box>
			}
			{/* ------------ */}
			<Grid item container>
				<Paper
					sx={{
						width: "100%",
						height: "calc(100vh - 200px)",
						marginBottom: 2,
						position: "relative",
						// su windows compare una doppia scroll bar con questa property
						// overflow: "scroll"x\x\
					}}
				>
					<Grid
						item
						container
						direction="column"
						sx={{
							height: "100%",
							overflow: "scroll",
						}}
					>
						<Grid container sx={{ height: "100%" }}>
							{(TBOX_MODE || !longData || !timeline) && [1, 7].includes(page) || [1, 6, 7, 22].includes(page) ? undefined :
								<DashboardLabel project={project} sectionTitle={pageTitle} tboxName={projectTbox.name} languages={languages} />
							}

							<Grid
								item
								container
								sx={{ paddingTop: 0 }}
							>
								{
									page === 1 ?
										<Cover
											projectName={project.name}
											companyName={company?.name}
										/>
										: page === 2 ?
											<IntroPage project={project} />
											: page === 3 ?
												<TestersRecap testers={projectTesters} testersNotes={project.tester_notes} onSaveTestersNotes={updateProjectMutation} />
												: page === 4 ?
													<TestersCharts testers={testersWithCompletedSession} />
													: page === 5 ?
														<DashboardProducts productsList={products} />
														: page === 6 && (TBOX_MODE || !longData || !timeline) ?
															<AdditionalNotes
																notes={project.notes}
																onSaveNotes={notes => updateProjectNotesMutation.mutate({
																	noteName: "notes",
																	note: notes
																})}
															/>
															: page === 6 && !TBOX_MODE && longData && timeline ?
																<Grid item xs>
																	<img
																		width="100%"
																		alt="cover"
																		src={dashboard_cover}
																	/>
																</Grid>
																: page === 7 && (TBOX_MODE || !longData || !timeline) ?
																	<Credits />
																	: page === 7 && !TBOX_MODE ?
																		<PADescription />
																		: page === 8 && !TBOX_MODE ?
																			<PADashboardSimpleBarChart
																				barChartData={MT14data}
																				sectionTitle="Cognitive Workload"
																				productsList={products}
																				notes={project.notesDeclarativeMT14}
																				onSaveNotes={notes => updateProjectNotesMutation.mutate({
																					noteName: "notesDeclarativeMT14",
																					note: notes
																				})}
																			/>
																			: page === 9 && !TBOX_MODE ?
																				<PADashboardSimpleBarChart
																					barChartData={MT23data}
																					sectionTitle="Engagement"
																					productsList={products}
																					notes={project.notesDeclarativeMT23}
																					onSaveNotes={notes => updateProjectNotesMutation.mutate({
																						noteName: "notesDeclarativeMT23",
																						note: notes
																					})}
																				/>
																				: page === 10 && !TBOX_MODE ?
																					<PADashboardSimpleBarChart
																						barChartData={MT39data}
																						sectionTitle="Relax"
																						productsList={products}
																						notes={project.notesDeclarativeMT39}
																						onSaveNotes={notes => updateProjectNotesMutation.mutate({
																							noteName: "notesDeclarativeMT39",
																							note: notes
																						})}
																					/>
																					: page === 11 && !TBOX_MODE ?
																						<PADashboardSimpleBarChart
																							barChartData={MT45data}
																							sectionTitle="Frontal Asymmetry"
																							productsList={products}
																							notes={project.notesDeclarativeMT45}
																							onSaveNotes={notes => updateProjectNotesMutation.mutate({
																								noteName: "notesDeclarativeMT45",
																								note: notes
																							})}
																							customDomain={([dataMin, dataMax]) => { const absMax = Math.max(Math.abs(dataMin), Math.abs(dataMax)); return [-absMax, absMax] }}
																						/>
																						: page === 12 && !TBOX_MODE ?
																							<PADashboardSimpleBarChart
																								lineChartData={CW_MT14data}
																								sectionTitle="Cognitive Workload by Time and Product"
																								productsList={products}
																								productPhasesList={productPhasesList}
																								notes={project.notesTimelineMT14}
																								onSaveNotes={notes => updateProjectNotesMutation.mutate({
																									noteName: "notesTimelineMT14",
																									note: notes
																								})}
																							/>
																							: page === 13 && !TBOX_MODE ?
																								<PADashboardSimpleBarChart
																									lineChartData={CW_MT23data}
																									sectionTitle="Engagement by Time and Product"
																									productsList={products}
																									productPhasesList={productPhasesList}
																									notes={project.notesTimelineMT23}
																									onSaveNotes={notes => updateProjectNotesMutation.mutate({
																										noteName: "notesTimelineMT23",
																										note: notes
																									})}
																								/>
																								: page === 14 && !TBOX_MODE ?
																									<PADashboardSimpleBarChart
																										lineChartData={CW_MT39data}
																										sectionTitle="Relax by Time and Product"
																										productsList={products}
																										productPhasesList={productPhasesList}
																										notes={project.notesTimelineMT39}
																										onSaveNotes={notes => updateProjectNotesMutation.mutate({
																											noteName: "notesTimelineMT39",
																											note: notes
																										})}
																									/>
																									: page === 15 && !TBOX_MODE ?
																										<PADashboardSimpleBarChart
																											lineChartData={CW_MT45data}
																											sectionTitle="Frontal Asymmetry by Time and Product"
																											productsList={products}
																											productPhasesList={productPhasesList}
																											notes={project.notesTimelineMT45}
																											onSaveNotes={notes => updateProjectNotesMutation.mutate({
																												noteName: "notesTimelineMT45",
																												note: notes
																											})}
																										/>
																										: page === 16 && !TBOX_MODE ?
																											<PADashboardSimpleBarChart
																												barChartData={likeOverallData}
																												sectionTitle="Overall Liking"
																												productsList={products}
																												notes={project.notesEegMT14}
																												onSaveNotes={notes => updateProjectNotesMutation.mutate({
																													noteName: "notesEegMT14",
																													note: notes
																												})}
																												customDomain={[likeOverallchartScale.minValue, likeOverallchartScale.maxValue]}
																											/>
																											: page === 17 && !TBOX_MODE ?
																												<PADashboardSimpleBarChart
																													barChartData={consumptionData}
																													sectionTitle="Consumption intention"
																													productsList={products}
																													notes={project.notesEegMT23}
																													onSaveNotes={notes => updateProjectNotesMutation.mutate({
																														noteName: "notesEegMT23",
																														note: notes
																													})}
																													customDomain={[consumptionChartScale.minValue, consumptionChartScale.maxValue]}
																												/>
																												: page === 18 && !TBOX_MODE ?
																													<PADashboardSimpleBarChart
																														barChartData={purchaseData}
																														sectionTitle="Purchase intention"
																														productsList={products}
																														notes={project.notesEegMT39}
																														onSaveNotes={notes => updateProjectNotesMutation.mutate({
																															noteName: "notesEegMT39",
																															note: notes
																														})}
																														customDomain={[purchaseChartScale.minValue, purchaseChartScale.maxValue]}
																													/>
																													: page === 19 && !TBOX_MODE ?
																														<PADashboardSimpleBarChart
																															barChartData={familiarityData}
																															sectionTitle="Familiarity"
																															productsList={products}
																															notes={project.notesEegMT45}
																															onSaveNotes={notes => updateProjectNotesMutation.mutate({
																																noteName: "notesEegMT45",
																																note: notes
																															})}
																															customDomain={[familiarityChartScale.minValue, familiarityChartScale.maxValue]}
																														/>
																														: page === 20 && !TBOX_MODE ?
																															<PADashboardTable
																																notesAverage={project.notesAverage}
																																onSaveTestersNotes={updateProjectMutation}
																																data={longData}
																																productsList={products}
																															/>
																															: page === 21 && !TBOX_MODE ?
																																<AdditionalNotes
																																	notes={project.notes}
																																	onSaveNotes={notes => updateProjectNotesMutation.mutate({
																																		noteName: "notes",
																																		note: notes
																																	})}
																																/>
																																: page === 22 && !TBOX_MODE ?
																																	<Credits />
																																	: undefined
								}
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Grid >
			<Grid container justifyContent="center" alignItems="center">
				<Grid item>
					<Stack spacing={2}>
						<Pagination count={(TBOX_MODE || !timeline || !longData) ? 6 : 22} page={page} color="primary" onChange={handleChange} />
					</Stack>
				</Grid>
			</Grid>


			{/* -------------------- PDF -------------------------------- */}
			{project &&
				<Grid
					sx={{
						position: "absolute",
						left: "-99999px"
					}}
				>
					<Grid ref={componentRef}>
						<style type="text/css" media="print">
							{"@page {" +
								" margin: 30px 30px 30px 30px !important;" +
								" size: landscape;" +
								" }"}
						</style>

						<Grid sx={{ breakBefore: "page" }}>
							<Cover
								projectName={project.name}
								companyName={company?.name}
							/>
						</Grid>

						{/* PROJECT OVERVIEW */}
						<Grid sx={{ breakBefore: "page" }}>
							<DashboardLabel project={project} sectionTitle="Project Overview" tboxName={projectTbox.name} languages={languages} />
							<H1Typography
								sx={{
									marginBottom: 1,
									marginTop: 2
								}}
							>
								Project Overview
							</H1Typography>
							<IntroPage project={project} />
						</Grid>

						{/* TESTERS OVERVIEW */}
						<Grid sx={{ breakBefore: "page" }}>
							<DashboardLabel project={project} sectionTitle="Testers Overview" tboxName={projectTbox.name} languages={languages} />
							<H1Typography
								sx={{
									marginBottom: 1,
									marginTop: 2
								}}
							>
								Testers Overview
							</H1Typography>
							<TestersRecap pdfMode testers={projectTesters} testersNotes={project?.tester_notes} onSaveTestersNotes={updateProjectMutation} />
						</Grid>

						{/* TESTERS CHARTS */}
						<Grid
							sx={{ breakBefore: "page" }}
						>
							<DashboardLabel project={project} sectionTitle="Testers Charts" tboxName={projectTbox.name} languages={languages} />
							<H1Typography
								sx={{
									marginBottom: 1,
									marginTop: 2
								}}
							>
								Testers Charts
							</H1Typography>
							<TestersCharts testers={testersWithCompletedSession} />
						</Grid>

						{/* PRODUCTS */}
						<Grid sx={{ breakBefore: "page" }}>
							<DashboardLabel project={project} sectionTitle="Products" tboxName={projectTbox.name} languages={languages} />

							<H1Typography
								sx={{
									marginBottom: 1,
									marginTop: 2
								}}
							>
								Products
							</H1Typography>
							<DashboardProducts productsList={products} />
						</Grid>

						{!TBOX_MODE && timeline && longData &&
							<>
								{/* COVER */}
								<Grid sx={{ breakBefore: "page" }}>
									<img
										alt="cover"
										src={dashboard_cover}
									/>
								</Grid>

								{/* Description */}
								<Grid sx={{ breakBefore: "page" }}>
									<PADescription />
								</Grid>

								{/* MT14 */}
								<Grid sx={{ breakBefore: "page" }}>
									<DashboardLabel project={project} sectionTitle="" tboxName={projectTbox.name} languages={languages} />
									<PADashboardSimpleBarChart
										pdfMode
										barChartData={MT14data}
										sectionTitle="Cognitive Workload"
										productsList={products}
										notes={project.notesDeclarativeMT14}
									/>
								</Grid>
								{/* MT23 */}
								<Grid sx={{ breakBefore: "page" }}>
									<DashboardLabel project={project} sectionTitle="" tboxName={projectTbox.name} languages={languages} />
									< PADashboardSimpleBarChart
										pdfMode
										barChartData={MT23data}
										sectionTitle="Engagement"
										productsList={products}
										notes={project.notesDeclarativeMT23}
									/>
								</Grid>
								{/* MT39 */}
								<Grid sx={{ breakBefore: "page" }}>
									<DashboardLabel project={project} sectionTitle="" tboxName={projectTbox.name} languages={languages} />
									<PADashboardSimpleBarChart
										pdfMode
										barChartData={MT39data}
										sectionTitle="Relax"
										productsList={products}
										notes={project.notesDeclarativeMT39}
									/>
								</Grid>
								{/* MT45 */}
								<Grid sx={{ breakBefore: "page" }}>
									<DashboardLabel project={project} sectionTitle="" tboxName={projectTbox.name} languages={languages} />
									<PADashboardSimpleBarChart
										pdfMode
										barChartData={MT45data}
										sectionTitle="Frontal Asymmetry"
										productsList={products}
										notes={project.notesDeclarativeMT45}
										customDomain={([dataMin, dataMax]) => { const absMax = Math.max(Math.abs(dataMin), Math.abs(dataMax)); return [-absMax, absMax] }}
									/>
								</Grid>
								{/*Cognitive Workload MT14 */}
								<Grid sx={{ breakBefore: "page" }}>
									<DashboardLabel project={project} sectionTitle="Full Experience Time-Line" tboxName={projectTbox.name} languages={languages} />
									<PADashboardSimpleBarChart
										pdfMode
										lineChartData={CW_MT14data}
										sectionTitle="Cognitive Workload by Time and Product"
										productsList={products}
										productPhasesList={productPhasesList}
										notes={project.notesTimelineMT14}
									/>
								</Grid>
								{/*Cognitive Workload MT23 */}
								<Grid sx={{ breakBefore: "page" }}>
									<DashboardLabel project={project} sectionTitle="Full Experience Time-Line" tboxName={projectTbox.name} languages={languages} />
									<PADashboardSimpleBarChart
										pdfMode
										lineChartData={CW_MT23data}
										sectionTitle="Engagement by Time and Product"
										productsList={products}
										productPhasesList={productPhasesList}
										notes={project.notesTimelineMT23}
									/>
								</Grid>
								{/*Cognitive Workload MT39 */}
								<Grid sx={{ breakBefore: "page" }}>
									<DashboardLabel project={project} sectionTitle="Full Experience Time-Line" tboxName={projectTbox.name} languages={languages} />
									<PADashboardSimpleBarChart
										pdfMode
										lineChartData={CW_MT39data}
										sectionTitle="Relax by Time and Product"
										productsList={products}
										productPhasesList={productPhasesList}
										notes={project.notesTimelineMT39}
									/>
								</Grid>
								{/*Cognitive Workload MT45 */}
								<Grid sx={{ breakBefore: "page" }}>
									<DashboardLabel project={project} sectionTitle="Full Experience Time-Line" tboxName={projectTbox.name} languages={languages} />
									<PADashboardSimpleBarChart
										pdfMode
										lineChartData={CW_MT45data}
										sectionTitle="Frontal Asymmetry by Time and Product"
										productsList={products}
										productPhasesList={productPhasesList}
										notes={project.notesTimelineMT45}
									/>
								</Grid>
								{/* Overall Liking */}
								<Grid sx={{ breakBefore: "page" }}>
									<DashboardLabel project={project} sectionTitle="Declarative Data Chart" tboxName={projectTbox.name} languages={languages} />
									<PADashboardSimpleBarChart
										pdfMode
										barChartData={likeOverallData}
										sectionTitle="Overall Liking"
										productsList={products}
										notes={project.notesEegMT14}
										customDomain={[likeOverallchartScale.minValue, likeOverallchartScale.maxValue]}
									/>
								</Grid>
								{/*Consumption intention */}
								<Grid sx={{ breakBefore: "page" }}>
									<DashboardLabel project={project} sectionTitle="Declarative Data Chart" tboxName={projectTbox.name} languages={languages} />
									<PADashboardSimpleBarChart
										pdfMode
										barChartData={consumptionData}
										sectionTitle="Consumption intention"
										productsList={products}
										notes={project.notesEegMT23}
										customDomain={[consumptionChartScale.minValue, consumptionChartScale.maxValue]}
									/>
								</Grid>
								{/*Purchase intention */}
								<Grid sx={{ breakBefore: "page" }}>
									<DashboardLabel project={project} sectionTitle="Declarative Data Chart" tboxName={projectTbox.name} languages={languages} />
									<PADashboardSimpleBarChart
										pdfMode
										barChartData={purchaseData}
										sectionTitle="Purchase intention"
										productsList={products}
										notes={project.notesEegMT39}
										customDomain={[purchaseChartScale.minValue, purchaseChartScale.maxValue]}
									/>
								</Grid>
								{/*Familiarity */}
								<Grid sx={{ breakBefore: "page" }}>
									<DashboardLabel project={project} sectionTitle="Declarative Data Chart" tboxName={projectTbox.name} languages={languages} />
									<PADashboardSimpleBarChart
										pdfMode
										barChartData={familiarityData}
										sectionTitle="Familiarity"
										productsList={products}
										notes={project.notesEegMT45}
										customDomain={[familiarityChartScale.minValue, familiarityChartScale.maxValue]}
									/>
								</Grid>
								{/*results */}
								<Grid sx={{ breakBefore: "page" }}>
									<DashboardLabel project={project} sectionTitle="Results" tboxName={projectTbox.name} languages={languages} />
									<PADashboardTable pdfMode notesAverage={project.notesAverage} onSaveTestersNotes={updateProjectMutation} data={longData} productsList={products} />
								</Grid>
							</>
						}

						{/* NOTES */}
						<Grid sx={{ breakBefore: "page" }}>
							<DashboardLabel project={project} sectionTitle="" tboxName={projectTbox.name} languages={languages} />
							<H1Typography
								sx={{
									marginBottom: 1,
									marginTop: 2
								}}
							>
								Notes
							</H1Typography>
							<AdditionalNotes pdfMode notes={project.notes} />
						</Grid>

						{/*Credits */}
						<Grid sx={{ breakBefore: "page" }}>
							<Credits />
						</Grid>
					</Grid>
				</Grid>
			}
		</Grid >
	)
}

