import { Autocomplete, FormHelperText, Grid, MenuItem, Select, styled, TextField } from "@mui/material"
import { DatePicker, DateValidationError } from "@mui/x-date-pickers"
import { countries } from "country-data-list"
import dayjs from "dayjs"
import { Dayjs } from "dayjs"
import { useMemo, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useMutation } from "react-query"

import { queryClient, testersQueries } from "../networking/networking"
import { useSnackbarStore } from "../stateManagement"
import { Tester } from "../types/genericTypes"
import SubmitButton from "./buttons/submitButton"
import CustomDialog from "./CustomDialog"
import { ParagraphTypography } from "./customTypography"

interface NewTesterFormProps {
	open: boolean
	onClose: () => void,
	company_id: number,
	project_id?: number,
	onTesterCreateSuccess?: (tester: Tester) => void
}

interface NewTesterFormInput {
	first_name: string,
	last_name: string,
	email_address?: string,
	phone_number?: string,
	gender: string,
	date_of_birth: Dayjs,
	ethnicity?: string,
	education?: string,
	professional_sector?: string,
	diet?: string,
	smoker?: string,
	country?: string,
	group: string,
}

const countriesName = countries.all.filter(country => country.status === "assigned")



const StyledTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "c6c6c6",
	},
	"& .MuiOutlinedInput-root": {
		backgroundColor: "#F6F6F6",
		"& fieldset": {
			borderColor: "#E1E1E1",
		},
		"&:hover fieldset": {
			borderColor: "#E1E1E1",
		},
	}
}) as typeof TextField

const genders = [
	"male",
	"female",
	"transgender female",
	"transgender male",
	"non binary",
	"I prefer not to disclose"
]
const educations = [
	"Primary School",
	"Middle School",
	"High School",
	"Bachelor’s Degree",
	"Master’s Degree",
	"Ph. D., MD, or JD",
	"Some college",
	"Associate’s degree",
	"na",
	"Other"
]
const ethnicities = [
	"",
	"Afghani",
	"African",
	"African American",
	"Afro-Colombian",
	"Akan",
	"Alaskan Native",
	"Albanian",
	"Algerian",
	"American",
	"Amerindian",
	"Andorran",
	"Angolan",
	"Arab",
	"Arab-Berber",
	"Argentinian",
	"Armenian",
	"Aruban",
	"Australian",
	"Austrian",
	"Aymaq",
	"Azerbaijani",
	"Azeri",
	"Bahraini",
	"Bamileke-Bamu",
	"Banda",
	"Baya",
	"Belarusian",
	"Belgian",
	"Bengali",
	"Bermudan",
	"Beti/Bassa",
	"Bolivian",
	"Bosniak",
	"Bosnian and Herzegovinian",
	"Brahwui",
	"Brazilian",
	"Bulgarian",
	"Burmese",
	"Cambodian",
	"Cameroonian",
	"Canadian",
	"Caribbean",
	"Celtic",
	"Chamorro",
	"Chilean",
	"Chinese",
	"Colombian",
	"Creole",
	"Croat",
	"Cuban",
	"Curacaoan",
	"Czech",
	"Danish",
	"Dominican",
	"Dutch",
	"East Indian",
	"Egyptian",
	"Emirati",
	"English",
	"Eritrean",
	"Estonian",
	"Ethiopian",
	"Falkland Islander",
	"Fang",
	"Faroese",
	"Fijan",
	"Filpino",
	"Finnish",
	"French",
	"Fulani",
	"Gabonese",
	"Gambian",
	"Georgian",
	"German",
	"Gibraltarian",
	"Greek",
	"Greenlandic",
	"Haitian",
	"Han Chinese",
	"Hausa",
	"Hispanic",
	"Hungarian",
	"Hutu",
	"Icelandic",
	"Indian",
	"Indo-Fijan",
	"Indo-Mauritian",
	"Indonesian",
	"Iraqi",
	"Irish",
	"Israeli",
	"Italian",
	"Jamaican",
	"Japanese",
	"Jordanian",
	"Kalanga",
	"Kazakh",
	"Kenyan",
	"Khmer",
	"Kongo",
	"Korean",
	"Kurdish",
	"Kuwaiti",
	"Kyrgyz",
	"Lao",
	"Latino",
	"Latvian",
	"Lebanese",
	"Lezghin",
	"Liberian",
	"Libyan",
	"Liechtensteiner",
	"Lithuanian",
	"Luxembourger",
	"Macedonian",
	"Malawan",
	"Malaysian",
	"Malian",
	"Maltese",
	"Maya",
	"Mbam",
	"Melanesian",
	"Mennonite",
	"Mestizo",
	"Mexican",
	"Moldovan",
	"Monegasque",
	"Mongolian",
	"Montenegrin",
	"Moroccan",
	"Mossi",
	"Namibian",
	"Native American",
	"Native Hawaiian",
	"Nauruan",
	"Nepali",
	"New Zealander",
	"Ngalop",
	"Nicaraguan",
	"Nigerian",
	"Niuean",
	"North Korean",
	"Norwegian",
	"Pachaie",
	"Pacific Islander",
	"Pakistani",
	"Palauan",
	"Palestinian",
	"Papuan",
	"Pashtun",
	"Persian",
	"Peruvian",
	"Polish",
	"Polynesian",
	"Portuguese",
	"Puerto Rican",
	"Punjabi",
	"Qatari",
	"Romani",
	"Romanian",
	"Russian",
	"Sami",
	"Samoan",
	"Sara (Ngambaye/Sara/Madjingaye/Mbaye)",
	"Saudi Arabian",
	"Scottish",
	"Serbian",
	"Shona",
	"Slovak",
	"Slovene",
	"Somali",
	"Sotho",
	"Spanish",
	"Sri Lankan",
	"Sudanese",
	"Sudanese Arab",
	"Surinamese",
	"Swedish",
	"Swiss",
	"Syrian",
	"Taiwanese",
	"Tajik",
	"Thai",
	"Tigre",
	"Tigrinya",
	"Tongan",
	"Tswana",
	"Tunisian",
	"Turkish",
	"Tutsi",
	"Ugandan",
	"Ukrainian",
	"Uzbek",
	"Venezuelan",
	"Vietnamese",
	"Yemeni",
	"na",
	"Other",

]
const professionalSectors = [
	"Data processing",
	"Education",
	"Agriculture",
	"Entertainment",
	"Finance",
	"Food services",
	"Health care",
	"Hotel services",
	"Information services",
	"Legal services",
	"Military",
	"Publishing",
	"Tourism",
	"Utilities",
	"Prefer not to disclose",
	"na",
	"Other"
]
const diets = [
	"Vegan",
	"Vegetarian",
	"Flexitarian",
	"Omnivore",
	"Pescatarian",
	"Other",
	"na"
]
const isSmoker = [
	"Yes",
	"No",
	"I am a former smoker",
	"na"
]

const optionValueFormatter = (value: string) => value
	.toLowerCase()
	.replaceAll("’", "")
	.replaceAll("'", "")
	.replaceAll("\"", "")
	.replaceAll(".", "")
	.split(" ")
	.join("_")

export const NewTesterForm = (props: NewTesterFormProps) => {
	const maxDate = dayjs().subtract(18, "year")
	const minDate = dayjs().subtract(100, "year")
	const [datePickerError, setDatePicker] = useState<DateValidationError | null>(null)
	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)

	const errorMessage: string = useMemo(() => {
		switch (datePickerError) {
			case "minDate": {
				return "We accept testers with less than 100 years only"
			}
			case "maxDate": {
				return "We accept testers 18+ only"
			}
			case "invalidDate": {
				return "Your date is not valid"
			}
			case "disableFuture": {
				return "Your date is not valid"
			}
			default: {
				return ""
			}
		}
	}, [datePickerError])

	const createTesterMutation = useMutation(
		testersQueries.createTester.name,
		testersQueries.createTester.fn,
		{
			onSuccess: (tester) => {
				queryClient.invalidateQueries(testersQueries.listTesters.name)
				props.onTesterCreateSuccess && props.onTesterCreateSuccess(tester)
				props.onClose()
				resetNewTester()
				openSnackbar("success", "New tester successfully created")
			},
			onError: () => {
				openSnackbar("error", "An error occurred while creating the Tester")
			}
		}
	)

	const {
		register: registerNewTester,
		handleSubmit: handleSubmitNewTester,
		control: newTesterControl,
		reset: resetNewTester,
		formState: { errors },
	} = useForm<NewTesterFormInput>({
		defaultValues: {
			first_name: "",
			last_name: "",
			email_address: "",
			phone_number: "",
			gender: "",
			date_of_birth: "",
			ethnicity: "",
			education: "",
			professional_sector: "",
			diet: "",
			smoker: "",
			country: ""
		}
	})

	const onNewTesterSubmit: SubmitHandler<NewTesterFormInput> = (data) => {
		createTesterMutation.mutate({
			...data,
			project_id: props.project_id,
			company_id: props.company_id,
		})

	}

	return (
		<CustomDialog
			open={props.open}
			onClose={props.onClose}
			title="Fill the form to create a Tester"
			subtitle="Fields with * are required"
		>
			<form onSubmit={handleSubmitNewTester(onNewTesterSubmit)}>
				<Grid
					container
					justifyContent="center"
					gap={3}
					paddingX={15}
				>
					<Grid item container spacing={3} alignItems="center">
						<Grid item xs={3}>
							<ParagraphTypography>First Name*</ParagraphTypography>
						</Grid>
						<Grid item xs={9}>
							<StyledTextField
								fullWidth
								{...registerNewTester("first_name", { required: true })}
								error={Boolean(errors.first_name)}
								helperText={errors.first_name && "Required field"}
								placeholder="Write the name of the Tester here"
							/>
						</Grid>
					</Grid>
					<Grid item container spacing={3} alignItems="center">
						<Grid item xs={3}>
							<ParagraphTypography>Last Name*</ParagraphTypography>
						</Grid>
						<Grid item xs={9}>
							<StyledTextField
								fullWidth
								error={Boolean(errors.last_name)}
								helperText={errors.last_name && "Required field"}
								{...registerNewTester("last_name", { required: true })}
								placeholder="Write the last name of the Tester here"
							/>
						</Grid>
					</Grid>
					<Grid item container spacing={3} alignItems="center">
						<Grid item xs={3}>
							<ParagraphTypography>Email</ParagraphTypography>
						</Grid>
						<Grid item xs={9}>
							<StyledTextField
								fullWidth
								{...registerNewTester("email_address", {
									validate: (value) => {
										const regexp = new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,4})+$")
										if (value) {
											return regexp.test(value) || "Invalid email format (e.g. example@email.com)"
										}
									}
								})}
								required={false}
								placeholder="Write the email of the Tester here"
							/>
							<ParagraphTypography sx={{ marginTop: 1 }} color="red">{errors?.email_address?.message}</ParagraphTypography>
						</Grid>
					</Grid>
					<Grid item container spacing={3} alignItems="center">
						<Grid item xs={3}>
							<ParagraphTypography>Phone</ParagraphTypography>
						</Grid>
						<Grid item xs={9}>
							<StyledTextField
								fullWidth
								{...registerNewTester("phone_number", {
									validate: (v) => {
										const regex = /^(.+?)(\d|\s)$/
										if (v) {
											return regex.test(v) || "Invalid Phone Number"
										}
									}
								})}
								required={false}
								placeholder="Write the phone number of the Tester here"
							/>
							<ParagraphTypography sx={{ marginTop: 1 }} color="red">{errors?.phone_number?.message}</ParagraphTypography>
						</Grid>
					</Grid>
					<Grid item container spacing={3} alignItems="center">
						<Grid item xs={3}>
							<ParagraphTypography>Date of Birth*</ParagraphTypography>
						</Grid>
						<Grid item xs={9}>
							<Controller
								name="date_of_birth"
								control={newTesterControl}
								rules={{ required: true }}
								render={({ field }) => (
									<DatePicker
										{...field}
										disableFuture
										value={field.value || null}
										format="YYYY-MM-DD"
										maxDate={maxDate}
										minDate={minDate}
										onError={(newError) => setDatePicker(newError)}
										slotProps={{
											textField: {
												error: Boolean(errors.date_of_birth) || Boolean(errorMessage),
												fullWidth: true,
												helperText: errors.date_of_birth && "Required Field" || errorMessage
											},
										}}
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item container spacing={3} alignItems="center">
						<Grid item xs={3}>
							<ParagraphTypography>Gender*</ParagraphTypography>
						</Grid>
						<Grid item xs={9}>
							<Controller
								name="gender"
								control={newTesterControl}
								rules={{ required: true }}
								render={({ field }) => (
									<Select
										error={Boolean(errors.gender)}
										displayEmpty
										fullWidth
										sx={{
											// overflow: "hidden",
											borderRadius: "3px",
											backgroundColor: "#f1f1f1",
										}}
										{...field}
									>
										<MenuItem value="" disabled>Select</MenuItem>
										{genders.map((gender) =>
											<MenuItem key={gender} value={optionValueFormatter(gender)}>{gender.charAt(0).toUpperCase() + gender.slice(1)}</MenuItem>
										)}
									</Select>
								)}
							/>
							{errors.gender &&
								<FormHelperText>
									<span
										style={{
											color: "#FF9595",
											marginLeft: "14px"
										}}
									>
										Required field
									</span>
								</FormHelperText>
							}
						</Grid>
					</Grid>
					<Grid item container spacing={3} alignItems="center">
						<Grid item xs={3}>
							<ParagraphTypography>Country</ParagraphTypography>
						</Grid>
						<Grid item xs={9}>
							<Controller
								name="country"
								control={newTesterControl}
								render={({ field }) => (
									<Autocomplete
										getOptionLabel={option => option.name}
										options={countriesName}
										{...field}
										value={countriesName.find(country => country.name === field.value) || null}
										onChange={(event, value) => field.onChange(value?.name)}
										fullWidth
										sx={{
											backgroundColor: "#f6f6f6"
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												placeholder="Please write the country of the Tester here"
												sx={{
													input: {
														color: "#1F1F1F",
														"&::placeholder": {
															opacity: 1,
															color: "#424242"
														},
													},
												}}
											/>
										)}
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item container spacing={3} alignItems="center">
						<Grid item xs={3}>
							<ParagraphTypography>Ethnicity</ParagraphTypography>
						</Grid>
						<Grid item xs={9}>
							<Controller
								control={newTesterControl}
								name="ethnicity"
								render={({ field }) => (
									<Autocomplete
										{...field}
										sx={{
											borderRadius: "3px",
											backgroundColor: "#f1f1f1",
										}}
										options={ethnicities}
										getOptionDisabled={(option) => option === ""}
										renderInput={(params) => (
											<TextField
												error={Boolean(errors.ethnicity)}
												{...params}
												placeholder="Select"
												sx={{
													input: {
														color: "#1F1F1F",
														"&::placeholder": {
															opacity: 1,
															color: "#424242"
														},
													},
												}}
											/>
										)}
										getOptionLabel={(option) => option}
										onChange={(_, value) => {
											field.onChange(value)
										}}
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item container spacing={3} alignItems="center">
						<Grid item xs={3}>
							<ParagraphTypography>Education</ParagraphTypography>
						</Grid>
						<Grid item xs={9}>
							<Controller
								name="education"
								control={newTesterControl}
								render={({ field }) => (
									<Select
										error={Boolean(errors.education)}
										displayEmpty
										fullWidth
										sx={{
											// overflow: "hidden",
											borderRadius: "3px",
											backgroundColor: "#f1f1f1",
										}}
										{...field}
									>
										<MenuItem value="" disabled>Select</MenuItem>
										{educations.map((education) =>
											<MenuItem key={education} value={optionValueFormatter(education)}>{education}</MenuItem>
										)}
									</Select>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item container spacing={3} alignItems="center">
						<Grid item xs={3}>
							<ParagraphTypography>Sector</ParagraphTypography>
						</Grid>
						<Grid item xs={9}>
							<Controller
								name="professional_sector"
								control={newTesterControl}
								render={({ field }) => (
									<Select
										error={Boolean(errors.professional_sector)}
										displayEmpty
										fullWidth
										sx={{
											// overflow: "hidden",
											borderRadius: "3px",
											backgroundColor: "#f1f1f1",
										}}
										{...field}
									>
										<MenuItem value="" disabled>Select</MenuItem>
										{professionalSectors.map((sector) =>
											<MenuItem key={sector} value={optionValueFormatter(sector)}>{sector}</MenuItem>
										)}
									</Select>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item container spacing={3} alignItems="center">
						<Grid item xs={3}>
							<ParagraphTypography>Diet</ParagraphTypography>
						</Grid>
						<Grid item xs={9}>
							<Controller
								name="diet"
								control={newTesterControl}
								render={({ field }) => (
									<Select
										error={Boolean(errors.diet)}
										displayEmpty
										fullWidth
										sx={{
											// overflow: "hidden",
											borderRadius: "3px",
											backgroundColor: "#f1f1f1",
										}}
										{...field}
									>
										<MenuItem value="" disabled>Select</MenuItem>
										{diets.map((diet) =>
											<MenuItem key={diet} value={optionValueFormatter(diet)}>{diet}</MenuItem>
										)}
									</Select>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item container spacing={3} alignItems="center">
						<Grid item xs={3}>
							<ParagraphTypography>Smoker</ParagraphTypography>
						</Grid>
						<Grid item xs={9}>
							<Controller
								name="smoker"
								control={newTesterControl}
								render={({ field }) => (
									<Select
										error={Boolean(errors.smoker)}
										displayEmpty
										fullWidth
										sx={{
											// overflow: "hidden",
											borderRadius: "3px",
											backgroundColor: "#f1f1f1",
										}}
										{...field}

									>
										<MenuItem value="" disabled>Select</MenuItem>
										{isSmoker.map((smoker) =>
											<MenuItem key={smoker} value={optionValueFormatter(smoker)}>{smoker}</MenuItem>
										)}
									</Select>
								)}
							/>
						</Grid>
					</Grid>
					<Grid item container spacing={3} alignItems="center">
						<Grid item xs={3}>
							<ParagraphTypography>Group</ParagraphTypography>
						</Grid>
						<Grid item xs={9}>
							<StyledTextField
								fullWidth
								{...registerNewTester("group",
									{
										validate: (value: string) => {
											// Caratteri accettati solo lettere o numeri
											const regex = new RegExp("^[a-zA-Z0-9]+$")
											if (value !== "" && !regex.test(value)) {
												return "Group must contain only letter or number."
											}
										}
									}
								)}
								placeholder="Write the group of the Tester here"
							/>
							<ParagraphTypography color="red">{errors.group?.message}</ParagraphTypography>
						</Grid>
					</Grid>
					<Grid item marginTop={4}>
						<SubmitButton text="Create" />
					</Grid>
				</Grid>
			</form>
		</CustomDialog>
	)
}