import { SvgIcon, SvgIconProps } from "@mui/material"

const imageIcon = {
	overflow: "visible"
}
export function SwallowIcon(props: SvgIconProps) {
	return (
		<SvgIcon
			{...props}
			sx={{
				...props.sx,
				...imageIcon
			}}
		>
			<path d="M16.0001 27C17.4451 27.004 18.8767 26.7213 20.2119 26.1681C21.5469 25.6151 22.7591 24.8027 23.7781 23.7779C23.9656 23.5904 24.0709 23.3361 24.0709 23.071C24.0709 22.8058 23.9656 22.5515 23.7781 22.3639L17.4141 15.9999L23.7781 9.63594C23.9656 9.44836 24.0709 9.19406 24.0709 8.9289C24.0709 8.66374 23.9656 8.40944 23.7781 8.22194C22.0659 6.5096 19.8365 5.41014 17.4357 5.09414C15.0349 4.77804 12.5969 5.26297 10.4999 6.47368C8.40265 7.68446 6.76385 9.55328 5.83705 11.7905C4.91041 14.0277 4.74783 16.5081 5.37463 18.8471C6.00135 21.1861 7.38243 23.2529 9.30363 24.7269C11.2248 26.2009 13.5786 26.9999 16.0002 26.9999L16.0001 27ZM16.0001 7.00002C18.0425 6.99416 20.0253 7.68838 21.6181 8.96706L15.2931 15.2931V15.293C15.1056 15.4806 15.0003 15.7349 15.0003 16C15.0003 16.2652 15.1056 16.5195 15.2931 16.7071L21.6181 23.0331V23.033C20.1147 24.2334 18.2663 24.9206 16.3439 24.9935C14.4214 25.0666 12.5263 24.5216 10.9361 23.4387C9.34597 22.3557 8.14485 20.7919 7.50865 18.9763C6.87247 17.1607 6.83475 15.1891 7.40115 13.3505C7.96747 11.512 9.10811 9.90328 10.6558 8.76048C12.2033 7.61758 14.0764 7.00064 16.0002 7.00016L16.0001 7.00002Z" />
			<path d="M27 16C27 17.1045 26.1045 18 25 18C23.8955 18 23 17.1045 23 16C23 14.8955 23.8955 14 25 14C26.1045 14 27 14.8955 27 16Z" />
			<path d="M16 11C16 12.1045 15.1045 13 14 13C12.8955 13 12 12.1045 12 11C12 9.89546 12.8955 9 14 9C15.1045 9 16 9.89546 16 11Z" />
		</SvgIcon >
	)
}
