import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined"
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined"
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined"
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined"
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined"
import RouterOutlinedIcon from "@mui/icons-material/RouterOutlined"
import ScienceOutlinedIcon from "@mui/icons-material/ScienceOutlined"
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import { Avatar, styled, SvgIconProps, SwipeableDrawer, Theme, useMediaQuery } from "@mui/material"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import React from "react"
import { useQuery } from "react-query"
import { Link as ReactRouterLink, useLocation } from "react-router-dom"

import thimus_wizard_logo_dark from "../assets/logos/thimus_wizard_logo_dark.svg"
import { TBOX_MODE } from "../constants"
import { userQueries } from "../networking/networking"
import { useOperatorStore, useUserStore } from "../stateManagement"
import { userRole } from "../types/genericTypes"
import { H1Typography, H2Typography } from "./customTypography"

const simpleRoles = ["superadmin", "admin", "reader"]


// ---------- types ------------
interface Props {
	open: boolean,
	setDrawerOpen: (open: boolean) => void,
}

// ---------- style -----------------
const getRoleColor = (role: string) => {
	if (role === "superadmin") {
		return "#FFBAC6"
	} else if (role === "admin") {
		return "#C2C9B4"
	} else if (role === "reader") {
		return "#E0E0E0"
	} else if (role === "operator") {
		return "#FFCBB2"
	}
}
const logoStyle = {
	height: "40px",
}

const StyledListButton = styled(ListItemButton)(({ theme }) => ({
	backgroundColor: theme.palette.paper.main,
	width: 250,
	height: 64,
	borderRadius: "5px",
	"& > *": { color: theme.palette.text.primary },
	"&:hover": {
		backgroundColor: theme.palette.primary.dark,
		"& > *": {
			color: "white"
		}
	},
	"&.Mui-selected": {
		backgroundColor: theme.palette.primary.main,
		"& > *": {
			color: "white"
		}
	},
	"&.Mui-selected:hover": {
		backgroundColor: theme.palette.primary.dark,
		"& > *": {
			color: "white"
		}
	},
})) as typeof ListItemButton
const LogoutListButton = styled(StyledListButton)(({ theme }) => ({
	"&:hover": {
		backgroundColor: "#FF9595",
		"& > *": {
			color: theme.palette.text.primary
		}
	},
})) as typeof ListItemButton

// ----------------------------------
const DRAWER_WIDTH = 300
export const AppDrawer = (props: Props) => {
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))
	const userRole = useUserStore((state) => state.userRole)
	const setUserRole = useUserStore((state) => state.setUserRole)
	const setUserCompany = useUserStore((state) => state.setUserCompany)
	const location = useLocation()
	const userName = useUserStore((state) => state.userName)
	const persistOpStore = useOperatorStore()
	const color = getRoleColor(userRole)
	const name = userName || persistOpStore.operatorName || ""

	interface drawerItem {
		title: string,
		icon: React.ReactElement<SvgIconProps>,
		to: string,
		onlyVisibleTo?: string[],
		disabled?: boolean
	}

	// use this variable to configure the drawer's buttons. you can make certain buttons visible only to specific groups adding the
	// key { onlyVisibleto:['superadmin', 'admin', 'reader','operator']}
	const drawerConfig: drawerItem[] = [
		{
			title: "Projects",
			icon: <ScienceOutlinedIcon />,
			to: "/projects",
		},
		{
			title: "Companies",
			icon: <DomainOutlinedIcon />,
			to: "/companies",
			onlyVisibleTo: ["superadmin"]
		},
		{
			title: "Users",
			icon: <AccountCircleOutlinedIcon />,
			to: "/users"
		},
		{
			title: "Templates",
			icon: <PostAddOutlinedIcon />,
			to: "/templates",
			onlyVisibleTo: ["superadmin"]
		},
		{
			title: "Testers",
			icon: <GroupsOutlinedIcon />,
			to: "/testers",
			onlyVisibleTo: ["superadmin", "admin", "reader"]
		},
		{
			title: "Classification",
			icon: <CategoryOutlinedIcon />,
			to: "/classification",
			onlyVisibleTo: ["superadmin", "admin", "reader"]
		},
		{
			title: "Phases",
			icon: <FormatListBulletedIcon />,
			to: "/phases",
			onlyVisibleTo: ["superadmin"]
		},
		{
			title: "Questions",
			icon: <DescriptionOutlinedIcon />,
			to: "/questions",
			onlyVisibleTo: ["superadmin"]
		},
		{
			title: "TBox",
			icon: <RouterOutlinedIcon />,
			to: "/tbox",
			onlyVisibleTo: ["superadmin", "admin"]
		},
		// LOGOUT button is handled separately for style customization
	]

	function getInitials(str: string): string {
		const words = str.split(" ", 3)
		const initials = words.map((word) => word.charAt(0).toUpperCase())
		return initials.join("")
	}

	useQuery(
		userQueries.listGroupsForUser.name,
		userQueries.listGroupsForUser.fn,
		{
			retry: 0,
			onSuccess: (data) => {
				const userRole = data.find(group => simpleRoles.includes(group.GroupName))?.GroupName as userRole
				const userCompany = data.find(group => group.GroupName.includes("company"))
				if (userRole) {
					setUserRole(userRole)
				}
				if (userCompany && userRole != "superadmin") {
					setUserCompany(userCompany)
				}
			},
			enabled: !TBOX_MODE
		},
	)

	const toggleDrawer =
		(open: boolean) =>
			(event: React.KeyboardEvent | React.MouseEvent) => {
				if (
					event.type === "keydown" &&
					((event as React.KeyboardEvent).key === "Tab" ||
						(event as React.KeyboardEvent).key === "Shift")
				) {
					return
				}
				props.setDrawerOpen(open)
			}

	return (
		<SwipeableDrawer
			anchor="left"
			open={props.open}
			onClose={() => { props.setDrawerOpen(false) }}
			onOpen={() => { props.setDrawerOpen(true) }}
			variant={!isMobile ? "permanent" : undefined}
			sx={{
				width: DRAWER_WIDTH,
				flexShrink: 0,
				["& .MuiDrawer-paper"]: {
					width: DRAWER_WIDTH,
					boxSizing: "border-box",
					borderWidth: 0
				}
			}}
		>
			<Box
				role="presentation"
				onClick={toggleDrawer(false)}
				onKeyDown={toggleDrawer(false)}
			>
				<List>
					<ListItem
						sx={{ justifyContent: "center" }}
						component={ReactRouterLink}
						to="/"
					>
						<img
							alt="logo-icon"
							src={thimus_wizard_logo_dark}
							style={logoStyle}
						/>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "center",
							paddingTop: 2
						}}
					>
						<Box sx={{ position: "relative" }}>
							<Avatar
								sx={{
									minHeight: "100px",
									minWidth: "100px",
									borderColor: color,
									backgroundColor: color,
									fontSize: 32
								}}
								alt="avatar"
								src="/static/images/avatar/1.jpg"
							>
								{getInitials(name)}
							</Avatar>
							{/* <SettingsOutlinedIcon
								sx={{
									backgroundColor: "white",
									borderRadius: "50%",
									padding: "3px",
									position: "absolute",
									bottom: 0,
									right: 0,
									cursor: "pointer",
									"&:hover": {
										backgroundColor: "#dc7e44",
										color: "white"
									}
								}} /> */}
						</Box>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "center",
							paddingTop: 2
						}}
					>
						<H2Typography textAlign="center">Welcome back,</H2Typography>
					</ListItem>
					<ListItem
						sx={{
							justifyContent: "center",
							paddingTop: 2,
							paddingBottom: 2,
						}}
					>
						<H1Typography textAlign="center"> {TBOX_MODE ? persistOpStore.operatorName : userName} </H1Typography>
					</ListItem>
					{/* LIST */}
					{drawerConfig.map((button) => {
						if (button.onlyVisibleTo && !button.onlyVisibleTo.includes(userRole)) {
							return
						} else {
							return (
								<ListItem
									key={button.title}
									component={ReactRouterLink}
									to={button.disabled ? "#" : button.to}
								>
									<StyledListButton
										disabled={Boolean(button.disabled)}
										selected={location.pathname.startsWith(button.to)}
									>
										<ListItemIcon>
											{button.icon}
										</ListItemIcon>
										<ListItemText primary={button.title} />
									</StyledListButton>
								</ListItem>
							)
						}
					})}
					<ListItem
						component={ReactRouterLink}
						to="/auth/signOut"
					>
						<LogoutListButton>
							<ListItemIcon>
								<LogoutOutlinedIcon sx={{ color: "text.secondary" }} />
							</ListItemIcon>
							<ListItemText primary="Logout" />
						</LogoutListButton>
					</ListItem>
				</List>
			</Box>
		</SwipeableDrawer>
	)
}
