import dayjs from "dayjs"
import _ from "lodash"

import { ReactComponent as ProductCard_0 } from "./assets/productCard/productCard_0.svg"
import { ReactComponent as ProductCard_1 } from "./assets/productCard/productCard_1.svg"
import { ReactComponent as ProductCard_2 } from "./assets/productCard/productCard_2.svg"
import { ReactComponent as ProductCard_3 } from "./assets/productCard/productCard_3.svg"
import { ReactComponent as ProductCard_4 } from "./assets/productCard/productCard_4.svg"
import { ReactComponent as ProductCard_5 } from "./assets/productCard/productCard_5.svg"
import { ReactComponent as ProductCard_6 } from "./assets/productCard/productCard_6.svg"
import { ReactComponent as ProductCard_7 } from "./assets/productCard/productCard_7.svg"
import { ReactComponent as ProductCard_8 } from "./assets/productCard/productCard_8.svg"
import { ReactComponent as ProductCard_9 } from "./assets/productCard/productCard_9.svg"
import { ReactComponent as ProductCard_10 } from "./assets/productCard/productCard_10.svg"
import { ReactComponent as ProductCard_11 } from "./assets/productCard/productCard_11.svg"
import { TBOX_MODE } from "./constants"
import { ReceivedCognitoUser } from "./networking/types"
import {
	Company,
	Phase,
	PhaseDescription,
	PhaseProduct,
	Product,
	Project,
	Question,
	SingleSession,
	TBox,
	Template,
	userRole
} from "./types/genericTypes"

export const tboxesSorter = (t1: TBox, t2: TBox) => {
	const nameA = t1.name.toUpperCase()
	const nameB = t2.name.toUpperCase()
	if (nameA < nameB) {
		return -1
	}
	if (nameA > nameB) {
		return 1
	}
	return 0
}

export const companiesSorter = (c1: Company, c2: Company) => {
	const nameA = c1.name.toUpperCase()
	const nameB = c2.name.toUpperCase()
	if (nameA < nameB) {
		return -1
	}
	if (nameA > nameB) {
		return 1
	}
	return 0
}
export const phaseProductSorter = (pp1: PhaseProduct, pp2: PhaseProduct) => {
	if (pp1.order < pp2.order) {
		return -1
	}
	if (pp1.order > pp2.order) {
		return 1
	}
	return 0
}

export const usersSorter = (u1: ReceivedCognitoUser, u2: ReceivedCognitoUser) => {
	const u1Groups = u1.Groups.map(g => g.GroupName)
	const u2Groups = u2.Groups.map(g => g.GroupName)
	const higherU1Group = u1Groups.includes("admin") ? "admin" : "reader"
	const higherU2Group = u2Groups.includes("admin") ? "admin" : "reader"

	if (higherU1Group === "admin" && higherU2Group !== "admin") return -1
	if (higherU2Group === "admin" && higherU1Group !== "admin") return 1
	const nameA = u1.Username.toUpperCase()
	const nameB = u2.Username.toUpperCase()
	if (nameA < nameB) {
		return -1
	}
	if (nameA > nameB) {
		return 1
	}
	return 0
}

export const productsSorter = (p1: Product, p2: Product) => {
	if (p1.order < p2.order) {
		return -1
	}
	if (p1.order > p2.order) {
		return 1
	}
	return 0
}

export const sessionsSorter = (s1: SingleSession, s2: SingleSession) => {
	if (s1.local_tester_id < s2.local_tester_id) {
		return -1
	}
	if (s1.local_tester_id > s2.local_tester_id) {
		return 1
	}
	return 0
}

export const phaseDescriptionSorter = (pd1: PhaseDescription, pd2: PhaseDescription) => {
	if (pd1.language === pd2.language) return 0
	if (pd1.language === "en") return -1
	if (pd2.language === "en") return 1
	const lang1 = pd1.language.toUpperCase()
	const lang2 = pd2.language.toUpperCase()
	if (lang1 < lang2) {
		return -1
	}
	if (lang1 > lang2) {
		return 1
	}
	return 0
}

type sortableByCreatedAt = Company | Project | Template | TBox | Question

export const sortByCreationDate = <data extends sortableByCreatedAt>(arr: data[]): data[] => {
	return _.sortBy(arr, "created_at")
}

export const hasPhasesDuplicateNames = (defaultPhases: Phase[], obj: { [key: string]: { id?: number, name: string, duration?: number, selected?: boolean } }): boolean => {
	/**
	 * defaultPhasesNames = Prende i nomi delle fasi dalla lista delle fasi di default e li trasforma in lower case
	 * customPhases = Dalle fasi inserite dall'utente, prendo solo le fasi custom
	 * customPhasesNames = Prendo i nomi delle customPhases, tengo quelli che non sono stringhe vuote e li trasforma in lower case
	 * Conto i nomi in comune tra defaultPhasesNames e customPhasesNames e restituisce true se ce ne sono
	 */
	const defaultPhasesNames = defaultPhases.map(phase => phase.name.toLowerCase())

	const customPhases = _.filter(obj, (phase, phaseId) => parseInt(phaseId) > 8 && Boolean(phase.selected))
	const customPhasesNames = _.map(customPhases, "name").filter(Boolean).map(_.toLower)

	return _.intersection(customPhasesNames, defaultPhasesNames).length > 0
}

export const handleExcelResponseFile = (response: string, filename: string) => {
	const blob = new Blob([response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
	const blobURL = window.URL.createObjectURL(blob)
	const tempLink = document.createElement("a")
	tempLink.style.display = "none"
	tempLink.href = blobURL
	tempLink.download = filename
	tempLink.setAttribute("target", "_blank")
	document.body.appendChild(tempLink)
	tempLink.click()
	document.body.removeChild(tempLink)
	window.URL.revokeObjectURL(blobURL)
}

export const getProductRandomImage = (product_id: number) => {
	switch (product_id % 12) {
		case 0:
			return <ProductCard_0 />
		case 1:
			return <ProductCard_1 />
		case 2:
			return <ProductCard_2 />
		case 3:
			return <ProductCard_3 />
		case 4:
			return <ProductCard_4 />
		case 5:
			return <ProductCard_5 />
		case 6:
			return <ProductCard_6 />
		case 7:
			return <ProductCard_7 />
		case 8:
			return <ProductCard_8 />
		case 9:
			return <ProductCard_9 />
		case 10:
			return <ProductCard_10 />
		case 11:
			return <ProductCard_11 />
	}
}

export const tBoxResponseFormatter = (tbox: TBox): TBox => ({
	...tbox,
	created_at: new Date(tbox.created_at + "Z"),
	updated_at: new Date(tbox.updated_at + "Z"),
	download_sync_at: tbox.download_sync_at && new Date(tbox.download_sync_at + "Z"),
	upload_sync_at: tbox.upload_sync_at && new Date(tbox.upload_sync_at + "Z"),
})

export const singleSessionResponseFormatter = (ss: SingleSession): SingleSession => ({
	...ss,
	created_at: new Date(ss.created_at + "Z"),
	start_at: ss.start_at && new Date(ss.start_at + "Z"),
	completed_at: ss.completed_at && new Date(ss.completed_at + "Z"),
})

// ho volutamente disabilitato lint per questa linea al fine di permettere l'utilizzo di any (poiche voglio poter passare
// a questa funzione qualuque tipo di oggetto). Se qualcuno ha un idea sul come scriverla senza utilizzare any la aggiorni pure, ma non
// dovrebbe causare problemi così com'è
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const areObjectsEqual = (obj1: any, obj2: any): boolean => {
	if (obj1 === obj2) {
		return true
	}

	if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
		return false
	}

	const keys1 = Object.keys(obj1)
	const keys2 = Object.keys(obj2)

	if (keys1.length !== keys2.length) {
		return false
	}

	for (const key of keys1) {
		if (!areObjectsEqual(obj1[key], obj2[key])) {
			return false
		}
	}

	return true
}

export const generateArray = (min: number, max: number): number[] => {
	return _.range(min, max + 1)
}

export const userCanEditProject = (project: Project, userRole: userRole) => {
	if (TBOX_MODE) {
		if (dayjs.utc().isBefore(project.start_datetime)) return false
		if ((5 * project.testers_count) / 100 <= project.max_testers_per_session) {
			if (project.completed_session_number > project.max_testers_per_session) return false
		}
		else {
			if (project.progress > 5) return false
		}
		if (project.completed) return false
	} else {
		if (!["superadmin", "admin"].includes(userRole)) return false
		if (dayjs.utc().isAfter(project.start_datetime)) return false
		if (project.completed) return false
	}
	return true
}