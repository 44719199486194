import { AlternateEmail } from "@mui/icons-material"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import {
	Avatar,
	Box,
	Card,
	CardActions,
	CardContent,
	Grid,
	IconButton,
	styled,
	Tooltip
} from "@mui/material"

import { TitleTypography, UserRoleTypography } from "../../../components/customTypography"
import { User } from "../../../types/genericTypes"
interface propsType {
	user: User,
	onEdit?: () => void,
	onDelete?: () => void,
	isOperator?: boolean
	disableEdit?: boolean
	disableDelete?: boolean
	disableSelection?: boolean
	selected?: boolean,
	handleClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}
//--------------- STYLE --------------------------
const cardStyle = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "flex-start",
	width: 300,
	height: 200,
}
const emailIconButtonStyle = {
	position: "absolute",
	// height: "20px",
	// width: "20px",
	// bottom: "-5px",
	bottom: 0,
	right: 0,
	padding: "3px",
	backgroundColor: "white",
	"&:hover": {
		backgroundColor: "white"
	}
}
export const StyledIconButton = styled(IconButton)(
	{
		padding: 2,
		height: "auto",
		width: "auto"
	}
) as typeof IconButton
//-----------------------------------

const getUserRole = (user: User) => {
	const groups = user.Groups.map(group => group.GroupName)
	if (groups.includes("superadmin")) {
		return "superadmin"
	} else if (groups.includes("admin")) {
		return "admin"
	} else if (groups.includes("reader")) {
		return "reader"
	} else if (groups.includes("operator")) {
		return "operator"
	} return ""
}
const getRoleColor = (role: string) => {
	if (role === "superadmin") {
		return "#FFBAC6"
	} else if (role === "admin") {
		return "#C2C9B4"
	} else if (role === "reader") {
		return "#E0E0E0"
	} else if (role === "operator") {
		return "#FFCBB2"
	}
}

export default function UserCard(props: propsType) {
	const role = getUserRole(props.user)
	const color = getRoleColor(role)
	const coloredStripeStyle = {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		backgroundColor: color,
		width: "inherit",
		height: "inherit",
		padding: "0 8px 0 16px"
	}
	const avatarStyle = {
		height: 80,
		width: 80,
		border: "6px solid",
		borderColor: color,
		backgroundColor: color,
		padding: !props.user.picture ? "24px" : undefined
	}
	const cardSelected = {
		backgroundColor: "#E9E9E9",
		color: "#fff",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "flex-start",
		width: 300,
		height: 200,
		border: "1px solid #484848",
	}

	const userName = props.isOperator ? props.user.Username : props.user.Attributes.find(attribute => attribute.Name === "name")?.Value || "no name"
	const names = userName.split(" ")
	const initials = names.length > 1 ? names[0].charAt(0) + names[1].charAt(0) : names[0].charAt(0) + names[0].charAt(1)
	return (
		<Card
			sx={props.selected ? cardSelected : cardStyle}
			onClick={props.disableSelection ? undefined : props.handleClick}
			style={props.disableSelection ? { cursor: "not-allowed" } : undefined}
		>
			<Grid container height="35px">
				<Box sx={coloredStripeStyle}>
					<UserRoleTypography color="#3F3F3F">
						{role.charAt(0).toUpperCase() + role.slice(1)}
					</UserRoleTypography>
					<CardActions
						sx={{
							justifyContent: "flex-end",
							width: "inherit",
							paddingRight: "15px"
						}}
					>
						{!props.disableEdit &&
							<StyledIconButton onClick={props.onEdit} >
								<EditIcon
									sx={{
										fontSize: "16px",
									}}
								/>
							</StyledIconButton>
						}
						{!props.disableDelete &&
							<StyledIconButton onClick={props.onDelete}>
								<DeleteIcon
									sx={{
										fontSize: "16px",
									}}
								/>
							</StyledIconButton>
						}
					</CardActions>
				</Box>
			</Grid>
			<CardContent
				sx={{
					height: "100%",
					width: "100%"
				}}
			>
				<Grid
					container
					alignItems="center"
					sx={{
						height: "100%",
					}}
				>
					<Grid item container justifyContent="center" xs={5} >
						<Grid
							item
							sx={{
								position: "relative",
								// display: "inline-block"
							}}
						>
							<Avatar
								sx={avatarStyle}
								alt="Profile Picture"
							>
								{initials}
							</Avatar>
							<Tooltip
								title={props.user.Attributes.find(attribute => attribute.Name === "email")?.Value}
								arrow
							>
								<IconButton
									size="small"
									sx={emailIconButtonStyle}
								>
									< AlternateEmail
										fontSize="small"
										sx={{
											color: "#556FFC",
											fontSize: "15px"
										}}
									/>
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
					<Grid item container justifyContent="center" xs={7} >
						<Grid item>

							<TitleTypography color="#3F3F3F" >
								{userName}
							</TitleTypography>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card >
	)
}