import { Grid, Slider } from "@mui/material"
import _ from "lodash"
import { useState } from "react"
import React from "react"
import { Control, Controller } from "react-hook-form"

import { ParagraphTypography, ProjectMenuTitleTypography } from "../../../../../components/customTypography"
import { LanguageButton } from "../../../../../components/LanguageButton"
import { language, Question } from "../../../../../types/genericTypes"
import { NewAnswerFormInput } from "../../TesterSurveyLayout"

interface questionProps {
	question: Question
	language: language
	control?: Control<NewAnswerFormInput> | undefined
	preview?: boolean
}


export const TesterVasScale = (props: questionProps) => {
	const [currentLang, setCurrentLang] = useState<language>(props.language)

	const marksDescription = props.question.questions_i18n.find(questionI18n => questionI18n.language === currentLang) || props.question.questions_i18n[0]

	return (
		<>
			<Grid
				item
				container
				justifyContent="center"
				flexDirection="column"
				rowGap={1}
				padding={2}
			>
				<Grid item>
					{props.question.questions_i18n.filter(question => question.language === currentLang).map((question) =>
						<ProjectMenuTitleTypography textAlign="center" key={question.language}>{question.value}</ProjectMenuTitleTypography>
					)}
				</Grid>
				<Grid item>
					<ParagraphTypography textAlign="center">Slide according to your opinion</ParagraphTypography>
				</Grid>
				{props.preview &&
					<Grid item container gap={1} justifyContent="center">
						{props.question.questions_i18n && props.question.questions_i18n.map(item =>
							<React.Fragment key={item.language}>
								<Grid item>
									<LanguageButton
										language={item.language}
										action={() => setCurrentLang(item.language)}
										isSelected={item.language === currentLang}
									/>
								</Grid>
							</React.Fragment >
						)}
					</Grid>
				}
			</Grid>
			<Grid
				marginX="10%"
				marginBottom={3}
				sx={{
					border: "1px solid #CCCCCC",
					borderStyle: "dashed"
				}}
			/>
			<Grid item container justifyContent="center" marginY={3} padding={10}>
				{(props.question.min_value !== null && props.question.max_value !== null) && (
					props.control ?
						<Controller
							name="vas_scale_response"
							control={props.control}
							rules={{ required: true }}
							render={({ field }) =>
								<Slider
									{...field}
									value={field.value || props.question.min_value || 0}
									onChange={e => field.onChange(parseFloat(_.get(e, ["target", "value"])))}
									valueLabelDisplay="off"
									step={0.1}
									marks={[
										{
											value: props.question.min_value || 0,
											label: marksDescription.min_value_description
										},
										{
											value: props.question.max_value || 10,
											label: marksDescription.max_value_description
										}
									]}
									min={props.question.min_value || undefined}
									max={props.question.max_value || undefined}
									sx={{ width: "10cm" }}
									slotProps={{
										markLabel: { style: { color: "#202020" } }
									}}
								/>
							}
						/>
						:
						<Slider
							value={props.question.min_value || 0}
							valueLabelDisplay="off"
							step={0.1}
							marks={[
								{
									value: props.question.min_value || 0,
									label: marksDescription.min_value_description
								},
								{
									value: props.question.max_value || 10,
									label: marksDescription.max_value_description
								}
							]}
							min={props.question.min_value || undefined}
							max={props.question.max_value || undefined}
							sx={{ width: "10cm" }}
							slotProps={{
								markLabel: { style: { color: "#202020" } }
							}}
						/>
				)}

			</Grid>
		</>
	)
}
