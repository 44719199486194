import {
	closestCenter,
	DndContext,
	DragEndEvent,
	UniqueIdentifier,
} from "@dnd-kit/core"
import { arrayMove, SortableContext } from "@dnd-kit/sortable"
import { Alert, CircularProgress, Grid } from "@mui/material"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { CustomButton } from "../../../../../components/buttons/CustomButton"
import SubmitButton from "../../../../../components/buttons/submitButton"
import CustomDialog from "../../../../../components/CustomDialog"
import { DashboardCardTitleTypography } from "../../../../../components/customTypography"
import { phaseQueries, projectQueries, queryClient } from "../../../../../networking/networking"
import { useSnackbarStore } from "../../../../../stateManagement"
import { File as BEFile, Phase, Product, Project } from "../../../../../types/genericTypes"
import { hasPhasesDuplicateNames } from "../../../../../utils"
import { PhaseRow } from "./PhaseRow"


interface props {
	setFormPhase: React.Dispatch<React.SetStateAction<1 | 2 | 3>>,
	product: Product
	project: Project | undefined
	userCanEdit?: boolean
}

export interface PhasesFormValues {
	[id: string]: {
		duration: number,
		selected: boolean,
		name: string,
		image: File | BEFile | undefined,
		description: string | undefined
	}
}

export const ConfigureProductPhasesForm = (props: props) => {
	const [sortedPhasesIDs, setSortedPhasesIDs] = useState<UniqueIdentifier[]>([])
	const [isModifiedTemplateOrRandomizedProductDialogOpen, setIsModifiedTemplateOrRandomizedProductDialogOpen] = useState(false)
	const [dialogText, setDialogText] = useState("")
	const [formData, setFormData] = useState<PhasesFormValues>()

	const { project_id = "" } = useParams()

	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)

	const { data: phases = [], isFetching: isPhasesFetching } = useQuery(
		phaseQueries.listPhases.name,
		phaseQueries.listPhases.fn,
		{
			select: data => _.orderBy<Phase>(data, "order", "asc"),
			onSuccess: data => {
				setSortedPhasesIDs(data.map(phase => phase.id))
			}
		}
	)

	useEffect(() => {
		// Serve per ordinare le fasi.
		// Usa prima l'ordinamento predefinito dalla tabella delle fasi, poi usa l'ordine personalizzato della fase nel prodotto
		// La logica di questa funzione non è spiegabile agli esseri umani. Sembra funzionare, accontentiamoci
		if (isPhasesFetching) return
		const orderedPhases = Array<Phase | undefined>(phases.length).fill(undefined)
		phases.forEach((phase, index) => {
			const productPhase = props.product.phases.find(p => p.phase_id === phase.id)
			if (!productPhase) {
				orderedPhases[index] = phase
			}
		})
		_.sortBy(props.product.phases, "order").forEach(phase => {
			const firstEmptyPhase = orderedPhases.findIndex(p => p === undefined)
			if (firstEmptyPhase === -1) {
				console.log("errore, non dovrei essere qui")
				return
			}
			orderedPhases[firstEmptyPhase] = {
				id: phase.phase_id,
				name: phase.name || "",
				order: phase.order,
			}
		})
		setSortedPhasesIDs(orderedPhases.map(phase => phase?.id || 0))
	}, [isPhasesFetching, phases, props.product.phases])

	const patchProductPhasesMutation = useMutation(
		projectQueries.patchProductPhases.name,
		projectQueries.patchProductPhases.fn,
		{
			onSuccess: () => {
				props.setFormPhase(3)
				queryClient.invalidateQueries([projectQueries.getProducts.name, parseInt(project_id)])
			},
			onError: () => {
				openSnackbar("error", "An error occurred while updating product phases")
			}
		}
	)

	const {
		control,
		register,
		handleSubmit: handleSubmitPhases,
		watch,
		setValue: setPhaseformValue,
		getValues: getPhaseFormValues
	} = useForm<PhasesFormValues>({
		defaultValues: props.product.phases.reduce(
			(accumulator, currentValue) => {
				return {
					...accumulator,
					[currentValue.phase_id]: {
						duration: currentValue.duration,
						selected: true,
						name: currentValue.name,
						description: currentValue.description,
						image: currentValue.image,
					}
				}
			},
			{}
		)
	})

	const handleModifiedTemplateOrRandomizedProductDialogClose = () => {
		setIsModifiedTemplateOrRandomizedProductDialogOpen(false)
	}
	const onPhasesSubmit: SubmitHandler<PhasesFormValues> = (data) => {
		setFormData(data)
		const validPhases = _.pickBy(
			data,
			(value, key) => {
				if (key !== "9")
					return value.selected && value.duration
				return value.selected && value.duration && value.name && value.name.length > 0
			}
		)
		const selectedSortedPhasesIDs = sortedPhasesIDs.filter(id => Object.keys(validPhases).includes(id.toString()))

		const finalPhases = _.mapValues(
			validPhases,
			(value, key) => ({
				..._.omit(value, "selected"),
				order: selectedSortedPhasesIDs.indexOf(parseInt(key)) + 1,
			})
		)

		if (_.isEqualWith(
			_.sortBy(props.product.phases, "order"),
			_.sortBy(finalPhases, "order"),
			(phase_a, phase_b) => {
				if (_.isArray(phase_a)) return undefined
				const name_a = phase_a.name || undefined
				const name_b = phase_b.name || undefined
				return phase_a.duration === phase_b.duration && phase_a.order === phase_b.order && name_a === name_b && phase_a.description === phase_b.description && phase_a.image === phase_b.image
			}
		)) {
			props.setFormPhase(3)
		} else {
			if (props.project?.template_id) {
				setDialogText("You are trying to modify a template. Changes will be applied to all products in the project.")
				setIsModifiedTemplateOrRandomizedProductDialogOpen(true)
			}
			if (props.product.random_order === true && props.project && props.project?.products_count > 1) {
				setDialogText("You are trying to modify a randomized product. Changes will be applied to all randomized products in the project.")
				setIsModifiedTemplateOrRandomizedProductDialogOpen(true)
			}
			else {
				patchProductPhasesMutation.mutate({
					project_id: parseInt(project_id),
					product_id: props.product.id,
					phases: finalPhases
				})
			}
		}
	}

	const confirmEditTemplateOrRandomizedProduct = (data: PhasesFormValues | undefined) => {

		const validPhases = _.pickBy(
			data,
			(value, key) => {
				if (key !== "9")
					return value.selected && value.duration
				return value.selected && value.duration && value.name && value.name.length > 0
			}
		)
		const selectedSortedPhasesIDs = sortedPhasesIDs.filter(id => Object.keys(validPhases).includes(id.toString()))
		const finalPhases = _.mapValues(
			validPhases,
			(value, key) => ({
				..._.omit(value, "selected"),
				order: selectedSortedPhasesIDs.indexOf(parseInt(key)) + 1
			})
		)
		patchProductPhasesMutation.mutate({
			project_id: parseInt(project_id),
			product_id: props.product.id,
			phases: finalPhases
		})
	}

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event
		if (over) {
			if (active.id !== over.id) {
				setSortedPhasesIDs(ids => {
					const activeIndex = ids.indexOf(active.id)
					const overIndex = ids.indexOf(over.id)
					return arrayMove(ids, activeIndex, overIndex)
				})
			}
		}
	}

	if (isPhasesFetching) {
		return <CircularProgress />
	}

	const values = watch()

	const isSelected = Object.entries(values).filter(([, value]) => value.selected).length

	return (
		<form onSubmit={handleSubmitPhases(onPhasesSubmit)}>
			<Grid
				container
				direction="column"
				spacing={2}
				justifyContent="center"
			>
				<Grid container item xs={12} justifyContent="center" gap={2}>
					<Grid
						item
						container
						xs={12}
						alignItems="center"
						justifyContent={props.project?.tester_mode ? "space-between" : "center"}
					>
						<Grid item xs={4}>
							<DashboardCardTitleTypography>Phases</DashboardCardTitleTypography>
						</Grid>
						<Grid item xs={1}>
							<DashboardCardTitleTypography>Duration</DashboardCardTitleTypography>
						</Grid>
						<Grid item xs={props.project?.tester_mode ? 6 : 1}>
							{props.project?.tester_mode &&
								<DashboardCardTitleTypography>Instruction</DashboardCardTitleTypography>
							}
						</Grid>
					</Grid>
					<DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
						<SortableContext items={sortedPhasesIDs}>
							{_.sortBy(phases, item => sortedPhasesIDs.indexOf(item.id)).map((phase) =>
								<Grid item xs={12} key={phase.id}>
									<PhaseRow
										projectLanguage={props.project?.language}
										userCanEdit={props.userCanEdit}
										control={control}
										getValues={getPhaseFormValues}
										setPhase={setPhaseformValue}
										watch={watch}
										register={register}
										phase={phase}
										templateID={props.project?.template_id}
										testerMode={props.project?.tester_mode}
									/>
								</Grid>
							)}
						</SortableContext>
					</DndContext>
				</Grid>

				{hasPhasesDuplicateNames(phases, values) &&
					<Grid item>
						<Alert severity="error"> This phase exists as a standard phase, please select it from the list</Alert>
					</Grid>
				}

				<Grid
					item
					container
					justifyContent="center"
					spacing={2}
					sx={{
						marginTop: 4,
						marginBottom: 4
					}}
				>
					<Grid item>
						<CustomButton text="Back" action={() => props.setFormPhase(1)} />
					</Grid>
					<Grid item>
						{props.userCanEdit ?
							<SubmitButton text="Next" disabled={isSelected === 0 || hasPhasesDuplicateNames(phases, values)} />
							:
							<CustomButton text="Next" primary action={() => { props.setFormPhase(3) }} />
						}
					</Grid>
				</Grid>
			</Grid>

			{/* MODIFIED TEMPLATE DIALOG */}
			<CustomDialog
				open={isModifiedTemplateOrRandomizedProductDialogOpen}
				onClose={() => { handleModifiedTemplateOrRandomizedProductDialogClose() }}
				title="Attention!"
			>
				<Grid container justifyContent="center">
					<Grid item sx={{ marginBottom: 5 }}>
						<DashboardCardTitleTypography>{dialogText}</DashboardCardTitleTypography>
					</Grid>

					<Grid item container justifyContent="flex-end" gap={2}>
						<Grid item>
							<CustomButton text="Cancel" primary action={() => { handleModifiedTemplateOrRandomizedProductDialogClose() }} />
						</Grid>
						<Grid item>
							<CustomButton text="Next" action={() => { confirmEditTemplateOrRandomizedProduct(formData) }} />
						</Grid>
					</Grid>
				</Grid>
			</CustomDialog>
		</form>
	)
}
