import { Grid, TextField } from "@mui/material"
import React, { useState } from "react"

import { CustomButton } from "../../../components/buttons/CustomButton"
import { TitleTypography } from "../../../components/customTypography"

interface props {
	notes: string | undefined
}

type ConditionalProps =
	{
		pdfMode: true,
		onSaveNotes?: never
	}
	| {
		onSaveNotes: (notes: string) => void
		pdfMode?: never
	};

export const AdditionalNotes = (props: props & ConditionalProps) => {
	const [notes, setNotes] = useState(props.notes || "")

	const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setNotes(e.target.value)
	}

	const handleSaveNotes = () => {
		if (!props.pdfMode) {
			props.onSaveNotes(notes)
		}
	}

	return (
		<Grid
			container
			sx={{
				padding: 3,
				paddingTop: 0
			}}
		>
			<Grid item xs={12} sx={{ marginBottom: 2 }}>
				<TitleTypography>Insights</TitleTypography>
			</Grid>
			<Grid item container>
				<TextField
					sx={{ minWidth: "100%" }}
					id="filled-multiline-static"
					multiline
					rows={12}
					value={notes}
					placeholder="Write any notes here (thoughts, suggestions, observations, etc.)"
					variant="filled"
					onChange={(e) => { handleNotesChange(e) }}
				/>
			</Grid>
			{!props.pdfMode &&
				<Grid container justifyContent="flex-end" sx={{ marginTop: 2 }}>
					<Grid item>
						<CustomButton text="Save" action={handleSaveNotes} primary />
					</Grid>
				</Grid>
			}
		</Grid>
	)
}
