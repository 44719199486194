import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import { Chip, CircularProgress, Grid } from "@mui/material"
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid"
import { DataGrid } from "@mui/x-data-grid/DataGrid"
import dayjs from "dayjs"
import _ from "lodash"
import { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

import { CustomButton } from "../../../components/buttons/CustomButton"
import CustomDataGridToolbar from "../../../components/CustomDataGridToolbar"
import { ParagraphTypography } from "../../../components/customTypography"
import { NewTesterForm } from "../../../components/NewTesterForm"
import { StyledModal } from "../../../components/StyledModal"
import { Toolbar, ToolbarConfig } from "../../../components/Toolbar"
import { projectQueries } from "../../../networking/networking"
import { useSnackbarStore } from "../../../stateManagement"
import { Tester } from "../../../types/genericTypes"


export const TestersSelection = () => {
	const { project_id = "" } = useParams()
	const navigate = useNavigate()
	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)
	const [isNewTesterModalOpen, setIsNewTesterModalOpen] = useState(false)
	const [isEndModalProjectOpen, setIsEndProjectModalOpen] = useState(false)

	const { data: project, isFetching: isProjectFetching } = useQuery(
		[projectQueries.getProject.name, parseInt(project_id)],
		() => projectQueries.getProject.fn(parseInt(project_id)),
	)
	const toolbarConfig: ToolbarConfig = {
		title: `Project ${project?.name}`,
		subtitle: "Data Charts",
		buttons: [{
			name: "New Tester",
			icon: <AddOutlinedIcon />,
			action: () => setIsNewTesterModalOpen(true)
		}]
	}
	const [selectedTestersId, setSelectedTestersId] = useState<GridRowSelectionModel>([])

	const columns: GridColDef[] = [
		{
			field: "local_tester_id",
			headerName: "Tester ID",
			width: 120,
			hideable: false,
		},
		{
			field: "first_name",
			headerName: "Name",
			width: 120,
		},
		{
			field: "date_of_birth",
			headerName: "Age",
			width: 120,
			type: "number",
			align: "left",
			headerAlign: "left",
			valueGetter: (params) => {
				return getAge(params.value.toString())
			}
		},
		{
			field: "country_external_format",
			headerName: "Country",
			width: 120,
		},
		{
			field: "gender_external_format",
			headerName: "Gender",
			width: 180,
		},
		{
			field: "diet_external_format",
			headerName: "Diet",
			width: 120,
		},
		{
			field: "smoker_external_format",
			headerName: "Smoker",
			width: 120,
		},
		{
			field: "group",
			headerName: "Group",
			width: 120,
		},
	]

	const getAge = (date: string) => {
		return dayjs.utc().diff(dayjs.utc(date), "y")
	}


	const { data: projectTesters = [], refetch: refetchProjectTesters, isFetching: isProjectTestersFetching } = useQuery(
		[projectQueries.listProjectTesters.name, parseInt(project_id)],
		() => projectQueries.listProjectTesters.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id)
		}
	)

	const { data: running_single_sessions = [], refetch: refetchRunningSingleSessions } = useQuery(
		[projectQueries.listSessions.name, parseInt(project_id), true],
		() => projectQueries.listSessions.fn(parseInt(project_id), true),
		{
			enabled: Boolean(project_id),
		}
	)

	const onTerminateActiveSession = useMutation(
		projectQueries.terminateSession.name,
		projectQueries.terminateSession.fn,
	)

	const handleTerminateActiveSession = () => {
		Promise.all(running_single_sessions.map(running_single_session => onTerminateActiveSession.mutateAsync({
			project_id: running_single_session.project_id,
			session_id: running_single_session.id
		})))
			.then(() => refetchRunningSingleSessions())
	}

	const setProjectCompletedMutation = useMutation(
		[projectQueries.setCompleted.name, parseInt(project_id)],
		projectQueries.setCompleted.fn,
		{
			onSuccess: () => {
				navigate("/")
			},
			onError: error => {
				console.error("Error setting project as completed", error)
				openSnackbar("error", "Error setting project as completed")
			}
		}
	)

	const onTesterCreateSuccess = (tester: Tester) => {
		setSelectedTestersId(ts => [...ts, tester.global_id])
		refetchProjectTesters()
	}

	if (isProjectFetching || !project || isProjectTestersFetching) {
		return <Grid container justifyContent="center"><CircularProgress /></Grid>
	}

	return (
		<>
			<Grid container padding={3}>
				<Toolbar config={toolbarConfig} />
				<Grid item container rowGap={2}>
					{projectTesters.length ?
						<>
							<Grid
								item
								container
								xs={12}
							>
								<DataGrid
									slots={{
										toolbar: CustomDataGridToolbar,
									}}
									style={{
										height: "460px",
										width: "inherit",
										backgroundColor: "white"
									}}
									sx={{
										"& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
											display: "none"
										}
									}}
									rows={projectTesters.filter(projectTester => !projectTester.has_session).map(projectTester => ({
										..._.omit(projectTester, "tester"),
										...projectTester.tester
									}))}
									columns={columns}
									checkboxSelection
									disableRowSelectionOnClick
									getRowId={(tester) => tester.local_tester_id}
									isRowSelectable={({ id }) => selectedTestersId.includes(id) || selectedTestersId.length < project.max_testers_per_session}
									onRowSelectionModelChange={(selectedTesterIDs) => setSelectedTestersId(selectedTesterIDs)}
								/>
							</Grid>
						</>
						: <ParagraphTypography>There are no testers selected for this project.</ParagraphTypography>}
					{project &&
						<Grid container marginTop={4} spacing={2} justifyContent="flex-end">
							<Grid item>
								<CustomButton
									text="End Project"
									style={{
										backgroundColor: "#FF9595",
										"&:hover": {
											backgroundColor: "#f55d42"
										}
									}}
									action={
										() => setIsEndProjectModalOpen(true)
										// () => setProjectCompletedMutation.mutate(parseInt(project_id))
									}
								/>
							</Grid>
							<Grid item>
								<CustomButton
									text="Session Setup"
									action={() => navigate(`/projects/${project_id}/testersConnection`,
										{
											state: projectTesters.filter(projectTester => selectedTestersId.includes(projectTester.local_tester_id))
										}
									)}
									style={{
										backgroundColor: "black",
										color: "white"
									}}
									disabled={selectedTestersId.length === 0}
								/>
							</Grid>
						</Grid>
					}
				</Grid>
			</Grid>
			<NewTesterForm
				open={Boolean(project) && isNewTesterModalOpen}
				onClose={() => setIsNewTesterModalOpen(false)}
				company_id={project.company_id}
				onTesterCreateSuccess={onTesterCreateSuccess}
				project_id={parseInt(project_id)}
			/>
			<StyledModal
				isOpen={running_single_sessions.length > 0}
				title="Active sessions must be closed before proceeding"
			>
				<CustomButton primary text="Ok" action={handleTerminateActiveSession} />
			</StyledModal>
			<StyledModal
				isOpen={isEndModalProjectOpen}
				handleClose={() => { setIsEndProjectModalOpen(false) }}
				title="Do you want to end this project?"
				subtitle="Warning: This action cannot be undone!"
				rightButton={
					{
						name: "Yes, I'm sure!",
						action: () => setProjectCompletedMutation.mutate(parseInt(project_id))
					}
				}
				leftButton={
					{
						name: "Cancel",
						action: () => setIsEndProjectModalOpen(false)
					}
				}
			>
				{projectTesters.filter(pt => !pt.has_session).length > 0 &&
					<>
						<Grid container item justifyContent="center" alignItems="center" marginTop={2}>
							<ParagraphTypography>These users have not completed the session</ParagraphTypography>
						</Grid>

						<Grid item container flexDirection="row" justifyContent="center" marginBottom={3}>
							{projectTesters.filter(pt => !pt.has_session).map(tester => {
								return (
									<Grid item margin={1} key={tester.local_tester_id}>
										<Chip
											label={`User ${tester.local_tester_id}`}
											sx={{
												background: "#FFD9C0",
												borderRadius: "8px"
											}}
										/>
									</Grid>
								)
							})}
						</Grid>
					</>
				}
			</StyledModal>
		</>
	)
}