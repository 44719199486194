import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
/*import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined"
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined"*/
import { CircularProgress, Grid, MenuItem, Select, styled, TextField } from "@mui/material"
import React from "react"
import { useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"

import SubmitButton from "../../components/buttons/submitButton"
import { ParagraphTypography, ProjectMenuTitleTypography } from "../../components/customTypography"
import { StyledModal } from "../../components/StyledModal"
import TBoxCard from "../../components/TBoxCard"
import {Toolbar, ToolbarConfig} from "../../components/Toolbar"
import { companyQueries, tboxQueries } from "../../networking/networking"
import { useSnackbarStore, useUserStore } from "../../stateManagement"
import { TBox } from "../../types/genericTypes"
import { companiesSorter, sortByCreationDate, tboxesSorter } from "../../utils"

interface TBoxFormInput {
	id: number,
	max_concurrent_testers?: number,
	company_id: number | "",
	name: string
}

interface ModalState {
	open: boolean,
	tbox?: TBox,
}

const StyledTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "c6c6c6",
	},
	"& .MuiOutlinedInput-root": {
		backgroundColor: "#F6F6F6",
		"& fieldset": {
			borderColor: "#E1E1E1",
		},
		"&:hover fieldset": {
			borderColor: "#E1E1E1",
		},
	}
}) as typeof TextField

export const TBoxes = () => {
	const [isNewTboxModalOpen, setIsNewTboxModalOpen] = useState(false)
	const [isEditActionModalOpen, setIsEditActionModalOpen] = useState<ModalState>({ open: false })
	const [isDeleteActionModalOpen, setIsDeleteActionModalOpen] = useState<ModalState>({ open: false })
	const { openSnackbar } = useSnackbarStore((state) => ({
		openSnackbar: state.openSnackbar
	}))
	const userRole = useUserStore((state) => state.userRole)
	const { data: companies = [], isFetching: isCompaniesFetching } = useQuery(
		companyQueries.listCompanies.name,
		companyQueries.listCompanies.fn,
		{
			select: data => data.sort(companiesSorter)
		}
	)

	const { data: TBoxes = [], isFetching: isTBoxesFetching, refetch: refetchTBoxes } = useQuery(
		tboxQueries.listTBoxes.name,
		tboxQueries.listTBoxes.fn,
		{
			select: data => data.sort(tboxesSorter)
		}
	)
	const { register: registerNewTbox, handleSubmit: handleSubmitNewTbox, reset: resetNewTbox, control: tboxControl } = useForm<TBoxFormInput>({
		defaultValues: {
			name: "",
			company_id: "",
			max_concurrent_testers: undefined
		}
	})
	const { register: registerEditTbox, handleSubmit: handleSubmitEditTbox, reset: resetEditTBox, control: editTBoxControl, formState: { isDirty: isEditTboxFormDirty } } = useForm<TBoxFormInput>({
		defaultValues: {
			name: "",
			company_id: "",
			max_concurrent_testers: undefined
		}
	})
	const createTBoxMutation = useMutation(
		tboxQueries.createTBox.name,
		tboxQueries.createTBox.fn,
		{
			onSuccess: () => {
				refetchTBoxes()
				setIsNewTboxModalOpen(false)
				resetNewTbox()
				openSnackbar("success", "Tbox successfully created")
			},
			onError: () => {
				openSnackbar("error", "An error occurred while creating the TBox")
			}
		}
	)

	const onNewTboxSubmit: SubmitHandler<TBoxFormInput> = data => {
		createTBoxMutation.mutate({
			name: data.name,
			max_concurrent_testers: data.max_concurrent_testers,
			company_id: data.company_id || undefined
		})
	}
	const updateTboxMutation = useMutation(
		tboxQueries.updateTBox.name,
		tboxQueries.updateTBox.fn,
		{
			onSuccess: () => {
				refetchTBoxes()
				setIsEditActionModalOpen({ open: false })
				resetEditTBox()
				openSnackbar("success", "Tbox successfully edited")
			},
			onError: () => {
				openSnackbar("error", "An error occurred while editing the TBox")
			}
		}
	)
	const onEditTboxSubmit: SubmitHandler<TBoxFormInput> = data => {
		updateTboxMutation.mutate(
			{
				id: data.id,
				tbox: {
					name: data.name,
					max_concurrent_testers: data.max_concurrent_testers,
					company_id: data.company_id || undefined
				}
			}
		)
	}

	//----- configuration for toolbar -------
	const toolbarConfig: ToolbarConfig = {
		title: "TBOX",
		subtitle: "Manage the TBOX units from here",
		buttons: [{
			name: "New TBOX",
			icon: <AddOutlinedIcon />,
			action: () => setIsNewTboxModalOpen(true),
			hide: userRole !== "superadmin",
		}
		/*{
			name: "Sort by",
			icon: <KeyboardArrowDownOutlinedIcon />,
			action: () => console.log("button3")
		},
		{
			name: "Filters",
			icon: <FilterAltOutlinedIcon />,
			action: () => console.log("button2")
		}*/]
	}

	const handleNewTboxModalClose = () => {
		// setSelectedCompany("")
		setIsNewTboxModalOpen(false)
	}
	const deleteTBoxMutation = useMutation(
		tboxQueries.deleteTBox.name,
		tboxQueries.deleteTBox.fn,
		{
			onSuccess: () => {
				refetchTBoxes()
				setIsDeleteActionModalOpen({ open: false })
				openSnackbar("success", "Tbox successfully deleted")
			},
			onError: () => {
				openSnackbar("error", "An error occurred while editing the TBox")
			}
		}
	)
	const handleDeleteTBox = () => {
		if (isDeleteActionModalOpen.tbox) {
			deleteTBoxMutation.mutate(isDeleteActionModalOpen.tbox.id)
			console.log("deleted")
			setIsDeleteActionModalOpen({ open: false })
		}
	}
	const handleEditActionModalClose = () => {
		console.log("edited")
		setIsEditActionModalOpen({ open: false })
	}

	const unassignedTBoxesFilter = (Tbox: TBox) => {
		if (Tbox.company_id === null)
			return true
		else false
	}
	const unassignedTBoxes = TBoxes.filter(unassignedTBoxesFilter)

	const assignedTBoxesFilter = (Tbox: TBox) => {
		if (Tbox.company_id !== null)
			return true
		else false
	}
	const assignedTBoxes = TBoxes.filter(assignedTBoxesFilter)
	const assignedTBoxesWithGroup = (company_id: number) => (tbox: TBox) => {
		return tbox.company_id === company_id
	}
	return (
		<Grid container padding={3}>
			<Toolbar config={toolbarConfig} />
			{(isTBoxesFetching || isCompaniesFetching) ? <Grid container justifyContent="center"><CircularProgress /></Grid>
				: (
					<>
						{/* UNASSIGNED TBOXES */}
						<Grid container justifyContent="flex-start" sx={{ gap: 2 }}>
							<Grid item xs={12}>
								<ProjectMenuTitleTypography>Unassigned TBoxes</ProjectMenuTitleTypography>
							</Grid>
							{unassignedTBoxes.sort(tboxesSorter).map((tbox) =>
								<TBoxCard
									key={tbox.id}
									tbox={tbox}
									onEdit={() => {
										setIsEditActionModalOpen({ open: true })
										resetEditTBox({
											name: tbox.name,
											company_id: tbox.company_id || "",
											id: tbox.id,
											max_concurrent_testers: tbox.max_concurrent_testers
										})
										console.log(String(tbox.company_id))
									}}
									onDelete={() => {
										setIsDeleteActionModalOpen({
											open: true,
											tbox
										})
									}}
									disableEdit={userRole !== "superadmin"}
									disableDelete={userRole !== "superadmin"}
								/>
							)}
							{unassignedTBoxes.length === 0 && (
								<Grid item>
									<ParagraphTypography color="text.disabled">There are no TBoxes at the moment yet</ParagraphTypography>
								</Grid>
							)}
						</Grid>


						{/* ASSIGNED TBOXES */}
						<Grid container justifyContent="flex-start" sx={{ gap: 2 }}>
							{companies.map((company) => (
								<React.Fragment key={company.id}>
									<Grid
										item
										key={company.id}
										xs={12}
										sx={{
											marginTop: "24px",
										}}
									>
										<ProjectMenuTitleTypography>{company.name}</ProjectMenuTitleTypography>
									</Grid>
									{sortByCreationDate(assignedTBoxes).filter(assignedTBoxesWithGroup(company.id)).sort(tboxesSorter).map((tbox) => (
										<Grid item key={tbox.id}>
											<TBoxCard
												key={tbox.id}
												tbox={tbox}
												onEdit={() => {
													setIsEditActionModalOpen({ open: true })
													resetEditTBox({
														name: tbox.name,
														company_id: tbox.company_id || undefined,
														id: tbox.id,
														max_concurrent_testers: tbox.max_concurrent_testers
													})
													console.log(String(tbox.company_id))
												}}
												onDelete={() => {
													setIsDeleteActionModalOpen({
														open: true,
														tbox
													})
												}}
												disableEdit={userRole !== "superadmin"}
												disableDelete={userRole !== "superadmin"}
											/>
										</Grid>
									))}
									{sortByCreationDate(assignedTBoxes).filter(assignedTBoxesWithGroup(company.id)).length === 0 && (
										<Grid item>
											<ParagraphTypography color="text.disabled">There are no TBoxes at the moment yet</ParagraphTypography>
										</Grid>
									)}
								</React.Fragment>
							))}
						</Grid>
					</>
				)
			}
			<StyledModal
				isOpen={isNewTboxModalOpen}
				handleClose={() => { handleNewTboxModalClose() }}
				title="New Tbox"
				subtitle="Fields with * are required"
			>
				<form onSubmit={handleSubmitNewTbox(onNewTboxSubmit)}>
					<Grid container justifyContent="center" gap={3} marginBottom={4} paddingX={5}>
						{/* Name textfield */}
						<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
							<Grid item xs={3}>
								<ParagraphTypography>Name*</ParagraphTypography>
							</Grid>
							<Grid item xs={9}>
								<StyledTextField
									fullWidth
									{...registerNewTbox("name")}
									required={true}
									placeholder="Write the name of the Tbox here"
								/>
							</Grid>
						</Grid>
						{/* Company Select */}
						<Grid item container spacing={3} justifyContent="flex-start" alignItems="center">
							<Grid item xs={3}>
								<ParagraphTypography>Company</ParagraphTypography>
							</Grid>
							<Grid item xs={9}>
								<Controller
									name="company_id"
									control={tboxControl}
									render={({ field }) => (
										<Select
											sx={{
												borderRadius: "3px",
												backgroundColor: "#f1f1f1",
											}}
											displayEmpty
											fullWidth
											{...field}
										>
											<MenuItem value=""><em>Unassigned</em></MenuItem>
											{companies.map((listItem) =>
												<MenuItem key={listItem.id} value={listItem.id}>{listItem.name}</MenuItem>
											)}
										</Select>
									)}
								/>
							</Grid>
						</Grid>
						{/* Max Concurrent Testers */}
						<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
							<Grid item xs={3}>
								<ParagraphTypography>Max EEGs Supported</ParagraphTypography>
							</Grid>
							<Grid item xs={9}>
								<StyledTextField
									fullWidth
									type="number"
									{...registerNewTbox("max_concurrent_testers", { valueAsNumber: true })}
									required={true}
									inputProps={{
										min: 1,
										max: 5
									}}
								/>
							</Grid>
						</Grid>
						<Grid item container justifyContent="center">
							<Grid item>
								<SubmitButton text="Create" />
							</Grid>
						</Grid>
					</Grid>
				</form>
			</StyledModal>
			{/* DeleteAction Dialog */}
			<StyledModal
				isOpen={isDeleteActionModalOpen.open}
				handleClose={() => { setIsDeleteActionModalOpen({ open: false }) }}
				title="Do you want to delete this TBox?"
				subtitle="Warning: This action cannot be undone!"
				rightButton={
					{
						name: "Yes, I'm sure!",
						action: () => handleDeleteTBox()
					}
				}
				leftButton={
					{
						name: "Cancel",
						action: () => setIsDeleteActionModalOpen({ open: false })
					}
				}
			/>
			{/* EditAction Dialog */}
			<StyledModal
				isOpen={isEditActionModalOpen.open}
				handleClose={() => { handleEditActionModalClose() }}
				title="Edit TBox"
			>
				<form onSubmit={handleSubmitEditTbox(onEditTboxSubmit)}>
					<Grid container justifyContent="center" gap={3} marginBottom={4} paddingX={5}>
						{/* Name textfield */}
						<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
							<Grid item xs={3}>
								<ParagraphTypography>Name*</ParagraphTypography>
							</Grid>
							<Grid item xs={9}>
								<StyledTextField
									fullWidth
									{...registerEditTbox("name")}
									required={true}
									placeholder="Write the name of the Tbox here"
								/>
							</Grid>
						</Grid>
						{/* Company Select */}
						<Grid item container spacing={3} justifyContent="flex-start" alignItems="center" >
							<Grid item xs={3}>
								<ParagraphTypography>Company</ParagraphTypography>
							</Grid>
							<Grid item xs={9}>
								<Controller
									name="company_id"
									control={editTBoxControl}
									render={({ field }) => (
										<Select
											sx={{
												borderRadius: "3px",
												backgroundColor: "#f1f1f1",
											}}
											displayEmpty
											fullWidth
											{...field}
										>
											<MenuItem value=""><em>Unassigned</em></MenuItem>
											{companies.map((listItem) =>
												<MenuItem key={listItem.id} value={listItem.id}>{listItem.name}</MenuItem>
											)}
										</Select>
									)}
								/>
							</Grid>
						</Grid>
						{/* Max Concurrent Testers */}
						<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
							<Grid item xs={3}>
								<ParagraphTypography>Max EEGs Supported</ParagraphTypography>
							</Grid>
							<Grid item xs={9}>
								<StyledTextField
									fullWidth
									type="number"
									{...registerEditTbox("max_concurrent_testers", { valueAsNumber: true })}
									required={true}
									inputProps={{
										min: 1,
										max: 5
									}}
								/>
							</Grid>
						</Grid>
						<Grid item container justifyContent="center">
							<Grid item>
								<SubmitButton text="Save" disabled={!isEditTboxFormDirty} />
							</Grid>
						</Grid>
					</Grid>
				</form>
			</StyledModal>
		</Grid >
	)
}
