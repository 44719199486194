import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import { Checkbox, Grid } from "@mui/material"
import { Control, Controller, UseFormRegister, useWatch } from "react-hook-form"

import { ParagraphTypography } from "../../../components/customTypography"
import { Question } from "../../../types/genericTypes"
import { LanguagesButtons } from "../../projects/configure/products/configureProduct/LanguagesButtons"
import { NewTemplateFormInput } from "../Templates"


interface props {
	question: Question,
	control: Control<NewTemplateFormInput>,
	register: UseFormRegister<NewTemplateFormInput>
}

const dragIconStyle = {
	color: "text.secondary",
	cursor: "grab",
	"&:active": {
		cursor: "grabbing",
	},
	"&:focus": {
		outline: "none"
	}
}

export const TemplateQuestionRow = (props: props) => {
	const isSelected = useWatch({
		control: props.control,
		name: `questions.${props.question.id}.selected`
	})
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: props.question.id,
		disabled: !isSelected,
	})
	const draggableStyle = {
		transform: CSS.Transform.toString(transform),
		transition,
	}
	const questionI18n = props.question.questions_i18n.find(i18n => i18n.language === "en") || props.question.questions_i18n[0]

	return (
		<Grid ref={setNodeRef} style={draggableStyle} container alignItems="baseline" padding={2}>
			<Grid item marginX={1}>
				<DragIndicatorIcon
					sx={dragIconStyle}
					{...attributes}
					{...listeners}
				/>
			</Grid>
			<Grid item container xs direction="column" alignItems="center" spacing={1}>
				<Grid item container alignItems="center">
					<Controller
						control={props.control}
						name={`questions.${props.question.id}.selected`}
						render={({ field}) => <Checkbox {...field} checked={!!field.value} />}
					/>

					<input type="hidden" {...props.register(`questions.${props.question.id}.id`, { value: props.question.id })} />

					<ParagraphTypography sx={{ marginLeft: 2 }}>
						{questionI18n.value}
					</ParagraphTypography>
				</Grid>
				<Grid item container spacing={1}>
					{props.question.questions_i18n.map((i18n) =>
						<Grid key={i18n.language} item>
							<LanguagesButtons language={i18n.language} />
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid >
	)
}
