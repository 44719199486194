import { AddOutlined as AddOutlinedIcon, Visibility } from "@mui/icons-material"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import {
	Checkbox,
	Divider,
	FormControlLabel,
	Grid,
	MenuItem,
	Select,
	styled,
	TextField,
} from "@mui/material"
import { DataGrid, GridActionsCellItem, GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { AxiosError } from "axios"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"

import { CustomButton } from "../../components/buttons/CustomButton"
import SubmitButton from "../../components/buttons/submitButton"
import CustomDataGridToolbar from "../../components/CustomDataGridToolbar"
import CustomDialog from "../../components/CustomDialog"
import {
	ParagraphTypography,
} from "../../components/customTypography"
import { LanguageButton } from "../../components/LanguageButton"
import { Toolbar, ToolbarConfig } from "../../components/Toolbar"
import { companyQueries, questionsQueries } from "../../networking/networking"
import { useSnackbarStore } from "../../stateManagement"
import { evaluation_grid_option, language, PossibleAnswer, QuestionI18n, translation } from "../../types/genericTypes"
import { companiesSorter, sortByCreationDate } from "../../utils"
import { TesterEvaluationGrid } from "../testerView/testerSurvey/questionary/questionType/TesterEvaluationGrid"
import { TesterMultiAnswer } from "../testerView/testerSurvey/questionary/questionType/TesterMultiAnswer"
import { TesterRatingIndex } from "../testerView/testerSurvey/questionary/questionType/TesterRatingIndex"
import { TesterSingleAnswer } from "../testerView/testerSurvey/questionary/questionType/TesterSingleAnswer"
import { TesterVasScale } from "../testerView/testerSurvey/questionary/questionType/TesterVasScale"
import { NewAnswerFormInput } from "../testerView/testerSurvey/TesterSurveyLayout"
import { EvaluationGrid } from "./components/EvaluationGrid"
import { Language } from "./components/Language"
import { MultipleAnswer } from "./components/MultipleAnswer"
import { RatingIndex } from "./components/RatingIndex"
import { SingleAnswer } from "./components/SingleAnswer"
import { VASScale } from "./components/VASScale"
interface QuestionTypes {
	id: number;
	name: string;
}

const questionsTypologies: QuestionTypes[] = [
	{
		id: 1,
		name: "Single Answer",
	},
	{
		id: 2,
		name: "Multiple Answers",
	},
	{
		id: 3,
		name: "Rating Index",
	},
	{
		id: 4,
		name: "Vas Scale",
	},
	{
		id: 5,
		name: "Evaluation Grid",
	},
]
export interface NewQuestionFormInput {
	label: string
	dataQuestion: {
		label: string,
		type: string,
		horizontal: boolean | null,
		company_id?: number | "",
		min_value?: number,
		max_value?: number,
		translations: translation[],
		possible_answers: PossibleAnswer[],
		evaluation_grid_options?: evaluation_grid_option
	}
}
interface deleteQuestionModal {
	isOpen: boolean,
	questionId?: number
}


interface configurationModal {
	isOpen: boolean,
	selectedQuestion?: number
}

// ------- style ---------
const StyledTextField = styled(TextField)({
	height: 50,
	width: 275,
	"& label.Mui-focused": {
		color: "c6c6c6",
	},
	"& .MuiOutlinedInput-root": {
		backgroundColor: "#F6F6F6",
		"& fieldset": {
			borderColor: "#E1E1E1",
		},
		"&:hover fieldset": {
			borderColor: "#E1E1E1",
		},
	},
}) as typeof TextField

// -----------------------

const optionValueFormatter = (value: string) => value
	.toLowerCase()
	.replaceAll("’", "")
	.replaceAll("'", "")
	.replaceAll("\"", "")
	.replaceAll(".", "")
	.split(" ")
	.join("_")


export const Questions = () => {
	const [isNewQuestionModalOpen, setIsNewQuestionModalOpen] = useState<configurationModal>({ isOpen: false })
	const [isDeleteActionModalOpen, setIsDeleteActionModalOpen] = useState<deleteQuestionModal>({ isOpen: false })
	const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<configurationModal>({ isOpen: false })

	const [currentLang, setCurrentLang] = useState<language>("en")
	const { openSnackbar } = useSnackbarStore((state) => ({
		openSnackbar: state.openSnackbar
	}))

	const { data: questions = [], refetch: refetchQuestions } = useQuery(
		questionsQueries.listQuestions.name,
		questionsQueries.listQuestions.fn,
	)

	const { data: companies = [] } = useQuery(
		companyQueries.listCompanies.name,
		companyQueries.listCompanies.fn,
		{
			select: data => (data.sort(companiesSorter))
		}
	)

	const { register: registerNewQuestion, handleSubmit, control, watch, getValues, setValue: setQuestionValue, reset: resetQuestion } = useForm<NewQuestionFormInput>({
		defaultValues: {
			dataQuestion: {
				label: "",
				type: "",
				company_id: "",
				horizontal: false,
				min_value: undefined,
				max_value: undefined,
				translations: [{
					value: "",
					language: "en"
				}],
				possible_answers: [],
				evaluation_grid_options: {}
				// Il default deve essere un oggetto vuoto, altrimenti react-hook-form pensa che sia un array sparso.
				// Ci pensa onQuestionSubmit a cancellare la chiave se non serve per il tipo di domanda
			},
		}
	})

	const { fields: fieldsPossibleAnswer, append: appendPossibleAnswer, remove: removePossibleAnswers } = useFieldArray({
		control,
		name: "dataQuestion.possible_answers",
	})

	const { append: appendTranslation } = useFieldArray({
		control,
		name: "dataQuestion.translations"
	})

	const watchQuestionType = watch("dataQuestion.type")
	const fieldsTranslations = watch("dataQuestion.translations")
	const getValueQuestionType = getValues("dataQuestion.type")

	const createQuestionsMutation = useMutation(
		questionsQueries.createQuestion.name,
		questionsQueries.createQuestion.fn,
		{
			onSuccess: () => {
				handleModalClose()
				refetchQuestions()
				openSnackbar("success", "Question successfully created")
				removePossibleAnswers()
			},
			onError: (error: AxiosError<{ message: string }>) => {
				if (error.response?.status === 400) {
					openSnackbar("error", error.response?.data.message)
				} else {
					openSnackbar("error", "An error occurred while creating the question")
				}
			}
		}
	)

	const updateQuestionMutation = useMutation(
		questionsQueries.updateQuestion.name,
		questionsQueries.updateQuestion.fn,
		{
			onSuccess: () => {
				handleModalClose()
				refetchQuestions()
				openSnackbar("success", "Question successfully created")
				removePossibleAnswers()
			},
			onError: (error: AxiosError<{ message: string }>) => {
				if (error.response?.status === 400) {
					openSnackbar("error", error.response?.data.message)
				} else {
					openSnackbar("error", "An error occurred while editing the question")
				}
			}
		}
	)

	const deleteQuestionsMutation = useMutation(
		questionsQueries.deleteQuestion.name,
		questionsQueries.deleteQuestion.fn,
		{
			onSuccess: () => {
				setIsDeleteActionModalOpen({ isOpen: false })
				refetchQuestions()
				openSnackbar("success", "Question successfully deleted")
			},

			onError: (error) => {
				const message: string | undefined = _.get(error, "response.data")
				setIsDeleteActionModalOpen({ isOpen: false })
				if (message === "The selected question has already been assigned to a product") {
					openSnackbar("warning", message)
				} else {
					openSnackbar("error", "An error occurred while deleting the Question")
				}
			}
		}
	)

	const onQuestionSubmit: SubmitHandler<NewQuestionFormInput> = (data) => {

		const company_id = data.dataQuestion.company_id === "" ? undefined : data.dataQuestion.company_id
		if (isNewQuestionModalOpen.selectedQuestion) {
			if (data.dataQuestion.type !== "evaluation_grid") {
				data.dataQuestion.evaluation_grid_options = undefined
			}
			const body = {
				...data.dataQuestion,
				company_id: company_id,
			}
			updateQuestionMutation.mutate({
				id: isNewQuestionModalOpen.selectedQuestion,
				question: body
			})
		} else {
			createQuestionsMutation.mutate({
				...data.dataQuestion,
				company_id: company_id
			})
		}
	}

	const columns: GridColDef[] = [
		{
			field: "Action",
			width: 115,
			type: "actions",
			getActions: (params) => [
				<GridActionsCellItem
					key="preview"
					icon={<Visibility sx={{ fontSize: 16 }} />}
					label="Preview"
					onClick={() => setIsPreviewModalOpen({
						isOpen: true,
						selectedQuestion: params.row.id
					})}
				/>,
				<GridActionsCellItem
					key="edit"
					icon={<EditIcon sx={{ fontSize: 16 }} />}
					label="Edit"
					onClick={() => setIsNewQuestionModalOpen({
						isOpen: true,
						selectedQuestion: params.row.id
					})}
				/>,
				<GridActionsCellItem
					key="edit"
					icon={<DeleteIcon sx={{ fontSize: 16 }} />}
					label="Edit"
					onClick={() => setIsDeleteActionModalOpen({
						isOpen: true,
						questionId: params.row.id
					})}
				/>,
			],
		},
		{
			field: "label",
			headerName: "Label",
			width: 200,
		},
		{
			field: "questions_i18n",
			headerName: "Question",
			width: 350,
			valueGetter: ({ value }: { value: QuestionI18n[] }) => {
				return value.find(question_i18n => question_i18n.language === "en")?.value
			}
		},
		{
			field: "type",
			headerName: "Type",
			width: 150,
			valueGetter: (params) => {
				// if (params.value) {
				switch (params.value) {
					case "single_answer":
						return "Single answer"
					case "multiple_answers":
						return "Multiple answer"
					case "rating_index":
						return "Rating index"
					case "vas_scale":
						return "Vas Scale"
					case "evaluation_grid":
						return "Evaluation grid"
					default:
						break
				}
				// }
			},
		},
		{
			field: "company_id",
			headerName: "Company",
			width: 150,
			valueGetter: (params) => {
				const company = companies.find(company => company.id === params.value)
				if (company) {
					return company.name
				} else {
					return "-"
				}
			},
		},
		{
			field: "language",
			headerName: "Languages",
			width: 350,
			renderCell: (params: GridRenderCellParams<language[]>) => {
				return (
					<Grid container spacing={1}>
						{params.value.map((lang: language) =>
							<Grid item key={lang}>
								<LanguageButton
									language={lang}
									disableClick
								/>
							</Grid>
						)}
					</Grid>
				)
			},
			valueGetter: (params) => {
				return _.map<language[]>(params.row.questions_i18n, "language")
			}
		},
	]

	//------ configuration for toolbar -------
	const toolbarConfig: ToolbarConfig = {
		title: "Questions",
		subtitle: "Here's your question's repository",
		buttons: [
			{
				name: "New Question",
				icon: <AddOutlinedIcon />,
				action: () => setIsNewQuestionModalOpen({ isOpen: true }),
			},
		],
	}


	const handleRemoveAnswer = (index: number) => {
		removePossibleAnswers(index)
	}

	const handleModalClose = () => {
		setIsNewQuestionModalOpen({ isOpen: false })
		setCurrentLang("en")
		resetQuestion()
	}

	const handleDeleteQuestion = (questionId: number) => {
		deleteQuestionsMutation.mutate(questionId)
	}

	const handlePreviewModal = (questionID: number | undefined) => {
		const question = questions.find((question) => question.id === questionID)

		switch (question?.type) {
			case "evaluation_grid":
				return (
					<TesterEvaluationGrid
						question={question}
						language="en"
						preview
					/>
				)
			case "multiple_answers":
				return (
					<TesterMultiAnswer
						question={question}
						language="en"
						getValues={function (): NewAnswerFormInput {
							throw new Error("Function not implemented.")
						}}
						preview
					/>
				)
			case "rating_index":
				return (
					<TesterRatingIndex
						question={question}
						language="en"
						preview
					/>
				)
			case "single_answer":
				return (
					<TesterSingleAnswer
						question={question}
						language="en"
						preview
					/>
				)
			case "vas_scale":
				return (
					<TesterVasScale
						question={question}
						language="en"
						preview
					/>
				)
			default:
				break
		}
	}
	useEffect(() => {
		if (watchQuestionType) {
			if (!isNewQuestionModalOpen.selectedQuestion) {
				// new question
				const translations = getValues().dataQuestion.translations

				removePossibleAnswers()
				setQuestionValue("dataQuestion", {
					label: getValues().dataQuestion.label,
					type: watchQuestionType,
					company_id: getValues().dataQuestion.company_id,
					horizontal: getValues().dataQuestion.horizontal,
					min_value: undefined,
					max_value: undefined,
					translations: translations,
					possible_answers: [],
					evaluation_grid_options: {}
				})
			} else {
				if (watchQuestionType !== "evaluation_grid") {
					setQuestionValue("dataQuestion.evaluation_grid_options", undefined)
				} else {
					setQuestionValue("dataQuestion.evaluation_grid_options", {})
				}
			}

		}
	}, [getValueQuestionType])

	useEffect(() => {
		if (isNewQuestionModalOpen.selectedQuestion) {
			const question = _.find(questions, { "id": isNewQuestionModalOpen.selectedQuestion })

			if (question) {
				const temporaryTranslations = _.forEach(question.questions_i18n, (question) => {
					if (!question.max_value_description) {
						delete question.max_value_description
					}
					if (!question.min_value_description) {
						delete question.min_value_description
					}

					delete question.question_id
				})

				const possible_answers: PossibleAnswer[] = []

				_.forEach(question.possible_answers, (answer) => {
					const temporaryAnswer: PossibleAnswer = {
						id: answer.id,
						label: answer.label,
						translations: []
					}
					_.forEach(answer.possible_answers_i18n, (i18n_translation) => {
						const temporaryTranslation: translation = {
							language: i18n_translation.language,
							value: i18n_translation.value,
						}
						temporaryAnswer.translations.push(temporaryTranslation)
					})
					possible_answers.push(temporaryAnswer)
				})

				setQuestionValue("dataQuestion.label", question.label)
				setQuestionValue("dataQuestion.type", question.type)
				setQuestionValue("dataQuestion.company_id", Number(question.company_id) || undefined)
				setQuestionValue("dataQuestion.translations", temporaryTranslations)
				setQuestionValue("dataQuestion.possible_answers", [])

				if (question.type === "single_answer") {
					setQuestionValue("dataQuestion.horizontal", question.horizontal)
				}
				if (["rating_index", "vas_scale", "evaluation_grid"].includes(question.type)) {
					setQuestionValue("dataQuestion.min_value", Number(question.min_value))
					setQuestionValue("dataQuestion.max_value", Number(question.max_value))
				}
				if (question.type === "evaluation_grid") {
					setQuestionValue("dataQuestion.evaluation_grid_options", _.get(question, "evaluation_grid_options") || {})
				}

				if (possible_answers.length) {
					possible_answers.map(possible_answer => {
						appendPossibleAnswer(possible_answer)
					})
				}
			}
		}
	}, [isNewQuestionModalOpen])

	return (
		<>
			<Grid container padding={3}>
				<Toolbar config={toolbarConfig} />
				<Divider />
				<Grid
					item
					style={{
						height: "70vh",
						width: "100%",
					}}
				>
					<DataGrid
						slots={{
							toolbar: CustomDataGridToolbar,
						}}
						columns={columns}
						rows={sortByCreationDate(questions)}
						disableRowSelectionOnClick
					/>
				</Grid>
				{/** Create question modal */}
				<CustomDialog
					open={isNewQuestionModalOpen.isOpen}
					onClose={handleModalClose}
					title="Question Settings"
				>
					<form onSubmit={handleSubmit(onQuestionSubmit)}>
						<Grid item container justifyContent="center" alignItems="center" spacing={1} marginBottom={5}>
							<Grid item>
								<ParagraphTypography>Languages: </ParagraphTypography>
							</Grid>

							{fieldsTranslations.map(item =>
								<React.Fragment key={item.language}>
									<Grid item>
										<LanguageButton
											language={item.language}
											action={() => setCurrentLang(item.language)}
											isSelected={item.language === currentLang}
										/>
									</Grid>
									{/* <input
										type="hidden"
										{...registerNewQuestion(`dataQuestion.translations.${fieldIndex}.language`, { value: item.language })}
										value={item.language}
									/> */}
								</React.Fragment >
							)}

							<Language
								action={(language) => {
									appendTranslation({
										language: language,
										value: ""
									})
									setCurrentLang(language)
								}}
								activeLanguages={fieldsTranslations.map(translation => translation.language)}
							/>
							<Grid item>
								<Controller
									name="dataQuestion.company_id"
									control={control}
									render={({ field }) => (
										<Select
											{...field}
											fullWidth
											displayEmpty
											disabled={Boolean(isNewQuestionModalOpen.selectedQuestion)}
											sx={{
												height: "40px",
												// width: "200px",
												borderRadius: "3px",
												backgroundColor: "#f1f1f1",
											}}
										>

											<MenuItem value="">Select a Company</MenuItem>
											{companies.map((company) =>
												<MenuItem key={company.id} value={company.id}>
													{company.name}
												</MenuItem>
											)}

										</Select>
									)}

								/>
							</Grid>
						</Grid>
						{fieldsTranslations.map((translation, i) => {
							if (currentLang === translation.language) {
								return (
									<Grid item container key={translation.language}>
										<Grid
											item
											container
											spacing={6}
											justifyContent="flex-start"
											alignItems="center"
											sx={{ marginBottom: 3 }}
										>

											<Grid item xs={1} paddingRight={4}>
												<ParagraphTypography width={110} height={0}>
													Question
												</ParagraphTypography>
											</Grid>
											<Grid item xs={4}>
												<StyledTextField
													{...registerNewQuestion(`dataQuestion.translations.${i}.value`, { required: true })}
													required={true}
													placeholder={currentLang === "en" ? "What's the best piece of advice you ever received?" : _.find(getValues("dataQuestion.translations"), t => t.language == "en")?.value}
												/>
											</Grid>
											<Grid item xs={1} paddingRight={4}>
												<ParagraphTypography width={70} height={0}>
													Label
												</ParagraphTypography>
											</Grid>
											<Grid item xs={5}>
												<StyledTextField
													disabled={i > 0}
													style={{
														width: "225px"
													}}
													{...registerNewQuestion("dataQuestion.label", { required: true })}
													// required={true}
													placeholder="Insert the question label here"
												/>
											</Grid>
											<Grid item xs={1} paddingRight={4}>
												<ParagraphTypography>
													Type
												</ParagraphTypography>
											</Grid>
											<Grid item xs={3}>
												<Controller
													name="dataQuestion.type"
													control={control}
													render={({ field }) => (
														<Select
															required
															fullWidth
															displayEmpty
															sx={{
																borderRadius: "3px",
																backgroundColor: "#f1f1f1",
															}}
															{...field}
															disabled={fieldsTranslations.map(translation => translation.language).indexOf(currentLang) !== 0}
														>
															<MenuItem disabled value="">Select a Type</MenuItem>
															{questionsTypologies.map((type) =>
																<MenuItem key={type.id} value={optionValueFormatter(type.name)}>{type.name}</MenuItem>
															)}
														</Select>
													)}

												/>
											</Grid>
											{watchQuestionType === "single_answer" && (
												<>
													<Grid item xs={1}>
														<ParagraphTypography>
															Horizontal?
														</ParagraphTypography>
													</Grid>
													<Grid item xs ml={1}>
														<Controller
															name="dataQuestion.horizontal"
															control={control}
															render={({ field }) => (
																<FormControlLabel
																	{...field}
																	checked={field.value === true}
																	disabled={i > 0}
																	control={<Checkbox />}
																	disableTypography
																	label={<ParagraphTypography>The question will be shown in an horizontal view</ParagraphTypography>}
																	labelPlacement="end"
																/>
															)}
														/>
													</Grid>
												</>
											)}
										</Grid>
										{watchQuestionType === "single_answer" && (
											fieldsPossibleAnswer.map((item, fieldIndex) => (
												<SingleAnswer
													key={item.id}
													// selectedQuestion={_.find(questions, { "id": isNewQuestionModalOpen.selectedQuestion })}
													registerField={registerNewQuestion}
													index={i}
													fieldIndex={fieldIndex}
													removeAnswer={handleRemoveAnswer}
													currentLang={currentLang}
													getValue={getValues}
												/>
											))
										)}

										{watchQuestionType === "multiple_answers" && (
											fieldsPossibleAnswer.map((item, fieldIndex) => (
												<MultipleAnswer
													key={item.id}
													removeAnswer={handleRemoveAnswer}
													currentLang={currentLang}
													registerField={registerNewQuestion}
													index={i}
													fieldIndex={fieldIndex}
													getValue={getValues}
												/>
											))
										)}
										{
											watchQuestionType === "rating_index" && (
												<RatingIndex
													index={i}
													registerField={registerNewQuestion}
													watch={watch}
													currentLang={currentLang}
												/>
											)
										}
										{
											watchQuestionType === "vas_scale" && (
												<VASScale
													index={i}
													registerField={registerNewQuestion}
													watch={watch}
													currentLang={currentLang}
												/>
											)
										}
										{
											watchQuestionType === "evaluation_grid" && (
												<EvaluationGrid
													registerField={registerNewQuestion}
													fields={fieldsPossibleAnswer}
													index={i}
													currentLang={currentLang}
													watch={watch}
													getValue={getValues}
													setQuestionValue={setQuestionValue}
													control={control}
												/>
											)
										}
										<Grid item container justifyContent="center" alignItems="center" spacing={2} marginTop={3}>
											{currentLang === "en" && (
												watchQuestionType === "single_answer" ?
													<Grid item>
														<CustomButton
															text="Add Answer"
															primary={false}
															icon={<AddOutlinedIcon />}
															action={() => {
																appendPossibleAnswer({
																	label: "",
																	translations: []
																})
															}}
														/>
													</Grid> : watchQuestionType === "multiple_answers" ? <Grid item>
														<CustomButton
															text="Add Answer"
															primary={false}
															icon={<AddOutlinedIcon />}
															action={() => {
																appendPossibleAnswer({
																	label: "",
																	translations: []
																})
															}}
														/>
													</Grid> : watchQuestionType === "evaluation_grid" ?
														<Grid item>
															<CustomButton
																text="Add Voice"
																primary={false}
																icon={<AddOutlinedIcon />}
																action={() => {
																	appendPossibleAnswer({
																		label: "",
																		translations: []
																	})
																}}
															/>
														</Grid> : null
											)}
											<Grid item>
												<SubmitButton text="Save" />
											</Grid>
										</Grid>
									</Grid>
								)
							}
						})
						}
					</form>
				</CustomDialog>

				{/** Delete question modal */}
				<CustomDialog
					open={isDeleteActionModalOpen.isOpen}
					onClose={() => setIsDeleteActionModalOpen({ isOpen: false })}
					title="Do you want to delete this question?"
					subtitle="Warning: This action cannot be undone!"
				>
					<Grid container item justifyContent="center" spacing={3}>
						<Grid item>
							<CustomButton text="Cancel" action={() => setIsDeleteActionModalOpen({ isOpen: false })} />
						</Grid>
						<Grid item>
							<CustomButton primary text="Yes, I'm Sure" action={() => isDeleteActionModalOpen.questionId && handleDeleteQuestion(isDeleteActionModalOpen.questionId)} />
						</Grid>
					</Grid>
				</CustomDialog>

				{/** Preview question modal */}
				<CustomDialog
					open={isPreviewModalOpen.isOpen}
					onClose={() => { setIsPreviewModalOpen({ isOpen: false }) }}
				>
					{handlePreviewModal(isPreviewModalOpen.selectedQuestion)}
				</CustomDialog>
			</Grid >
		</>
	)
}
