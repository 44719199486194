import { Divider, Grid, styled, TextField } from "@mui/material"
import _ from "lodash"
import { useEffect, useState } from "react"
import {
	Control,
	Controller,
	FieldArrayWithId,
	UseFormGetValues,
	UseFormRegister,
	UseFormSetValue,
	UseFormWatch
} from "react-hook-form"

import { DefaultTextTypography, ParagraphTypography } from "../../../components/customTypography"
import { language } from "../../../types/genericTypes"
import { generateArray } from "../../../utils"
import { NewQuestionFormInput } from "../Questions"

interface EvaluationGridProps {
	registerField: UseFormRegister<NewQuestionFormInput>
	fields: FieldArrayWithId<NewQuestionFormInput, "dataQuestion.possible_answers", "id">[]
	index: number
	currentLang: language
	watch: UseFormWatch<NewQuestionFormInput>
	getValue: UseFormGetValues<NewQuestionFormInput>
	setQuestionValue: UseFormSetValue<NewQuestionFormInput>
	control: Control<NewQuestionFormInput>
}

// ------------ STYLE ------------
const StyledTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "c6c6c6",
	},
	"& .MuiOutlinedInput-root": {
		backgroundColor: "#F6F6F6",
		"& fieldset": {
			borderColor: "#E1E1E1",
		},
		"&:hover fieldset": {
			borderColor: "#E1E1E1",
		},
	},
}) as typeof TextField

// --------------------------------

export const EvaluationGrid = (props: EvaluationGridProps) => {

	const minValue = props.watch("dataQuestion.min_value")
	const maxValue = props.watch("dataQuestion.max_value")
	const [error, setError] = useState(false)
	const evaluationGridOptions = props.watch("dataQuestion.evaluation_grid_options")

	useEffect(() => {
		let errors = true
		if (
			(typeof minValue !== "number" || isNaN(minValue)) ||
			(typeof maxValue !== "number" || isNaN(maxValue)) ||
			(minValue > maxValue)
		) {
			errors = true
		} else {
			errors = false
			// 	ensure dataQuestion.evaluation_grid_options contains only right keys
			if (evaluationGridOptions) {
				const keys = generateArray(minValue, maxValue)
				const new_evaluation_grid_options = _.cloneDeep(evaluationGridOptions)
				const new_evaluation_grid_options_keys = Object.keys(new_evaluation_grid_options)
				for (const k in new_evaluation_grid_options_keys) {
					if (!keys.includes(parseInt(k))) {
						delete new_evaluation_grid_options[k]
					}
				}
				props.setQuestionValue("dataQuestion.evaluation_grid_options", new_evaluation_grid_options)
			}
		}
		setError(errors)
	}, [minValue, maxValue])


	return (
		<Grid container justifyContent="center">
			{/* NUMBER OF RATING SECTION */}
			<Grid item marginBottom={2} >
				<DefaultTextTypography textAlign="center">
					Set the number of rating section, here
				</DefaultTextTypography>
			</Grid>
			<Grid item container>
				<Grid item xs={6} padding={1}>
					<TextField
						{...props.registerField("dataQuestion.min_value", { valueAsNumber: true })}
						disabled={props.index > 0}
						defaultValue={null}
						label="Min Value"
						variant="outlined"
						fullWidth
						error={error}
					/>
				</Grid>
				<Grid item marginBottom={2} xs={6} padding={1}>
					<TextField
						{...props.registerField("dataQuestion.max_value", { valueAsNumber: true })}
						disabled={props.index > 0}
						defaultValue={null}
						label="Max Value"
						variant="outlined"
						fullWidth
						error={error}
					/>
				</Grid>
			</Grid>
			{!error &&
				<>
					<Divider
						style={{
							width: "100%",
							marginTop: "24px",
							marginBottom: "24px"
						}}
					/>
					{/* TEXT FOR RATING AREAS */}
					<Grid item marginBottom={2}>
						<DefaultTextTypography textAlign="center">
							Set the text for each of the rating areas, here
						</DefaultTextTypography>
					</Grid>
					<Grid item container>
						{
							generateArray(minValue || 0, maxValue || 0).map(option => {
								return (
									<Grid item xs={6} key={option} padding={1}>
										<input
											{...props.registerField(`dataQuestion.evaluation_grid_options.${option}.${props.index}.language`)}
											type="hidden"
											defaultValue={props.currentLang}
										/>

										<Controller
											name={`dataQuestion.evaluation_grid_options.${option}.${props.index}.value`}
											control={props.control}
											defaultValue=""
											rules={{ required: true }}
											render={({ field }) => (
												<TextField
													{...field}
													label={option}
													placeholder={props.currentLang === "en" ? "Never being influenced by prejudices" : _.find(props.getValue(`dataQuestion.evaluation_grid_options.${option}`), t => t.language === "en")?.value}
													variant="outlined"
													fullWidth
													required
												/>
											)}
										/>
									</Grid>
								)

							}
							)
						}
					</Grid>
				</>
			}
			<Divider
				style={{
					width: "100%",
					marginTop: 24,
					marginBottom: 24
				}}
			/>
			<Grid item marginBottom={2} xs={12}>
				<DefaultTextTypography textAlign="center">
					Set your question text here
				</DefaultTextTypography>
			</Grid>
			{props.fields.map((field, fieldIndex) => {
				return (
					<Grid container key={field.id} justifyContent="space-between" sx={{ marginBottom: 2 }}>

						<Grid item xs={1} alignSelf="center">
							<ParagraphTypography >
								Voice #{fieldIndex + 1}
							</ParagraphTypography>
						</Grid>
						<Grid item xs={7}>
							<StyledTextField
								sx={{ display: "none" }}
								{...props.registerField(`dataQuestion.possible_answers.${fieldIndex}.translations.${props.index}.language`, { value: props.currentLang })}
							/>
							<StyledTextField
								{...props.registerField(`dataQuestion.possible_answers.${fieldIndex}.translations.${props.index}.value`)}
								fullWidth
								required={true}
								placeholder={props.currentLang === "en" ? "Never being influenced by prejudices" : _.find(props.getValue(`dataQuestion.possible_answers.${fieldIndex}.translations`), t => t.language == "en")?.value}
							/>
						</Grid>
						<Grid item xs={3}>
							<StyledTextField
								disabled={props.index > 0}
								{...props.registerField(`dataQuestion.possible_answers.${fieldIndex}.label`)}
								fullWidth
								required={true}
								placeholder="label"
							/>
						</Grid>
					</Grid>
				)
			})}
		</Grid >
	)
}