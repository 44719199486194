import { createTheme } from "@mui/material/styles"

declare module "@mui/material/styles" {

	interface Palette {
		paper: Palette["primary"];
		tboxCard: Palette["primary"];
	}

	interface PaletteOptions {
		paper: PaletteOptions["primary"];
		tboxCard: PaletteOptions["primary"];

	}
}

// Create a theme instance.
const customTheme = createTheme({
	palette: {
		primary: {
			main: "#d8702e",
			light: "#ffd9c1"
		},
		secondary: {
			main: "#19857b",
		},
		error: {
			main: "#FE8E8E",
			dark: "#c51313",
		},
		paper:{
			main:"#f6f6f6"
		},
		background: {
			default: "#f6f6f6"
		},
		tboxCard:{
			main:"#408E91"
		},
		text: {
			disabled: "#989799"
		}
	},
	typography:{
		fontFamily:	"Poppins"
	},
})

export default customTheme


