import LocationOnIcon from "@mui/icons-material/LocationOn"
import RouterIcon from "@mui/icons-material/Router"
import TranslateIcon from "@mui/icons-material/Translate"
import { Box, CircularProgress, Divider, Grid, LinearProgress, LinearProgressProps, Typography } from "@mui/material"

import Depth from "../../../assets/backgrounds/Depth.svg"
import Direction from "../../../assets/backgrounds/Direction.svg"
import dashboard_logo from "../../../assets/logos/dashboard_logo.svg"
import thimus_wizard_logo_dark from "../../../assets/logos/thimus_wizard_logo_dark.svg"
import { DashboardCardTitleTypography, H1Typography, XLTypography } from "../../../components/customTypography"
import { language, Project } from "../../../types/genericTypes"
import { LanguagesButtons } from "../../projects/configure/products/configureProduct/LanguagesButtons"
interface props {
	project: Project | undefined
	sectionTitle: string
	tboxName: string | undefined
	languages: string[] | undefined
}

export const DashboardLabel = (props: props) => {

	function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
		return (
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					color: "#566ffc"
				}}
			>
				<Box
					sx={{
						width: "100%",
						mr: 1
					}}
				>
					<LinearProgress variant="determinate" {...props} color="inherit" />
				</Box>
				<Box sx={{ minWidth: 35 }}>
					<Typography variant="body2" color="text.secondary">{`${Math.round(
						props.value,
					)}%`}</Typography>
				</Box>
			</Box>
		)
	}


	return (
		<Grid
			item
			container
			sx={{
				position: "relative",
				// height: "150px",
				// overflow: "scroll",
				marginBottom: 2,
			}}
		>
			<img
				alt="logo-icon"
				src={Depth}
				style={{
					position: "absolute",
					right: "0px",
					top: "0px",
					zIndex: 0
				}}
			/>
			<img
				alt="logo-icon"
				src={Direction}
				style={{
					position: "absolute",
					left: "120px",
					top: "-20px",
					zIndex: 0,
					height: "170px",
					opacity: "0.5"
				}}
			/>
			<Grid
				item
				container
				justifyContent="space-between"
				sx={{
					padding: 3,
					zIndex: 999,
					paddingBottom: 1
				}}
			>
				<Grid item container xs={12} justifyContent="space-between" alignItems="center">
					<Grid item>
						<img
							alt="logo-icon"
							src={thimus_wizard_logo_dark}
							style={{ height: "30px" }}
						/>
					</Grid>
					<Grid item>
						<img
							alt="logo-icon"
							src={dashboard_logo}
							style={{ height: "20px" }}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12} container justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
					{/* PROJECT + SECTION NAME */}
					<Grid item>
						{props.project ?
							<>
								<XLTypography
									sx={{
										display: "inline-block",
										marginRight: 2
									}}
								>
									Project
								</XLTypography>
								<XLTypography color="primary" sx={{ display: "inline-block" }}>{props.project.name}</XLTypography>
							</>
							: <CircularProgress />
						}
					</Grid>
					<Grid item>
						<H1Typography sx={{ color: "text.secondary" }}>{props.sectionTitle}</H1Typography>
					</Grid>
				</Grid>


				<Grid item container alignItems="center">
					<Divider
						sx={{
							width: "100%",
						}}
					/>
				</Grid>
				<Grid
					item
					xs={6}
					container
					alignItems="center"
					sx={{
						paddingTop: 1,
						paddingBottom: 1,
					}}
				>
					{/* location */}
					<Grid item >
						<Grid container alignItems="center" >
							<LocationOnIcon color="primary" />
							<DashboardCardTitleTypography
								sx={{
									display: "inline-block",
									marginRight: 2
								}}
							>
								{props.project?.place}
							</DashboardCardTitleTypography>
						</Grid>
					</Grid>
					{/* tbox */}
					<Grid item >
						<Grid container alignItems="center" >
							<RouterIcon color="primary" />
							<DashboardCardTitleTypography
								sx={{
									display: "inline-block",
									marginRight: 2
								}}
							>
								{props.tboxName || "No TBox selected"}
							</DashboardCardTitleTypography>
						</Grid>
					</Grid>
					{/* languages */}
					<Grid item >
						<Grid container alignItems="center" >
							<TranslateIcon color="primary" />
							<DashboardCardTitleTypography
								sx={{
									display: "inline-block",
									marginRight: 1
								}}
							>
								{"Languages: "}
							</DashboardCardTitleTypography>

							{props.languages && props.languages.length > 0 ?
								// decommentare per ottenere tutte le lingue
								// props.languages?.map((language, i) =>
								// 	<Grid item key={i}>
								// 		<LanguagesButtons language={language as language} noBorder />
								// 	</Grid>
								// )
								< LanguagesButtons language={props.languages[0] as language} noBorder />
								: "No languages"}

						</Grid>
					</Grid>
				</Grid>
				<Grid item container alignItems="center" xs={5}>
					<Box sx={{ width: "100%" }}>
						<LinearProgressWithLabel value={props.project?.progress || 0} sx={{ borderRadius: "2px" }} />
					</Box>
				</Grid>
				<Grid item container alignItems="center">
					<Divider sx={{ width: "100%" }} />
				</Grid>
			</Grid>
		</Grid>
	)
}
