import BarChartIcon from "@mui/icons-material/BarChart"
import { Grid, TextField } from "@mui/material"
import _ from "lodash"
import React, { Fragment, useEffect, useState } from "react"
import {
	Bar,
	BarChart,
	CartesianGrid,
	Label,
	Legend,
	Line,
	LineChart,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts"
import { AxisDomain } from "recharts/types/util/types"

import { CustomButton } from "../../components/buttons/CustomButton"
import { TitleTypography } from "../../components/customTypography"
import { PhaseProduct, Product, ProjectTester } from "../../types/genericTypes"
import { CW_data } from "./PostAnalyticsDashboardLayout"


interface CommonProps {
	testers?: ProjectTester[]
	notes: string | undefined
	sectionTitle: string
	barChartData?: {
		[k: string]: string | undefined;
		name: string;
	}[]
	lineChartData?: CW_data[]
	productsList: Product[]
	productPhasesList?: PhaseProduct[]
	customDomain?: AxisDomain | undefined
}
type ConditionalProps =
	{
		pdfMode: true,
		onSaveNotes?: never
	}
	| {
		onSaveNotes: (notes: string) => void
		pdfMode?: never
	};

const CHART_COLORS_LIST = ["#347678", "#ea2a35", "#f35941", "#ff9150", "#f9ca7e", "#CCD189", "#73ADF2", "#C99FFF", "#97CDD6", "#818182"]

export const PADashboardSimpleBarChart = (props: CommonProps & ConditionalProps) => {
	const [notes, setNotes] = useState(props.notes || "")

	useEffect(() => {
		setNotes(props.notes || "")
	}, [props.notes])

	const [productsIds, setProductIds] = useState<string[]>([])


	const handleTestersNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setNotes(e.target.value)
	}

	const handleSaveTestersNotes = () => {
		if (!props.pdfMode) {
			props.onSaveNotes(notes)
		}
	}

	useEffect(() => {
		if (props.barChartData) {
			const temporaryProductIds: string[] = []
			props.barChartData.map((element) => {
				Object.keys(_.omit(element, ["name"])).map((productId) => {
					if (!temporaryProductIds.includes(productId)) {
						temporaryProductIds.push(productId)
					}
				})
			})
			setProductIds(temporaryProductIds)

		}
	}, [props.barChartData])

	useEffect(() => {
		if (props.lineChartData && props.lineChartData.length) {
			setProductIds(Object.keys(props.lineChartData[0].values))
		}
	}, [JSON.stringify(props.lineChartData)])

	const filteredLineChartData = _.filter(props.lineChartData, (item) => item.phase_code !== "cw" || "sw")

	return (
		<Grid
			container
			sx={{
				padding: 3,
				paddingTop: 0
			}}
		>
			<Grid item container xs={12} md={6} >
				<Grid
					item
					container
					xs={12}
					sx={{
						maxHeight: "25px",
						marginBottom: 2
					}}
				>
					<BarChartIcon color="primary" sx={{ marginRight: 1 }} />
					<TitleTypography sx={{ marginRight: 1 }}>{props.sectionTitle}</TitleTypography>
					<TitleTypography color="primary">{props.testers?.length}</TitleTypography>
				</Grid>

				<Grid item xs={12}>
					{/* LINE CHART */}
					{props.lineChartData &&
						<ResponsiveContainer width={props.pdfMode ? "45%" : "100%"} height={300}>
							<LineChart
								data={filteredLineChartData}
								margin={{
									top: 5,
									right: 30,
									left: 20,
									bottom: 5,
								}}
							>
								<CartesianGrid vertical={false} />
								<XAxis dataKey="name" type="number" domain={[0, "dataMax + 1"]} tickCount={_.sumBy(props.productPhasesList, "duration") / 2 + 1} />

								{_.orderBy(props.productPhasesList, "order").map((phase, index, arr) =>
									<Fragment key={phase.id}>
										<ReferenceLine x={phase.duration + _.sumBy(arr.slice(0, index), "duration")} strokeDasharray="3 3" />
										<ReferenceLine key={phase.id} x={(phase.duration / 2) + _.sumBy(arr.slice(0, index), "duration")} stroke="transparent">
											<Label value={phase.name} offset={30} position="bottom" />
										</ReferenceLine>
									</Fragment>
								)}

								<YAxis domain={["auto", "auto"]} />
								<Tooltip
									formatter={(value) => {
										if (typeof value === "number") {
											return value.toFixed(3)
										} else if (typeof value === "string") {
											return parseFloat(value).toFixed(3)
										} else {
											return value
										}
									}}
								/>
								<Legend
									wrapperStyle={{
										position: "relative",
										marginTop: "0px"
									}}
								/>

								{_.orderBy(productsIds).map((productId, i) =>
									<Line
										isAnimationActive={false}
										key={productId}
										dataKey={`values.${productId}`}
										name={props.productsList.find(product => product.product_id.toLowerCase() === productId.toLowerCase())?.product_id}
										stroke={CHART_COLORS_LIST[i]}
										fill={CHART_COLORS_LIST[i]}
									/>
								)}
							</LineChart>
						</ResponsiveContainer>
					}

					{/* BAR CHART */}
					{props.barChartData &&
						<ResponsiveContainer width={props.pdfMode ? "45%" : "100%"} height={300}>
							<BarChart
								data={props.barChartData}
								margin={{
									top: 5,
									right: 30,
									left: 20,
									bottom: 5,
								}}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="name" />
								<YAxis domain={props.customDomain} />
								<Tooltip
									formatter={(value) => {
										if (typeof value === "number") {
											return value.toFixed(3)
										} else if (typeof value === "string") {
											return parseFloat(value).toFixed(3)
										} else {
											return value
										}
									}}
								/>
								<Legend />
								<ReferenceLine y={0} stroke="#000" />

								{_.orderBy(productsIds).map((productId, i) =>
									<Bar
										key={productId}
										isAnimationActive={!props.pdfMode}
										dataKey={productId}
										fill={CHART_COLORS_LIST[i]}
										name={props.productsList.find(product => product.product_id.toLowerCase() === productId.toLowerCase())?.product_id}
									/>
								)}

							</BarChart>
						</ResponsiveContainer>
					}
				</Grid>
			</Grid>
			<Grid item container xs={12} md={6}>
				<Grid container>
					<Grid item xs={12} sx={{ marginBottom: 2 }}>
						<TitleTypography>Notes</TitleTypography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							sx={{ minWidth: "100%" }}
							id="filled-multiline-static"
							multiline
							rows={13}
							value={notes}
							placeholder="Write any notes here (thoughts, suggestions, observations, etc.)"
							variant="filled"
							onChange={(e) => { handleTestersNotesChange(e) }}
						/>
					</Grid>
				</Grid>
			</Grid>
			{
				!props.pdfMode &&
				<Grid container justifyContent="flex-end" sx={{ marginTop: 2 }}>
					<Grid item>
						<CustomButton text="Save" action={handleSaveTestersNotes} primary />
					</Grid>
				</Grid>
			}
		</Grid >
	)
}
