import { Avatar, Card, CardContent, Divider, Grid } from "@mui/material"

import { endpoint } from "../../../App"
import { AfterTasteIcon } from "../../../components/customIcons/AfterTasteIcon"
import { ChewIcon } from "../../../components/customIcons/ChewIcon"
import { ConceptIcon } from "../../../components/customIcons/ConceptIcon"
import { LookIcon } from "../../../components/customIcons/LookIcon"
import { OtherPhaseIcon } from "../../../components/customIcons/OtherPhaseIcon"
import { SmellIcon } from "../../../components/customIcons/SmellIcon"
import { SwallowIcon } from "../../../components/customIcons/SwallowIcon"
import { TasteIcon } from "../../../components/customIcons/TasteIcon"
import { TouchIcon } from "../../../components/customIcons/TouchIcon"
import { DashboardCardParagraphTypography, DashboardCardParagraphTypographyBold, H2Typography } from "../../../components/customTypography"
import { Product } from "../../../types/genericTypes"
import { getProductRandomImage } from "../../../utils"
interface props {
	product: Product
}
const avatarStyle = {
	height: 80,
	width: 80,
	marginRight: "auto",
	borderColor: "#D86F2E",
	backgroundColor: "white"
}
const phaseIconStyle = {
	fontSize: "15px",
	color: "#D8702E",
}
export const getPhase = (phase: string) => {
	switch (phase) {
		case "aftertaste":
			return <AfterTasteIcon sx={phaseIconStyle} />
		case "chew":
			return <ChewIcon sx={phaseIconStyle} />
		case "concept":
			return <ConceptIcon sx={phaseIconStyle} />
		case "look":
			return <LookIcon sx={phaseIconStyle} />
		case "smell":
			return <SmellIcon sx={phaseIconStyle} />
		case "swallow":
			return <SwallowIcon sx={phaseIconStyle} />
		case "taste":
			return <TasteIcon sx={phaseIconStyle} />
		case "touch":
			return <TouchIcon sx={phaseIconStyle} />
		default:
			return <OtherPhaseIcon sx={phaseIconStyle} />
	}
}

export const DashboardProductCard = (props: props) => {
	return (
		<Card
			sx={{
				width: "450px",
				height: "210px",
			}}
		>
			<CardContent>
				<Grid container justifyContent="space-between" spacing={1}>
					<Grid item xs={3}>
						<Avatar
							sx={avatarStyle}
							src={props.product.image ? `${endpoint}/api/medias/default/${props.product.image.file_id}` : undefined}
							alt="Profile Picture"
						>
							{!props.product.image && getProductRandomImage(props.product.id)}
						</Avatar>
					</Grid>
					<Grid item container justifyContent="flex-start" xs={9} direction="column" spacing={1}>
						<Grid item>
							<H2Typography
								sx={{
									overflowWrap: "break-word",
									overflowY: "auto",
									maxHeight: "3rem"
								}}
							>
								{props.product.product_id}
							</H2Typography>
						</Grid>
						<Grid item><Divider /></Grid>
						<Grid item>
							<DashboardCardParagraphTypographyBold
								style={{
									fontSize: "11px",
									color: "#3F3F3F"
								}}
							>
								{props.product.category} {">"} {props.product.subcategory} {">"} {props.product.typology} {">"} <span style={{ color: "#D8702E" }}>{props.product.product}</span>
							</DashboardCardParagraphTypographyBold>
						</Grid>
					</Grid>
				</Grid>
				<Grid container sx={{ marginTop: 2 }}>
					{props.product.phases.map((phase) => {
						return (
							<Grid item container key={phase.phase_id} xs={4} alignItems="center">
								<Grid item xs={2} marginRight={1}>
									{getPhase(phase.name.toLowerCase())}
								</Grid>
								<Grid item xs={6}>
									<DashboardCardParagraphTypography>{phase.name}</DashboardCardParagraphTypography>
								</Grid>
								<Grid item xs={2}>
									<DashboardCardParagraphTypography>{phase.duration}s</DashboardCardParagraphTypography>
								</Grid>
							</Grid>
						)
					})}
				</Grid>
			</CardContent>
		</Card>
	)
}
