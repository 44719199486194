import { CircularProgress, Grid } from "@mui/material"
import { SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"

import SubmitButton from "../../../components/buttons/submitButton"
import {
	projectQueries,
	queryClient,
	questionsQueries,
	testersAnswers,
	testersQueries
} from "../../../networking/networking"
import { useSnackbarStore } from "../../../stateManagement"
import { TesterEvaluationGrid } from "./questionary/questionType/TesterEvaluationGrid"
import { TesterMultiAnswer } from "./questionary/questionType/TesterMultiAnswer"
import { TesterRatingIndex } from "./questionary/questionType/TesterRatingIndex"
import { TesterSingleAnswer } from "./questionary/questionType/TesterSingleAnswer"
import { TesterVasScale } from "./questionary/questionType/TesterVasScale"


export interface NewAnswerFormInput {
	single_answer_response: number | null,
	multiple_answer_response: number[] | null,
	rating_index_response: number | null,
	vas_scale_response: number | null,
	evaluation_grid_response: {
		[possible_answer_label: string]: string
	} | null
}


export const TesterSurveyLayout = () => {
	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)
	const { project_id = "", local_tester_id = "", product_id = "", question_id = "" } = useParams()

	const { data: project, isFetching: isProjectFetching } = useQuery(
		[projectQueries.getProject.name, parseInt(project_id)],
		() => projectQueries.getProject.fn(parseInt(project_id)),
		{
			enabled: Boolean(project_id),
		}
	)

	const { data: single_session, isFetching: isSingleSessionFetching } = useQuery(
		[testersQueries.getCurrentSingleSession.name, parseInt(project_id), parseInt(local_tester_id)],
		() => testersQueries.getCurrentSingleSession.fn(parseInt(project_id), parseInt(local_tester_id)),
		{
			enabled: Boolean(local_tester_id),
			onSuccess: () => {
				newAnswerReset()
			},

			// refetchInterval: ((single_session) => {
			// 	if (!single_session?.current_product_id_to_respond) {
			// 		return 2000
			// 	} else {
			// 		return false
			// 	}
			// })
		}
	)

	const { data: question, isFetching: isQuestionsFetching } = useQuery(
		questionsQueries.listQuestions.name,
		() => questionsQueries.listQuestions.fn(),
		// () => questionsQueries.getQuestionsForProduct.fn({ product_id: parseInt(product_id) }),
		{
			enabled: Boolean(product_id),
			select: data => data.find(question => question.id === parseInt(question_id)),
		}
	)

	const createAnswerMutation = useMutation(
		testersAnswers.createAnswer.name,
		testersAnswers.createAnswer.fn,
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries([testersQueries.getCurrentSingleSession.name, parseInt(project_id), parseInt(local_tester_id)])
				await queryClient.invalidateQueries([projectQueries.getProject.name, parseInt(project_id)])
			},
			onError: () => {
				openSnackbar("error", "Please answer the question in order to proceed")
			}
		}
	)

	const {
		handleSubmit: handleSubmitNewAnswer,
		control,
		getValues,
		reset: newAnswerReset
	} = useForm<NewAnswerFormInput>({
		defaultValues: {
			single_answer_response: null,
			multiple_answer_response: null,
			rating_index_response: null,
			vas_scale_response: null,
			evaluation_grid_response: null,
		}
	})

	const onNewAnswerSubmit: SubmitHandler<NewAnswerFormInput> = (data) => {
		if (!single_session) return
		if (!single_session.current_product_id_to_respond) return
		if (!single_session.current_question_to_respond) return

		createAnswerMutation.mutate({
			single_session_id: single_session.id,
			product_id: single_session.current_product_id_to_respond,
			question_id: single_session.current_question_to_respond.id,
			single_answer_response: data.single_answer_response,
			multiple_answer_response: data.multiple_answer_response,
			rating_index_response: data.rating_index_response,
			vas_scale_response: data.vas_scale_response,
			evaluation_grid_response: data.evaluation_grid_response,
		})
	}

	if (isProjectFetching || isSingleSessionFetching || isQuestionsFetching || !question) {
		return <Grid container justifyContent="center"><CircularProgress /></Grid>
	}

	const language = project?.language || "en"
	const renderQuestion = () => {
		switch (question.type) {
			case "single_answer":
				return <TesterSingleAnswer question={question} language={language} control={control} />
			case "multiple_answers":
				return <TesterMultiAnswer question={question} language={language} control={control} getValues={getValues} />
			case "rating_index":
				return <TesterRatingIndex question={question} language={language} control={control} />
			case "vas_scale":
				return <TesterVasScale question={question} language={language} control={control} />
			case "evaluation_grid":
				return <TesterEvaluationGrid question={question} language={language} control={control} />
		}
	}

	return (
		<form onSubmit={handleSubmitNewAnswer(onNewAnswerSubmit)}>
			<Grid container justifyContent="center">
				<Grid item xs>
					{renderQuestion()}
				</Grid>
				<Grid item container justifyContent="center" marginTop={3}>
					<SubmitButton
						text={createAnswerMutation.isLoading ? <CircularProgress size={24}/> : "Next"}
						disabled={isSingleSessionFetching || createAnswerMutation.isLoading}
					/>
				</Grid>
			</Grid>
		</form>
	)
}
