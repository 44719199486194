import { Delete as DeleteIcon } from "@mui/icons-material"
import { CircularProgress, Grid } from "@mui/material"
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid"
import _ from "lodash"
import { useState } from "react"
import { useMutation, useQuery } from "react-query"

import CustomDataGridToolbar from "../../components/CustomDataGridToolbar"
import { ParagraphTypography, ProjectMenuTitleTypography } from "../../components/customTypography"
import {Toolbar, ToolbarConfig} from "../../components/Toolbar"
import { companyQueries, testersQueries } from "../../networking/networking"
import { useSnackbarStore, useUserStore } from "../../stateManagement"
import { companiesSorter } from "../../utils"

interface selectedIds {
	[companyId: number]: GridRowSelectionModel
}

const columns: GridColDef[] = [
	{
		field: "global_id",
		headerName: "Global ID",
		width: 120,
		hideable: false,
	},
	{
		field: "first_name",
		headerName: "First name",
		width: 120,
	},
	{
		field: "last_name",
		headerName: "Last name",
		width: 120,
	},
	{
		field: "date_of_birth",
		headerName: "Date of Birth",
		type: "date",
		width: 120,
		valueGetter: ({ value }) => value && new Date(value),
		// valueFormatter: ({ value }) => value ? value.toLocaleDateString() : "",
	},
	{
		field: "gender_external_format",
		headerName: "Gender",
		width: 120,

	},
	{
		field: "country_external_format",
		headerName: "Country",
		width: 120,
	},
	{
		field: "ethnicity_external_format",
		headerName: "Ethnicity",
		width: 120,
	},
	{
		field: "eduction_external_format",
		headerName: "Education",
		width: 120,
	},
	{
		field: "professional_sector_external_format",
		headerName: "Professional Sector",
		width: 120,
	},
	{
		field: "diet_external_format",
		headerName: "Diet",
		width: 120,
	},
	{
		field: "smoker_external_format",
		headerName: "Smoker",
		width: 120,
	},
	{
		field: "phone_number",
		headerName: "Number",
		width: 120,
	},
	{
		field: "email_address",
		headerName: "Email",
		width: 120,
	},
	{
		field: "group",
		headerName: "Group",
		width: 120,
	},
]

export const Testers = () => {
	const [selectedTestersId, setSelectedTestersId] = useState<selectedIds>({})
	const userRole = useUserStore((state) => state.userRole)
	const userCompany = useUserStore((state) => state.userCompany)

	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)

	const getAllSelectedIds = (selectedIds: selectedIds): GridRowSelectionModel => {
		return Object.values(selectedIds).reduce((acc, currentArray) => {
			return acc.concat(currentArray)
		}, [])
	}

	const { data: testers = [], refetch: refetchTesters, isLoading: isTestersLoading } = useQuery(
		testersQueries.listTesters.name,
		testersQueries.listTesters.fn,
	)

	const deleteTesterMutation = useMutation(
		testersQueries.deleteTester.name,
		testersQueries.deleteTester.fn,
	)

	const { data: companies = [] } = useQuery(
		companyQueries.listCompanies.name,
		companyQueries.listCompanies.fn,
		{
			select: data => data.sort(companiesSorter)
		}
	)

	const toolbarConfig: ToolbarConfig = {
		title: "Testers",
		subtitle: "Manage all the Testers from here",
		buttons: userRole === "superadmin" || userRole === "admin" ?
			[
				{
					name: "Delete selected",
					icon: deleteTesterMutation.isLoading ? <CircularProgress size={18} /> : <DeleteIcon />,
					action: async () => {
						try {
							await Promise.all(getAllSelectedIds(selectedTestersId).map((id) => deleteTesterMutation.mutateAsync(parseInt(id.toString()))))
							openSnackbar("success", "Testers successfully deleted")
							await refetchTesters()
						} catch {
							openSnackbar("error", "Error deleting testers")
						}
					},
					disabled: getAllSelectedIds(selectedTestersId).length === 0 || deleteTesterMutation.isLoading || isTestersLoading,
					primary: false,
				},
			] : undefined
	}

	return (
		<Grid container padding={3} >
			<Toolbar config={toolbarConfig} />
			<Grid item container>
				{companies.map((company) => {
					if (userRole != "superadmin" && company.cognito_group_name != userCompany?.GroupName) {
						return
					} else {
						const companyTesters = _.filter(testers, { "company_id": company.id })
						return (
							<Grid
								key={company.id}
								item
								container
								marginBottom={3}
								spacing={2}
							>
								<Grid item xs={12}>
									<ProjectMenuTitleTypography>{company.name}</ProjectMenuTitleTypography>
								</Grid>
								<Grid item xs={12}>
									{companyTesters.length ?
										<DataGrid
											sx={{ width: "100%" }}
											rows={companyTesters}
											columns={columns}
											// autoPageSize
											slots={{
												toolbar: CustomDataGridToolbar,
											}}
											autoHeight
											checkboxSelection={["superadmin", "admin"].includes(userRole)}
											disableRowSelectionOnClick
											getRowId={(tester) => tester.global_id}
											onRowSelectionModelChange={(selectedTesterIDs) => setSelectedTestersId(prevState => ({
												...prevState,
												[company.id]: selectedTesterIDs,
											}))}
										/> :
										<ParagraphTypography color="text.disabled">There are no testers at the moment yet</ParagraphTypography>
									}
								</Grid>
							</Grid>
						)
					}
				})}
			</Grid>
		</Grid >
	)
}
