import { Button, CircularProgress, Grid, Link, TextField, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import React, { useState } from "react"
import { useMutation } from "react-query"
import { Link as ReactRouterLink, useNavigate } from "react-router-dom"

import { authQueries } from "../../networking/networking"

//---------------STYLE --------------------------
const TitleTypography = styled(Typography)(({ theme }) => ({
	width: "100%",
	color: theme.palette.primary.main,
})) as typeof Typography

//-----------------------------------

export default function ForgotPassword() {
	const navigate = useNavigate()

	const [step, setStep] = useState("FORGOT_PASSWORD")
	const [email, setEmail] = useState("")
	const [code, setCode] = useState("")
	const [password, setPassword] = useState("")
	const [repeatPassword, setRepeatPassword] = useState("")
	const [error, setError] = useState("")
	const [errorCode, setErrorCode] = useState("")
	const [passwordError, setPasswordError] = useState("")

	const forgotPassword = useMutation({
		mutationKey: authQueries.forgotPassword.name,
		mutationFn: authQueries.forgotPassword.fn,
		onError: (error: { code: string }) => {
			if (error.code === "UserNotFoundException") {
				setError("Account not found")
			} else {
				console.error("forgotPassword unpredicted error", error)
			}
		},
		onSuccess: details => {
			console.log("forgotPassword details", details)
			setError("")
			setStep("FORGOT_PASSWORD_SUBMIT")
		},
	})

	const forgotPasswordSubmit = useMutation({
		mutationKey: authQueries.forgotPasswordSubmit.name,
		mutationFn: authQueries.forgotPasswordSubmit.fn,
		onError: (error: { code: string, message: string }) => {
			if (error.code === "CodeMismatchException") {
				setErrorCode("Invalid verification code provided, please try again.")
				setPasswordError("")
			} else {
				setErrorCode("")
				if (error.code === "InvalidPasswordException") {
					setPasswordError("Password must contain at least 8 characters, including an uppercase letter, a lowercase letter, a number, and a symbol.")
				} else {
					setPasswordError(error.message)
				}
			}
		},
		onSuccess: () => {
			navigate("/auth/signIn", { replace: true })
		},
	})

	const handleSubmitUsername = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		forgotPassword.mutate({
			username: email,
		})
	}

	const handleSubmitUsernameCodePassword = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		forgotPasswordSubmit.mutate({
			username: email,
			code: code,
			newPassword: password
		})
	}

	if (step === "FORGOT_PASSWORD") return (
		<form onSubmit={handleSubmitUsername}>
			<Grid container justifyContent="center">
				<Grid item sx={{ marginBottom: "30px" }}>
					<TitleTypography gutterBottom variant="h5" sx={{ fontWeight: "bold" }}> Forgot password </TitleTypography>
				</Grid>
				{/* username */}
				<Grid item xs={12} sx={{ marginBottom: "30px" }}>
					<Typography gutterBottom> User name</Typography>
					<TextField
						fullWidth
						variant="outlined"
						type="email"
						autoComplete="email"
						error={Boolean(error)}
						helperText={error}
						value={email}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
					/>
				</Grid>

				{/* options */}
				<Grid item container alignItems="center" sx={{ marginTop: "40px" }} justifyContent="flex-end">
					<Grid item >
						<Link component={ReactRouterLink} to="/auth/signIn" underline="hover" sx={{ fontWeight: "bold" }}>Sign in</Link>
					</Grid>
				</Grid>


				{/* submit button */}
				<Button
					type="submit"
					variant="contained"
					disabled={!email}
					sx={{
						marginTop: "40px",
						width: "100%",
						padding: "15px 0"
					}}
				>
					{forgotPassword.isLoading ? <CircularProgress size={25} /> : "Reset password"}
				</Button>
			</Grid>
		</form>)

	if (step === "FORGOT_PASSWORD_SUBMIT") return (
		<form onSubmit={handleSubmitUsernameCodePassword}>
			<Grid container justifyContent="center">
				<Grid item sx={{ marginBottom: "30px" }}>
					<TitleTypography gutterBottom variant="h5" sx={{ fontWeight: "bold" }}> Forgot password </TitleTypography>
				</Grid>
				{/* code */}
				<Grid item xs={12} sx={{ marginBottom: "30px" }}>
					<Typography gutterBottom> Code </Typography>
					<TextField
						fullWidth
						variant="outlined"
						type="text"
						autoComplete="off"
						error={Boolean(errorCode)}
						helperText={errorCode}
						value={code}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCode(event.target.value)}
					/>
				</Grid>
				{/* password */}
				<Grid item xs={12} sx={{ marginBottom: "30px" }}>
					<Typography gutterBottom> Password</Typography>
					<TextField
						fullWidth
						variant="outlined"
						type="password"
						autoComplete="new-password"
						value={password}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
					/>
				</Grid>
				{/* repeat new password */}
				<Grid item xs={12}>
					<Typography gutterBottom> Repeat password</Typography>
					<TextField
						fullWidth
						variant="outlined"
						type="password"
						autoComplete="new-password"
						error={Boolean(password) && Boolean(repeatPassword) && password !== repeatPassword}
						helperText={(Boolean(password) && Boolean(repeatPassword) && password !== repeatPassword) ? "Passwords don't match" : <span style={{ color: "#FF9595" }}>{passwordError}</span>}
						value={repeatPassword}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRepeatPassword(event.target.value)}
					/>
				</Grid>

				{/* submit button */}
				<Button
					type="submit"
					variant="contained"
					disabled={forgotPasswordSubmit.isLoading || !email || !code || !password || !repeatPassword || password !== repeatPassword}
					sx={{
						marginTop: "40px",
						width: "100%",
						padding: "15px 0"
					}}
				>
					{forgotPasswordSubmit.isLoading ? <CircularProgress size={25} /> : "Reset password"}
				</Button>
			</Grid>
		</form>
	)
	return (<p>Step non previsto {step}</p>)

}