import { Grid } from "@mui/material"
import Box from "@mui/material/Box"
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress"
import { linearProgressClasses } from "@mui/material/LinearProgress"
import _ from "lodash"

import { DashboardCardParagraphTypography } from "./customTypography"

const ProgressBarStyle = {
	height: 10,
	borderRadius: 5,
	backgroundColor: "#DEDEDE",
	[`& .${linearProgressClasses.bar}`]: {
		backgroundColor: "#556FFC",
	}
}
function LinearProgressWithLabel(props: LinearProgressProps & {
	value: number,
	current_product_id_to_respond: number | null,
	current_question_order_to_respond: number,
	totalQuestions: number
 }) {
	return (
		<Grid
			container
			flexDirection="column"
			sx={{
				display: "flex",
				alignItems: "center"
			}}
		>
			<Grid item container justifyContent="space-between" padding="0 8px 0 8px">
				<Grid item>
					<DashboardCardParagraphTypography>
						Status: In progress
					</DashboardCardParagraphTypography>
				</Grid>
				<Grid item>
					{!props.current_product_id_to_respond ?
						<DashboardCardParagraphTypography>
							{`${Math.round(props.value || 0)}%`}
						</DashboardCardParagraphTypography>
						:
						<DashboardCardParagraphTypography>
							{props.current_question_order_to_respond - 1} / {props.totalQuestions}
						</DashboardCardParagraphTypography>
					}
				</Grid>
			</Grid>
			<Grid
				item
				sx={{
					width: "100%",
				}}
			>
				<LinearProgress
					variant="determinate"
					{..._.omit(props, ["current_product_id_to_respond", "current_question_order_to_respond", "totalQuestions"])}
					value={
						props.current_product_id_to_respond  ? ((props.current_question_order_to_respond-1)/props.totalQuestions) * 100
							:
							props.value
					}
				/>
			</Grid>
		</Grid>
	)
}

type ProgressBarProps = {
	progress: number
	totalQuestions?: number
	current_product_id_to_respond: number | null
	current_question_order_to_respond: number | undefined
}

export default function ProgressBar(props: ProgressBarProps) {
	return (
		<Box sx={{ width: "100%" }}>
			<LinearProgressWithLabel
				variant="determinate"
				value={props.progress}
				current_product_id_to_respond={props.current_product_id_to_respond}
				current_question_order_to_respond={props.current_question_order_to_respond || 0}
				totalQuestions={props.totalQuestions || 0}
				sx={ProgressBarStyle}
			/>
		</Box>
	)
}