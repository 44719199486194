import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"
import { Card, CardContent, CircularProgress, Grid } from "@mui/material"
import dayjs from "dayjs"
import _ from "lodash"
import { useEffect, useState } from "react"

import { CustomPieChart } from "../../../components/CustomPieChart"
import { ChartTitle, DashboardCardParagraphTypography, ParagraphTypography } from "../../../components/customTypography"
import { chartData, ProjectTester } from "../../../types/genericTypes"

interface chartDataFormat {
	genderData: chartData[],
	smokerData: chartData[],
	dietData: chartData[],
}

interface props {
	testers: ProjectTester[]
}
export const TestersCharts = (props: props) => {
	// const { project_id = "" } = useParams()
	const [chartsData, setChartsData] = useState<chartDataFormat>({
		genderData: [],
		smokerData: [],
		dietData: []
	})


	const getAge = (date: string) => {
		return dayjs.utc().diff(dayjs.utc(date), "y")
	}

	const isBetween = (x: number, min: number, max: number) => {
		return x >= min && x <= max
	}

	// const { data: projectTesters = [] } = useQuery(
	// 	[projectQueries.listProjectTesters.name, parseInt(project_id)],
	// 	() => projectQueries.listProjectTesters.fn(parseInt(project_id)),
	// 	{
	// 		enabled: Boolean(project_id)
	// 	}
	// )

	useEffect(() => {
		//GENDER
		const male = {
			name: "Male",
			value: 0,
			color: "#5F0F40"
		}
		const female = {
			name: "Female",
			value: 0,
			color: "#0F4C5C"
		}
		const transgender_male = {
			name: "Transgender male",
			value: 0,
			color: "#E36414"
		}
		const transgender_female = {
			name: "Transgender female",
			value: 0,
			color: "#1D3557"
		}
		const non_binary = {
			name: "Non Binary",
			value: 0,
			color: "#9A031E"
		}
		const not_specify = {
			name: "N/D",
			value: 0,
			color: "#FB8B24"
		}
		//SMOKE
		const smoker = {
			name: "Smoker",
			value: 0,
			color: "#657ED4"
		}
		const not_smoker = {
			name: "Not smoker",
			value: 0,
			color: "#3626A7"
		}
		const ex_smoker = {
			name: "Former smoker",
			value: 0,
			color: "#292068"
		}
		// DIET
		const vegan = {
			name: "Vegan",
			value: 0,
			color: "#231942"
		}
		const vegetarian = {
			name: "Vegetarian",
			value: 0,
			color: "#5E548E"
		}
		const flexitarian = {
			name: "Flexitarian",
			value: 0,
			color: "#9F86C0"
		}
		const omnivore = {
			name: "Omnivore",
			value: 0,
			color: "#BE95C4"
		}
		const pescatarian = {
			name: "Pescatarian",
			value: 0,
			color: "#E1C4D4"
		}
		const other_diet = {
			name: "Other",
			value: 0,
			color: "#E0B1CB"
		}

		//AGE
		const tier1 = {
			name: "18-25",
			value: 0,
			color: "#0D3B66"
		}

		const tier2 = {
			name: "26-30",
			value: 0,
			color: "#FAF0CA"
		}

		const tier3 = {
			name: "31-35",
			value: 0,
			color: "#F4D35E"
		}

		const tier4 = {
			name: "36-40",
			value: 0,
			color: "#EE964B"
		}

		const tier5 = {
			name: "41-45",
			value: 0,
			color: "#F95738"
		}

		const tier6 = {
			name: "46-50",
			value: 0,
			color: "#EE765F"
		}
		_.forEach(props.testers, (projectTester) => {
			//AGE
			const age = getAge(projectTester.tester.date_of_birth.toString())

			if (isBetween(age, 0, 18)) {
				tier1.value += 1

			} else if (isBetween(age, 19, 25)) {
				tier2.value += 1

			} else if (isBetween(age, 26, 30)) {
				tier3.value += 1

			} else if (isBetween(age, 31, 40)) {
				tier4.value += 1

			} else if (isBetween(age, 41, 45)) {
				tier5.value += 1

			} else if (age > 50) {
				tier6.value += 1
			}
			//GENDER
			switch (projectTester.tester.gender) {
				case "male":
					male.value += 1
					break
				case "female":
					female.value += 1
					break
				case "transgender_male":
					transgender_male.value += 1
					break
				case "transgender_female":
					transgender_female.value += 1
					break
				case "non_binary":
					non_binary.value += 1
					break
				case "i_prefer_not_to_disclose":
					not_specify.value += 1
					break
				default:
					break
			}
			//SMOKE
			switch (projectTester.tester.smoker) {
				case "yes":
					smoker.value += 1
					break
				case "no":
					not_smoker.value += 1
					break
				case "i_am_a_former_smoker":
					ex_smoker.value += 1
					break
				default:
					break
			}
			//DIET
			switch (projectTester.tester.diet) {
				case "vegan":
					vegan.value += 1
					break
				case "vegetarian":
					vegetarian.value += 1
					break
				case "flexitarian":
					flexitarian.value += 1
					break
				case "omnivore":
					omnivore.value += 1
					break
				case "pescatarian":
					pescatarian.value += 1
					break
				case "other":
					other_diet.value += 1
					break
				default:
					break
			}
		})


		const ageData: chartData[] = [tier1, tier2, tier3, tier4, tier5, tier6]
		const genderData: chartData[] = [male, female, transgender_male, transgender_female, non_binary, not_specify]
		const smokerData: chartData[] = [smoker, not_smoker, ex_smoker]
		const dietData: chartData[] = [vegan, vegetarian, flexitarian, omnivore, pescatarian, other_diet]

		const temporaryChartsData = {
			ageData,
			genderData,
			dietData,
			smokerData,
		}
		setChartsData(temporaryChartsData)
	}, [props.testers])
	return (
		<Grid
			item
			container
			spacing={3}
			sx={{
				padding: 3,
				paddingTop: 0,
			}}
		>
			{props.testers.length === 0 ?
				<Grid item>
					<ParagraphTypography>No testers in this project</ParagraphTypography>
				</Grid>
				:

				Object.keys(chartsData).map((chart) => {
					const data = chartsData[chart as keyof chartDataFormat]
					let chartTitle = ""

					switch (chart) {
						case "ageData":
							chartTitle = "Tester's age"
							break
						case "genderData":
							chartTitle = "Tester's gender"
							break

						case "smokerData":
							chartTitle = "Smokers"
							break
						case "dietData":
							chartTitle = "Tester's diet"
							break
						default:
							break
					}
					return (
						<Grid
							item
							xs={6}
							lg={3}
							key={chart}
							sx={{
								minWidth: "340px",
								zIndex: 999
							}}
						>
							<Card
								sx={{
									minWidth: "320px",
									minHeight: "345px",
									backgroundColor: "white",
								}}
							>
								<CardContent>
									<Grid container justifyContent="center">
										<Grid item xs={12}>
											<ChartTitle gutterBottom alignSelf="flex-start">
												{chartTitle}
											</ChartTitle>
										</Grid>
										<Grid item>
											{Object.keys(chartsData).length === 0 ?
												<CircularProgress /> :
												<CustomPieChart data={data} />
											}
										</Grid>
										<Grid item container>
											{data.map((item) =>
												<Grid container alignItems="flex-start" flexWrap="nowrap" item key={item.name} xs={6}>
													<Grid item>
														<FiberManualRecordIcon
															style={{
																color: item.color,
																fontSize: "18px",
																marginRight: 2
															}}
														/>
													</Grid>
													<Grid item>
														<DashboardCardParagraphTypography style={{ wordBreak: "break-word" }}>{item.name}</DashboardCardParagraphTypography>
													</Grid>
												</Grid>
											)}
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					)
				}
				)

			}
		</Grid>
	)
}
