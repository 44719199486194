import { Grid, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import _ from "lodash"
import { useState } from "react"
import React from "react"
import { Control, Controller } from "react-hook-form"

import { ParagraphTypography, ProjectMenuTitleTypography } from "../../../../../components/customTypography"
import { LanguageButton } from "../../../../../components/LanguageButton"
import { language, Question } from "../../../../../types/genericTypes"
import { NewAnswerFormInput } from "../../TesterSurveyLayout"

interface questionProps {
	question: Question
	language: language
	control?: Control<NewAnswerFormInput> | undefined
	preview?: boolean
}

export const TesterEvaluationGrid = (props: questionProps) => {
	const [currentLang, setCurrentLang] = useState<language>(props.language)

	return (
		<>
			<Grid
				item
				container
				justifyContent="center"
				flexDirection="column"
				rowGap={1}
				padding={2}
			>
				{props.question.questions_i18n.filter(question => question.language === currentLang).map((question) =>
					<ProjectMenuTitleTypography textAlign="center" key={question.language}>{question.value}</ProjectMenuTitleTypography>
				)}
				<ParagraphTypography textAlign="center">Choose according to your opinion</ParagraphTypography>
				{props.preview &&
					<Grid item container gap={1} justifyContent="center">
						{props.question.questions_i18n && props.question.questions_i18n.map(item =>
							<React.Fragment key={item.language}>
								<Grid item>
									<LanguageButton
										language={item.language}
										action={() => setCurrentLang(item.language)}
										isSelected={item.language === currentLang}
									/>
								</Grid>
							</React.Fragment >
						)}
					</Grid>
				}
			</Grid>
			<Grid
				marginX="10%"
				marginBottom={3}
				sx={{
					border: "1px solid #CCCCCC",
					borderStyle: "dashed"
				}}
			/>
			<Grid
				item
				container
				justifyContent="center"
			>
				<Grid item>
					<TableContainer>
						<Table sx={{ width: "800px" }}>
							<TableHead>
								<TableRow>
									<TableCell sx={{ width: "20%" }} />
									{_.map(props.question.evaluation_grid_options, (evaluation_grid_option, evaluation_grid_option_id) =>
										evaluation_grid_option.filter(item => item.language === currentLang).map(item =>
											<TableCell key={evaluation_grid_option_id} align="center" width={`${80 / _.size(props.question.evaluation_grid_options)}%`}>
												{item.value}
											</TableCell>
										))}
								</TableRow>
							</TableHead>
							<TableBody>
								{props.question.possible_answers
									.map(possible_answer => possible_answer.possible_answers_i18n?.filter(possible_answer_i18n => possible_answer_i18n.language === currentLang)
										.map(possible_answer_i18n =>
											<TableRow key={possible_answer_i18n.possible_answer_id}>
												<TableCell sx={{ width: "20%" }} align="left">
													{possible_answer_i18n.value}
												</TableCell>
												{_.map(props.question.evaluation_grid_options, (evaluation_grid_option, evaluation_grid_option_id) =>
													evaluation_grid_option.filter(item => item.language === currentLang).map(() =>
														<TableCell key={evaluation_grid_option_id} align="center" sx={{ paddingY: "3px" }}>
															{props.control ?
																<Controller
																	control={props.control}
																	name={`evaluation_grid_response.${possible_answer.label}`}
																	rules={{ required: true }}
																	render={({ field }) => (
																		<Radio
																			// checked={selectedValues[possible_answer_i18n.possible_answer_id] === evaluation_grid_option_id}
																			checked={field.value === evaluation_grid_option_id}
																			// onChange={event => handleChange(possible_answer_i18n.possible_answer_id, event.target.value)}
																			onChange={field.onChange}
																			value={evaluation_grid_option_id}
																			name={evaluation_grid_option_id}
																		/>
																	)}
																/>
																:
																<Radio
																	value={evaluation_grid_option_id}
																	name={evaluation_grid_option_id}
																/>
															}
														</TableCell>
													))}
											</TableRow>
										))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</>
	)
}
