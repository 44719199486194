import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import { CircularProgress, Grid, IconButton, MenuItem, Paper, Select, SelectChangeEvent, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import _ from "lodash"
import { Fragment, useEffect, useState } from "react"
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"

import { CustomButton } from "../../components/buttons/CustomButton"
import SubmitButton from "../../components/buttons/submitButton"
import CustomDialog from "../../components/CustomDialog"
import { DefaultTextTypography } from "../../components/customTypography"
import { LanguageButton } from "../../components/LanguageButton"
import { StyledModal } from "../../components/StyledModal"
import { Toolbar, ToolbarConfig } from "../../components/Toolbar"
import { companyQueries, PhaseDescriptionEdit, phaseQueries } from "../../networking/networking"
import { useSnackbarStore } from "../../stateManagement"
import { language, Phase, PhaseDescription } from "../../types/genericTypes"
import { companiesSorter, phaseDescriptionSorter } from "../../utils"
import { getPhase } from "../dashboard/components/DashboardProductCard"
import { Language } from "../question/components/Language"

type EditDialogConfigType = {
	isOpen: true,
	phaseToEditId?: number
} | {
	isOpen: false,
	phaseToEditId?: never
}

type DeleteTranslationDialogConfigType = {
	isOpen: true,
	phaseDescription: PhaseDescription
} | {
	isOpen: false
}

interface PhaseDescriptionFormInput {
	id: number | null,
	phase_id: number,
	company_id: number | null,
	language: language,
	value: string,
}

type ConfirmCompanyChangeDialogConfigType = {
	isOpen: true,
	companyId: string
} | {
	isOpen: false,
}

export interface phaseTranslationFormInput {
	id: number | undefined,
	company_id: string,
	description_i18n: PhaseDescriptionFormInput[],
}

const StyledTextField = styled(TextField)({
	width: 450,
	"& label.Mui-focused": {
		color: "c6c6c6",
	},
	"& .MuiOutlinedInput-root": {
		backgroundColor: "#F6F6F6",
		"& fieldset": {
			borderColor: "#E1E1E1",
		},
		"&:hover fieldset": {
			borderColor: "#E1E1E1",
		},
	},
}) as typeof TextField

const Phases = () => {
	const [currentLang, setCurrentLang] = useState<language>("en")
	const [companyId, setCompanyId] = useState<string>("")
	const [descriptions, setDescriptions] = useState<PhaseDescriptionFormInput[]>([])
	const [isConfirmCompanyChangeAlertOpen, setIsConfirmCompanyChangeAlertOpen] = useState<ConfirmCompanyChangeDialogConfigType>({
		isOpen: false,
	})
	const [editDialogConfig, setEditDialogConfig] = useState<EditDialogConfigType>({
		isOpen: false,
	})
	const [isDeleteTranslationModalOpen, setIsDeleteTranslationModalOpen] = useState<DeleteTranslationDialogConfigType>({ isOpen: false })

	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)

	const toolbarConfig: ToolbarConfig = {
		title: "Phases",
		subtitle: "Manage phases from here",
	}

	const { data: phases = [], isFetching: isPhasesFetching, refetch: refetchPhases } = useQuery(
		phaseQueries.listPhases.name,
		phaseQueries.listPhases.fn,
		{
			select: data => data.map(phase => ({
				...phase,
				description: phase.description_i18n?.sort(phaseDescriptionSorter)
			} as Phase))
		}
	)

	const { data: companies = [] } = useQuery(
		companyQueries.listCompanies.name,
		companyQueries.listCompanies.fn,
		{
			select: data => data.sort(companiesSorter)
		}
	)

	const patchPhaseDescriptionMutation = useMutation(
		phaseQueries.patchPhase.name,
		phaseQueries.patchPhase.fn,
		{
			onSuccess: () => {
				setEditDialogConfig({ isOpen: false })
				setIsDeleteTranslationModalOpen({ isOpen: false })
				openSnackbar("success", "Phase successfully updated")
				refetchPhases()
			}
		}
	)


	const defaultValues: phaseTranslationFormInput = {
		id: undefined,
		company_id: "",
		description_i18n: [{
			id: null,
			value: "",
			language: "en"
		} as PhaseDescriptionFormInput],
	}

	const {
		handleSubmit: handleSubmitPhase,
		control,
		watch,
		reset: resetPhaseForm,
		setValue: setPhaseFormValue,
		formState: { dirtyFields }
	} = useForm<phaseTranslationFormInput>({ defaultValues })

	useEffect(() => {
		setCompanyId(watch().company_id)
	}, [watch().company_id])


	useEffect(() => {
		setDescriptions(watch().description_i18n)
	}, [watch().description_i18n])

	useEffect(() => {
		if (!phases.length) return

		const currentPhase = _.find(phases, (phase) => phase.id === editDialogConfig.phaseToEditId)
		if (!currentPhase) {
			// il form è chiuso
			resetPhaseForm(defaultValues)
			return
		}

		let currentTranslations

		if (companyId !== "") {
			currentTranslations = _.filter(currentPhase?.description_i18n, (description) => description.company_id === Number(companyId))
		} else {
			currentTranslations = _.filter(currentPhase?.description_i18n, (description) => description.company_id === null)
		}

		if (currentTranslations.length > 0) {
			resetPhaseForm({
				id: currentPhase.id,
				company_id: companyId,
				description_i18n: currentTranslations,
			})
		} else {
			resetPhaseForm({
				...defaultValues,
				company_id: companyId
			})
		}
		setCurrentLang("en")
	}, [phases, companyId, editDialogConfig.phaseToEditId])

	const handleEditDialogClose = () => {
		setEditDialogConfig({
			isOpen: false
		})
		resetPhaseForm(defaultValues)
	}

	const { append: appendTranslation } = useFieldArray({
		control,
		name: "description_i18n"
	})

	const onPhaseSubmit: SubmitHandler<phaseTranslationFormInput> = (data) => {
		if (!editDialogConfig.phaseToEditId) return
		const currentPhase = _.find(phases, (phase) => phase.id === editDialogConfig.phaseToEditId)
		if (!currentPhase) return

		patchPhaseDescriptionMutation.mutate({
			id: currentPhase.id,
			description_i18n: data.description_i18n.map(t => ({
				id: t.id,
				phase_id: currentPhase.id,
				company_id: Number(data.company_id) || undefined,
				language: t.language,
				value: t.value
			} as PhaseDescriptionEdit)).concat(
				currentPhase.description_i18n?.filter(d => d.company_id !== (Number(data.company_id) || null)).map(d => d as PhaseDescriptionEdit) || []
			)
		})
	}

	const handleChangeCompany = (e: SelectChangeEvent<string>) => {
		if (_.some(dirtyFields.description_i18n?.map((t) => t.value))) {
			setIsConfirmCompanyChangeAlertOpen({
				isOpen: true,
				companyId: e.target.value
			})
		} else {
			setPhaseFormValue("company_id", e.target.value)
		}
	}

	const handleConfirmChangeCompany = () => {
		if (isConfirmCompanyChangeAlertOpen.isOpen) {
			setPhaseFormValue("company_id", isConfirmCompanyChangeAlertOpen.companyId, { shouldDirty: false })
		}
		setIsConfirmCompanyChangeAlertOpen({ isOpen: false })
	}

	//-------------- render --------------------------

	if (isPhasesFetching) {
		return (
			<Grid container padding={3}>
				<Toolbar config={toolbarConfig} />
				<Grid container justifyContent="center">
					<CircularProgress />
				</Grid>
			</Grid>
		)
	}

	return (
		<Grid container padding={3}>
			<Toolbar config={toolbarConfig} />
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell colSpan={2}>Standard Instruction</TableCell>
							<TableCell colSpan={2}>Customized Instruction</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{_.filter(phases, (phase) => phase.id < 9).map((phase) => (
							<Fragment key={phase.id}>
								{phase.description_i18n && phase.description_i18n.length > 0 ?
									phase.description_i18n.map((desc, index, collection) => {
										const filteredDescriptions = _.filter(phase.description_i18n, (d) => d.language === desc.language)
										const customDescCompany = _.filter(companies, (c) => c.id === desc.company_id)[0]

										return (
											<TableRow
												key={desc.id}
												sx={{
													borderTop: "1px solid #E0E0E0",
													verticalAlign: "top"
												}}
											>
												{index === 0 &&
													<TableCell
														rowSpan={collection.length}
														sx={{
															borderRight: "1px solid #E0E0E0",
															borderTop: "1px solid #E0E0E0",
															width: "20%"
														}}
													>
														<Grid item container alignItems="center" gap={1}>
															<Grid item marginRight={1}>
																<IconButton
																	size="small"
																	onClick={() => setEditDialogConfig({
																		isOpen: true,
																		phaseToEditId: phase.id
																	})}
																>
																	<EditIcon sx={{ fontSize: "15px" }} />
																</IconButton>
															</Grid>
															<Grid item>
																{getPhase(phase.name.toLowerCase())}
															</Grid>
															<Grid item alignItems="center">
																{phase.name}
															</Grid>
														</Grid>
													</TableCell>
												}
												{desc.id === filteredDescriptions[0].id &&
													<TableCell
														rowSpan={filteredDescriptions.length}
														sx={{
															width: "10%",
														}}
													>
														<LanguageButton
															language={desc.language}
															disableClick
														/>
													</TableCell>
												}
												{desc.id === filteredDescriptions[0].id &&
													<TableCell
														rowSpan={filteredDescriptions.length}
														sx={{
															borderRight: "1px solid #E0E0E0",
															width: "30%"
														}}
													>
														{desc.company_id === null && desc.value}
													</TableCell>
												}

												{desc.company_id !== null && customDescCompany &&
													<TableCell sx={{ width: "5%" }} >
														<IconButton
															size="small"
															onClick={() =>
																setIsDeleteTranslationModalOpen({
																	isOpen: true,
																	phaseDescription: desc
																})}
														>
															<DeleteIcon
																sx={{
																	fontSize: "15px",
																	padding: 0
																}}
															/>
														</IconButton>
													</TableCell>
												}
												{desc.company_id !== null && customDescCompany &&
													<TableCell sx={{ width: "10%" }}>{customDescCompany.name}</TableCell>
												}
												{desc.company_id !== null && customDescCompany &&
													<TableCell sx={{ width: "25%" }}>{desc.company_id !== null && desc.value}</TableCell>
												}
											</TableRow>
										)
									})
									:
									<TableRow
										key={phase.id}
										sx={{
											borderTop: "1px solid #E0E0E0",
											verticalAlign: "top"
										}}
									>

										<TableCell
											sx={{
												borderRight: "1px solid #E0E0E0",
												borderTop: "1px solid #E0E0E0",
												width: "20%"
											}}
										>
											<Grid item container alignItems="center" gap={1}>
												<Grid item marginRight={1}>
													<IconButton
														size="small"
														onClick={() => setEditDialogConfig({
															isOpen: true,
															phaseToEditId: phase.id
														})}
													>
														<EditIcon sx={{ fontSize: "15px" }} />
													</IconButton>
												</Grid>
												<Grid item>
													{getPhase(phase.name.toLowerCase())}
												</Grid>
												<Grid item alignItems="center">
													{phase.name}
												</Grid>
											</Grid>
										</TableCell>
										<TableCell sx={{ width: "10%" }} />
										<TableCell
											sx={{
												borderRight: "1px solid #E0E0E0",
												width: "30%"
											}}
										/>
										<TableCell sx={{ width: "10%" }} />
										<TableCell sx={{ width: "30%" }} />
									</TableRow>
								}
							</Fragment>
						))}
					</TableBody>
				</Table>
			</TableContainer>


			{/* DIALOG */}

			<StyledModal
				isOpen={isDeleteTranslationModalOpen.isOpen}
				handleClose={() => { setIsDeleteTranslationModalOpen({ isOpen: false }) }}
				title="Do you want to delete this translation?"
				rightButton={
					{
						name: "Yes, I'm sure!",
						action: () => {
							if (isDeleteTranslationModalOpen.isOpen) {
								const phase = _.find(phases, (phase) => phase.id === isDeleteTranslationModalOpen.phaseDescription.phase_id)
								if (phase) {
									patchPhaseDescriptionMutation.mutate({
										id: isDeleteTranslationModalOpen.phaseDescription.phase_id,
										description_i18n: phase.description_i18n?.filter((desc) => desc.id !== isDeleteTranslationModalOpen.phaseDescription.id).map(t => ({
											id: t.id,
											phase_id: t.id,
											company_id: Number(t.company_id) || undefined,
											language: t.language,
											value: t.value
										} as PhaseDescriptionEdit)) || []
									})
								}
							}
						}
					}
				}
				leftButton={
					{
						name: "Cancel",
						action: () => setIsDeleteTranslationModalOpen({ isOpen: false })
					}
				}
			/>

			<CustomDialog
				open={editDialogConfig.isOpen}
				onClose={handleEditDialogClose}
				title="Phase Description Languages"
			>
				<form onSubmit={handleSubmitPhase(onPhaseSubmit)}>
					<Grid container justifyContent="center" alignItems="center" rowGap={5}>
						{/* PHASE NAME */}
						<Grid item container justifyContent="center">
							<DefaultTextTypography>{_.find(phases, (phase) => phase.id === editDialogConfig.phaseToEditId)?.name}</DefaultTextTypography>
						</Grid>
						<Grid item xs={5}>
							<Controller
								name="company_id"
								control={control}
								render={({ field }) => (
									<Select
										{...field}
										fullWidth
										displayEmpty
										sx={{
											height: "40px",
											borderRadius: "3px",
											backgroundColor: "#f1f1f1",
										}}
										onChange={handleChangeCompany}
									>

										<MenuItem value="">Any Company</MenuItem>
										{companies.map((company) =>
											<MenuItem key={company.id} value={company.id}>
												{company.name}
											</MenuItem>
										)}

									</Select>
								)}

							/>
						</Grid>
						<Grid item container alignItems="center" xs={12}>
							{/* LANGUAGES */}
							<Grid item container wrap="nowrap" justifyContent="center" alignItems="center" columnGap={2}>
								<Grid item>
									<DefaultTextTypography>Languages:</DefaultTextTypography>
								</Grid>
								<Grid item container width="fit-content" gap={1}>
									{descriptions.map((item) =>
										<Grid item key={item.language}>
											<LanguageButton
												language={item.language}
												action={() => setCurrentLang(item.language)}
												isSelected={item.language === currentLang}
											/>
										</Grid>
									)}
								</Grid>
								<Grid item>
									<Language
										action={(language) => {
											if (!editDialogConfig.phaseToEditId) return
											appendTranslation({
												id: null,
												language: language,
												value: "",
												company_id: Number(companyId),
												phase_id: editDialogConfig.phaseToEditId
											})
											setCurrentLang(language)
										}}
										activeLanguages={descriptions.map(translation => translation.language)}
									/>
								</Grid>
							</Grid>
						</Grid>
						{/* DESCRIPTION */}
						{descriptions.map((translation, i) => {
							if (currentLang === translation.language) {
								return (
									<Grid item container alignItems="center" justifyContent="center" key={i} gap={5}>
										<Grid item xs={2}>
											<DefaultTextTypography>Description</DefaultTextTypography>
										</Grid>
										<Grid item >
											<StyledTextField
												onChange={(e) => setPhaseFormValue(`description_i18n.${i}.value`, e.target.value, { shouldDirty: true })}
												value={translation.value}
												required={true}
												multiline
												rows={2}
												placeholder={`Describe the sensations about the product's ${_.find(phases, (phase) => phase.id === editDialogConfig.phaseToEditId)?.name.toLowerCase()}`}
											/>
										</Grid>
									</Grid>
								)
							}
						})}
						<Grid container item xs={12} justifyContent="center">
							<SubmitButton text="Save" />
						</Grid>
						<CustomDialog open={isConfirmCompanyChangeAlertOpen.isOpen} title="All changes will be lost. Proceed?" onClose={() => setIsConfirmCompanyChangeAlertOpen({ isOpen: false })}>
							<Grid container justifyContent="center">
								<Grid item sx={{ marginRight: 2 }}>
									<CustomButton primary text="No" action={() => setIsConfirmCompanyChangeAlertOpen({ isOpen: false })} />
								</Grid>
								<Grid item>
									<CustomButton text="Yes" action={handleConfirmChangeCompany} />
								</Grid>
							</Grid>
						</CustomDialog>
					</Grid>
				</form>
			</CustomDialog>
		</Grid>
	)
}

export default Phases
