import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material"
import _ from "lodash"
import { useEffect, useState } from "react"
import { UseFormRegister, UseFormWatch } from "react-hook-form"

import { DefaultTextTypography } from "../../../components/customTypography"
import { language } from "../../../types/genericTypes"
import { generateArray } from "../../../utils"
import { NewQuestionFormInput } from "../Questions"

interface RateIndexProps {
	registerField: UseFormRegister<NewQuestionFormInput>,
	index: number
	watch: UseFormWatch<NewQuestionFormInput>
	currentLang: language
}



export const RatingIndex = (props: RateIndexProps) => {

	const minValue = props.watch("dataQuestion.min_value")
	const maxValue = props.watch("dataQuestion.max_value")
	const minValueDescription = props.watch(`dataQuestion.translations.${props.index}.min_value_description`)
	const maxValueDescription = props.watch(`dataQuestion.translations.${props.index}.max_value_description`)
	const minValueDescriptionEn = _.find(props.watch("dataQuestion.translations"), t => t.language == "en")?.min_value_description
	const maxValueDescriptionEn = _.find(props.watch("dataQuestion.translations"), t => t.language == "en")?.max_value_description
	const [ratingArray, setRatingArray] = useState<number[]>([])
	const [error, setError] = useState(false)


	useEffect(() => {
		if (typeof minValue === "number" && !isNaN(minValue) && typeof maxValue === "number" && !isNaN(maxValue)) {
			if (minValue > maxValue) {
				setRatingArray([])
				setError(true)
			} else {
				setRatingArray(generateArray(minValue, maxValue))
				setError(false)
			}
		} else {
			setRatingArray([])
			setError(false)
		}
	}, [minValue, maxValue])

	return (
		<>
			<Grid
				item
				container
				justifyContent="center"
				sx={{ marginBottom: 3 }}
			>
				<Grid item width={516}>
					<DefaultTextTypography>
						This is the values that the User will see to provide the answer.
						You can set the MIN value and the MAX value here.
					</DefaultTextTypography>
				</Grid>
			</Grid>

			<Grid item container justifyContent="center" spacing={2} marginBottom={3}>
				<Grid item xs={6}>
					<TextField
						type="number"
						{...props.registerField("dataQuestion.min_value", { valueAsNumber: true })}
						disabled={props.index > 0}
						id="outlined-basic"
						label="Min Value"
						variant="outlined"
						style={{ width: "100%" }}
						error={error}
					/>
				</Grid>
				<Grid item xs={6} marginBottom={2}>
					<TextField
						type="number"
						disabled={props.index > 0}
						{...props.registerField("dataQuestion.max_value", { valueAsNumber: true })}
						id="outlined-basic"
						label="Max Value"
						variant="outlined"
						style={{ width: "100%" }}
						error={error}
					/>
				</Grid>

				<Grid item xs={6}>
					<TextField
						{...props.registerField(`dataQuestion.translations.${props.index}.min_value_description`)}
						id="outlined-basic"
						label="Min Value Description"
						placeholder={props.currentLang === "en" ? "Min Value Description" : minValueDescriptionEn}
						variant="outlined"
						style={{ width: "100%" }}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						{...props.registerField(`dataQuestion.translations.${props.index}.max_value_description`)}
						id="outlined-basic"
						label="Max Value Description"
						placeholder={props.currentLang === "en" ? "Max Value Description" : maxValueDescriptionEn}
						variant="outlined"
						style={{ width: "100%" }}
					/>
				</Grid>
				<Grid item container justifyContent="center" sx={{ margin: 3 }} >
					<FormControl>
						<RadioGroup
							row
							aria-labelledby="demo-row-radio-buttons-group-label"
							name="row-radio-buttons-group"
						>
							{ratingArray && ratingArray.length > 1 && (ratingArray.map((rate, idx) => {
								if (minValueDescription && maxValueDescription) {
									return (
										<FormControlLabel
											key={idx}
											value={rate}
											control={<Radio />}
											label={((ratingArray[0] === rate ? minValueDescription : "") || (ratingArray[ratingArray.length - 1] === rate ? maxValueDescription : ""))}
											labelPlacement="bottom"
											sx={{
												margin: 0,
												width: 75,
												flexGrow: 1,
												flexBasis: 0,
												textAlign: "center"
											}}
										/>
									)
								}
								else {
									return (
										<FormControlLabel
											key={idx}
											value={rate}
											control={<Radio />}
											label={rate}
											labelPlacement="bottom"
											sx={{
												margin: 0,
												width: 75,
												flexGrow: 1,
												flexBasis: 0,
												textAlign: "center",
											}}
										/>
									)
								}

							}))}
						</RadioGroup>
					</FormControl>
				</Grid>
			</Grid>
		</>
	)
}
