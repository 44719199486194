import { Autocomplete, Checkbox, Grid, styled, TextField } from "@mui/material"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { CustomButton } from "../../../../../components/buttons/CustomButton"
import SubmitButton from "../../../../../components/buttons/submitButton"
import { ParagraphTypography } from "../../../../../components/customTypography"
import { StyledDropzone } from "../../../../../components/StyledDropzone"
import { classificationQueries, projectQueries, queryClient } from "../../../../../networking/networking"
import { useOperatorStore, useSnackbarStore } from "../../../../../stateManagement"
import { Classification, Product } from "../../../../../types/genericTypes"
import { editModalModel } from "../ConfigureProjectProducts"


interface props {
	setFormPhase: React.Dispatch<React.SetStateAction<1 | 2 | 3>>
	setProduct: React.Dispatch<React.SetStateAction<editModalModel>>
	productsList: Product[]
	order?: number
	product?: Product
	userCanEdit?: boolean
}

interface FormInput {
	image: File | undefined,
	description: string | undefined,
	classification_id: number,
	productId: string,
	random_order: boolean,
}

const StyledTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "c6c6c6",
	},
	"& .MuiOutlinedInput-root": {
		backgroundColor: "#F6F6F6",
		"& fieldset": {
			borderColor: "#E1E1E1",
		},
		"&:hover fieldset": {
			borderColor: "#E1E1E1",
		},
	}
}) as typeof TextField

export const ConfigureProductGeneralForm = (props: props) => {

	const [image, setImage] = useState<File[]>([])

	const { project_id = "" } = useParams()
	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)
	const isUserOperator = useOperatorStore().isUserOperator

	const {
		register: registerNewProductCensimento,
		handleSubmit: handleSubmitNewProductCensimento,
		control: newProductControl,
		reset: newProductCensimentoReset,
		setValue: setValueNewProduct,
		formState: { errors, dirtyFields }
	} = useForm<FormInput>({
		defaultValues: {
			image: undefined,
			description: "",
			classification_id: undefined,
			productId: "",
			random_order: false,
		}
	})

	const { data: classifications = [] } = useQuery(
		classificationQueries.listClassifications.name,
		classificationQueries.listClassifications.fn,
	)

	const createProductMutation = useMutation(
		projectQueries.createProduct.name,
		projectQueries.createProduct.fn,
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries([projectQueries.getProducts.name, parseInt(project_id)])
				queryClient.invalidateQueries([projectQueries.getProject.name, parseInt(project_id)])
				props.setProduct({
					isOpen: true,
					product: data
				})
				props.setFormPhase(2)
			},
			onError: () => {
				console.log("error")
				openSnackbar("error", "Error creating product")
			}
		}
	)

	const updateProductMutation = useMutation(
		projectQueries.updateProduct.name,
		projectQueries.updateProduct.fn,
		{
			onSuccess: () => {
				props.setFormPhase(2)
				queryClient.invalidateQueries([projectQueries.getProducts.name, parseInt(project_id)])
			},
			onError: () => {
				console.log("update error")
				openSnackbar("error", "Error updating product")
			}
		}
	)

	const getClassificationByFields = (category: string, subcategory: string, typology: string, product: string): Classification | undefined => {
		return _.find(
			classifications,
			classification =>
				classification.category === category
				&& classification.subcategory === subcategory
				&& classification.typology === typology
				&& classification.product === product
		)
	}

	const setImageActionNewProduct = (files: File[]) => {
		setImage(files)
		setValueNewProduct("image", files[0], { shouldDirty: true })
	}


	useEffect(() => {
		if (props.product && classifications) {
			newProductCensimentoReset({
				//TODO: trovare il modo di mostrare l'immagine corrente
				//image: props.product.image || undefined,
				description: props.product.description || undefined,
				classification_id: getClassificationByFields(
					props.product?.category,
					props.product?.subcategory,
					props.product?.typology,
					props.product?.product,
				)?.id,
				productId: props.product.product_id,
				random_order: props.product.random_order,
			})
			if (props.product.image) {
				setImage([new File([], props.product.image.filename)])
			}
		}
	}, [props.product, classifications])

	const onSubmit: SubmitHandler<FormInput> = (data) => {
		if (!props.product && props.order) {
			createProductMutation.mutate({
				project_id: parseInt(project_id),
				product_id: data.productId,
				description: data.description,
				classification_id: data.classification_id,
				random_order: data.random_order,
				image: data.image,
				order: props.order
			})
		} else if (props.product) {
			updateProductMutation.mutate({
				project_id: parseInt(project_id),
				product_id: props.product.id,
				product: {
					product_id: (dirtyFields.productId && data.productId !== props.product.product_id) ? data.productId : undefined,
					description: (dirtyFields.description && data.description !== props.product.description) ? data.description : undefined,
					classification_id: (dirtyFields.classification_id && data.classification_id !== getClassificationByFields(
						props.product?.category,
						props.product?.subcategory,
						props.product?.typology,
						props.product?.product,
					)?.id) ? data.classification_id : undefined,
					image: dirtyFields.image ? (image.length > 0 ? image[0] : null) : undefined,
					random_order: (dirtyFields.random_order && data.random_order !== props.product.random_order) ? data.random_order : undefined,
				}
			})
		}
	}

	const navigateToNextPage = () => {
		props.setFormPhase(2)
	}

	const existingProductIdList = props.productsList.map(element => element.product_id)

	return (
		<form onSubmit={handleSubmitNewProductCensimento(onSubmit)}>
			<Grid container direction="column" spacing={2}>
				{/* IMAGE */}
				{/* TODO: mostrare immagine anche se read-only */}
				{props.userCanEdit &&
					<Grid item container spacing={3} alignItems="center">
						<Grid item xs={3} >
							<ParagraphTypography>Image</ParagraphTypography>
						</Grid>
						<Grid item xs={9}>
							<StyledDropzone
								acceptedFileFormats={{
									"image/jpeg": [".jpeg"],
									"image/jpg": [".jpg"],
									"image/png": [".png"]
								}}
								file={image}
								setFile={setImageActionNewProduct}
								disabled={isUserOperator}
							/>
						</Grid>
					</Grid>
				}

				{/* DESCRIPTION */}
				<Grid item container spacing={3} alignItems="center">
					<Grid item xs={3}>
						<ParagraphTypography>Description</ParagraphTypography>
					</Grid>
					<Grid item xs={9}>
						<StyledTextField
							{...registerNewProductCensimento("description")}
							placeholder="Write the product description here"
							fullWidth
							multiline
							disabled={isUserOperator || !props.userCanEdit}
							minRows={4}
						/>
					</Grid>
				</Grid>

				{/* CLASSIFICATION */}
				<Grid item container spacing={3} justifyContent="center" alignItems="center">
					<Grid item xs={3}>
						<ParagraphTypography>Classification*</ParagraphTypography>
					</Grid>
					<Grid item xs={9}>
						<Controller
							name="classification_id"
							control={newProductControl}
							disabled={isUserOperator || !props.userCanEdit}
							render={({ field }) => (
								<Autocomplete
									getOptionLabel={(option) => `${option.category} > ${option.subcategory} > ${option.typology} > ${option.product}`}
									disablePortal
									options={classifications}
									{...field}
									value={classifications.find(c => c.id === field.value) || null}
									onChange={(event, value) => field.onChange(value?.id)}
									fullWidth
									sx={{
										backgroundColor: "#f6f6f6"
									}}
									renderInput={(params) => <TextField required {...params} placeholder="Write the classification of the product here" />}
								/>
							)}
							rules={{ required: true }}
						/>
					</Grid>
				</Grid>

				{/* PRODUCT ID */}
				<Grid item container spacing={3} justifyContent="center" alignItems="center">
					<Grid item xs={3}>
						<ParagraphTypography>ProductID*</ParagraphTypography>
					</Grid>
					<Grid item xs={9}>
						<StyledTextField
							{...registerNewProductCensimento("productId",
								{
									validate: (value: string) => {
										// Caratteri accettati solo lettere o numeri
										const regex = new RegExp("^[a-zA-Z0-9]+$")
										if (!regex.test(value)) {
											return "Product ID must contain only letter or number."
										}
										return (
											(!existingProductIdList.includes(value) || props?.product?.product_id === value) ||
											"Product Id already exist"
										)

									}
								}
							)}
							required={true}
							disabled={isUserOperator || !props.userCanEdit}
							fullWidth
							placeholder="Write the ID of the product here (e.g.: 123AB)"
						/>
						<ParagraphTypography color="red">{errors.productId?.message}</ParagraphTypography>
					</Grid>
				</Grid>

				<Grid item container spacing={3} justifyContent="center" alignItems="center">
					<Grid item xs={3}>
						<ParagraphTypography>Randomize</ParagraphTypography>
					</Grid>
					<Grid item xs={9}>
						<Controller
							name="random_order"
							control={newProductControl}
							disabled={isUserOperator || !props.userCanEdit}
							render={({ field }) => <Checkbox {...field} checked={field.value} />}
						/>
					</Grid>
				</Grid>

				<Grid item container justifyContent="center" marginTop={4}>
					<Grid item>
						{isUserOperator || !props.userCanEdit ?
							<CustomButton
								text="Next"
								primary
								action={navigateToNextPage}
							/>
							:
							<SubmitButton text="Next" />
						}
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}
