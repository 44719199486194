import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined"
import { Grid, TextField } from "@mui/material"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { UseMutationResult } from "react-query"

import { CustomButton } from "../../../components/buttons/CustomButton"
import { CustomPieChart } from "../../../components/CustomPieChart"
import { ParagraphTypography, TitleTypography } from "../../../components/customTypography"
import { editProjectParams } from "../../../networking/networking"
import { chartData, Project, ProjectTester } from "../../../types/genericTypes"
interface props {
	testers: ProjectTester[]
	testersNotes: string | undefined
	onSaveTestersNotes: UseMutationResult<Project, unknown, editProjectParams, unknown>
	pdfMode?: boolean
}

export const TestersRecap = (props: props) => {

	const [testersNotes, setTestersNotes] = useState(props.testersNotes || "")
	const [chartData, setChartData] = useState<chartData[]>()

	const handleTestersNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setTestersNotes(e.target.value)
	}

	const handleSaveTestersNotes = () => {
		props.onSaveTestersNotes.mutate({ tester_notes: testersNotes  })
	}

	useEffect(() => {
		if (props.testers.length > 0) {

			const not_started = {
				name: "Not started",
				value: 0,
				color: "#832E3D"
			}

			const completed = {
				name: "Completed",
				value: 0,
				color: "#1F7B18"
			}

			const in_progress = {
				name: "In progress",
				value: 0,
				color: "#556FFC"
			}

			const incompleted = {
				name: "Uncompleted",
				value: 0,
				color: "#D8702E"
			}

			_.forEach(props.testers, (tester) => {
				if (!tester.has_session) {
					not_started.value = not_started.value + 1
				}

				if (tester.session && tester.session.running) {
					in_progress.value = in_progress.value + 1
				}

				if (tester.session && tester.session.uncompleted) {
					incompleted.value = incompleted.value + 1
				}

				if (tester.session && !tester.session.uncompleted) {
					completed.value = completed.value + 1
				}

			})

			const chartData: chartData[] = [not_started, in_progress, incompleted, completed]
			setChartData(chartData)
		}
	}, [props.testers])

	return (
		<Grid
			container
			sx={{
				overflow: "hidden",
				padding: 3,
				paddingTop: 0
			}}
		>
			<Grid item container xs={12} md={6} >
				<Grid item container xs={12} sx={{ maxHeight: "25px" }}>
					<GroupsOutlinedIcon color="primary" sx={{ marginRight: 1 }} />
					<TitleTypography sx={{ marginRight: 1 }}>Total Testers</TitleTypography>
					<TitleTypography color="primary">{props.testers.length}</TitleTypography>
				</Grid>
				{/* CHART */}
				{props.testers.length > 0 &&
					<Grid item container sx={{ minHeight: "100%" }} alignItems="center">
						<Grid item xs={12} md={5}>
							<CustomPieChart data={chartData} pdfMode={props.pdfMode} />
						</Grid>
						<Grid
							item
							container
							xs={7}
							sx={{
								marginTop: 2,
								paddingRight: 2,
							}}
						>
							{chartData?.map((singleData) => {
								return (
									<Grid
										item
										container
										key={singleData.name}
										alignItems="center"
										justifyItems="space-between"
										xs={12}
										sx={{
											height: "60px",
											width: "190px",
											backgroundColor: singleData.color,
											borderRadius: "10px",
											padding: 1,
											color: "white",
											marginBottom: 1,
										}}
									>
										<Grid item container justifyContent="center">
											<TitleTypography>
												{singleData.name}
											</TitleTypography>
										</Grid>
										<Grid item container justifyContent="center">
											<ParagraphTypography>{`${singleData.value} / ${props.testers.length}`}</ParagraphTypography>
										</Grid>
									</Grid>
								)
							})}
						</Grid>
					</Grid>
				}
			</Grid>
			<Grid item xs={12} md={6}>
				<Grid container>
					<Grid item xs={12}>
						<TitleTypography>Notes</TitleTypography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							sx={{ minWidth: "100%" }}
							id="filled-multiline-static"
							multiline
							rows={13}
							value={testersNotes}
							placeholder="Write any notes here (thoughts, suggestions, observations, etc.)"
							variant="filled"
							onChange={(e) => { handleTestersNotesChange(e) }}
						/>
					</Grid>
				</Grid>
			</Grid>
			{!props.pdfMode &&
				<Grid container justifyContent="flex-end" sx={{ marginTop: 2 }}>
					<Grid item>
						<CustomButton text="Save" action={handleSaveTestersNotes} primary />
					</Grid>
				</Grid>
			}
		</Grid >
	)
}

