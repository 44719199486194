import { SvgIcon, SvgIconProps } from "@mui/material"

const imageIcon = {
	overflow: "visible",
}
export function RandomizeIcon(props: SvgIconProps) {
	return (
		<SvgIcon
			{...props}
			sx={{
				...props.sx,
				...imageIcon
			}}
		>
			<g mask="url(#mask0_2935_17322)">
				<path d="M10.3772 1.31541C9.95668 0.894862 9.27496 0.894862 8.85443 1.31541L5.04735 5.12249C4.6268 5.54305 4.6268 6.22477 5.04735 6.6453L5.3629 6.96085L7.46186 6.96089C8.35251 6.96089 9.07732 7.6857 9.07732 8.57635V10.6199C9.48979 10.8584 10.0245 10.8062 10.3773 10.4535L14.1854 6.64535C14.6049 6.22479 14.6049 5.54307 14.1854 5.12254L10.3772 1.31541ZM7.46182 6.69163C7.01596 6.69163 6.65412 6.32974 6.65412 5.88392C6.65412 5.43805 7.016 5.07621 7.46182 5.07621C7.90769 5.07621 8.26953 5.4381 8.26953 5.88392C8.26953 6.32979 7.90769 6.69163 7.46182 6.69163ZM9.61577 6.69163C9.1699 6.69163 8.80807 6.32974 8.80807 5.88392C8.80807 5.43805 9.16995 5.07621 9.61577 5.07621C10.0616 5.07621 10.4235 5.4381 10.4235 5.88392C10.4235 6.32979 10.0616 6.69163 9.61577 6.69163ZM11.7697 6.69163C11.3239 6.69163 10.962 6.32974 10.962 5.88392C10.962 5.43805 11.3239 5.07621 11.7697 5.07621C12.2156 5.07621 12.5774 5.4381 12.5774 5.88392C12.5775 6.32979 12.2156 6.69163 11.7697 6.69163Z" />
				<path d="M7.46183 7.5H2.07696C1.48194 7.5 1 7.98247 1 8.57696V13.9618C1 14.5569 1.48247 15.0388 2.07696 15.0388H7.46183C8.05631 15.0388 8.53879 14.5569 8.53879 13.9618V8.57696C8.53879 7.98249 8.05632 7.5 7.46183 7.5ZM3.42312 13.4234C2.97725 13.4234 2.61541 13.0615 2.61541 12.6157C2.61541 12.1698 2.9773 11.8079 3.42312 11.8079C3.86899 11.8079 4.23087 12.1698 4.23087 12.6157C4.23087 13.0615 3.86899 13.4234 3.42312 13.4234ZM3.42312 10.7309C2.97725 10.7309 2.61541 10.369 2.61541 9.92321C2.61541 9.47735 2.9773 9.11551 3.42312 9.11551C3.86899 9.11546 4.23087 9.4773 4.23087 9.92317C4.23087 10.369 3.86899 10.7309 3.42312 10.7309ZM6.11556 13.4234C5.66969 13.4234 5.30785 13.0615 5.30785 12.6157C5.30785 12.1698 5.66973 11.8079 6.11556 11.8079C6.56142 11.8079 6.92326 12.1698 6.92326 12.6157C6.92326 13.0615 6.56142 13.4234 6.11556 13.4234ZM6.11556 10.7309C5.66969 10.7309 5.30785 10.369 5.30785 9.92321C5.30785 9.47735 5.66973 9.11551 6.11556 9.11551C6.56142 9.11551 6.92326 9.47739 6.92326 9.92321C6.92326 10.369 6.56142 10.7309 6.11556 10.7309Z" />
			</g>
		</SvgIcon>
	)
}
