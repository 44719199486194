import { CircularProgress, Grid } from "@mui/material"
import { AxiosError } from "axios"
import { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"

import SubmitButton from "../../components/buttons/submitButton"
import { StyledDropzone } from "../../components/StyledDropzone"
import { StyledModal } from "../../components/StyledModal"
import { Toolbar, ToolbarConfig } from "../../components/Toolbar"
import { classificationQueries } from "../../networking/networking"
import { useSnackbarStore, useUserStore } from "../../stateManagement"
import { List } from "./components/List"

export const Classification = () => {
	const userRole = useUserStore((state) => state.userRole)

	const handleSubtitle = () => {
		switch (userRole) {
			case "superadmin":
				return "Here you can view, import and export the products classification."
			case "reader":
				return "Here you can view the products classification."
			default:
				return "Here you can view and export the products classification."
		}
	}
	//configure toolbar from here
	const toolbarConfig: ToolbarConfig = {
		title: "Classification",
		subtitle: handleSubtitle(),
		buttons: [{
			name: "Upload file",
			// icon: <AddOutlinedIcon />,
			action: () => {
				setIsNewCategoryModalOpen(true)
				setUploadedFile([])
			},
			hide: userRole !== "superadmin"
		},
		{
			name: "Export file",
			// // icon: <CachedOutlinedIcon />,
			action: () => downloadClassificationsFileMutation.mutate(),
			hide: !["superadmin", "admin"].includes(userRole)
		},
		]
	}

	const [isNewCategoryModalOpen, setIsNewCategoryModalOpen] = useState(false)

	const [subCategories, setSubCategories] = useState<string[] | undefined>()
	const [typologies, setTypologies] = useState<string[] | undefined>()
	const [products, setProducts] = useState<string[] | undefined>()

	const [selectedCategory, setSelectedCategory] = useState<string>()
	const [selectedSubCategory, setSelectedSubCategory] = useState<string>()
	const [selectedTypology, setSelectedTypology] = useState<string>()

	const [uploadedFile, setUploadedFile] = useState<File[]>([])

	const openSnackbar = useSnackbarStore((state) => state.openSnackbar)

	const { data: classifications = [], refetch: refetchClassification } = useQuery(
		classificationQueries.listClassifications.name,
		classificationQueries.listClassifications.fn
	)

	const downloadClassificationsFileMutation = useMutation(
		classificationQueries.downloadClassificationsFile.name,
		classificationQueries.downloadClassificationsFile.fn,
		{
			onSuccess: (response) => {
				const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
				const blobURL = window.URL.createObjectURL(blob)
				const tempLink = document.createElement("a")
				tempLink.style.display = "none"
				tempLink.href = blobURL
				tempLink.download = "classification.xlsx"
				tempLink.setAttribute("target", "_blank")
				document.body.appendChild(tempLink)
				tempLink.click()
				document.body.removeChild(tempLink)
				window.URL.revokeObjectURL(blobURL)
				openSnackbar("success", "Classification successfully exported")
			},
			onError: (data) => {
				console.log(data)
				openSnackbar("error", "An error occurred while exporting the classification")
			}
		}
	)
	const uploadClassificationsFileMutation = useMutation(
		classificationQueries.uploadClassificationsFile.name,
		classificationQueries.uploadClassificationsFile.fn,
		{
			onSuccess: () => {
				refetchClassification()
				openSnackbar("success", "Classification successfully imported")
				setSelectedCategory(undefined)
				setSelectedSubCategory(undefined)
				setSelectedTypology(undefined)
				setSubCategories(undefined)
				setTypologies(undefined)
				setProducts(undefined)
			},
			onError: (error: AxiosError) => {
				console.log("error import classification", error?.response?.data as string)
				openSnackbar("error", error?.response?.status === 400 ? error?.response?.data as string : "error import classification")
			}
		}
	)

	//update subCategories list when a category is selected
	useEffect(() => {
		if (selectedCategory) {
			const filteredData = classifications
				.filter(c => c.category === selectedCategory)
				.map(c => c.subcategory)
			setSubCategories(Array.from(new Set(filteredData)))
			setTypologies(undefined)
			setProducts(undefined)
			setSelectedSubCategory(undefined)
			setSelectedTypology(undefined)
		}

	}, [selectedCategory])

	//update Typologies list when a subcategory is selected
	useEffect(() => {
		if (selectedSubCategory) {
			const filteredData = classifications
				.filter(c => c.category === selectedCategory && c.subcategory === selectedSubCategory)
				.map(c => c.typology)
			setTypologies(Array.from(new Set(filteredData)))
			setProducts(undefined)
			setSelectedTypology(undefined)
		}

	}, [selectedSubCategory])

	//update Products list when a Typology is selected
	useEffect(() => {
		if (selectedTypology) {
			const filteredData = classifications
				.filter(c => c.category === selectedCategory && c.subcategory === selectedSubCategory && c.typology === selectedTypology)
				.map(c => c.product)
			setProducts(Array.from(new Set(filteredData)))
		}

	}, [selectedTypology])

	const handleNewCategoryModalClose = () => {
		setUploadedFile([])
		setIsNewCategoryModalOpen(false)
	}

	return (
		<Grid container padding={3} >
			<Toolbar config={toolbarConfig} />
			{uploadClassificationsFileMutation.isLoading ? <Grid container justifyContent="center"><CircularProgress /></Grid> :
				<Grid item container rowSpacing={2}>
					{/* CATEGORIES */}
					<Grid item xs={12}>
						<List sectionTitle="Category" list={Array.from(new Set(classifications.map(c => c.category)))} onChipClick={setSelectedCategory} selectedChip={selectedCategory} />
					</Grid>

					{/* SUBCATEGORIES */}
					{subCategories &&
						<Grid item xs={12}>
							<List sectionTitle="Sub-category" list={subCategories} onChipClick={setSelectedSubCategory} selectedChip={selectedSubCategory} />
						</Grid>
					}

					{/* TYPOLOGIES */}
					{typologies &&
						<Grid item xs={12}>
							<List sectionTitle="Typology" list={typologies} onChipClick={setSelectedTypology} selectedChip={selectedTypology} />
						</Grid>
					}

					{/* PRODUCTS */}
					{products &&
						<Grid item xs={12}>
							<List sectionTitle="Product" list={products} clickable={false} />
						</Grid>
					}
				</Grid>
			}
			<StyledModal isOpen={isNewCategoryModalOpen} handleClose={handleNewCategoryModalClose} title="Upload file">
				<Grid container justifyContent="center" sx={{ marginBottom: 3 }}>
					<Grid item container justifyContent="center" alignItems="center">
						<StyledDropzone
							acceptedFileFormats={{ "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"] }}
							file={uploadedFile}
							setFile={setUploadedFile}
						/>
					</Grid>
					<Grid item marginTop={4}>
						<SubmitButton
							text="Upload"
							disabled={!uploadedFile || !uploadedFile.length}
							onClick={() => {
								setIsNewCategoryModalOpen(false)
								uploadClassificationsFileMutation.mutate(uploadedFile[0])
							}}
						/>
					</Grid>
				</Grid>
			</StyledModal>
		</Grid>
	)
}
