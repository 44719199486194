import { Box, Grid, Typography } from "@mui/material"

import { ReactComponent as LogoBiancoP } from "../../../assets/icons/logo bianco_p.svg"
import { ReactComponent as LogoT } from "../../../assets/icons/logo_t_arancio.svg"

interface Props {
	projectName: string,
	companyName: string | undefined
}

export const Cover = (props: Props) => {
	return (
		<Grid container height="100%">
			<Grid item container xs={6} direction="column" alignItems="center" justifyContent="center" sx={{ backgroundColor: theme => theme.palette.primary.main }}>
				<Typography variant="h3" sx={{ textTransform: "uppercase" }} fontWeight="bold" textAlign="center">{props.projectName}</Typography>
				<Typography variant="h4" sx={{ textTransform: "uppercase" }} mt={2} textAlign="center">{props.companyName}</Typography>
			</Grid>
			<Grid item xs={6} position="relative">
				<Box
					component="img"
					alt="dashboard cover"
					src={require("../../../assets/dashobard_cover.jpg")}
					sx={{
						objectFit: "cover",
						objectPosition: "center",
						maxWidth: "100%",
						minWidth: 500,
						height: "100%"
					}}
				/>
				<LogoT
					style={{
						position: "absolute",
						top: 10,
						right: 10,
						height: 75
					}}
				/>
				<LogoBiancoP
					style={{
						position: "absolute",
						bottom: 30,
						left: "calc(50% - 133px / 2)",
						height: 50
					}}
				/>
			</Grid>
		</Grid>
	)
}