import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { RateReview } from "@mui/icons-material"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import { Checkbox, Grid, IconButton, InputAdornment, TextField } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import _ from "lodash"
import { useState } from "react"
import { Control, Controller, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch, useWatch } from "react-hook-form"

import { CustomButton } from "../../../../../components/buttons/CustomButton"
import CustomDialog from "../../../../../components/CustomDialog"
import { ParagraphTypography } from "../../../../../components/customTypography"
import { useOperatorStore } from "../../../../../stateManagement"
import { language, Phase } from "../../../../../types/genericTypes"
import { getPhase } from "../../../../dashboard/components/DashboardProductCard"
import { PhasesFormValues } from "./ConfigureProductPhasesForm"
import UploadButton from "./UploadButton"

interface props {
	templateID: number | null | undefined,
	phase: Phase,
	testerMode: boolean | undefined
	projectLanguage?: language
	control: Control<PhasesFormValues>,
	register: UseFormRegister<PhasesFormValues>,
	setPhase: UseFormSetValue<PhasesFormValues>,
	watch: UseFormWatch<PhasesFormValues>,
	getValues: UseFormGetValues<PhasesFormValues>
	userCanEdit?: boolean
}

const dragIconStyle = {
	color: "text.secondary",
	cursor: "grab",
	"&:active": {
		cursor: "grabbing",

	},
	"&:focus": {
		outline: "none"
	}
}

export const PhaseRow = (props: props) => {
	const isUserOperator = useOperatorStore().isUserOperator
	const [isConceptDescriptionModalOpen, setIsConceptDescriptionModalOpen] = useState(false)

	const isSelected = useWatch({
		control: props.control,
		name: `${props.phase.id}.selected`
	})

	const textfieldInputStyle = {
		height: "56px",
		background: !isSelected ? "#f1f1f1" : undefined,
		fontWeight: 400,
		fontSize: "14px",
	}

	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: props.phase.id,
		disabled: !isSelected || Boolean(props.templateID) || !props.userCanEdit,
	})
	const draggableStyle = {
		transform: CSS.Transform.toString(transform),
		transition
	}

	const isCheckboxDisabled = (checked: boolean, isDirty: boolean) => {
		if (isUserOperator) {
			if (isDirty) {
				return false
			} else {
				if (checked) {
					return false
				} else {
					return true
				}
			}
		} else {
			return false
		}
	}
	const companyPhaseDescription = _.find(props.phase.description_i18n, description => description.company_id !== null && description.language === props.projectLanguage)
	const commonPhaseDescription = _.find(props.phase.description_i18n, description => description.company_id === null && description.language === props.projectLanguage)

	return (
		<Grid ref={setNodeRef} style={draggableStyle} >
			<Grid
				container
				alignItems="center"
				justifyContent={props.testerMode ? "space-between" : "center"}
			>
				{/* NAME FIELD */}
				<Grid item container xs={4}>
					<Grid item container alignItems="center" height="56px">
						<Grid
							item
							container
							flexWrap="nowrap"
							alignItems="center"
						>
							<DragIndicatorIcon
								sx={dragIconStyle}
								{...attributes}
								{...listeners}
							/>
							<Grid item width="fit-content">
								<Controller
									control={props.control}
									name={`${props.phase.id}.selected`}
									render={({ field, fieldState }) =>
										<Checkbox
											size="medium"
											{...field}
											checked={!!field.value}
											disabled={Boolean(props.templateID) || isCheckboxDisabled(!!field.value, fieldState.isDirty) || !props.userCanEdit}
										/>
									}
								/>
							</Grid>
							<Grid item container width="fit-content" paddingX={2}>
								<Grid item>
									{getPhase(props.phase.name.toLowerCase())}
								</Grid>
							</Grid>
							<Grid item alignItems="center">
								{props.phase.order === 9 ?
									<TextField
										{...props.register(`${props.phase.id}.name`)}
										disabled={!isSelected || Boolean(props.templateID) || !props.userCanEdit}
										required
										InputProps={{
											style: textfieldInputStyle
										}}
										placeholder="Other phase name"
									/>
									: <ParagraphTypography> {props.phase.name} </ParagraphTypography>
								}
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				{/* DURATION FIELD */}
				<Grid item container xs={1}>
					<Grid
						item
						alignItems="center"
					>
						<TextField
							{...props.register(`${props.phase.id}.duration`, { valueAsNumber: true })}
							type="number"
							required
							disabled={!isSelected || !props.userCanEdit}
							sx={!isSelected ? { backgroundColor: "#f1f1f1" } : undefined}
							inputProps={{
								style: { textAlign: "center" },
								min: 6
							}}
							InputProps={{
								endAdornment: <InputAdornment position="end">s</InputAdornment>,
							}}
						/>
					</Grid>
				</Grid>

				{/* DESCRIPTION */}
				<Grid item container xs={props.testerMode ? 6 : 1} columnGap={1}>
					<Grid item container alignItems="center" xs={10}>
						{props.phase.id !== 9 && props.testerMode &&
							<Tooltip
								title={companyPhaseDescription?.value || commonPhaseDescription?.value || "-"}
								placement="bottom"
							>
								<ParagraphTypography
									sx={{
										overflow: "hidden",
										textOverflow: "ellipsis",
										display: "-webkit-box",
										WebkitLineClamp: "2",
										WebkitBoxOrient: "vertical",
									}}
								>
									{companyPhaseDescription?.value || commonPhaseDescription?.value || "-"}
								</ParagraphTypography>
							</Tooltip>
						}

						{/* CUSTOM PHASE DESCRIPTION */}
						{props.phase.id >= 9 && props.testerMode &&
							<Grid item xs={12} >
								<TextField
									{...props.register(`${props.phase.id}.description`)}
									required
									fullWidth
									disabled={!isSelected || !props.userCanEdit}
									placeholder="Tester mode description"
									InputProps={{
										style: textfieldInputStyle
									}}

								/>
							</Grid>
						}
					</Grid>

					{/* CONCEPT DESCRIPTION DIALOG */}
					{props.testerMode &&
						<Grid item container xs={1} alignItems="center">
							{props.phase.name === "Concept" &&
								<Grid item>
									<IconButton disabled={!isSelected} onClick={() => setIsConceptDescriptionModalOpen(true)}>
										<RateReview color={isSelected ? "primary" : "disabled"} />
									</IconButton>
								</Grid>
							}
						</Grid>
					}
				</Grid>

				<CustomDialog
					open={isConceptDescriptionModalOpen}
					onClose={() => { setIsConceptDescriptionModalOpen(false) }}
					title="Description"
				>
					<Grid container gap={2} justifyContent="center" alignItems="center">
						<Grid item xs={9}>
							<TextField
								{...props.register(`${props.phase.id}.description`)}
								required
								fullWidth
								disabled={!isSelected}
								placeholder="Tester mode description"
								InputProps={{
									style: textfieldInputStyle
								}}
							/>
						</Grid>
						<Grid item>
							<UploadButton
								disabled={!props.userCanEdit}
								file={props.getValues(`${props.phase.id}.image`)}
								setFile={(file) => {
									props.setPhase(`${props.phase.id}.image`, file)
								}}
							/>
						</Grid>
						<Grid container justifyContent="center">
							<Grid item marginTop={1}>
								<CustomButton text="Close" action={() => setIsConceptDescriptionModalOpen(false)} primary />
							</Grid>
						</Grid>
					</Grid>
				</CustomDialog>
				{/* offset x allineamento label */}
				{/* <Grid item xs={1} /> */}
			</Grid>
		</Grid >
	)
}
