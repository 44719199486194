import { Autorenew, Settings } from "@mui/icons-material"
import CheckIcon from "@mui/icons-material/Check"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import {
	Avatar,
	Box,
	Card,
	CardActions,
	CardContent,
	Chip,
	Grid,
} from "@mui/material"
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress"
import { linearProgressClasses } from "@mui/material/LinearProgress"
import dayjs from "dayjs"
import _ from "lodash"

import { endpoint } from "../App"
import { ReactComponent as BarChartIcon } from "../assets/icons/barChartIcon.svg"
import { ReactComponent as DownloadIcon } from "../assets/icons/downloadIcon.svg"
import { ReactComponent as LocationIcon } from "../assets/icons/locationIcon.svg"
import { ReactComponent as StartIcon } from "../assets/icons/startIcon.svg"
import { TBOX_MODE } from "../constants"
import { StyledIconButton } from "../pages/users/components/userCard"
import { useUserStore } from "../stateManagement"
import { Project } from "../types/genericTypes"
import { DashboardCardParagraphTypography, DashboardCardParagraphTypographyBold, DashboardCardTitleTypography } from "./customTypography"
interface propsType {
	project: Project,
	onEdit: () => void,
	onDelete: () => void,
	onPlay: () => void
	onDownload?: () => void
	onAnalytics?: () => void
	userCanEdit: boolean
}
//--------------- STYLE -------------------------
const cardStyle = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "flex-start",
	width: 300,
	height: 200,
}
const cardContentStyle = {
	flexWrap: "nowrap",
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	width: "inherit",
	height: "130px"
}

const linearProgressWithLabelStyle = {
	height: 10,
	borderRadius: 5,
	backgroundColor: "#DEDEDE",
	[`& .${linearProgressClasses.bar}`]: {
		backgroundColor: "#556FFC",
	}
}
const avatarStyle = {
	height: 80,
	width: 80,
	marginRight: "32px",
}
interface propsLinearProgress extends LinearProgressProps {
	inConfiguration: boolean
	completed: boolean
	value: number
}

//-----------------------------------
const LinearProgressWithLabel = (props: propsLinearProgress) => {
	return (
		<Grid
			container
			flexDirection="column"
			width="90%"
			sx={{
				display: "flex",
				alignItems: "center"
			}}
		>
			<Grid item container justifyContent="space-between" padding="0 8px 0 8px">
				<Grid item>
					<DashboardCardParagraphTypography>
						Status: {props.inConfiguration ? "In Configuration" : props.completed ? "Completed" : "In progress"}
					</DashboardCardParagraphTypography>
				</Grid>
				{(TBOX_MODE && !props.inConfiguration && !props.completed) &&
					<Grid item>
						<DashboardCardParagraphTypography>
							{`${Math.round(props.value)}%`}
						</DashboardCardParagraphTypography>
					</Grid>}
			</Grid>
			{(TBOX_MODE && !props.inConfiguration && !props.completed) &&
				<Grid
					item
					sx={{
						width: "100%",
					}}
				>
					<LinearProgress variant="determinate" {..._.omit(props, ["inConfiguration", "completed"])} />
				</Grid>
			}
		</Grid>
	)
}

export default function ProjectCard(props: propsType) {
	const coloredStripeStyle =
	{
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		backgroundColor: "#F5C39C",
		width: "inherit",
		height: "inherit",
		padding: "0 8px 0 8px"
	}
	const userRole = useUserStore((state) => state.userRole)

	const userCanDownload = () => {
		if (TBOX_MODE) return false
		if (dayjs.utc().isBefore(props.project.start_datetime)) return false
		if (!props.project.configured) return false
		if (!["superadmin", "admin"].includes(userRole)) return false
		if (userRole === "admin" && !props.project.completed) return false
		return true
	}

	const userCanPlay = () => {
		if (!TBOX_MODE) return false
		if (!props.project.configured) return false
		if (dayjs.utc().isBefore(props.project.start_datetime)) return false
		if (props.project.completed) return false
		return true
	}

	const userCanViewGraphics = () => {
		if (TBOX_MODE) {
			if (!props.project.configured) return false
			if (dayjs.utc().isBefore(props.project.start_datetime)) return false
		} else {
			if (!props.project.configured) return false
			if (dayjs.utc().isBefore(props.project.start_datetime)) return false
			if (userRole !== "superadmin" && !props.project.completed) return false
		}
		return true
	}
	const userCanDelete = () => {
		if (TBOX_MODE) return false
		if (!["superadmin", "admin"].includes(userRole)) return false
		if (props.project.completed) {
			if (dayjs.utc().isAfter(props.project.start_datetime)) {
				if (["superadmin"].includes(userRole)) return true
			}
		}
		if (props.project.configured) {
			if (dayjs.utc().isAfter(props.project.start_datetime)) return false
		}
		return true
	}

	const projectCardStatus = () => {
		if (TBOX_MODE) {
			if (!props.project.configured) {
				return <Chip icon={<InfoOutlinedIcon style={{ color: "#7A0A04" }} />} label="Invalid configuration" sx={{ backgroundColor: "#FFD3D3" }} size="small" />
			} else if (props.project.completed) {
				return <Chip icon={<CheckIcon style={{ color: "#1F7B18" }} />} label="Project completed" sx={{ backgroundColor: "#F2FFE4" }} size="small" />
			} else if (dayjs().isAfter(props.project.start_datetime)) {
				return (
					<LinearProgressWithLabel
						variant="determinate"
						value={props.project.progress || 0}
						sx={linearProgressWithLabelStyle}
						inConfiguration={dayjs.utc().isBefore(props.project.start_datetime)}
						completed={props.project.completed}
					/>
				)
			} else {
				return <Chip icon={<Settings style={{ color: "#94791B" }} />} label="In Configuration" sx={{ backgroundColor: "#FFE9BF" }} size="small" />
			}
		} else {
			if (!props.project.configured) {
				return <Chip icon={<InfoOutlinedIcon style={{ color: "#7A0A04" }} />} label="Invalid configuration" sx={{ backgroundColor: "#FFD3D3" }} size="small" />
			} else if (props.project.completed) {
				return <Chip icon={<CheckIcon style={{ color: "#1F7B18" }} />} label="Project completed" sx={{ backgroundColor: "#F2FFE4" }} size="small" />
			} else if (dayjs.utc().isAfter(props.project.start_datetime)) {
				return <Chip icon={<Autorenew style={{ color: "#506585" }} />} label="Running" sx={{ backgroundColor: "#E1EFFF" }} size="small" />
			} else {
				return <Chip icon={<Settings style={{ color: "#94791B" }} />} label="In Configuration" sx={{ backgroundColor: "#FFE9BF" }} size="small" />
			}
		}
	}


	return (
		<Card sx={cardStyle}>
			<Grid container height="35px">
				<Box sx={coloredStripeStyle}>
					<CardActions
						sx={{
							width: "inherit",
						}}
					>
						<Grid item container spacing={1.5}>
							{userCanPlay() &&
								<Grid item>
									<StyledIconButton onClick={props.onPlay}>
										<StartIcon />
									</StyledIconButton>
								</Grid>
							}
							{userCanViewGraphics() &&
								<Grid item >
									<StyledIconButton onClick={props.onAnalytics}>
										<BarChartIcon />
									</StyledIconButton>
								</Grid>
							}
							{userCanDownload() &&
								<Grid item>
									<StyledIconButton onClick={props.onDownload}>
										<DownloadIcon />
									</StyledIconButton>
								</Grid>
							}
						</Grid>

						<StyledIconButton onClick={props.onEdit}>
							{props.userCanEdit ?
								<EditIcon
									sx={{
										fontSize: "16px",
									}}
								/> :
								<RemoveRedEyeIcon
									sx={{
										fontSize: "16px",
									}}
								/>
							}
						</StyledIconButton>

						{
							userCanDelete() &&
							<StyledIconButton onClick={props.onDelete} >
								<DeleteIcon
									sx={{
										fontSize: "16px",
									}}
								/>
							</StyledIconButton>
						}
					</CardActions>
				</Box>
			</Grid>
			<CardContent sx={cardContentStyle}>
				<Avatar
					sx={avatarStyle}
					src={props.project.image ? `${endpoint}/api/medias/default/${props.project.image.file_id}` : ""}
					alt="Profile Picture"
				/>
				<Grid container direction="column" spacing={1}>
					<Grid item>
						<DashboardCardTitleTypography>
							{props.project.name}
						</DashboardCardTitleTypography>
					</Grid>
					<Grid item container direction="column">
						<Grid item container spacing={1}>
							<Grid item width="30px" >
								<DashboardCardParagraphTypographyBold color="#993000">
									{props.project.testers_count}
								</DashboardCardParagraphTypographyBold>
							</Grid>
							<Grid item>
								<DashboardCardParagraphTypographyBold>
									Testers
								</DashboardCardParagraphTypographyBold>
							</Grid>
						</Grid>
						<Grid item container spacing={1} >
							<Grid item width="30px" >
								<DashboardCardParagraphTypographyBold color="#993000">
									{props.project.products_count}
								</DashboardCardParagraphTypographyBold>
							</Grid>
							<Grid item>
								<DashboardCardParagraphTypographyBold>
									Products
								</DashboardCardParagraphTypographyBold>
							</Grid>
						</Grid>
						{props.project.place &&
							<Grid item container wrap="nowrap" spacing={1}>
								<Grid item width="30px">
									<DashboardCardParagraphTypographyBold>
										<LocationIcon />
									</DashboardCardParagraphTypographyBold>
								</Grid>
								<Grid item>
									<DashboardCardParagraphTypographyBold>
										{props.project.place}
									</DashboardCardParagraphTypographyBold>
								</Grid>
							</Grid>
						}
					</Grid>
				</Grid>
			</CardContent>
			{projectCardStatus()}

			{/* {props.project.configured ?
				<LinearProgressWithLabel
					variant="determinate"
					value={props.project.progress || 0}
					sx={linearProgressWithLabelStyle}
					inConfiguration={dayjs.utc().isBefore(props.project.start_datetime)}
					completed={props.project.completed}
				/>
				:
				<Chip icon={<InfoOutlinedIcon color="primary" />} label="Invalid configuration" sx={{ backgroundColor: "#FFD3D3" }} size="small" />

			} */}
		</Card >
	)
}
