import { SvgIcon, SvgIconProps } from "@mui/material"

const imageIcon = {
	overflow: "visible"
}
export function BaselineIcon(props: SvgIconProps) {
	return (
		<SvgIcon
			{...props}
			sx={{
				...props.sx,
				...imageIcon
			}}
		>
			<path d="M18.0362 7.88779L16.0543 14.8241C15.9218 15.2877 15.6833 15.6655 15.3798 15.9536H11.9368C11.7379 15.7622 11.5643 15.5293 11.4267 15.2542L9.7664 11.9336L7.7563 15.9536H4.50403L7.5363 9.88909C8.45515 8.05128 11.0779 8.05128 11.9968 9.88909L13.5511 12.9976L15.6509 5.64829C16.3453 3.21776 19.7989 3.24424 20.4562 5.68507L23.2207 15.9536H20.2082L18.0362 7.88779Z" />
			<path d="M1.38715 22.1875H4.63942L2.75587 25.9547C2.39659 26.6732 1.52286 26.9645 0.804406 26.6052C0.0858726 26.246 -0.205381 25.3723 0.153873 24.6538L1.38715 22.1875Z" />
			<path d="M25.3723 23.9467L24.8986 22.1875H21.8861L22.9583 26.1702C23.618 28.6204 27.0895 28.6343 27.7684 26.1894L28.8801 22.1875H25.8609L25.3723 23.9467Z" />
			<path d="M27.5922 15.9538H30.6114L31.9462 11.1485C32.1612 10.3745 31.708 9.57274 30.934 9.35775C30.16 9.14275 29.3582 9.59598 29.1433 10.3699L27.5922 15.9538Z" />
			<path d="M1.35754 20.1097H3.37045V18.0317H1.35754V20.1097Z" />
			<path d="M5.51331 20.1097H7.52632V18.0317H5.51331V20.1097Z" />
			<path d="M9.53918 20.1097H11.5521V18.0317H9.53918V20.1097Z" />
			<path d="M13.5651 20.1097H15.578V18.0317H13.5651V20.1097Z" />
			<path d="M17.5909 20.1097H19.6038V18.0317H17.5909V20.1097Z" />
			<path d="M21.6168 20.1097H23.6297V18.0317H21.6168V20.1097Z" />
			<path d="M25.6427 20.1097H27.6556V18.0317H25.6427V20.1097Z" />
			<path d="M29.6694 20.1097H31.6823V18.0317H29.6694V20.1097Z" />
		</SvgIcon >
	)
}
