import {
	AddOutlined as AddOutlinedIcon,
	/*FilterAltOutlined as FilterAltOutlinedIcon,
	KeyboardArrowDownOutlined as KeyboardArrowDownOutlinedIcon*/
} from "@mui/icons-material"
import { VisibilityOff } from "@mui/icons-material"
import Visibility from "@mui/icons-material/Visibility"
import { CircularProgress, Grid, IconButton, InputAdornment, MenuItem, Select, styled, TextField } from "@mui/material"
import { Auth } from "aws-amplify"
import _ from "lodash"
import React, { useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"

import SubmitButton from "../../components/buttons/submitButton"
import CustomDialog from "../../components/CustomDialog"
import { ParagraphTypography, ProjectMenuTitleTypography } from "../../components/customTypography"
import { StyledDropzone } from "../../components/StyledDropzone"
import { StyledModal } from "../../components/StyledModal"
import { Toolbar, ToolbarConfig } from "../../components/Toolbar"
import { companyQueries, operatorQueries, userQueries } from "../../networking/networking"
import { ReceivedCognitoUser } from "../../networking/types"
import { useSnackbarStore, useUserStore } from "../../stateManagement"
import { Operator, User } from "../../types/genericTypes"
import { Company, UserRole } from "../../types/genericTypes"
import { companiesSorter, usersSorter } from "../../utils"
import UserCard from "./components/userCard"
const simpleRoles = ["superadmin", "admin", "reader"]

interface NewUserFormInput {
	role: string,
	company_id: string,
	email: string,
	fullName: string,
	password: string,
	profilePicture: File,
}

interface signUpParams {
	data: NewUserFormInput,
	companyGroup: string
}

interface EditUserFormInput {
	name: string,
	profilePicture: File,
	password: string,
	role: string,
	email: string,
}


interface EditOperatorFormInput {
	name: string,
	profilePicture: File,
	password: string,
	email: string,
}

interface ModalState {
	open: boolean,
	username?: string,
	operatorId?: number,
}

interface EditUserModalState {
	open: boolean,
	user?: User,
}

interface editOperatorModal {
	open: boolean,
	operator?: Operator,
}


// ------- style ---------
const StyledTextField = styled(TextField)({
	height: 50,
	width: 430,
	"& label.Mui-focused": {
		color: "c6c6c6",
	},
	"& .MuiOutlinedInput-root": {
		backgroundColor: "#F6F6F6",
		"& fieldset": {
			borderColor: "#E1E1E1",
		},
		"&:hover fieldset": {
			borderColor: "#E1E1E1",
		},
	}
}) as typeof TextField

// const uploadButtonStyle = {
// 	backgroundColor: "#F6F6F6",
// 	color: "#010101",
// 	width: 200,
// 	height: 45,
// 	borderRadius: 2
// }
//-----------------------

const roles: UserRole[] = [
	{
		id: "superadmin",
		name: "Superadmin"
	},
	{
		id: "admin",
		name: "Company admin"
	},
	{
		id: "reader",
		name: "Company reader"
	},
	{
		id: "operator",
		name: "TBox operator"
	},
]

const rolesForEdit: UserRole[] = [
	{
		id: "admin",
		name: "Company admin"
	},
	{
		id: "reader",
		name: "Company reader"
	},

]

const Users = () => {
	const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false)
	const [isEditOperatorModalOpen, setIsEditOperatorModalOpen] = useState<editOperatorModal>({ open: false })
	const [isEditModalOpen, setIsEditModalOpen] = useState<EditUserModalState>({ open: false })
	const [isDeleteActionModalOpen, setIsDeleteActionModalOpen] = useState<ModalState>({ open: false })
	const [profilePicture, setProfilePicture] = useState<File[]>([])
	const userRole = useUserStore((state) => state.userRole)
	const userCompany = useUserStore((state) => state.userCompany)
	const userStore = useUserStore()
	const getCurrentUserSub = async () => {
		const currentUser = await Auth.currentSession().then((res) => res.getIdToken())
		return currentUser.payload.sub
	}

	const [showPassword, setShowPassword] = React.useState(false)


	const handleClickShowPassword = () => setShowPassword((show) => !show)
	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	const { openSnackbar } = useSnackbarStore((state) => ({
		openSnackbar: state.openSnackbar
	}))

	const { data: companies = [] } = useQuery(
		companyQueries.listCompanies.name,
		companyQueries.listCompanies.fn,
		{
			select: data => data.sort(companiesSorter)
		}
	)

	const { data: usersWithGroups = [], isFetching: isUsersLoading, refetch: refetchUsers } = useQuery(
		userQueries.listUsersWithGroups.name,
		userQueries.listUsersWithGroups.fn,
	)

	const { data: operatorsUsers = [], isFetching: isOperatorsLoading, refetch: refetchOperators } = useQuery(
		operatorQueries.listOperators.name,
		operatorQueries.listOperators.fn,
	)

	const {
		register: registerNewUser,
		handleSubmit: handleSubmitNewUser,
		control: newUserControl,
		watch: newUserWatch,
		reset: newUserReset,
		formState: { errors },
	} = useForm<NewUserFormInput>({
		defaultValues: {
			role: "",
			company_id: "",
			email: "",
			fullName: "",
			password: "",
			profilePicture: undefined,
		}
	})


	const createOperatorMutation = useMutation(
		operatorQueries.createOperator.name,
		operatorQueries.createOperator.fn,
		{
			onSuccess: () => {
				setIsNewUserModalOpen(false)
				refetchOperators()
				openSnackbar("success", "Operator successfully created")
			},
			onError: () => {
				openSnackbar("error", "An error occurred while creating the Operator")
			}
		}
	)

	const createCloudUser = useMutation(
		userQueries.adminCreateUser.name,
		userQueries.adminCreateUser.fn,
		{
			onError: (error: { response: { status: number, data: { message: string } } }) => {
				if (error.response.status === 400) {
					openSnackbar("error", error.response.data.message)
				} else
					openSnackbar("error", "An error occurred while creating the user")
			}
		}
	)

	const addUserToGroup = useMutation(
		userQueries.addToGroup.name,
		userQueries.addToGroup.fn,
		{
			onSuccess: () => {
				console.log("User added to group") //Leave this log!
				refetchUsers()
			},
			onError: () => {
				openSnackbar("error", "An error occurred while adding the user to the group")
			}


		}
	)

	const removeUserFromGroup = useMutation(
		userQueries.removeFromGroup.name,
		userQueries.removeFromGroup.fn,
		{
			onError: () => {
				openSnackbar("error", "An error occurred while removing the user from the group")
			}
		}
	)

	const deleteCognitoUser = useMutation(
		userQueries.deleteUser.name,
		userQueries.deleteUser.fn,
		{
			onSuccess: () => {
				console.log("user deleted from cognito")
				refetchUsers()
				setIsDeleteActionModalOpen({ open: false })
				openSnackbar("success", "User successfully deleted")
			},
			onError: () => {
				openSnackbar("error", "An error occurred while deleting the user")
			}
		}
	)


	const deleteOperatorUser = useMutation(
		operatorQueries.deleteOperator.name,
		operatorQueries.deleteOperator.fn,
		{
			onSuccess: () => {
				refetchOperators()
				setIsDeleteActionModalOpen({ open: false })
				openSnackbar("success", "User successfully deleted")
			},
			onError: () => {
				openSnackbar("error", "An error occurred while deleting the user")
			}
		}
	)

	async function signUp({ data, companyGroup }: signUpParams) {
		try {
			await createCloudUser.mutateAsync(data)

			await addUserToGroup.mutateAsync({
				username: data.email,
				group: data.role
			})

			if (data.role != "superadmin") {
				await addUserToGroup.mutateAsync({
					username: data.email,
					group: userRole === "superadmin" ? companyGroup : userCompany ? userCompany.GroupName : ""
				})
			}
			// refetchUsers()
			setIsNewUserModalOpen(false)
			openSnackbar("success", "User successfully created")
		} catch (error) {
			console.log("error signing up:", error)
		}
	}

	const onNewUserSubmit: SubmitHandler<NewUserFormInput> = (data) => {
		const companyData = _.find(companies, { "id": data.company_id }) as Company
		const companyGroup = _.get(companyData, "cognito_group_name")
		const nonSuperAdminCompany = _.find(companies, { "cognito_group_name": userCompany?.GroupName }) as Company

		if (data.role === "operator") {
			createOperatorMutation.mutate({
				fullname: data.fullName,
				password: data.password,
				company_id: userRole === "superadmin" ? data.company_id : nonSuperAdminCompany.id.toString(),
				email: data.email,
				// image: undefined
			})

		} else {
			signUp({
				data,
				companyGroup
			})
		}

	}
	const watchNewUserRoleField = newUserWatch("role")

	//UPDATE COGNITO USER
	const { register: registerEditUser, handleSubmit: handleSubmitEditUser, reset: resetEditUser, control: editUserControl, formState: { isDirty: isEditUserFormDirty } } = useForm<EditUserFormInput>()

	const onEditUserSubmit: SubmitHandler<EditUserFormInput> = (data) => {
		const user = isEditModalOpen.user
		const currentUsername = user?.Attributes.find(attribute => attribute.Name === "name")?.Value
		const currentRole = user?.Groups.find(group => simpleRoles.includes(group.GroupName))?.GroupName
		const currentUserIdentifier = user?.Attributes.find(attribute => attribute.Name === "email")?.Value
		if (user) {
			if (data.name != currentUsername) {
				updateUser.mutate({
					username: user.Username,
					attributes: [
						{
							Name: "name",
							Value: data.name
						}
					]
				})
				// controllo se il "sub" (es: 79d74cee-dc47-4844-96be-3acde941f197) dell'utente loggato
				// è lo stesso di quello a cui sto modificando l'username. Se sì, aggiorno l'username nello userStore
				// così da non dover fare un logout per vedere il nuovo nome visualizzato nel "Welcome back,[username]"
				getCurrentUserSub().then((currentUserSub) => {
					if (user.Attributes[0].Value === currentUserSub) {
						userStore.setUserName(data.name)
					}
				})
			}

			// decommentare e rimuovere il disabled dal campo per dare la possibilità di modificare la mail. da testare quando il BE accetterà il campo
			// if (currentUserIdentifier !== data.email) {
			// 	updateUser.mutateAsync({
			// 		username: user.Username,
			// 		attributes: [
			// 			{
			// 				Name: "email",
			// 				Value: data.email
			// 			}
			// 		]
			// 	})
			// }

			//se l'utente ha modificato il ruolo
			if (data.role != currentRole && currentRole != undefined) {
				removeUserFromGroup.mutateAsync({
					username: user.Username,
					group: currentRole
				})

				if (currentUserIdentifier) {
					addUserToGroup.mutateAsync({
						username: currentUserIdentifier,
						group: data.role
					})
				}

			}
		}
		setIsEditModalOpen({ open: false })
	}
	//UPDATE OPERATOR
	const updateOperator = useMutation(
		operatorQueries.updateOperator.name,
		operatorQueries.updateOperator.fn,
		{
			onSuccess: () => {
				refetchOperators()
				setIsEditOperatorModalOpen({ open: false })
				openSnackbar("success", "Operator successfully edited")
			},
			onError: () => {
				openSnackbar("error", "An error occurred while editing the operator")
			}
		}
	)

	const {
		register: registerEditOperator,
		handleSubmit: handleSubmitEditOperator,
		reset: resetEditOperator,
		setValue: setValueEditOperator,
		formState: { isDirty: isEditOperatorFormDirty }
	} = useForm<EditOperatorFormInput>({
		defaultValues: {
			email: "",
			name: "",
			password: "",
			profilePicture: undefined,
		}
	})
	const onEditOperatorSubmit: SubmitHandler<EditOperatorFormInput> = (data) => {
		if (isEditOperatorModalOpen.operator?.id) {
			updateOperator.mutate({
				id: isEditOperatorModalOpen.operator.id,
				operator: {
					email: data.email,
					fullname: data.name,
					password: data.password,
					image: data.profilePicture,  // TODO: implement me
				}
			})
		}
	}

	//UPDATE USER
	const updateUser = useMutation(
		userQueries.updateUser.name,
		userQueries.updateUser.fn,
		{
			onSuccess: () => {
				setIsEditModalOpen({ open: false })
				refetchUsers()
				openSnackbar("success", "User successfully edited")
			},
			onError: () => {
				openSnackbar("error", "An error occurred while editing the user")
			}
		}
	)


	//----- configuration for toolbar -------
	const toolbarConfig: ToolbarConfig = {
		title: "Users",
		subtitle: "Manage the Companies Users from here",
		buttons: [
			{
				name: "New User",
				icon: <AddOutlinedIcon />,
				action: () => {
					setIsNewUserModalOpen(true)
					newUserReset()
				},
				disabled: !(userRole === "superadmin" || userRole === "admin")
			}
			/*{
				name: "Sort by",
				icon: <KeyboardArrowDownOutlinedIcon />,
				action: () => console.log("button3")
			},
			{
				name: "Filters",
				icon: <FilterAltOutlinedIcon />,
				action: () => console.log("button2")
			}*/]
	}

	const handleEditModalClose = () => {
		setIsEditModalOpen({ open: false })
	}
	const handleEditOperatorModalClose = () => {
		setIsEditOperatorModalOpen({ open: false })
		resetEditOperator()
	}
	const handleNewUserModalClose = () => {
		setIsNewUserModalOpen(false)
	}
	const handleDeleteUser = () => {
		if (isDeleteActionModalOpen.username) {
			deleteCognitoUser.mutate({ username: isDeleteActionModalOpen.username })
		} else if (isDeleteActionModalOpen.operatorId) {
			deleteOperatorUser.mutate(isDeleteActionModalOpen.operatorId)
		}
	}

	const userWithGroup = (groupName: string) => (user: ReceivedCognitoUser) => {
		return user.Groups.map(group => group.GroupName).includes(groupName)
	}
	const superAdminUsersFilter = (user: ReceivedCognitoUser) => {
		return userWithGroup("superadmin")(user)
	}
	const notSuperAdminUsersFilter = (user: ReceivedCognitoUser) => {
		return !superAdminUsersFilter(user)
	}



	const superAdminUsers = usersWithGroups.filter(superAdminUsersFilter)
	const notSuperAdminUsers = usersWithGroups.filter(notSuperAdminUsersFilter)

	const handleEditModalOpen = (user: ReceivedCognitoUser) => {
		setIsEditModalOpen({
			open: true,
			user: user
		})

		const currentRoleGroup = user.Groups.find(group => simpleRoles.includes(group.GroupName))?.GroupName
		if (currentRoleGroup) {
			resetEditUser({
				name: user.Attributes.find(attribute => attribute.Name === "name")?.Value,
				role: currentRoleGroup
			})
		}
	}

	const handleOperatorEditOpen = (operator: Operator) => {
		setIsEditOperatorModalOpen({
			open: true,
			operator: operator
		})


		resetEditOperator({
			name: operator.fullname,
			password: undefined,
			email: operator.email
		})

	}

	const setProfilePictureActionEditOperator = (files: File[]) => {
		setProfilePicture(files)
		setValueEditOperator("profilePicture", files[0], { shouldDirty: true })
	}

	const getCognitoUserCompany = (user: User) => {
		const companiesNames: string[] = companies.map((company) => company.name.toLowerCase())
		const userCompany = user.Groups.map((group) => group.Description).filter((description) => description && companiesNames.includes(description.toLowerCase()))
		if (userCompany[0]) {
			return userCompany[0].replace(/,/g, "")
		} else {
			return ""
		}
	}

	const isSelectedUserSuperAdmin = (user: User) => {
		const isSuperadmin = _.some(user.Groups, { GroupName: "superadmin" })

		if (isSuperadmin) {
			return true
		} else {
			return false
		}
	}

	return (
		<Grid container padding={3}>
			<Toolbar config={toolbarConfig} />
			{isUsersLoading || isOperatorsLoading ? <Grid container justifyContent="center"><CircularProgress /></Grid> :
				<>
					{
						userRole === "superadmin" &&
						<Grid container spacing={3} >
							{
								superAdminUsers.sort(usersSorter).map((user) => (
									<Grid item key={user.Username}>
										<UserCard
											user={user}
											onEdit={() => { handleEditModalOpen(user) }}
											onDelete={() => {
												setIsDeleteActionModalOpen({
													open: true,
													username: user.Username
												})
											}}
										/>
									</Grid>
								))
							}
						</Grid>
					}
					<Grid container spacing={3}>
						{companies.map(company => {
							if (userRole != "superadmin" && company.cognito_group_name != (userCompany?.GroupName || localStorage.getItem("company_group_name"))) {
								return
							} else {
								return (
									<React.Fragment key={company.id}>
										<Grid
											item
											key={company.id}
											xs={12}
											sx={{
												marginTop: "24px",
											}}
										>
											<ProjectMenuTitleTypography>{company.name}</ProjectMenuTitleTypography>
										</Grid>
										{notSuperAdminUsers.filter(userWithGroup(company.cognito_group_name)).sort(usersSorter).map((user) => (
											<Grid item key={user.Username}>
												<UserCard
													disableDelete={(!(userRole === "superadmin" || userRole === "admin"))}
													disableEdit={(!(userRole === "superadmin" || userRole === "admin"))}
													user={user}
													onEdit={() => { handleEditModalOpen(user) }}
													onDelete={() => {
														setIsDeleteActionModalOpen({
															open: true,
															username: user.Username
														})
													}}
												/>
											</Grid>
										))}
										{operatorsUsers.map((operator: Operator) => {
											if (operator.company_id === company.id) {
												const user = {
													Username: operator.fullname,
													Attributes: [{
														Name: "name",
														Value: "value"
													},
													{
														Name: "email",
														Value: operator.email
													}],
													Groups: [{
														GroupName: "operator",
														Description: "description"
													}],
													UserCreateDate: operator.created_at,
													UserLastModifiedDate: operator.updated_at,
													Enabled: true,
													UserStatus: "test"

												}
												return (
													<Grid item key={operator.id}>
														<UserCard
															key={operator.id}
															isOperator
															user={user}
															disableEdit={!(userRole === "superadmin" || userRole === "admin")}
															disableDelete={!(userRole === "superadmin" || userRole === "admin")}
															// onEdit={() => {
															// 	setIsEditOperatorModalOpen({
															// 		open: true,
															// 		operator: operator
															// 	})
															// }}
															onEdit={() => {
																handleOperatorEditOpen(operator)
															}}
															onDelete={() => {
																setIsDeleteActionModalOpen({
																	open: true,
																	operatorId: operator.id
																})
															}}
														/>
													</Grid>
												)

											}
										})}
										{notSuperAdminUsers.filter(userWithGroup(company.cognito_group_name)).length === 0 && !_.find(operatorsUsers, { "company_id": company.id }) && (
											<Grid item>
												<ParagraphTypography color="text.disabled">There are no users at the moment yet</ParagraphTypography>
											</Grid>
										)}

									</React.Fragment>
								)
							}
						})}
					</Grid>


					{/* NEW USER MODAL */}
					<CustomDialog
						open={isNewUserModalOpen}
						onClose={() => { handleNewUserModalClose() }}
						title="New User"
						subtitle="Fields with * are required"
					>
						<form onSubmit={handleSubmitNewUser(onNewUserSubmit)}>
							<Grid container justifyContent="center">
								<Grid item container xs={8} spacing={3}>
									{/* ROLE */}
									<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
										<Grid item>
											<ParagraphTypography width={110} height={21}>Role*</ParagraphTypography>
										</Grid>
										<Grid item>
											<Controller
												name="role"
												control={newUserControl}
												render={({ field }) => (
													<Select
														required
														displayEmpty
														fullWidth
														sx={{
															height: "45px",
															width: "430px",
															// overflow: "hidden",
															borderRadius: "3px",
															backgroundColor: "#f1f1f1",
														}}
														{...field}
													>
														<MenuItem value="" disabled><em>Select a role</em></MenuItem>
														{roles.map((role) => {
															if (userRole != "superadmin" && role.id === "superadmin") {
																return
															} else {
																return (
																	<MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
																)
															}
														}
														)}
													</Select>
												)}
												rules={{ required: true }}
											/>
										</Grid>
									</Grid>
									{
										userRole === "superadmin" &&
										<>
											{/* COMPANY */}
											<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
												<Grid item>
													<ParagraphTypography width={110} height={21}>Company*</ParagraphTypography>
												</Grid>
												<Grid item>
													<Controller
														name="company_id"
														control={newUserControl}
														render={({ field }) => (
															<Select
																disabled={!watchNewUserRoleField || watchNewUserRoleField === "superadmin"}
																required={Boolean(watchNewUserRoleField) && watchNewUserRoleField !== "superadmin"}
																displayEmpty
																fullWidth
																sx={{
																	height: "45px",
																	width: "430px",
																	// overflow: "hidden",
																	borderRadius: "3px",
																	backgroundColor: "#f1f1f1",
																}}
																{...field}
															>
																<MenuItem value="" disabled><em>Select a company</em></MenuItem>
																{companies.map((company) =>
																	<MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
																)}
															</Select>
														)}
														rules={{ required: watchNewUserRoleField && watchNewUserRoleField !== "superadmin" }}
													/>
												</Grid>
											</Grid>
										</>
									}
									<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
										<Grid item>
											<ParagraphTypography width={110} height={21}>Full name*</ParagraphTypography>
										</Grid>
										<Grid item>
											<StyledTextField
												{...registerNewUser("fullName", {
													validate: v => {
														const regex = /^[\w]+(?=.*[A-Za-z0-9 ]).{2,}$/
														return regex.test(v) || "Full name must contains at least 2 character"
													}
												})}
												required={true}
												placeholder="Write the name of the user here"
											/>
											<ParagraphTypography sx={{ marginTop: 1 }} color="red">{errors?.fullName?.message}</ParagraphTypography>
										</Grid>
									</Grid>
									{/* EMAIL */}
									<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
										<Grid item>
											<ParagraphTypography width={110} height={21}>Email*</ParagraphTypography>
										</Grid>
										<Grid item>
											<StyledTextField
												{...registerNewUser("email", {
													validate: (value: string) => {
														const regexp = new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,4})+$")
														if (!regexp.test(value)) {
															return "Invalid email format"
														}
														else return true
													}
												})}
												required={true}
												placeholder="Write the mail of the user here"
											/>
											<ParagraphTypography sx={{ marginTop: 1 }} color="red">{errors?.email?.message}</ParagraphTypography>
										</Grid>
									</Grid>
									{/* PASSWORD */}
									<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
										<Grid item>
											<ParagraphTypography width={110} height={21}>Password*</ParagraphTypography>
										</Grid>
										<Grid item>
											<StyledTextField
												{...registerNewUser("password", {
													validate: {
														passwordValidator: v => {
															const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[[\]{}()\-"#/\\,.^@$!%*?&<>':;|_~`+=])[A-Za-z\d[\]{}()\-"#/\\,.^@$!%*?&<>':;|_~`+=]{8,}$/
															return regex.test(v) || "Password must contain at least 8 characters, including an uppercase letter, a lowercase letter, a number, and a symbol."
														}
													}
												})}
												type={showPassword ? "text" : "password"}
												InputProps={{
													autoComplete: "new-password",
													endAdornment:
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowPassword}
																onMouseDown={handleMouseDownPassword}
																edge="end"
															>
																{showPassword ? <VisibilityOff /> : <Visibility />}
															</IconButton>
														</InputAdornment>
												}}
												required={true}
												placeholder="Write the password of the user here"
											/>
											<ParagraphTypography sx={{ marginTop: 1 }} color="red">{errors?.password?.message}</ParagraphTypography>
										</Grid>
									</Grid>
									{/* PROFILE PICTURE */}
									{/* <Grid item container spacing={3} justifyContent="flex-start" alignItems="center">
									<Grid item >
										<ParagraphTypography width={110} height={21}>Profile picture</ParagraphTypography>
									</Grid>
									<Grid item>
										<StyledDropzone
											acceptedFileFormats={{ "image/*": [".jpeg", ".png", ".svg", ".jpg"] }}
											file={profilePicture}
											setFile={setProfilePicture}
											registerField={{

												register: registerNewUser,
												fieldName: "image"

											}} />
									</Grid>
								</Grid> */}
									<Grid item container justifyContent="center" marginTop={4}>
										<Grid item>
											<SubmitButton text="Create" />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					</CustomDialog >
					{/* edit modal */}
					<CustomDialog
						open={isEditModalOpen.open}
						onClose={() => { handleEditModalClose() }}
						title="Edit User"
						subtitle="Fields with * are required"
					>
						<form onSubmit={handleSubmitEditUser(onEditUserSubmit)}>
							{isEditModalOpen.user &&
								<Grid container justifyContent="center" sx={{ marginBottom: 3 }}>
									<Grid item container xs={8}>
										{/* name */}
										<Grid item container alignItems="center" sx={{ marginBottom: 3 }} xs={12}>
											<Grid item xs={2}>
												<ParagraphTypography width={110} height={21}>Name</ParagraphTypography>
											</Grid>
											<Grid item xs={10}>
												<StyledTextField
													{...registerEditUser("name")}
													required={true}
													placeholder="Write the name of the user here"
												/>
											</Grid>
										</Grid>
										{/* email */}
										<Grid item container alignItems="center" sx={{ marginBottom: 3 }} xs={12}>
											<Grid item xs={2}>
												<ParagraphTypography width={110} height={21}>Email</ParagraphTypography>
											</Grid>
											<Grid item xs={10}>
												<StyledTextField
													{...registerEditUser("email")}
													defaultValue={isEditModalOpen.user?.Attributes.find(attribute => attribute.Name === "email")?.Value}
													disabled={true}
												/>
											</Grid>
										</Grid>
										{/* company */}
										{userRole === "superadmin" && !isSelectedUserSuperAdmin(isEditModalOpen.user) &&
											<Grid item container alignItems="center" sx={{ marginBottom: 3 }} xs={12}>
												<Grid item xs={2}>
													<ParagraphTypography width={110} height={21}>Company</ParagraphTypography>
												</Grid>
												<Grid item xs={10}>
													<StyledTextField
														defaultValue={getCognitoUserCompany(isEditModalOpen.user)}
														disabled={true}
													/>
												</Grid>
											</Grid>
										}
										{/* role */}
										{isEditModalOpen.user?.Groups.find(group => simpleRoles.includes(group.GroupName))?.GroupName != "superadmin" &&
											<Grid item container spacing={3} alignItems="center" sx={{ marginBottom: 3 }} xs={12}>
												<Grid item xs={2}>
													<ParagraphTypography width={110} height={21}>Role</ParagraphTypography>
												</Grid>
												<Grid item xs={10}>
													<Controller
														name="role"
														control={editUserControl}
														render={({ field }) => (
															<Select
																required
																displayEmpty
																fullWidth
																sx={{
																	height: "45px",
																	width: "200px",
																	// overflow: "hidden",
																	borderRadius: "3px",
																	backgroundColor: "#f1f1f1",
																}}
																{...field}
															>
																{rolesForEdit.map((role) =>
																	<MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
																)}
															</Select>
														)}
													/>
												</Grid>
											</Grid>
										}
									</Grid>
									{/* <Grid item container spacing={3} justifyContent="flex-start" alignItems="center">
									<Grid item >
										<ParagraphTypography width={110} height={21}>Profile picture</ParagraphTypography>
									</Grid>
									<Grid item>
										<StyledDropzone
											acceptedFileFormats={{

												"image/png": [".png"],
												"image/jpeg": [".jpeg"],
												"image/jpg": [".jpg"],
												"image/svg": [".svg"],
											}}
											file={profilePicture}
											setFile={setProfilePicture}
											registerField={{
												register: registerEditUser,
												fieldName: "image"
											}} />
									</Grid>
								</Grid> */}
									<Grid item container marginTop={4} xs={12} justifyContent="center">
										<Grid item>
											<SubmitButton text="Save" disabled={!isEditUserFormDirty} />
										</Grid>
									</Grid>
								</Grid>
							}
						</form>
					</CustomDialog>

					{/* edit operator modal */}
					<CustomDialog
						open={isEditOperatorModalOpen.open}
						onClose={() => { handleEditOperatorModalClose() }}
						title="Edit operator"
						subtitle="Fields with * are required"
					>
						<form onSubmit={handleSubmitEditOperator(onEditOperatorSubmit)}>
							<Grid container justifyContent="center">
								<Grid item container xs={8}>
									<Grid item container gap={3}>
										{/* name */}
										<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
											<Grid item>
												<ParagraphTypography width={110} height={21}>Name*</ParagraphTypography>
											</Grid>
											<Grid item>
												<StyledTextField
													required={true}
													{...registerEditOperator("name")}
													placeholder="Write the name of the operator here"
												/>
											</Grid>
										</Grid>

										{/* email */}
										<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
											<Grid item>
												<ParagraphTypography width={110} height={21}>Email*</ParagraphTypography>
											</Grid>
											<Grid item>
												<StyledTextField
													{...registerEditOperator("email")}
													placeholder="Write the email of the operator here"
												/>
											</Grid>
										</Grid>

										{/* password */}
										<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
											<Grid item>
												<ParagraphTypography width={110} height={21}>Password</ParagraphTypography>
											</Grid>
											<Grid item>
												<StyledTextField
													{...registerEditOperator("password")}
													placeholder="Write the new password for the operator here"
												/>
											</Grid>
										</Grid>

										{/* picture */}
										<Grid item container spacing={3} alignContent="flex-start" alignItems="center">
											<Grid item>
												<ParagraphTypography width={110} height={21}>Profile picture</ParagraphTypography>
											</Grid>
											<Grid item>
												<StyledDropzone
													acceptedFileFormats={{
														"image/jpeg": [".jpeg"],
														"image/jpg": [".jpg"],
														"image/png": [".png"]
													}}
													file={profilePicture}
													setFile={setProfilePictureActionEditOperator}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item container justifyContent="center" marginTop={4}>
										<Grid item>
											<SubmitButton text="Save" disabled={!isEditOperatorFormDirty} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					</CustomDialog>
					{/* delete modal */}
					< StyledModal
						isOpen={isDeleteActionModalOpen.open}
						handleClose={() => { setIsDeleteActionModalOpen({ open: false }) }}
						title="Do you want to delete this User?"
						subtitle="Warning: This action cannot be undone!"
						rightButton={
							{
								name: "Yes, I'm sure!",
								action: () => handleDeleteUser()
							}
						}
						leftButton={
							{
								name: "Cancel",
								action: () => setIsDeleteActionModalOpen({ open: false })
							}
						}
					/>
				</>
			}
		</Grid >
	)
}

export default Users
