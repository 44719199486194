import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined"
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined"
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined"
import { Grid } from "@mui/material"

import { ParagraphTypography } from "../../components/customTypography"

export const ContactsLabel = () => {
	return (
		<Grid container >
			<Grid
				item
				container
				justifyContent="center"

			>
				<Grid item container alignItems="center" xs={2}>
					<EmailOutlinedIcon sx={{ marginRight: 1 }} color="primary" />
					<ParagraphTypography>info@thimus.com</ParagraphTypography>
				</Grid>
				<Grid item container alignItems="center" xs={2}>
					<LocalPhoneOutlinedIcon sx={{ marginRight: 1 }} color="primary" />
					<ParagraphTypography>+39 030 728 1576</ParagraphTypography>
				</Grid>
				<Grid item container alignItems="center" xs={2}>
					<LanguageOutlinedIcon sx={{ marginRight: 1 }} color="primary" />
					<ParagraphTypography>www.thimus.com</ParagraphTypography>
				</Grid>
			</Grid>
		</Grid>
	)
}
