import Battery1BarIcon from "@mui/icons-material/Battery1Bar"
import Battery3BarIcon from "@mui/icons-material/Battery3Bar"
import Battery5BarIcon from "@mui/icons-material/Battery5Bar"
import BatteryFullIcon from "@mui/icons-material/BatteryFull"
import BatteryUnknownIcon from "@mui/icons-material/BatteryUnknown"

interface BatteryIconProps {
    batteryValue: number | undefined
}

export function BatteryIcon(props: BatteryIconProps) {
	if (!props.batteryValue)  // undefined or 0
		return <BatteryUnknownIcon/>
	else if (props.batteryValue < 33)  // 1 -> 32
		return <Battery1BarIcon sx={{ color: "#bf2b17" }} />
	else if (props.batteryValue < 66)  // 33 -> 65
		return <Battery3BarIcon sx={{ color: "#cfa613" }} />
	else if (props.batteryValue < 96)  // 66 -> 95
		return <Battery5BarIcon color="success" />
	else  // 96 -> 100
		return <BatteryFullIcon color="success" />
}