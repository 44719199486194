import { Button } from "@mui/material"
import React from "react"

import { ButtonTypography } from "../customTypography"

interface submitButtonProps {
	text: string | React.ReactElement,
	disabled?: boolean
	onClick?: React.FormEventHandler
}

const submitButtonStyle = {
	width: "150px",
	height: "45px",
	backgroundColor: "black",
	color: "white",
	boxShadow: "none",
	cursor: "pointer",
	"&:hover": {
		backgroundColor: "#010101",
		color: "white"
	}
}
const SubmitButton = (props: submitButtonProps) => {
	return (
		<Button
			sx={submitButtonStyle}
			size="small"
			type="submit"
			variant="contained"
			disabled={props.disabled}
			onClick={props.onClick}
			style={props.disabled ? {
				opacity: "0.5",
				color: "white"
			} : undefined}
		>
			<ButtonTypography>
				{props.text}
			</ButtonTypography>
		</Button >
	)
}

export default SubmitButton