import { Chip, styled } from "@mui/material"

import { ReactComponent as FranceIcon } from "../assets/icons/languages/france.svg"
import { ReactComponent as GermanyIcon } from "../assets/icons/languages/germany.svg"
import { ReactComponent as ItalyIcon } from "../assets/icons/languages/italy.svg"
import { ReactComponent as SpainIcon } from "../assets/icons/languages/spain.svg"
import { ReactComponent as UkIcon } from "../assets/icons/languages/uk.svg"
import { language } from "../types/genericTypes"
interface props {
	language: language
	action?: (lang: language) => void
	isSelected?: boolean
	disableClick?: boolean
}

const iconStyle = {
	height: 16,
	width: 16,
	marginLeft: 6,
}
const availableIconLanguages = {
	"it": <ItalyIcon style={iconStyle} />,
	"en": <UkIcon style={iconStyle} />,
	"es": <SpainIcon style={iconStyle} />,
	"de": <GermanyIcon style={iconStyle} />,
	"fr": <FranceIcon style={iconStyle} />
}

export const LanguageButton = (props: props) => {

	const StyledChip = styled(Chip)(({ theme }) => ({
		minWidth: 7.5,
		height: 24,
		borderRadius: "5px",
		borderColor: "#3F3F3F",
		border: props.isSelected ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.text.secondary}`
	})) as typeof Chip

	return (
		<StyledChip
			variant="outlined"
			label={props.language.toUpperCase()}
			avatar={availableIconLanguages[props.language]}
			onClick={props.disableClick ? undefined : () => {
				if (props.action) {
					props.action(props.language)
				}
			}}
		/>
	)
}
