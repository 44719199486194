import {
	AddOutlined as AddOutlinedIcon,
	// FilterAltOutlined as FilterAltOutlinedIcon,
	// KeyboardArrowDownOutlined as KeyboardArrowDownOutlinedIcon
} from "@mui/icons-material"
import { CircularProgress, Grid, styled, TextField } from "@mui/material"
import { AxiosError } from "axios"
import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"

import { CustomButton } from "../../components/buttons/CustomButton"
import SubmitButton from "../../components/buttons/submitButton"
import { DefaultTextTypography, ParagraphTypography } from "../../components/customTypography"
import { StyledDropzone } from "../../components/StyledDropzone"
import { StyledModal } from "../../components/StyledModal"
import { Toolbar, ToolbarConfig } from "../../components/Toolbar"
import { companyQueries } from "../../networking/networking"
import { useSnackbarStore } from "../../stateManagement"
import { Company } from "../../types/genericTypes"
import { companiesSorter, sortByCreationDate } from "../../utils"
import CompanyCard from "./components/companyCard"


interface formInput {
	name: string,
	logo?: File,
}

interface ModalState {
	open: boolean,
	company?: Company,
}

interface CompanyDetails {
	operators: number
	projects: number
	questions: number
	tboxes: number
	testers: number
}
//--------------- STYLE --------------------------
const StyledTextField = styled(TextField)({
	height: 50,
	"& label.Mui-focused": {
		color: "c6c6c6",
	},
	"& .MuiOutlinedInput-root": {
		backgroundColor: "#F6F6F6",
		"& fieldset": {
			borderColor: "#E1E1E1",
		},
		"&:hover fieldset": {
			borderColor: "#E1E1E1",
		},
	}
}) as typeof TextField

// const uploadButtonStyle = {
// 	backgroundColor: "#F6F6F6",
// 	color: "#010101",
// 	width: 154,
// 	height: 45,
// 	borderRadius: 2
// }
//-----------------------------------

const Companies = () => {
	const [isEditActionModalOpen, setIsEditActionModalOpen] = useState<ModalState>({ open: false })
	const [isDeleteActionModalOpen, setIsDeleteActionModalOpen] = useState<ModalState>({ open: false })
	const [deleteCompanyButtonText, setDeleteCompanyButtonText] = useState("Yes, I'm sure!")
	const [isNewCompanyModalOpen, setIsNewCompanyModalOpen] = useState(false)
	const [logoPicture, setLogoPicture] = useState<File[]>([])
	const [companyDetails, setCompanyDetails] = useState<CompanyDetails>()

	const { openSnackbar } = useSnackbarStore((state) => ({
		openSnackbar: state.openSnackbar
	}))
	const { data: companies = [], refetch: refetchCompanies } = useQuery(
		companyQueries.listCompanies.name,
		companyQueries.listCompanies.fn,
		{
			select: data => (data.sort(companiesSorter))
		}
	)
	const createCompanyMutation = useMutation(
		companyQueries.createCompany.name,
		companyQueries.createCompany.fn,
		{
			onSuccess: () => {
				refetchCompanies()
				setIsNewCompanyModalOpen(false)
				resetNewCompany()
				openSnackbar("success", "Company successfully created")
			},
			onError: () => {
				openSnackbar("error", "An error occurred while creating the company")
			}
		}
	)
	const updateCompanyMutation = useMutation(
		companyQueries.updateCompany.name,
		companyQueries.updateCompany.fn,
		{
			onSuccess: () => {
				refetchCompanies()
				setIsEditActionModalOpen({ open: false })
				openSnackbar("success", "Company successfully edited")
			},
			onError: (error: { response: { status: number, data: string } }) => {
				if (error.response.status === 400) {
					openSnackbar("error", error.response.data)
				} else
					openSnackbar("error", "An error occurred while editing the company")
			}
		}
	)
	const deleteCompanyMutation = useMutation(
		[companyQueries.deleteCompany.name, false],
		(company: number) => companyQueries.deleteCompany.fn(company, false),
		{
			onSuccess: () => {
				refetchCompanies()
				setIsDeleteActionModalOpen({ open: false })
				openSnackbar("success", "Company successfully deleted")
			},
			onError: (error: AxiosError<{
				details: {
					operators: number
					projects: number
					questions: number
					tboxes: number
					testers: number
				},
				message: string
			}>) => {
				if (error.response?.status === 400) {
					setCompanyDetails({
						operators: error.response.data.details.operators,
						projects: error.response.data.details.projects,
						questions: error.response.data.details.questions,
						tboxes: error.response.data.details.tboxes,
						testers: error.response.data.details.testers
					})
					setDeleteCompanyButtonText("Delete anyway")
				} else
					openSnackbar("error", "An error occurred while deleting the company")
			}
		}
	)
	const forceDeleteCompanyMutation = useMutation(
		[companyQueries.deleteCompany.name, true],
		(company: number) => companyQueries.deleteCompany.fn(company, true),
		{
			onSuccess: () => {
				refetchCompanies()
				setIsDeleteActionModalOpen({ open: false })
				setCompanyDetails(undefined)
				openSnackbar("success", "Company successfully deleted")
			},
			onError: (error: AxiosError<{
				message: string
			}>) => {
				if (error.response?.status === 400) {
					openSnackbar("error", error.response.data.message)
				} else
					openSnackbar("error", "An error occurred while deleting the company")
			}
		}
	)

	const {
		register: registerNewCompany,
		handleSubmit: handleSubmitNewCompany,
		reset: resetNewCompany,
		setValue: setValueNewCompany,
	} = useForm<formInput>()
	const onNewCompanySubmit: SubmitHandler<formInput> = data => {
		createCompanyMutation.mutate({
			name: data.name,
			logo: data.logo // TODO: implement me
		})
	}

	const {
		register: registerEditCompany,
		handleSubmit: handleSubmitEditCompany,
		reset: resetEditCompany,
		setValue: setValueEditCompany,
		formState: { isDirty: isEditCompanyFormDirty }
	} = useForm<formInput>({
		defaultValues: {
			name: "",
			logo: undefined,
		}
	})

	const onCompanyEditSubmit: SubmitHandler<formInput> = data => {
		if (isEditActionModalOpen.company) {
			console.log(data)
			updateCompanyMutation.mutate({
				id: isEditActionModalOpen.company.id,
				company: {
					name: data.name,
					logo: logoPicture.length > 0 ? logoPicture[0] : null
				}
			})
		}
	}

	//----- configuration for toolbar -------
	const toolbarConfig: ToolbarConfig = {
		title: "Companies",
		subtitle: "Manage the Companies from here",
		buttons: [
			{
				name: "New Company",
				icon: <AddOutlinedIcon />,
				action: () => {
					resetNewCompany()
					setLogoPicture([])
					setIsNewCompanyModalOpen(true)
				}
			},
			// {
			// 	name: "Sort by",
			// 	icon: <KeyboardArrowDownOutlinedIcon />,
			// 	action: () => console.log("button3")
			// },
			// {
			// 	name: "Filters",
			// 	icon: <FilterAltOutlinedIcon />,
			// 	action: () => console.log("button4")
			// }
		]
	}
	//--------------------------

	const handleDeleteCompany = () => {
		if (isDeleteActionModalOpen.company) {
			if (companyDetails === undefined) {
				deleteCompanyMutation.mutate(isDeleteActionModalOpen.company.id)
			} else {
				forceDeleteCompanyMutation.mutate(isDeleteActionModalOpen.company.id)
			}
		}
	}

	const handleEditActionModalClose = () => {
		setIsEditActionModalOpen({ open: false })
		setLogoPicture([])
	}
	const handleCreateActionModalClose = () => {
		setIsNewCompanyModalOpen(false)
		setLogoPicture([])
	}

	const setLogoActionNewCompany = (files: File[]) => {
		setLogoPicture(files)
		setValueNewCompany("logo", files[0], { shouldDirty: true })
	}

	const setLogoActionEditCompany = (files: File[]) => {
		setLogoPicture(files)
		setValueEditCompany("logo", files[0], { shouldDirty: true })
	}

	return (
		<Grid container padding={3}>
			<Toolbar config={toolbarConfig} />
			<Grid container spacing={3}>
				{sortByCreationDate(companies).map((company) => (
					<Grid item key={company.id}>
						<CompanyCard
							company={company}
							onEdit={() => {
								resetEditCompany({
									name: company.name
								})
								setIsEditActionModalOpen({
									open: true,
									company
								})
								if (company.logo) {
									setLogoPicture([new File([], company.logo.filename)])
								}
							}}
							onDelete={() => {
								setIsDeleteActionModalOpen({
									open: true,
									company
								})
							}}
						/>
					</Grid>
				))}
			</Grid>
			{/*  creation modal */}
			{/* TODO: spostare questo form in un componente separato */}
			<StyledModal
				isOpen={isNewCompanyModalOpen}
				handleClose={() => handleCreateActionModalClose()}
				title="New Company"
				subtitle="Fields with * are required"
			>
				<form onSubmit={handleSubmitNewCompany(onNewCompanySubmit)}>
					<Grid container direction="column" spacing={3} marginBottom={3}>
						<Grid item container spacing={3} alignItems="center">
							<Grid item xs={3}>
								<ParagraphTypography>Name*</ParagraphTypography>
							</Grid>
							<Grid item xs={9}>
								<StyledTextField
									{...registerNewCompany("name")}
									fullWidth
									required
									placeholder="Write the name of the Company here"
								/>
							</Grid>
						</Grid>
						<Grid item container spacing={3} alignItems="center">
							<Grid item xs={3}>
								<ParagraphTypography>Upload Logo</ParagraphTypography>
							</Grid>
							<Grid item xs={9}>
								<StyledDropzone
									acceptedFileFormats={{
										"image/jpeg": [".jpeg"],
										"image/jpg": [".jpg"],
										"image/png": [".png"]
									}}
									file={logoPicture}
									setFile={setLogoActionNewCompany}
								/>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							alignSelf="center"
						>
							{createCompanyMutation.isLoading ?
								<CustomButton
									text=""
									disabled
									icon={
										<Grid container justifyContent="center" alignItems="center">
											<CircularProgress
												size={24}
											/>
										</Grid>
									}
								/> : <SubmitButton text="Create" />
							}
						</Grid>
					</Grid>
				</form>
			</StyledModal>

			{/* edit modal */}
			{/* TODO: spostare questo form in un componente separato */}
			<StyledModal
				isOpen={isEditActionModalOpen.open}
				handleClose={() => { handleEditActionModalClose() }}
				title="Edit Company"
			>
				<form onSubmit={handleSubmitEditCompany(onCompanyEditSubmit)}>
					<Grid container direction="column" spacing={3} marginBottom={3}>
						<Grid item container spacing={3} alignItems="center">
							<Grid item xs={3}>
								<ParagraphTypography>Name*</ParagraphTypography>
							</Grid>
							<Grid item xs={9}>
								<StyledTextField
									{...registerEditCompany("name")}
									fullWidth
									required
									placeholder="Write the name of the Company here"
								/>
							</Grid>
						</Grid>
						<Grid item container spacing={3} alignItems="center">
							<Grid item xs={3}>
								<ParagraphTypography>Upload Logo</ParagraphTypography>
							</Grid>
							<Grid item xs={9}>
								<StyledDropzone
									acceptedFileFormats={{
										"image/jpeg": [".jpeg"],
										"image/jpg": [".jpg"],
										"image/png": [".png"]
									}}
									file={logoPicture}
									setFile={setLogoActionEditCompany}
								/>
							</Grid>
						</Grid>
						<Grid item alignSelf="center" xs={12}>
							{updateCompanyMutation.isLoading ?
								<CustomButton
									text=""
									disabled
									icon={<CircularProgress size={24} />}
								/> : <SubmitButton text="Save" disabled={!isEditCompanyFormDirty} />
							}
						</Grid>
					</Grid>
				</form>
			</StyledModal>

			{/* delete modal */}
			< StyledModal
				isOpen={isDeleteActionModalOpen.open}
				handleClose={() => {
					setIsDeleteActionModalOpen({ open: false })
					setCompanyDetails(undefined)
				}}
				title="Do you want to delete this Company?"
				subtitle="Warning: This action cannot be undone!"
				rightButton={
					{
						name: deleteCompanyButtonText,
						action: () => handleDeleteCompany()
					}
				}
				leftButton={
					{
						name: "Cancel",
						action: () => {
							setIsDeleteActionModalOpen({ open: false })
							setCompanyDetails(undefined)
						}
					}
				}
			>
				{companyDetails !== undefined &&
					<Grid container direction="column" alignContent="center" alignItems="center" marginY={2}>
						<Grid item>
							<DefaultTextTypography>This company has the following associated elements:</DefaultTextTypography>
						</Grid>
						<Grid item>
							<ul>
								{companyDetails.operators !== 0 &&
									<li>{`Operators: ${companyDetails.operators}`}</li>
								}
								{companyDetails.projects !== 0 &&
									<li>{`Projects: ${companyDetails.projects}`}</li>
								}
								{companyDetails.questions !== 0 &&
									<li>{`Questions: ${companyDetails.questions}`}</li>
								}
								{companyDetails.tboxes !== 0 &&
									<li>{`Tboxes: ${companyDetails.tboxes}`}</li>
								}
								{companyDetails.testers !== 0 &&
									<li>{`Testers: ${companyDetails.testers}`}</li>
								}
							</ul>
						</Grid>
					</Grid>
				}

			</StyledModal>
		</Grid >
	)
}

export default Companies