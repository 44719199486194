import { Grid } from "@mui/material"
import { ReactElement } from "react"

import { H1Typography, ParagraphTypography, XLTypography } from "../../../components/customTypography"
import { Project } from "../../../types/genericTypes"

interface introProps {
	product: string | undefined
	language: Project["language"]
	testerMode?: boolean
	localTesterID: string
}

type TextType = {
	[key in Project["language"]]: {
		title: ReactElement
		p: string[]
	}
}


export const TesterSurveyIntro = (props: introProps) => {
	const welcomeText: TextType = {
		en: {
			title: <XLTypography fontSize={50}>{`Welcome, ${props.localTesterID}`}</XLTypography>,
			p: [
				"You will see all the instructions related to the test on the screen. Please read them carefully and click the play button when you are ready to perform the required action.",
				"The duration is preset by the program, so you are required to perform the action until the new set of instructions appears on the screen.",
				"During the test, we ask that you minimize any movements, including speaking.",
				"The operator is available for any assistance you might need.",
				"Enjoy!",
			]
		},
		de: {
			title: <XLTypography fontSize={50}>{`Willkommen Prüfer, ${props.localTesterID}`}</XLTypography>,
			p: [
				"Auf dem Bildschirm sehen Sie alle Anweisungen für den Test. Bitte lesen Sie diese sorgfältig durch und klicken Sie auf die Schaltfläche \"Play\", wenn Sie bereit sind, die gewünschte Aktion durchzuführen.",
				"Die Dauer ist vom Programm vorgegeben, d. h. Sie müssen die Aktion so lange durchführen, bis die neuen Anweisungen auf dem Bildschirm erscheinen.",
				"Während des Tests bitten wir Sie, alle Bewegungen zu minimieren, auch das Sprechen.",
				"Der Bediener steht Ihnen zur Verfügung, wenn Sie Hilfe benötigen.",
				"Viel Spaß!"
			],
		},
		es: {
			title: <XLTypography fontSize={50}>{`¡Bienvenido, ${props.localTesterID}!`}</XLTypography>,
			p: [
				"En la pantalla podrá seguir las instrucciones para llevar a cabo la prueba. Por favor lea con atención cada una de ellas, y cuando esté listo para iniciar, presione el botón PLAY.",
				"Cada fase ha sido programada con una duración especifica, por lo que deberá llevar a cabo cada acción hasta que la siguiente indicación aparezca en su pantalla.",
				"Durante la prueba, le pedimos mantenerse quieto y no hablar. ",
				"El operador estará disponible para asistirlo en cualquier momento."
			],
		},
		fr: {
			title: <XLTypography fontSize={50}>{`Bienvenu, ${props.localTesterID}!`}</XLTypography>,
			p: [
				"Vous trouverez toutes les instructions liées au test sur l'écran. Veuillez les lire attentivement et cliquez sur le bouton PLAY lorsque vous êtes prêt à effectuer l'action requise.",
				"La durée est prédéfinie par le programme, vous devez donc effectuer l'action jusqu'à ce que la nouvelle instruction apparaisse à l'écran.",
				"Pendant le test, nous vous demandons de minimiser tout mouvement, et d’éviter de parler. L'opérateur est disponible pour toute assistance dont vous pourriez avoir besoin.",
				"Bonne continuation!"
			],
		},
		it: {
			title: <XLTypography fontSize={50}>{`Benvenuto tester, ${props.localTesterID}`}</XLTypography>,
			p: [
				"Sullo schermo vengono visualizzate tutte le istruzioni relative al test. Leggile attentamente e fai clic sul pulsante play quando sei pronto a eseguire l'azione richiesta.",
				"La durata è preimpostata dal programma, per cui è necessario eseguire l'azione fino a quando sullo schermo non compare una nuova serie di istruzioni.",
				"Durante il test, ti chiediamo di ridurre al minimo qualsiasi movimento, compreso parlare.",
				"L'operatore è a disposizione per qualsiasi assistenza."
			],
		},
	}
	return (
		<>
			<Grid container justifyContent="center" spacing={2}>
				<Grid item marginY={3}>
					<H1Typography textAlign="center">{props.testerMode ? welcomeText[props.language].title : "Welcome to the Thimus tester area"}</H1Typography>
				</Grid>
				<Grid
					container
					marginX="10%"
					sx={{
						border: "1px solid #CCCCCC",
						borderStyle: "dashed"
					}}
				/>
			</Grid>
			<Grid item container direction="column" justifyContent="center" marginY={3} gap={4}>
				{!props.product ?
					<Grid item>
						{props.testerMode ?
							welcomeText[props.language].p.map(p =>
								<ParagraphTypography
									sx={{
										fontSize: "18px",
									}}
									key={p}
									textAlign="center"
									paddingX={2}
									paddingY={1}
								>{p}</ParagraphTypography>
							) :
							<ParagraphTypography textAlign="center">Please, follow the operator&apos;s instructions.</ParagraphTypography>
						}
					</Grid>
					:
					<>
						<Grid item>
							<ParagraphTypography textAlign="center">You’re about to complete a quick survey about this product:</ParagraphTypography>
						</Grid>
						<Grid item>
							<H1Typography textAlign="center" color="#D8702E">{props.product}</H1Typography>
						</Grid>
					</>
				}
			</Grid>
		</>
	)
}
