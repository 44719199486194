import { SvgIcon, SvgIconProps } from "@mui/material"

const imageIcon = {
	overflow: "visible"
}
export function OtherPhaseIcon(props: SvgIconProps) {
	return (
		<SvgIcon
			{...props}
			sx={{
				...props.sx,
				...imageIcon
			}}
		>
			<path d="M16 5.20001C10.0448 5.20001 5.19995 10.0448 5.19995 16C5.19995 21.9552 10.0448 26.8 16 26.8C21.9552 26.8 26.8 21.9552 26.8 16C26.8 10.0448 21.9552 5.20001 16 5.20001ZM16 25.6C10.7066 25.6 6.39995 21.2934 6.39995 16C6.39995 10.7066 10.7066 6.40001 16 6.40001C21.2934 6.40001 25.6 10.7066 25.6 16C25.6 21.2934 21.2934 25.6 16 25.6Z" />
			<path d="M19.6 15.4H16.6V12.4C16.6 12.0686 16.3314 11.8 16 11.8C15.6686 11.8 15.4 12.0686 15.4 12.4V15.4H12.4C12.0686 15.4 11.8 15.6686 11.8 16C11.8 16.3314 12.0686 16.6 12.4 16.6H15.4V19.6C15.4 19.9314 15.6686 20.2 16 20.2C16.3314 20.2 16.6 19.9314 16.6 19.6V16.6H19.6C19.9314 16.6 20.2 16.3314 20.2 16C20.2 15.6686 19.9314 15.4 19.6 15.4Z" />
		</SvgIcon>
	)
}
